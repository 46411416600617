import React from "react";
import "./Testimonials.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Grid, Card, CardContent } from "@mui/material";
import profileImg from "./testimonial profile.png";
import comma from "./inverted comma.png"
import img from "./image 99088.png"


export default function Testimonials() {
  const data = [
    {
      title: "Affordable service in the market",
      body: "Great customer centricity and affordable. Best part is home pickup and delivery facility. All the best to the wonderful tea.",
      profile: "testimonial profile.png",
      name: "Santosh Singh",
      vehicle: "Suzuki Access",
    },
    {
      title: "Good at finding the issue root cause and fast service",
      body: "There was some noise in the fan ofthe car. The Suzuki showroom guys told me to change the car fan which costed around Rs.5000 to Rs.6000. But,Gativan mechanic inspected the issue and tightened the part which had play and solved the noise issue. Also,I had given my car for servicing at 2pm and got it back at 7pm. It was really a good experience with Gativan.",
      profile: "testimonial profile.png",
      name: "Nagesh Fadnavis",
      vehicle: "Maruti Suzuki Swift ",
    },
    {
      title: "Less of a hassle and a good customized service",
      body: "All I needed to do was book the appointment and handover the vehicle, on request the replaced parts were promptly delivered also got a call from service person regarding comfirmation on change of parts and if I had any specific preference for the same.",
      profile: "testimonial profile.png",
      name: "Prasad Kadam",
      vehicle: "Yamaha FZ",
    },
    {
      title: "Bike running smoother than before",
      body: "Took a test ride and running smoothly after servicing. I am happy with the servicing.",
      profile: "testimonial profile.png",
      name: "Sumeet Jajodia",
      vehicle: "Honda Activa",
    },
    {
      title: "Happy with Gativan service",
      body: "I took a test ride, all working fine now. I am happy with the servicing.",
      profile: "testimonial profile.png",
      name: "Santosh Singh",
      vehicle: "Suzuki Access",
    },
    {
      title: "Gativan mechanic found root cause of my car issue, happy",
      body: "Servicing was good. My car is now working fine and I have not faced issue yet. I was a bit doubtful that garages usually replace the battery directly in such cases but you did not do that just for increasing the cost. I am happy with that.",
      profile: "testimonial profile.png",
      name: "Pawan Joshi",
      vehicle: "Honda Jazz",
    },
    {
      title: "Best vehicle servicing in market",
      body: "Well servicing done.",
      profile: "testimonial profile.png",
      name: "Ravishankar",
      vehicle: "Hyundai Grand i10",
    },
    {
      title: "Doorstep service makes everything hassle-free!",
      body: "Very good servicing experience. I would recommend everyone else to do servicing through gativan, There was also door step pick up and drop which saved me a lot of hassle.",
      profile: "testimonial profile.png",
      name: "Sushant Alange",
      vehicle: "Honda Dream Yuga",
    },
    {
      title: "Best service transparency out there",
      body: "I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
      profile: "testimonial profile.png",
      name: "Yash Sharma",
      vehicle: "Honda Dio",
    },
  ];

  const testimonialData = [
    
    {
      id: 3,
      img:"/Assets/testimonials/tushar-more.jpeg",
      date: "October 18, 2021",
      title: "Had really great experience with gativan",
      desc: "It was great experience. Really liked their service and will recommend to everyone.",
      name: "Tushar More"
    },
    {
      id: 1,
      img:"/Assets/testimonials/volkswagen-ashutosh.jpeg",
      date: "February 1, 2023",
      title: "Highly recommended and must visit or call once",
      desc: "I have a Volkswagen Ameo. Authorized Service centre was trapping me in part replacements for negligible issue. Gativan suggested to avoid any type of replacement. These types of true suggestions are rarely given in current world scenarios. Looking forward for services in future for any car related issues. Highly recommended and must visit or call once.",
      name: "Amin"
    },
    {
      id: 2,
      img:"/Assets/testimonials/benelli.jpeg",
      date: "April 5, 2023",
      title: "Great difference in performance after servicing",
      desc: "Nice service Pick and drop is fast Great difference in performance after servicing.",
      name: "Jitendra S"
    },
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 180, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 120, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };
  return (
    <>
      <Box container className="testimonial-container">
        <Box container className="homepage-headers">
          <h2>What our customer say about us</h2>
        </Box>
        <Carousel
          // infinite
          partialVisible={true}
          arrows
          responsive={responsive}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="container"
          className="Carousel testimonial-carousel"
          additionalTransfrom={0}
          dotListClass=""
          draggable
          focusOnSelect={false}
          renderDotsOutside
          slidesToSlide={1}
          swipeable
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          showDots
          sx={{ margin: "0" }}
        >
          {
            testimonialData?.map((item) => {
              const {id,img, date, title, desc, name} = item;
              return (
                <Box className="testimonial-card-container" key={id}>
                  <Box container className="testimonial-card-image">
                    <img src={img} />
                  </Box>
                  <Box className="testimonial-card-review">
                    <Box>
                      <p className="testimonial-review-date">{date}</p>
                    </Box>
                    <Box>
                      <h2 className="testimonial-review-heading">{title}</h2>
                    </Box>
                    <Box>
                      <p className="testimonial-review-desc">
                        {desc}
                      </p>
                    </Box>
                    <Box className="testimonial-customer-name-container">
                      <p>- {name}</p>
                    </Box>
                  </Box>
                </Box>
              )
            })
          }

        </Carousel>
      </Box>
    </>
  );
}
