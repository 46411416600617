const initialState = {
     timeSlot: '',
     date: '',
     day: '',
     address: {
          houseNo: '',
          area: '',
          city: '',
          pinCode: ' ',
          landMark: ''
     }

}

export const CustomerDetails_Reducer = (state = initialState, action) => {

     switch (action.type) {
          case 'ADD_CUSTOMERDETAILS':
               return state = action.payload

          default:
               return state
     }
}

const mobile = {
     mobileNumber: ''
}
export const MobileNumberReducer = (state = mobile, action) => {

     switch (action.type) {
          case 'MobileNumber':
               return state = action.payload;
          default:
               return state
     }
}

export const ChangeVehicleComponent = (state = false, action) => {
     switch (action.type) {
          case 'triggerChangeVehocle':
               return state = action.payload;

          default:
               return state;
     }
}