import React, { useState, useEffect, useContext, useMemo } from "react";
import { Card, Grid, Box, Typography, Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./Service.Card.css";
import { AppContext } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import {
  StoreCartTotal,
  addToCart,
  handleChangeVehicelComponent,
  handleOpenModalState,
} from "../../../action";
import httpCommon from "../../../http-common";

import ReactGA from "react-ga4";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate, useParams } from "react-router-dom";
import {
  getServiceActivities,
  getServiceDetails,
} from "../../../services/vehicleservices/vehicleservices";

import axios from "axios";

export default function ServiceCard({
  serviceData,
  vehicleSelectedData,
  cartModal,
}) {
  const [showlist, setShowlist] = useState([]);
  const [showDetailsList, setShowDetailsList] = useState([]);
  const [viewButton, setviewButton] = useState("View More");
  // const [addToCartBtn, setAddtoCartBtn] = useState('Add Service')
  const [serviceDetails, setServiceDetails] = useState([]);
  const [serviceActivities, setServiceActivities] = useState([]);

  const { Modalopen, setModalOpen } = cartModal;

  const URL = httpCommon.defaults.baseURL;

  const navigation = useNavigate();

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();

  let addToCartBtn = "+ Add service";

  let findData = "";

  const dispatch = useDispatch();
  const contextData = useContext(AppContext);
  const {
    setIsCheckOut,
    setcheckOutButton,
    customerPickData,
    setCustomePickData,
  } = contextData;

  const {
    serviceId,
    service,
    displayPrice,
    actualPrice,
    addServiceToCart,
    subCategoryId,
    detailsList,
    activities,
    imagePath,
  } = serviceData;

  // if(serviceData.addServiceToCart === true){
  //      setAddtoCartBtn("Added")
  // }

  const windowWidth = window.innerWidth;

  const getServiceDetailsData = async (serviceId) => {
    const response = await getServiceDetails(serviceId);
    const data = await response.data;
    if (data.statusCode === 200) {
      setServiceDetails(data.result);
    }
  };

  const getServiceActivitiesData = async (serviceId) => {
    const response = await getServiceActivities(serviceId);
    const data = await response.data;
    if (data.statusCode === 200) {
      setServiceActivities(data.result);
    }
  };

  // ---------------------------- getting browerId from redux --------------------
  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  // --------------------------- getting mobile number from redux ---------------------
  const getMobileNumberRedux = useSelector(
    (state) => state.MobileNumberReducer
  );

  //---------------------------------------------- getting Browser Id ----------------------

  const SaveDataWBrowser = async (visitorId, serviceData) => {
    const cartItems = {
      serviceId: serviceData?.serviceId,
      categoryId: serviceData?.serviceCategoryId,
      subCategoryId: serviceData?.serviceSubCategoryId,
    };
    const userDataObj = {
      browserId: visitorId,
      vehicleType: vehicleSelectedData?.vehicleType?.id,
      brandId: vehicleSelectedData?.brand?.id,
      modelId: vehicleSelectedData?.model?.id,
      variantId: vehicleSelectedData?.variant?.id,
      mobileNumber: getMobileNumberRedux?.mobileNumber,
      cartItems: [cartItems],
    };
    const saveCart = await axios.post(`${URL}/cart`, userDataObj);
    const result = await saveCart.data;
  };

  const ViewMorehandle = () => {
    if (viewButton === "View More") {
      setShowlist(activities);
      setShowDetailsList(detailsList);
      setviewButton("View Less");
    } else {
      setShowlist(activities.slice(0, 6));
      setShowDetailsList(detailsList.slice(0, 2));
      setviewButton("View More");
    }
  };

  const ServiseReduxData = useSelector((state) => state.AddToCartReducer);
  const CartTotalReducer = useSelector((state) => state.CartTotalReducer);

  function handleAddServiceToCart(serviceData) {
    const found = ServiseReduxData.result.some(
      (e) => e.serviceId === serviceData.serviceId
    );

    if (!found) {
      serviceData.addServiceToCart = true;
      dispatch(addToCart(serviceData, customerPickData));
     //  dispatchCartTotals(serviceData);
      SaveDataWBrowser(getBrowserIdRedux && getBrowserIdRedux, serviceData);
    }
  }

  const dispatchCartTotals = (service) => {
    const subTotal = CartTotalReducer.subTotal + service?.displayPrice;
    const cartItems = CartTotalReducer.cartItems + 1;
    const cartTotal = CartTotalReducer.cartTotal + service?.displayPrice;
    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        subTotal: subTotal,
        cartItems: cartItems,
        cartTotal: cartTotal,
      })
    );
  };

  const handleAddtoCart = (serviceData) => {
    // dispatch(addToCart(serviceData))

    handleAddServiceToCart(serviceData);

    ReactGA.event({
      category: "Add to Cart",
      action: "Clicked on Add to Cart",
      label: "Add to Cart",
    });

    const MobileView = window.innerWidth;
    if (MobileView <= 768) {
      // setIsCheckOut(false)
      setcheckOutButton("View Cart");
    }
  };

  const handleSelectVehilce = () => {
    ReactGA.event({
      category: "Select Vehicle",
      action: "Clicked on Select Vehicle from Vehicle Service Items Page",
      label: "Select Vehicle",
    });

    if (windowWidth <= "768") {
      dispatch(handleChangeVehicelComponent(true));
      dispatch(handleOpenModalState(true));
    }
  };

  const reduxCartData = useSelector((state) => state.AddToCartReducer);

  useEffect(() => {
    setShowlist([]);
    setShowDetailsList([]);
    if (windowWidth <= 768) {
      if (detailsList.length == 0) {
        if (activities.length != 0) {
          setShowlist(activities.slice(0, 4));
          if (document.getElementById("serviceCard-ServiceActivities")) {
            document.getElementById(
              "serviceCard-ServiceActivities"
            ).style.display = "block";
          }
        }
      } else if (detailsList.length != 0) {
        if (document.getElementById("serviceCard-commonServices")) {
          document.getElementById("serviceCard-commonServices").style.display =
            "block";
        }

        setShowDetailsList(detailsList);
        if (showlist.length != 0) {
          if (document.getElementById("serviceCard-ServiceActivities")) {
            document.getElementById(
              "serviceCard-ServiceActivities"
            ).style.display = "none";
          }
        }
      }
    } else if (windowWidth > 768) {
      setShowlist(activities.slice(0, 6));
      setShowDetailsList(detailsList.slice(0, 2));
    }
  }, [serviceData]);

  useEffect(() => {
    findData = reduxCartData.result.filter((e) => {
      if (e.service === service) {
        addToCartBtn = "Added";
      }
    });
  }, [reduxCartData]);

  const getSelectedVehicle = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  const handleViewDetials = () => {
    ReactGA.event({
      category: "View Details",
      action: "Clicked on View Details",
      label: "View Details",
    });
    if (windowWidth <= 768) {
      navigation(   vehicleSelectedData?.brand?.brandName != ""  ?  `${service?.replaceAll(" ","-").toLowerCase()}/${vehicleSelectedData?.brand?.brandName?.replaceAll(" ", "-").toLowerCase()}/${vehicleSelectedData?.model?.modelName?.replaceAll(" ", "-").toLowerCase()}/${vehicleSelectedData?.variant?.variantName?.replaceAll(" ", "-").toLowerCase()}`  : `${service?.replaceAll(" ","-").toLowerCase()}`, {
        state: {
          serviceId: serviceId,
          serviceCategoryType: serviceCategoryType,
        },
      });
    }
  };

  addServiceToCart
    ? (addToCartBtn = "Added")
    : (addToCartBtn = "+ Add Service");

  return (
    <>
      {displayPrice !== 0 && (
        <Box className="service-card-conatiner" id="service-card-conatiner">
          <Box className="serviceCard-topSection">
            <Box className="serviceCard-image">
              <img src={`${URL}${imagePath}`} alt="" />
            </Box>
            <Box className="serviceCard-serviceNameandLists">
              <Typography className="service-card-serviceName">
                {service}
              </Typography>

              <Box
                className="serviceCard-commonServices"
                id="serviceCard-commonServices"
                // style={{ display: showlist ? "block" : "none"}}
              >
                {showDetailsList.map((item) => {
                  const { id, detail, serviceId, activity } = item;
                  return (
                    <Box key={id} className="details-container">
                      <p>
                        {" "}
                        <FiberManualRecordIcon
                          className="commonMenu-circleIcon"
                          fontSize="1"
                        />{" "}
                        <CheckCircleIcon className="commonMenu-checkIcon" />
                        {detail}
                      </p>
                    </Box>
                  );
                })}
              </Box>

              <Box
                container
                className="serviceCard-ServiceActivities"
                id="serviceCard-ServiceActivities"
                // sx={{ display: windowWidth <= 768 ? (detailsList.length == 0 ? "block" : "none") : "block" }}
              >
                {showlist &&
                  showlist.map((ele) => {
                    const { id, activity, serviceId } = ele;
                    return (
                      <Box key={id}>
                        <p className="serviceActivities-list">
                          {" "}
                          <CheckCircleIcon className="serviceActivities-list-checkicon" />
                          {activity}{" "}
                        </p>
                      </Box>
                    );
                  })}
              </Box>
              <Box container>
                {showlist.length > 4 ? (
                  // <Button onClick={()=>ViewMorehandle()} className='serviceCard-moreButton'>{viewButton}</Button>
                  <Typography
                    variant="h6"
                    onClick={() => {
                      ViewMorehandle();
                    }}
                    className="serviceCard-moreButton"
                  >
                    {viewButton == "View Less" ? (
                      <RemoveIcon className="add-remove-icon" />
                    ) : (
                      <AddIcon className="add-remove-icon" />
                    )}{" "}
                    {viewButton}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
          {/* -------price and buttons------------------ */}

          {variant ? (
            <Box className="serviceCard-bottumSection">
              <Box>
                <Box className="serviceCard-pricesSection">
                  <Typography className="serviceCard-displayPrice">
                    <CurrencyRupeeIcon className="displayPrice-Rupee-icon" />
                    {actualPrice}
                  </Typography>
                  <Typography className="serviceCard-actualPrice">
                    {" "}
                    <CurrencyRupeeIcon className="actualPrice-Rupee-icon" />
                    {displayPrice}
                  </Typography>
                </Box>
                {/* <Box>
                                   <Box className='serviceCard-pricesSection'>
                                        <Typography className='serviceCard-actualPrice'>Service Starting @ <CurrencyRupeeIcon className='actualPrice-Rupee-icon' />{getSelectedVehicle.vehicleType.vehicleName === "Car"?"2500":"795"}</Typography>
                                   </Box>
                                   <p>Call to confirm rate for your {getSelectedVehicle.vehicleType.vehicleName} <Box className='price-section-call-btn' ><a href="tel:+918263090692"  ><PhoneIcon className='price-section-call-icon'/><p>8263090692</p></a></Box> </p>
                              </Box> */}
              </Box>
              <Box className="serviceCard-buttons">
                {/* {showlist.length > 4 ?
                                        // <Button onClick={()=>ViewMorehandle()} className='serviceCard-moreButton'>{viewButton}</Button>
                                        <Typography variant='h6' onClick={()=>{ViewMorehandle()}} className='serviceCard-moreButton'>{viewButton}</Typography>
                                        : ''
                                   } */}
                <Button
                  variant="outlined"
                  onClick={() => handleViewDetials()}
                  className="serviceCard-viewDetailsButton"
                >
                  + View More
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleAddtoCart(serviceData)}
                  style={{
                    backgroundColor:
                      windowWidth <= "768"
                        ? addToCartBtn == "Added"
                          ? "#122164"
                          : "#7B8BD4"
                        : addToCartBtn == "Added"
                        ? "#122164"
                        : "#FFFFFF",
                    color:
                      windowWidth <= "768"
                        ? "#FFFFFF"
                        : addToCartBtn == "Added"
                        ? "#FFFFFF"
                        : "#122164",
                  }}
                  className="serviceCard-addtoacartButton"
                >
                  {addToCartBtn}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className="select-vehicle-btn-container">
              <Box></Box>
              <Box
                className="select-vehicle-btn-container"
                onClick={handleSelectVehilce}
              >
                <Button variant="outlined" className="select-vehicle-btn">
                  Select Vehicle
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
