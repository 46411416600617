import React, { useEffect, useState } from "react";
import "./RoadSideAssistance.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DownloadApp from "../ReuseComponent/downloadApp/DownloadApp";
import ServicePriceListTable from "../HomePage/ServicePriceListTable";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import FAQ_home from "../ReuseComponent/FAQ/FAQ_home";
import Testimonials from "../ReuseComponent/testimonials/Testimonials";
import Card from "@mui/material/Card";


export default function RoadSideAssistance() {
  const [show, setShow] = useState([]);
  const [colorindexform, setcolorindexform] = useState(0);
  useEffect(() => {
    setShow(VSPrice_Data_Car);
  }, [setShow]);

  //---------FAQ Data-----------------
  const FAQData = [
    {
      title: "How can I book my vehicle service on Gativan?",
      body: "Book your service within a minute. Dive in to gativan.in.Select your vehicle type (bike/car),select the brand (e.g. Honda) followed by the vehicle (e.g. Amaze). Enter your phone number and tap on Check price to book your slots! Select your convenient time slots and book it! We offer free pick up and delivery all over Pune, so you don’t miss out the best moments with your dear ones! ",
    },
    {
      title: "How much do you charge for Periodic Servicing?",
      body: " Gativan’s Periodic Servicing starts at Rs. 795 for bikes and Rs. 2399 for cars.",
    },
    {
      title: "Why should I choose Gativan in Pune?",
      body: "Gativan offers best bike/car services with best prices in Pune. Not only you get a quality service from our trained mechanics, but also avail transparency throughout.",
    },

    {
      title: "Can I choose a Gativan Service Centre of my choice in the city?",
      body: "Yes, definitely! Rest assured, all our service stations are trained for quality servicing. We will assign you the near most service centre based on your location.",
    },
  ];
  const PopularServices = [
    {
      id: 1,
      images: "/Assets/images/addtocart/PMS Comprehensive.png",
      serviceName: "Periodic Service",
      desp: "Improves Car Performance",
      totalArea: 1086,
      bookedArea: " Improves Car Performance ",
    },
    {
      id: 2,
      images: "/Assets/images/addtocart/SPA Interior.png",
      serviceName: "SPA Interior",
      desp: "Improves Car Performance",
      totalArea: 600,
      bookedArea: " Improves Car Performance ",
    },
    {
      id: 3,
      images: "/Assets/images/addtocart/WheelCarePackage.png",
      serviceName: "Wheel Care Package",
      desp: "Improves Car Performance",
      totalArea: 386,
      bookedArea: " Improves Car Performance ",
    },
    {
      id: 4,
      images: "/Assets/images/addtocart/Amaron Car Battery Mobile.png",
      serviceName: "Battery Service",
      desp: "Improves Car Performance",
      totalArea: 450,
      bookedArea: " Improves Car Performance ",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.2,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };
  //------------ vehicle service price list---------------------
  const VSPrice_Data_Car = [
    {
      servicetype: "Car Inspections/ Diagnostices",
      Authorized: "600",
      GativanStartingPrice: "449",
      Savings: "25%",
    },
    {
      servicetype: "Car Basic Service	",
      Authorized: "3299",
      GativanStartingPrice: "2299",
      Savings: "30%",
    },
    {
      servicetype: "Car Wheel & Tyre Care	",
      Authorized: "1690",
      GativanStartingPrice: "1349",
      Savings: "20%",
    },
    {
      servicetype: "Car AC Service	",
      Authorized: "2449",
      GativanStartingPrice: "1599",
      Savings: "35%",
    },
    {
      servicetype: "Car Battery Replacement	",
      Authorized: "5999",
      GativanStartingPrice: "4499",
      Savings: "25%",
    },
    {
      servicetype: "Car Denting & Painting	",
      Authorized: "3000",
      GativanStartingPrice: "2250",
      Savings: "25%",
    },
  ];

  const VSPrice_Data_Bike = [
    {
      servicetype: "Bike Service	",
      Authorized: "1500",
      GativanStartingPrice: "750",
      Savings: "50%",
    },
    {
      servicetype: "Bike Battery Replacement",
      Authorized: "2000",
      GativanStartingPrice: "1500",
      Savings: "25%",
    },
  ];
  return (
    <>
      <Box className="road-side-assistance-image-container">
        <Box className='road-side-assitance-image'>
        </Box>
        <Box className="road-side-assitance-textfield">
          <h4>Where Do You Want Car Roadside Assistance Services?</h4>

          <Box className="near-me-search-bar">
            <Box className="near-me-input-container">
              <Box className="near-me-input-div">
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "#e5e5e5",
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "white",
                          },
                        },
                      },
                    },
                  }}
                  Variant="standard"
                  placeholder="Enter Your Location"
                  className="input-field"
                ></TextField>
              </Box>
            </Box>
            <Box>
              <Button className="near-me-search-btn" varient="contained">
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container className="rating-review">
        <Grid xs={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              color="green"
              className="rating"
              fontWeight="bold"
            >
              <h4>
                4.2/<font>5</font>
              </h4>
            </Typography>

            <Box>
              <Rating
                name="half-rating-read"
                style={{ color: "#FFCE31" }}
                defaultValue={4.4}
                precision={0.2}
                readOnly
              />
            </Box>
            <Box mt={1}>
              <Typography variant="p" style={{ fontSize: "16px" }}>
                Based on 600+
              </Typography>
              <br />
              <Typography variant="p" style={{ fontSize: "16px" }}>
                Genuine Customers
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} style={{ height: "120px" }}>
          <h2 className="vertical_line"> </h2>
        </Grid>

        <Grid xs={5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="cenetr"
            justifyContent="center"
          >
            <img
              src={process.env.PUBLIC_URL + "/Assets/homepage/check price.png"}
              alt=""
            />
            <Typography mt={1} variant="p" style={{ fontSize: "16px" }}>
              Over 50+ Car &
            </Typography>
            <Typography variant="p"> Bike Services</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="sub-container">
        <Box className="popular-services">
          <Box className="homepage-headers" id="FAQ">
            <h2>Popular Services</h2>
          </Box>

          <Carousel
            // infinite
            partialVisible={false}
            arrows
            responsive={responsive}
            autoPlaySpeed={3000}
            centerMode={false}
            containerClass="container"
            className="Carousel"
            additionalTransfrom={0}
            dotListClass=""
            draggable
            focusOnSelect={false}
            renderDotsOutside
            slidesToSlide={1}
            swipeable
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={100}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            showDots
            sx={{ margin: "0" }}
          >
            {PopularServices.map((item) => {
              return (
                <React.Fragment key={item?.id}>
                  <Box>
                    <Card sx={{ maxWidth: 400 }} style={{ marginBottom: "5%" }}>

                      <img
                        className="popular-service-image"
                        src={`${item.images}`}
                      />
                      <Box className="popular-service-info">
                        <Typography className="popular-service-content">
                          Trending
                        </Typography>
                        <Typography className="popular-service-content">
                          {item.serviceName}
                        </Typography>
                        <Typography className="popular-service-content">
                          {item.desp}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box className="people-booked-your-area">
                        {" "}
                        <span> {item.totalArea} </span>
                        {item.bookedArea}{" "}
                      </Box>
                    </Card>
                  </Box>
                </React.Fragment>
              );
            })}
          </Carousel>
        </Box>
        {/* <Box className="see-how-its-done">
        <Box className="homepage-headers" id="FAQ">
            <h2>See How it's Done</h2>
          </Box>
        </Box> */}
        <Box className="customer-review">
          <Testimonials />
        </Box>
        <Box className="download-the-app">
          <DownloadApp />
        </Box>
        <Box className="price-comparison-chart">
          <Box className="homepage-headers">
            <h2>Vehicle Service price list (Pune ,India - 2022)</h2>
          </Box>
          <Box className="VSprice-btns">
            <Button
              variant="text"
              onClick={() => {
                setShow(VSPrice_Data_Car);
                setcolorindexform(0);
              }}
              style={{
                fontWeight: "bold",
                backgroundColor: colorindexform == 0 ? "#7B8BD4" : "#fff",
                color: colorindexform == 0 ? "#fff" : "#122164",
              }}
            >
              Car{" "}
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setShow(VSPrice_Data_Bike);
                setcolorindexform(1);
              }}
              style={{
                fontWeight: "bold",
                backgroundColor: colorindexform == 0 ? "#fff" : "#7B8BD4",
                color: colorindexform == 0 ? "#122164" : "#fff",
              }}
            >
              Bike
            </Button>
          </Box>
          <ServicePriceListTable Data={show} />
        </Box>
        <Box className="roadside-faq">
          <Box className="homepage-headers" id="FAQ">
            <h2>Frequently Asked Vehicle service Questions</h2>
          </Box>
          <FAQ_home data={FAQData} />{" "}
        </Box>

      </Box>
    </>
  );
}
