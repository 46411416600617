
const SelectionVehicle = {
     vehicleType: {
          vehicleId: '1',
          vehicleName: 'Car'
     },
     brand: {
          brandId: '',
          brandName: ''
     },
     model: {
          modelId: '',
          modelName: '',
          imagePath: ''
     },
     variant: {
          variantId: '',
          variantName: ''
     }
}

export const SelectionVehicleReducer = (state = SelectionVehicle, action) => {

     switch (action.type) {
          case 'CustomerPickData':
               return state = action.payload

          default:
               return state
     }
}

const serviceCategory = {
     serviceCategoryId: '',
     serviceCategory: ''
}

export const serviceCategoryReducer = (state = serviceCategory, action) => {
     switch (action.type) {
          case 'ServiceCategory':
               return state = action.payload

          default:
               return state
     }
}