import React, { useContext, useEffect, useState } from 'react'
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAllServices, addToCart, getMobileNumber, handleBrowserUserData, handleSetBrowserId } from '../action';
import { AppContext } from '../App';
import { getBrand, getBrandById, getCartBrowserId, getModel, getModelById, getServiceByIdWithPrice, getSubCategoriesWithPrice, getSubCategoriesWithPriceById, getVariant, getVariantById, getVehicleById, getVehicleType } from '../services/vehicleservices/vehicleservices';

// import the fingerprintjs opensource library
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default function SelectedBrowserIdData() {
    const [fpHash, setFpHash] = useState('');
    const [browserIdDetails, setBrowserIdDetails] = useState({});
    const [vehicle, setVehicle] = useState({});
    const [brand, setBrand] = useState({});
    const [model, setModel] = useState({});
    const [variant, setVariant] = useState({});



    const AppContextData = useContext(AppContext);


    const { customerPickData, setCustomePickData } = AppContextData


    const dispatch = useDispatch();


    //---------------------------------------------- getting Browser Id ----------------------

    const setFp = async () => {
        const fp = await FingerprintJS.load();
  
        const { visitorId } = await fp.get();
  
        setFpHash(visitorId);
      };

    useEffect(() => {
        setFp();
    }, []);

    useEffect(() => {
        dispatch(handleSetBrowserId(fpHash))
    }, [fpHash]);



    // getting brower Data from redux
    const browserData = useSelector(
        state => state.BrowserUserData
    );

    // getting added services data from redux
    const ServiseReduxData = useSelector(
        (state) => state.AddToCartReducer
    );

    // getting selected vehicle from redux
    const vehicleSelectedData = useSelector(
        state => state.SelectionVehicleReducer
    );

    // getting browserId from redux
    const getBrowserIdRedux = useSelector(
        state => state.SaveBrowserId
    )


    // get user data w.r.t. browser id
    const getUserDataByBrowserId = async (browserId) => {
        try {
            const Response = await getCartBrowserId(browserId);
            const data = await Response.data;
            if (data.statusCode == 200) {
                setBrowserIdDetails(data.result)
            }
        } catch (err) {
            console.log(err)
        }
    }


    //get sub categories with prices
    const getSubCategoriesDataWithPrice = async (serviceId, modelId, variantId) => {
        try {
            const response = await getServiceByIdWithPrice(serviceId, modelId, variantId);
            const data = await response.data;
            if (data.statusCode == 200) {
                handleAddServiceToCart(data?.result)
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    //get vehicle type by id
    const vehicleById = async (vehicleId) => {
        try {
            const response = await getVehicleById(vehicleId);
            const data = await response.data;
            if(data.statusCode == 200) {
                setVehicle(data?.result);
            }
        }catch (error) {
            console.log(error)
        }
    }

    //get brand by id
    const brandById = async (brandId) => {
        try {
            const response = await getBrandById(brandId);
            const data = await response.data;
            if (data.statusCode == 200) {
                setBrand(data?.result)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

     //get brand by id
     const modelById = async (modelId) => {
        try {
            const response = await getModelById(modelId);
            const data = await response.data;
            if (data.statusCode == 200) {
                setModel(data?.result)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

     //get brand by id
     const variantById = async (variantId) => {
        try {
            const response = await getVariantById(variantId);
            const data = await response.data;
            if (response.status == 200) {
                setVariant(data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        dispatch(handleBrowserUserData(browserIdDetails))
    }, [browserIdDetails])

    useEffect(() => {
        getBrowserIdRedux && getUserDataByBrowserId(getBrowserIdRedux)
    }, [getBrowserIdRedux]);


    useEffect(() => {
        browserData?.vehicleType && vehicleById(browserData?.vehicleType)
        browserData?.brandId && brandById(browserData?.brandId)
        browserData?.modelId && modelById(browserData?.modelId)
        browserData?.variantId && variantById(browserData?.variantId)

    }, [browserData?.vehicleType, browserData?.brandId, browserData?.modelId, browserData?.variantId]);

    useEffect(() => {
        browserData?.cartItems?.map((item) => {
            getSubCategoriesDataWithPrice(item?.serviceId, browserData?.modelId, browserData?.variantId)
        })
    }, [browserData?.modelId, browserData?.variantId, browserData?.cartItems])




    useEffect(() => {
        dispatch(getMobileNumber({ mobileNumber: browserData?.mobileNumber }))
    }, [browserData?.mobileNumber]);



    useMemo(() => {
        setCustomePickData({
            ...customerPickData,
            vehicleType: { id: vehicle?.id, vehicleName: vehicle?.vehicleType },
            brand: { id: brand?.brandId, brandName: brand?.brand },
            model: { id: model?.modelId, modelName: model?.model, imagePath: model?.imageUrl },
            variant: { id: variant?.id, variantName: variant?.variantName }
        })
    }, [vehicle, brand, model, variant])


    function handleAddServiceToCart(serviceData) {

        const found = ServiseReduxData.result.some(e => e.serviceId == serviceData.serviceId)

        if (!found) {
            serviceData.addServiceToCart = true
            dispatch(addToCart(serviceData, customerPickData))
        }
    }

    return null;
}