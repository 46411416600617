let BrowserId = '';

export const SaveBrowserId = (state = BrowserId, action) => {
    switch (action.type) {
        case 'SetBrowserId':
            return state = action.payload;

        default:
            return state;
    }
}