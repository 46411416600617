import axios from "axios";

const Services = {
  result: [],
};

export const AddToCartReducer = (state = Services, action) => {
  switch (action.type) {
    case "ADDTOCART":
      return {
        ...state,
        result: [...state.result, action.payload],
      };

    case "REMOVEFROMCART":
      const temp = [...state.result];
      temp.splice(action.payload, 1);
      return {
        result: (state.result = [...temp]),
      };
    case "REMOVEALLCART":
      return (state = action.payload);
    default:
      return state;
  }
};
const initState = {
  subTotal: 0,
  couponDiscount: 0,
  cartItems: 0,
  cartTotal: 0,
  payNow: 0,
  coupon: {},
};

export const CartTotalReducer = (state = initState, action) => {
  switch (action.type) {
    case "CARTTOTAL":
      return {
        ...state,
        subTotal: action.payload?.subTotal,
        couponDiscount: action.payload?.couponDiscount,
        cartTotal: action.payload?.cartTotal,
        cartItems: action.payload?.cartItems,
        payNow: action.payload?.payNow,
        coupon: action.payload?.coupon,
      };
    default:
      return state;
  }
};