import React from 'react';
import './GativanBenefits.css';
import {
    Box,
    Grid,
} from '@mui/material';

export default function GativanBenefits() {
    const GativanBenifitsData = [
        {
            'id': 1,
            'img': 'map.png',
            'title': 'Free Doorstep Pick-up & Drop'
        },
        {
            'id': 2,
            'img': 'Rupees.png',
            'title': 'Transparent Pricing'

        },

        {
            'id': 3,
            'img': 'Spares_parts.png',
            'title': '100% Genuine Spare Parts'

        },
        {
            'id': 4,
            'img': 'quality_workmanship.png',
            'title': 'Quality Workmanship '

        },
    ]
    return (
        <>
            <Box position='relative'>
                <Box className="homepage-headers">
                    <h2 > Gativan Benefits </h2>
                </Box>
                <Box >
                    <Box className='GativanBenefitsImgContainer'>
                        <Grid container spacing={3} columns={12} sx={{ display: "flex" }} >
                            {GativanBenifitsData.map((item, index) => {
                                return (
                                    <Grid item key={index} xs={6} md={3} >
                                        <Box className='Benefitscontainer'>
                                            <Box className='Benefitsimg'>
                                                <img
                                                    src={process.env.PUBLIC_URL + `/Assets/homepage/GativanBenefits/${item.img}`}
                                                    alt="gativan motion"
                                                />
                                            </Box>
                                            <Box className='Benefitsdesp'>
                                                <p>{item.title}</p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
