import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './WorkshopNearYou.css'
import Carousel from 'react-multi-carousel';
import { Box, Modal, Typography } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { AppContext } from '../../App';


export default function WorkshopNearYou() {

    const [modalOpen, setModalOpen] = useState(false)

    const navigation = useNavigate();

    const AppContextData = useContext(AppContext);
    const { customerPickData, setCustomePickData, serviceCategoryId, setServiceCategoryId } = AppContextData


    const brandName = customerPickData?.brand?.brandName?.replaceAll(" ", "-").toLowerCase();
    const modelName = customerPickData?.model?.modelName?.replaceAll(" ", "-").toLowerCase();
    const variantName = customerPickData?.variant?.variantName?.replaceAll(" ", "-").toLowerCase();

    const data = [
        {
            id: 1,
            img: "/Assets/homepage/workshopNearYou/workshopBMW.jpeg"
        },
        {
            id: 2,
            img: "/Assets/homepage/workshopNearYou/workshop1.jpeg"
        },
        {
            id: 3,
            img: "/Assets/homepage/workshopNearYou/workshop2.jpeg"
        },
        {
            id: 4,
            img: "/Assets/homepage/workshopNearYou/locateUs.jpeg"
        },
    ]
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 50 // this is needed to tell the amount of px that should be visible.
        }
    }

    // getting current location from redux
    const currentLocation = useSelector(
        state => state.LocationReducer
    )

    // getting selected vehicle from redux
    const vehicleSelectedData = useSelector(
        (state) => state.SelectionVehicleReducer
    );

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleBookNowClick = () => {
        const location = currentLocation.cityName.toLowerCase()
        const vehicleType = customerPickData?.vehicleType?.vehicleName.toLowerCase()
        if (customerPickData?.variant?.variantName) {
            navigation(`/${location}/${vehicleType}/periodic-services/${brandName}/${modelName}/${variantName}`);
        }
        else {
            navigation(`/${location}/${vehicleType}/periodic-services`);
        }

    }




    return (
        <React.Fragment>
            <Box container className="workshop-carousel-container">
                <Carousel
                    // infinite
                    partialVisible={true}
                    responsive={responsive}
                    autoPlaySpeed={10000}
                    containerClass="container"
                    className="Carousel"
                    additionalTransfrom={0}
                >
                    {
                        data.map((item) => {
                            const { id, img } = item
                            return (
                                <Box className="image-container" onClick={() => setModalOpen(true)}>
                                    <img src={process.env.PUBLIC_URL + `${img}`} />
                                </Box>
                            )
                        })
                    }

                </Carousel>
            </Box>

            <Modal
                onClose={handleCloseModal} open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="map-container">
                    <div className="modal-title" id="modal-modal-title" >
                        <h2>Gativan Workshops Near You</h2>
                        <ClearIcon className="clear-icon" onClick={() => handleCloseModal()} />
                    </div>
                    <div className="modal-description" id="modal-modal-description" >
                        <div className="button-container">
                            <button onClick={() => handleBookNowClick()} >Book Now</button>
                        </div>
                        <iframe
                            src="https://www.google.com/maps/d/embed?mid=1glSIQP9XncfRgFg-xOec0DmdmyITKmE&ehbc=2E312F"
                            className='map-iframe'
                            onClick={(e) => e.stopPropagation()}
                        />

                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}
