import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useHistoryNavigationTwo = () => {
  const navigate = useNavigate();
  const [shouldGoBack, setShouldGoBack] = useState(false);

  const currentLocation = useSelector((state) => state.LocationReducer);

  useEffect(() => {
    const handlePopstate = (event) => {
      if (!shouldGoBack) {
        // Prevent the default behavior of popstate
        event.preventDefault();
        // Reset the URL to the current one (popping the state)
        window.history.pushState(null, null, window.location.pathname);
        setShouldGoBack(true);

        // Navigate to the desired location
        navigate(`/${currentLocation?.cityName?.toLocaleLowerCase()?.replace(/\s/g, "-")}`);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [shouldGoBack, navigate, currentLocation]);

  useEffect(() => {
    if (shouldGoBack) {
      // Use the navigate function to go back in history
      navigate(-1);
      setShouldGoBack(false); // Reset shouldGoBack after navigating
    }
  }, [navigate, shouldGoBack]);

  return {
    triggerGoBack: () => setShouldGoBack(true)
  };
};

export default useHistoryNavigationTwo;
