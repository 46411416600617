import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Box, Grid, Typography, Divider } from "@mui/material";
import Carousel from "react-multi-carousel";
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import useHistoryNavigation from "../useHistoryNavigation/useHistoryNavigation";

export default function Blog() {
  useHistoryNavigation();
  const [video, setvideo] = useState();
  useEffect(() => {
    setvideo("https://www.youtube.com/embed/geXrJo7Y0RY?autoplay=1");
  }, []);
  // =================carousel blog image==============//
  const blog_images = [
    {
      id: 1,
      images: "/Assets/blog/Blogmain.png",

    },
    {
      id: 2,
      images: "/Assets/blog/Mahindra Scorpio.jpg",
    },
    {
      id: 3,
      images: "/Assets/blog/Tata Sierra EV.jpg",
    },
    {
      id: 4,
      images: "/Assets/blog/Ultraviolette F77.jpg",
    },
  ];
  // ============main latest blogs===========//
  const latest_blogs = [
    {
      id: 1,
      title: "How to Repair Rust on a Car?",
      images: "/Assets/blog/new blogs/How to Repair Rust on a Car.jpg",
      // author: "Chaitali Sawant",
      // date: "25 July 2022",
      url: "how-to-repair-rust-on-a-car",
    },
    {
      id: 2,
      title: "Bike Maintenance Tips – Easy Steps of Bike Service",
      images: "/Assets/blog/new blogs/Bike Maintenance Tips – Easy Steps of Bike Service.jpg",
      // author: "Chaitali Sawant",
      // date: "25 July 2022",
      url: "bike-maintenance-tips-easy-steps-of-bike-service",
    },
    {
      id: 3,
      title: "Signs That You Need to Repair Your Car Air Conditioner",
      images: "/Assets/blog/new blogs/Signs That You Need to Repair Your Car Air Conditioner.jpg",
      // author: "Chaitali Sawant",
      // date: "25 July 2022",
      url: "signs-that-you-need-to-repair-your-car-air-conditioner",
    },
    // {
    //   id: 1,
    //   title: "Top 15 Cars Launching in India in 2022",
    //   images: "/Assets/blog/Mahindra Scorpio.jpg",
    //   author: "Chaitali Sawant",
    //   date: "25 July 2022",
    //   url: "top-15-cars-launching-in-india-in-2022",
    // },
    // {
    //   id: 2,
    //   title: "Best 5 EV Cars Launching in India in 2022",
    //   images: "/Assets/blog/Tata Sierra EV.jpg",
    //   author: "Rohan Vyas",
    //   date: "25 July 2022",
    //   url: "best-5-EV-cars-launching-in-india-in-2022",
    // },
    // {
    //   id: 3,
    //   title: "Top 7 EV Bikes Launching in 2022",
    //   images: "/Assets/blog/Ultraviolette F77.jpg",
    //   author: "Chaitali Sawant",
    //   date: "25 July 2022",
    //   url: "top-7-EV-bikes-launching-in-2022",
    // },
  ];
  // ==================latest car news==================//
  const latest_car = [
    {
      id: 1,
      name: "Top 15 Cars Launching in India in 2022",
      images: "/Assets/blog/Mahindra Scorpio.jpg",
      author: "Rohan Vyas",
      date: "15 Jan 2022",
      url: "top-15-cars-launching-in-india-in-2022",
    },

  ];
  // ======================featured articals ====================//
  const featured_articles = [
    {
      id: 1,
      name: "Top 7 EV Bikes Launching in 2022",
      images: "/Assets/blog/Ultraviolette F77.jpg",
      author: "Chaitali Sawant",
      date: "15 Jan 2022",
      url: "top-7-EV-bikes-launching-in-2022",
    },
    {
      id: 2,
      name: "Best Bike Maintenance Checklist and Suggestions",
      images: "/Assets/blog/bikerepair.png",
      author: "Rohan Vyas",
      date: "15 Jan 2022",
      url: "best-bikes-maintenance-checklist-and-suggestions",
    },
  ];
  const electric_cars = [
    {
      id: 1,
      name: "Best 5 EV Cars Launching in India in 2022",
      images: "/Assets/blog/Mahindra XUV300 EV.jpg",
      author: "Rohan Vyas",
      date: "15 Jan 2022",
      url: "best-5-EV-cars-launching-in-india-in-2022",
    },
  ];
  const navigation = useNavigate();

  const Videos = [
    {
      id: 1,
      url: "https://www.youtube.com/embed/geXrJo7Y0RY?autoplay=1",
      images: "/Assets/blog/interior shampoing and scrubbing.jpg",
      title: "Car Germ Cleaning by Gativan Mechanic | Safety for Covid19.",
      time: "0:30",
    },
    {
      id: 2,
      url: "https://www.youtube.com/embed/winHoFKli5I?autoplay=1",
      images: "/Assets/blog/best car and bike service.jpg",
      title:
        "Best Car and Bike Service Platform Across Pune | Experience a detailed Car Inspection by Gativan 🚗",
      time: "1:15",
    },
    {
      id: 3,
      url: "https://www.youtube.com/embed/MITP5J_eJ9Y?autoplay=1",
      images: "/Assets/blog/easily repair your car and bike.jpg",
      title: "Easily Repair your Car or Bike",
      time: "1:55",
    },
    {
      id: 4,
      url: "https://www.youtube.com/embed/mPFw7eojw24?autoplay=1",
      images: "/Assets/blog/oil change at doorstep.jpg",
      title: "Oil change at doorstep",
      time: "0:29",
    },
    {
      id: 5,
      url: "https://www.youtube.com/embed/72kvKNNSJfE?autoplay=1",
      images: "/Assets/blog/deep cleaning of bike at gativan garage.jpg",
      title: "Deep Cleaning of Bike at Gativan garage",
      time: "6:57",
    },
  ];

  const handleVideo = (item) => {
    setvideo(item.url);
  };
  const autoPlay = "?autoplay=1";
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        navigation("/blog")
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Gativan Blogs</title>
        <meta
          name="description"
          content="Latest Blogs on car and bike, car news, bike news, car blogs, bike blogs automotive articles, car articles, motorcycle news and much more. One plarform for all car and bike information."
        />
        <link rel="canonical" href="https://www.gativan.in/blog" />
      </Helmet>
      {/*=================Blog  container==================*/}
      <Box className="blog-container">
        <Grid container colSpacing={5}>
          <Grid item xs={12} sm={12} md={7}>
            {/*=====================Main Blog Image=====================*/}
            <Box className="blog-image">
              <Box>
                <img
                  className="blog-img"
                  src={process.env.PUBLIC_URL + "/Assets/blog/Blogmain.png"}
                />
                {/*========================latest blogs section ================*/}
                <Box className="blogs">
                  {latest_blogs.map((item) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <Box
                          key={item.id}
                          className="blog-card"
                          onClick={() => {
                            navigation(`/blog/${item.url}`);
                          }}
                        >
                          <img
                            className="blogs-images"
                            src={`${item.images}`}
                          />
                          <Typography variant="h6" className="blog-title">
                            {item.title}
                          </Typography>
                          <Box className="autor-date">
                            <Typography className="author-name">
                              {item.author}
                            </Typography>
                            <Typography className="writing-date">
                              {item.date}
                            </Typography>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Box>
              {/*======================blog images section================*/}
              <Box className="blog-carousel">
                <Carousel
                  showDots={true}
                  responsive={{
                    mobile: {
                      breakpoint: { max: 768, min: 0 },
                      items: 1,
                      slidesToSlide: 1, // optional, default to 1.
                    },
                  }}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay
                  autoPlaySpeed={4000}
                  transitionDuration={1000}
                  removeArrowOnDeviceType={["mobile"]}
                  deviceType
                >
                  {blog_images.map((item, index) => {
                    return (
                      <Box key={index} className="blog-carousel-item">
                        <img src={`${item.images}`} />
                      </Box>
                    );
                  })}
                </Carousel>
              </Box>
            </Box>
          </Grid>
          {/*=============================Gativan youtube video section===============*/}
          <Grid item xs={12} sm={12} md={5}>
            <Box className='youtube-video'>
              <iframe
                src={video}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen

                className="gativan-youtube-video"
              ></iframe>
              <Box className="youtube-playlist">
                {Videos.map((item) => {
                  return (

                    <Box key={item.id}>
                      <Box mt={2} onClick={() => handleVideo(item)}>
                        <Box src={item.url}>
                          <Box className="youtubevideo-title">
                            <Box>
                              <img
                                width="60px"
                                height="40px"
                                src={`${item.images}`}
                              />
                            </Box>

                            <Box className="video-title">
                              {item.title}
                              <br />
                              <Box className='video-time'>{item.time}</Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

          </Grid>
        </Grid>
        {/*===================Refer and Earn Image==================*/}
        <Box className="refer-latest-featured-electric">
          <img
            className="refer-and-earn"
            src={process.env.PUBLIC_URL + "/Assets/blog/referandearn.png"}
            alt=""
          />

          {/*============================Latest Car News Blog Sections==============*/}
          <Box className="latest-car-news">
            <Box className="blog-page-heading">
              <Divider>
                <h3>Latest Car News</h3>
              </Divider>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {latest_car.map((item) => {
                  return (
                    <React.Fragment key={item?.id} >
                      <Grid item xs={12} sm={4} md={3} >
                        <Box
                          className="latest-card"
                          onClick={() => {
                            navigation(`/blog/${item.url}`);
                          }}
                        >
                          <img
                            className="blogs-images"
                            src={`${item.images}`}
                          />
                          <Typography className="blog-title">
                            {item.name}
                          </Typography>

                          <Box className="autor-date">
                            <Typography className="author-name">
                              {item.author}
                            </Typography>
                            <Typography className="writing-date">
                              {item.date}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          {/*=================Featured Articals Blog Sections==================== */}
          <Box className="featured-articles">
            <Box className="blog-page-heading">
              <Divider>
                <h3>Featured Article</h3>
              </Divider>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {featured_articles.map((item) => {
                  return (
                    <React.Fragment key={item?.id} >
                      <Grid item xs={12} sm={4} md={3} >
                        <Box
                          className="latest-card"
                          onClick={() => {
                            navigation(`/blog/${item.url}`);
                          }}
                        >
                          <img
                            className="blogs-images"
                            src={`${item.images}`}
                            alt="First slide"
                          />
                          <Typography className="blog-title">
                            {item.name}
                          </Typography>
                          <Box className="autor-date">
                            <Typography className="author-name">
                              {item.author}
                            </Typography>
                            <Typography className="writing-date">
                              {item.date}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          {/*=====================Electrical Cars Blog Sections===============*/}
          <Box className="electric-cars">
            <Box className="blog-page-heading">
              <Divider>
                <h3>Electric Cars</h3>
              </Divider>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                // spacing={{ xs: 2, md: 4 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {electric_cars.map((item) => {
                  return (
                    <React.Fragment key={item?.id}>
                      <Grid item xs={12} sm={4} md={3} >
                        <Box
                          className="latest-card"
                          onClick={() => {
                            navigation(`/blog/${item.url}`);
                          }}
                        >
                          <img
                            className="blogs-images"
                            src={`${item.images}`}
                          />
                          <Typography className="blog-title">
                            {item.name}
                          </Typography>
                          <Box className="autor-date">
                            <Typography className="author-name">
                              {item.author}
                            </Typography>
                            <Typography className="writing-date">
                              {item.date}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
