import React, { useState, useEffect, useContext, Suspense } from "react";
import HowGativanWorks from "./HowGativanWork/HowGativanWorks";
import { getHomeBanners } from "../../services/homeServices";
import GativanMotionPerformance from "./GativanMotionPerformance";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Button, Box, Typography } from "@mui/material";
import ServicePriceListTable from "./ServicePriceListTable";
import "./Home.css";
import FAQ_home from "../ReuseComponent/FAQ/FAQ_home";
import MainSelection from "../Selection/MainSelection.Component";
import CarouselComponent from "./CarouselComponent";
import OurServices from "./OurServices/OurServices";
import httpCommon from "../../http-common";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Testimonials from "../ReuseComponent/testimonials/Testimonials";
import WhyChooseGativan from "../ReuseComponent/whyChooseGativan/whyChooseGativan";
import BookServices from "../ReuseComponent/bookServices/BookServices";
import CarouselLogo from "./CarouselLogo";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../App";
import { chooseLocation, handleChangeVehicelComponent } from "../../action";
import BlogPost from "../ReuseComponent/blogPost/BlogPost";
import GativanExperts from "./GativanExperts";
// import VideoSection from "./VideoSection";

import WorkshopNearYou from "./WorkshopNearYou";
import LoaderSpinner from "../loading/LoaderSpinner";
import DownloadApp from "../ReuseComponent/downloadApp/DownloadApp";
import CityModal from "../CityModal";
import axios from "axios";

const VideoSection = React.lazy(() => import("./VideoSection"));

ReactGA.initialize("UA-202262670-1");
ReactGA.initialize("G-MV6P9VNPW1");
ReactGA.pageview(window.location.pathname + window.location.search);

// const WhatsApp = () => {
//   var url =
//     "https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?89641";
//   var s = document.createElement("script");
//   s.type = "text/javascript";
//   s.async = true;
//   s.src = url;
//   var options = {
//     enabled: true,
//     chatButtonSetting: {
//       backgroundColor: "#4dc247",
//       ctaText: "Message Us",
//       borderRadius: "25",
//       marginLeft: "0",
//       marginBottom: "20",
//       marginRight: "50",
//       position: "right",
//     },
//     brandSetting: {
//       brandName: "Gativan",
//       brandSubTitle: " Car and Bike Services",
//       brandImg:
//         "https://cdn.octopix.in/uploads/company-logo/2022/01/14/gativan-NynF9oF1C8UuKqFHu4Ayj3qKR6Z1EdImKdi7RliE0DzBEH8j22XMWWkSETPYPcgQ1owYBCdAFvaGA9K8-350x350.jpg",
//       welcomeText: "Hi there!\nHow can I help you?",
//       messageText: "Hello, I have a question about {{page_link}}",
//       backgroundColor: "#0a5f54",
//       ctaText: "Start Chat",
//       borderRadius: "25",
//       autoShow: false,
//       phoneNumber: "919322775435",
//     },
//   };
//   s.onload = function () {
//     CreateWhatsappChatWidget(options);
//   };
//   var x = document.getElementsByTagName("script")[0];
//   x.parentNode.insertBefore(s, x);
// };

export default function Home() {
  // Mobile view carousel images name
  // const Img = ['Car Cleaning Offer card Mobile', 'Denting and Painting Offer Card Mobile', 'Gativan Dust Swiper', 'insurance claim']
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const [show, setShow] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [colorindexform, setcolorindexform] = useState(0);
  const baseURL = httpCommon.defaults.baseURL;
  useEffect(() => {
    setShow(VSPrice_Data_Car);
  }, [setShow]);

  const navigation = useNavigate();

  const dispatch = useDispatch();

  const [showCities, setShowCities] = useState([]); // Initialize with your city data
  const [selectedCity, setSelectedCity] = useState(null); // State to hold the selected city

  // Function to handle city selection
  const handleCitySelection = (city) => {
    setSelectedCity(city);
    // Dispatch logic here with the selected city information
    // For example:
    // dispatchFunction(selectedCity);
  };

  const {
    customerPickData,
    setCustomePickData,
    serviceCategoryId,
    setServiceCategoryId,
    setIsCheckOut,
    isShowSearch,
    setIsShowSearch,
    isModalOpen,
    setIsModalOpen,
  } = useContext(AppContext);

  // // GET CAROUSEL IMAGE
  const getCarouselImages = async () => {
    try {
      const response = await getHomeBanners();
      const data = await response.data;
      setBannerImages(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    
    getCarouselImages();
  }, []);













  // ----------------------END PRELOADING HOME CAROUSEL IMAGES--------------------------------

  // const DestopHeroImages = [
  //   {
  //     id: 1,
  //     img: "1",
  //   },
  //   {
  //     id: 2,
  //     img: "2",
  //   },
  //   {
  //     id: 3,
  //     img: "3",
  //   },
  // ];

  // useEffect(()=>{
  //   const vehicleTypeData = {
  //     vehicleId: '1',
  //     vehicleName: 'Car'
  //     // Add other properties if needed
  //   };

  // },)

  // getting selected vehicle from redux
  const vehicleSelectedData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  // console.log(vehicleSelectedData.vehicleType.vehicleName)

  // useEffect(()=>{

  //   {
  //     vehicleSelectedData.vehicleType.vehicleName ==""? setCustomePickData({ ...customerPickData, vehicleType: { id: 1, vehicleName: 'Car' } }) :
  //   }

  // },[])

  useEffect(() => {
    if (
      !vehicleSelectedData.vehicleType ||
      typeof vehicleSelectedData.vehicleType.vehicleName === "undefined"
    ) {
      setCustomePickData({
        ...customerPickData,
        vehicleType: { id: 1, vehicleName: "Car" },
      });
    }
  }, [vehicleSelectedData]); // Adding vehicleSelectedData to the dependency array to trigger the effect when it changes

  // const [DestopHeroImage, setDataBanner] = useState([]);

  // const booleanVar = window.innerWidth <= 768 ? "true" : "false"

  // useEffect(() => {

  //  axios.get(`${URL}/homeBanner/isMobile/${booleanVar}`)
  //    .then(response => {
  //      setDataBanner(response.data.result);
  //    })
  //    .catch(error => {

  //      console.error('Error fetching data:', error);
  //    });
  // }, [booleanVar]);

  // console.log(DestopHeroImage)

  //Aditya
  const [DestopHeroImage, setDataBanner] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Fetch data based on initial screen size
    axios
      .get(`${URL}/homeBanner/isMobile/${isMobile}`)
      .then((response) => {
        setDataBanner(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  // const DestopHeroImages = [
  //   {
  //     id: 1,
  //     img: "1",
  //     imageUrl:
  //       "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/merc-desktop.webp",
  //   },
  //   {
  //     id: 2,
  //     img: "2",
  //     imageUrl:
  //       "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/periodic-desktop.webp",
  //   },
  //   {
  //     id: 3,
  //     img: "3",
  //     imageUrl:
  //       "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/ktm-desktop.webp",
  //   },
  // ];

  const MobileCarouselData = [
    {
      id: 1,
      img: "Monsoon",
      ga: "Clicked on Monsoon",
      imageUrl:
        "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/Car+Periodic+Servicing-mobile.webp",
    },
    {
      id: 2,
      img: "Service",
      ga: "Clicked on Service",
      imageUrl:
        "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/Dussehra+Vehicle+Servicing+-+mobile.webp",
    },
    {
      id: 3,
      img: "Wheel",
      ga: "Clicked on Wheel",
      imageUrl:
        "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/Insurance+Claim+Card+-+mobile.webp",
    },
    {
      id: 4,
      img: "Insurance Claim Card",
      ga: "Clicked on Car Insurance Claim Card",
      imageUrl:
        "https://gativan-website-home-carousel-images.s3.ap-south-1.amazonaws.com/Wheel+%26+Tyre+Care+Service+-+mobile.webp",
    },
  ];

  //---------FAQ Data-----------------
  const FAQData = [
    {
      title: "How can I book my vehicle service on Gativan?",
      body: "Book your service within a minute. Dive in to gativan.in.Select your vehicle type (bike/car),select the brand (e.g. Honda) followed by the vehicle (e.g. Amaze). Enter your phone number and tap on Check price to book your slots! Select your convenient time slots and book it! We offer free pick up and delivery all over Pune, so you don’t miss out the best moments with your dear ones! ",
    },
    {
      title: "How much do you charge for Periodic Servicing?",
      body: " Gativan’s Periodic Servicing starts at Rs. 795 for bikes and Rs. 2399 for cars.",
    },
    {
      title: "Why should I choose Gativan in Pune?",
      body: "Gativan offers best bike/car services with best prices in Pune. Not only you get a quality service from our trained mechanics, but also avail transparency throughout.",
    },

    {
      title: "Can I choose a Gativan Service Centre of my choice in the city?",
      body: "Yes, definitely! Rest assured, all our service stations are trained for quality servicing. We will assign you the near most service centre based on your location.",
    },
  ];

  //------------ vehicle service price list---------------------
  const VSPrice_Data_Car = [
    {
      servicetype: "Car Inspections/ Diagnostices",
      Authorized: "600",
      GativanStartingPrice: "449",
      Savings: "25%",
    },
    {
      servicetype: "Car Basic Service	",
      Authorized: "3299",
      GativanStartingPrice: "2299",
      Savings: "30%",
    },
    {
      servicetype: "Car Wheel & Tyre Care	",
      Authorized: "1690",
      GativanStartingPrice: "1349",
      Savings: "20%",
    },
    {
      servicetype: "Car AC Service	",
      Authorized: "2449",
      GativanStartingPrice: "1599",
      Savings: "35%",
    },
    {
      servicetype: "Car Battery Replacement	",
      Authorized: "5999",
      GativanStartingPrice: "4499",
      Savings: "25%",
    },
    {
      servicetype: "Car Denting & Painting	",
      Authorized: "3000",
      GativanStartingPrice: "2250",
      Savings: "25%",
    },
  ];

  const VSPrice_Data_Bike = [
    {
      servicetype: "Bike Service	",
      Authorized: "1500",
      GativanStartingPrice: "750",
      Savings: "50%",
    },
    {
      servicetype: "Bike Battery Replacement",
      Authorized: "2000",
      GativanStartingPrice: "1500",
      Savings: "25%",
    },
  ];

  // ---------------featuredServicesData-----------------------------
  const featuredServicesData = [
    {
      id: 1,
      img: "/Assets/homepage/featuredServices/Royal Enfield Bike Servicing.webp",
      title: "Want Your Royal Beast Serviced?",
      description: "Custom Gativan Service for Royal Enfield",
    },
    {
      id: 2,
      img: "/Assets/homepage/featuredServices/Boost Car Pro Service.png",
      title: "Boost your car with a Pro service",
      description: "Avail the new Car Pro Periodic Service",
    },
    {
      id: 3,
      img: "/Assets/homepage/featuredServices/Royal Enfield Bike Servicing.webp",
      title: "Want Your Royal Beast Serviced?",
      description: "Custom Gativan Service for Royal Enfield",
    },
  ];

  //--------------gativan luxury---------------------------------------
  const gativanLuxuryData = [
    {
      id: 1,
      img: "/Assets/homepage/gativanLuxury/Mercedes Car Servicing.webp",
      title: "Issues in your Merc?",
      description: "Mechanic trained for luxury segment",
    },
    {
      id: 2,
      img: "/Assets/homepage/gativanLuxury/Range Rover Car Servicing.webp",
      title: "Is your Rover Beast running slow?",
      description: "Improve Motion Performance by 2X",
    },
    {
      id: 3,
      img: "/Assets/homepage/gativanLuxury/Audi Car Servicing.webp",
      title: "Is your Audi craving your attention?",
      description: "24x7 Service Booking facility",
    },
    {
      id: 4,
      img: "/Assets/homepage/gativanLuxury/BMW Car Servicing.webp",
      title: "Give your BMW a premium service",
      description: "Gativan Quality Assured deliveries",
    },
    {
      id: 5,
      img: "/Assets/homepage/gativanLuxury/Volvo Car Servicing.webp",
      title: "Super Savings for your Volvo!",
      description: "Price with assured savings",
    },
  ];

  const brandWeServiceData = [
    {
      id: 1,
      brand1: "brandsService/royalenfield.png",
      // brand1: 'brandsService/Honda1.png',
      brand2: "brandsService/Audi.png",
      // brand2: 'brandsService/MarutiSuzuki.png',
    },
    {
      id: 2,
      brand1: "brandsService/Honda1.png",
      // brand1: 'brandsService/Bajaj.png',
      brand2: "brandsService/Mercedes.png",
      // brand2: 'brandsService/Tata.png',
    },
    {
      id: 3,
      brand1: "brandsService/KTM.png",
      brand2: "brandsService/BMW.png",
      // brand2: 'brandsService/Ford.png',
    },
    {
      id: 4,
      brand1: "brandsService/aprilia.png",
      // brand1: 'brandsService/Hero.png',
      brand2: "brandsService/Skoda.png",
      // brand2: 'brandsService/Hyundai.png',
    },
    {
      id: 5,
      brand1: "brandsService/Bajaj.png",
      brand2: "brandsService/jaguar.png",
    },
    {
      id: 5,
      brand1: "brandsService/Tvs.png",
      brand2: "brandsService/Tata.png",
    },
    {
      id: 6,
      brand1: "brandsService/Suzuki.png",
      brand2: "brandsService/Toyota.png",
    },
    {
      id: 7,
      brand1: "brandsService/Hero.png",
      brand2: "brandsService/MarutiSuzuki.png",
    },
    {
      id: 8,
      brand1: "brandsService/Yamaha.png",
      brand2: "brandsService/Ford.png",
    },
    {
      id: 9,
      brand1: "brandsService/Vespa.png",
      brand2: "brandsService/MG.png",
    },
    {
      id: 10,
      brand1: "brandsService/Jawa.png",
      brand2: "brandsService/Hyundai.png",
    },
  ];

  const OilBrandsServiceData = [
    {
      id: 1,
      brand1: "SparesBrandWeUse/Bosch.png",
      brand2: "SparesBrandWeUse/Varroc.png",
    },
    {
      id: 2,
      brand1: "SparesBrandWeUse/Castrol.png",
      brand2: "SparesBrandWeUse/ASK.png",
    },
    {
      id: 3,
      brand1: "SparesBrandWeUse/Motul.png",
      brand2: "SparesBrandWeUse/Brembo.png",
    },
    {
      id: 4,
      brand1: "SparesBrandWeUse/Mobil.png",
      brand2: "SparesBrandWeUse/Gabriel.png",
    },
    {
      id: 5,
      brand1: "SparesBrandWeUse/Shell.png",
      brand2: "SparesBrandWeUse/Rolon.png",
    },
    {
      id: 6,
      brand1: "SparesBrandWeUse/Exide.png",
      brand2: "SparesBrandWeUse/Fiem.png",
    },
    {
      id: 7,
      brand1: "SparesBrandWeUse/Amaron.png",
      brand2: "SparesBrandWeUse/Osaram Bulb.png",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // GETTING CURRENT LOCAION FROM REDUX
  const currentLocation = useSelector((state) => state.LocationReducer);

  // ACCESSING SELECTED VEHILCE
  const {
    vehicleType: { vehicleName, id },
  } = useSelector((state) => state.SelectionVehicleReducer);

  const handleMobileCarouselClick = (title, redirectionUrl) => {
    const location = currentLocation?.cityName?.toLowerCase();
    ReactGA.event({
      category: "Combos & offers",
      action: title,
      label: title,
    });

    let urlArr = redirectionUrl?.split("/").slice(2).join("/");

    setCustomePickData({
      ...customerPickData,
      vehicleType: { id: "1", vehicleName: "Car" },
      // vehicleType: { id: id, vehicleName: vehicleName },
    });
    setServiceCategoryId(1);

    if (title === "Gativan App Car Service" || "Gativan App Bike Service") {
      window.location.href = redirectionUrl;
    } else {
      let urlArr = redirectionUrl?.split("/").slice(23).join("/");

      navigation(urlArr);
    }

    // switch (title) {
    //   case "Monsoon":
    //     setCustomePickData({
    //       ...customerPickData,
    //       vehicleType: { id: "1", vehicleName: "Car" },
    //     });
    //     setServiceCategoryId(1);
    //     navigation(navigateUrl);
    //     break;
    //   case "Service":
    //     setCustomePickData({
    //       ...customerPickData,
    //       vehicleType: { id: "1", vehicleName: "Car" },
    //     });
    //     setServiceCategoryId(1);
    //     navigation(navigateUrl);
    //     break;
    //   case "Wheel":
    //     setCustomePickData({
    //       ...customerPickData,
    //       vehicleType: { id: "1", vehicleName: "Car" },
    //     });
    //     setServiceCategoryId(9);
    //     navigation(navigateUrl);
    //     break;
    //   case "Insurance Clain Card":
    //     setCustomePickData({
    //       ...customerPickData,
    //       vehicleType: { id: "1", vehicleName: "Car" },
    //     });
    //     setServiceCategoryId(9);
    //     navigation(navigateUrl);
    //     break;
    // }
  };

  // const handleMobileCarouselClick = (ga, img) => {
  //   const location = currentLocation?.cityName?.toLowerCase();
  //   ReactGA.event({
  //     category: "Combos & offers",
  //     action: ga,
  //     label: ga,
  //   });
  //   switch (img) {
  //     case "Monsoon":
  //       setCustomePickData({
  //         ...customerPickData,
  //         vehicleType: { id: "1", vehicleName: "Car" },
  //       });
  //       setServiceCategoryId(1);
  //       navigation(`/${location}/car/periodic-services`);
  //       break;
  //     case "Service":
  //       setCustomePickData({
  //         ...customerPickData,
  //         vehicleType: { id: "1", vehicleName: "Car" },
  //       });
  //       setServiceCategoryId(1);
  //       navigation(`/${location}/car/periodic-services`);
  //       break;
  //     case "Wheel":
  //       setCustomePickData({
  //         ...customerPickData,
  //         vehicleType: { id: "1", vehicleName: "Car" },
  //       });
  //       setServiceCategoryId(9);
  //       navigation(`/${location}/car/wheel-and-tyre-care`);
  //       break;
  //     case "Insurance Claim Card":
  //       setCustomePickData({
  //         ...customerPickData,
  //         vehicleType: { id: "1", vehicleName: "Car" },
  //       });
  //       setServiceCategoryId(9);
  //       navigation(`/${location}/car/insurance-claim`);
  //       break;
  //   }
  // };

  useEffect(() => {
    scrollToTop();
    setIsCheckOut(true);
    setIsShowSearch(false);
    // dispatch(handleChangeVehicelComponent(false));
  }, []);

  const imageUrls = [
    {
      id: 1,
      title: "Insurance",
      imageUrl:
        "https://testapi.gativan.in/api/homeCarousel/Insurance_1694783653138.png",
      redirectionUrl: "www.gativan.in/pune/car/insurance-claim",
    },
    {
      id: 2,
      title: "Wheel & Type",
      imageUrl:
        "https://testapi.gativan.in/api/homeCarousel/Wheel_1694783591807.png",
      redirectionUrl: "www.gativan.in/pune/car/wheel-and-tyre-care",
    },
  ];

  const browserData = useSelector((state) => state.BrowserUserData);

  // useEffect(() => {
  //   if (browserData.city == null || browserData.city.trim() === "") {
  //     // Render the CityModal if city data is not available or empty
  //     // Replace 'YourCityModalComponent' with the actual name of your CityModal component
  //     return <CityModal />;
  //   } else {
  //     // Navigate to the city route if city data is available
  //     navigation(`/${browserData.city}`);
  //   }
  // }, []);

  // console.log("mainnnnnnnnn", browserData.city);

  const URL = httpCommon.defaults.baseURL;

  const [data, setData] = React.useState([]);

  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  React.useEffect(() => {
    // Axios GET request
    axios
      .get(`${URL}/cart/${getBrowserIdRedux}`)
      .then((response) => {
        // Handle successful response
        setData(response.data.result.city);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
  }, [browserData]); // Empty dependency array means this effect runs once on component mount

  // useEffect(() => {
  //   if (data == null || data === "") {
  //     // Render the CityModal component or perform some action
  //     // For example:
  //     // setIsCityModalOpen(true); // Assuming you have a state variable to control modal visibility
  //   } else {
  //     // Perform navigation based on browserData.city
  //     navigation(`/${browserData.city}`);
  //   }
  // }, [data, browserData.city, navigation]); // Include variables that the useEffect depends on

  // useEffect(()=>{

  // },[])

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenLocationPopup");
    if (!hasSeenPopup) {
      // If the popup hasn't been seen before, show it
      setShowPopup(true);
    }
  }, []);

  const handleLocationSelection = () => {
    // Set a flag in local storage to indicate the popup has been seen
    localStorage.setItem("hasSeenLocationPopup", "true");
    setShowPopup(false);
  };

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();

  useEffect(() => {
    dispatch(chooseLocation({ cityName: location }));
    localStorage.removeItem("cityName123");
    localStorage.setItem("cityName123", location);
  }, []);

  return (
    <>
      {/* {browserData.city == null ? : " "} */}

      {/* {
  
}  */}

      {/* {showPopup && (
       <CityModal />
      )} */}

      {/* <CityModal /> */}

      <Helmet>
        <title>Car and Bike Repair and Maintenance Sevices - Gativan</title>
        <meta
          name="description"
          content="Book Car And Bike Service And Repairs, Car Wash, Denting-Painting, Car Wheel Care, Car AC Services And Many More At Best Car And Bike Garages in Pune"
        />
      </Helmet>
      <Box className="home-container">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          infinite={true}
          autoPlay
          autoPlaySpeed={4000}
          centerMode={false}
          className="home-desktop-carousel"
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {DestopHeroImage.map((item) => {
            const { id, imageUrl } = item;

            return (
              <img
                key={id}
                src={imageUrl}
                style={{
                  display: "block",
                  height: "100%",
                  margin: "auto",
                  width: "100%",
                }}
              />
            );
          })}
        </Carousel>
        <Box className=" home-image">
          {/* <Box className="home-background-logo">
            <img
              src={process.env.PUBLIC_URL + "/Assets/homepage/Hero Image.png"}
              alt=""
            />
          </Box> */}
          <Box className="home-carousel">
            <Carousel
              // showDots={true}
              responsive={{
                mobile: {
                  breakpoint: { max: 768, min: 0 },
                  items: 1,
                  slidesToSlide: 1, // optional, default to 1.
                },
              }}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay
              autoPlaySpeed={4000}
              transitionDuration={1000}
              removeArrowOnDeviceType={["mobile"]}
              deviceType
              // dotListClass="custom-dot-list-style"
            >
              {
                DestopHeroImage?.map((item) => {
                  const { id, imageUrl, redirectionUrl, title } = item;

                  // console.log(redirectionUrl)
                  return (
                    <Box
                      key={id}
                      className="home-carousel-item"
                      onClick={() =>
                        handleMobileCarouselClick(title, redirectionUrl)
                      }
                    >
                      {/* <img src={`${baseURL}${imageUrl}`} alt={title} /> */}
                      <Helmet>
                        <link
                          rel="preload"
                          as="image"
                          href={imageUrl}
                          imagesrcset="wolf_400px.jpg 400w, wolf_800px.jpg 800w"
                        />
                      </Helmet>
                      <img src={imageUrl} alt={title} />
                    </Box>
                  );
                })

                // imageUrls?.map((item) => {
                //   const { id, imageUrl, redirectionUrl, title } = item;
                //   return (
                //     <Box
                //       key={id}
                //       className="home-carousel-item"
                //       onClick={() =>
                //         handleMobileCarouselClick(title, redirectionUrl)
                //       }
                //     >
                //       <img src={`${baseURL}${imageUrl}`} alt={title} />
                //       <Helmet>
                //           <link
                //             rel="preload"
                //             as="image"
                //             href={`${baseURL}${imageUrl}`}
                //             imagesrcset="wolf_400px.jpg 400w, wolf_800px.jpg 800w"
                //           />
                //         </Helmet>
                //       <img src={imageUrl} alt={title} />
                //     </Box>
                //   );
                // })

                // MobileCarouselData?.map((item) => {
                //   const { id, img, ga } = item;
                //   return (
                //     <Box
                //       key={id}
                //       className="home-carousel-item"
                //       onClick={() => handleMobileCarouselClick(ga, img)}
                //     >
                //       <img
                //         src={
                //           process.env.PUBLIC_URL +
                //           `/Assets/homepage/MobileViewImages/${img}.png`
                //         }
                //         alt="First slide"
                //       />
                //     </Box>
                //   );
                // })
              }
            </Carousel>
          </Box>
        </Box>

        {/* -----------------Cart --------------------------------- */}
        <Box className="home-cart">
          <Box className="cart-card">
            <MainSelection />
          </Box>
        </Box>

        {/* ---------------Main contain----------------------------- */}

        <Box className="home-mainconetin">
          {/* --------------------------OurServices---------------------------------------- */}
          <Box className="home-our-services">
            <OurServices />
          </Box>

          {/* ------------------------------------------ service comparison ------------------- */}

          {/* <Box className="">
            <Box className="homepage-headers">
              <h2>Service Comparison</h2>
            </Box>
            <Box>

              <ServiceComparison />
            </Box>
          </Box> */}

          {smallPhone && (
            <Box
              container
              position="relative"
              className="home-gativan-video-section"
            >
              <Suspense fallback={<LoaderSpinner />}>
                <VideoSection />
              </Suspense>
            </Box>
          )}

          <Box container position="relative" className="home-gativan-experts">
            <GativanExperts />
          </Box>

          {/* --------------------------Refer & Earn----------------------------------- */}
          {/* <Box className="home-refer-earn">
            <Box className="home-refer-image">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/images/blog/refer & earn.png"
                }
                alt=""
              />
            </Box>
            <Box className="refer-div">
              <h2>Refer & Earn</h2>
              <p>
                Invite Your Friend and get <font>RS 50</font> Off on your Next
                Booking
              </p>
            </Box>
          </Box> */}

          {/* ------------------------How Gativan Works------------------------------------- */}
          <Box className="home-how-gativan-work">
            <HowGativanWorks />
          </Box>

          {/* --------------------------Gativan Motion Performance----------------------------------- */}
          {/* <Box className="home-gativan-motion-performance">
            <GativanMotionPerformance />
          </Box> */}

          {/* ---------------------------- Workshop Near You ---------------------------- */}
          <Box className="home-gativan-luxury">
            <Box className="homepage-headers">
              <h2>Choose the Workshop Near You</h2>
            </Box>
            <Box>
              <WorkshopNearYou />
            </Box>
          </Box>

          {/* ------------------------------Gativan Luxury------------------------------- */}
          <Box className="home-gativan-luxury">
            <Box className="homepage-headers">
              <h2>Gativan Luxury</h2>
            </Box>
            <Box>
              <CarouselComponent CarouselData={gativanLuxuryData} />
            </Box>
          </Box>

          {/* --------------Featured Services------------------- */}
          <Box className="home-royal-enfield">
            <Box className="homepage-headers">
              <h2>Featured Services</h2>
            </Box>
            <CarouselComponent CarouselData={featuredServicesData} />
          </Box>

          {/* ----------------------------Brands We Service--------------------------------*/}
          <Box className="home-brand-weservice">
            <Box className="homepage-headers">
              <h2>Brands We Service</h2>
            </Box>
            <CarouselLogo CarouselLogoData={brandWeServiceData} />
          </Box>

          {/* ---------------------What our customers say about us?------------------------ */}
          <Box container position="relative" className="home-woc-say">
            <Testimonials />
          </Box>

          <Box>
            <BlogPost />
          </Box>






          {/* ----------------------------Oil Brand We Use-------------------------------- */}
          {/* <Box className="home-oil-brand-weservice">
            <Box className="homepage-headers">
              <h2>Spare Brands We Use</h2>
            </Box>
            <CarouselLogo CarouselLogoData={OilBrandsServiceData} />
          </Box> */}

          {/* -----------------------------GativanBenefits------------------------------- */}
          {/* <Box className="home-benefites">
            <GativanBenefits />
          </Box> */}

          {/* --------- Vehicle Service price list------------------------- */}
          {/* <Box className="home-vsprice">
            <Box className="homepage-headers">
              <h2>Vehicle Service price list (Pune ,India - 2022)</h2>
            </Box>
            <Box className="VSprice-btns">
              <Button
                variant="text"
                onClick={() => {
                  setShow(VSPrice_Data_Car);
                  setcolorindexform(0);
                }}
                style={{
                  fontWeight: "bold",
                  backgroundColor: colorindexform == 0 ? "#7B8BD4" : "#fff",
                  color: colorindexform == 0 ? "#fff" : "#122164",
                }}
              >
                Car{" "}
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setShow(VSPrice_Data_Bike);
                  setcolorindexform(1);
                }}
                style={{
                  fontWeight: "bold",
                  backgroundColor: colorindexform == 0 ? "#fff" : "#7B8BD4",
                  color: colorindexform == 0 ? "#122164" : "#fff",
                }}
              >
                Bike
              </Button>
            </Box>
            <ServicePriceListTable Data={show} />
          </Box> */}

          {/* -------------------------------Blog Post & Article------------------------------------ */}
          {/* <Box className="home-blog-post">
            <h2>Blog Post & Article</h2>
          </Box> */}

          {/* --------------Frequently Asked Vehicle service Question---------------------- */}
          {/* <Box className="home-faq">
            <Box className="homepage-headers" id="FAQ">
              <h2>Frequently Asked Vehicle service Questions</h2>
            </Box>
            <FAQ_home data={FAQData} />
          </Box> */}

          {/* ------------------------------------WhyChooseGativan---------------------------------------- */}
          <Box className="home-wch-gativan" position="relative">
            <WhyChooseGativan />
          </Box>

          {/* ------------------------------------ SERVICES SECTION STARTS ---------------------------------------- */}
          <Box className="home-wch-gativan" position="relative">
            {/* <BookServices /> */}
          </Box>
          {/* ------------------------------------ SERVICES SECTION ENDS---------------------------------------- */}

          {/* -------------------------Download the app-------------- */}

          <Box className="download-the-app">
            <DownloadApp />
          </Box>
        </Box>
      </Box>
    </>
  );
}
