import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Divider, Modal, Typography } from "@mui/material";
import "./ViewDetailedBill.css";
import { useSelector } from "react-redux";

const ViewDetailedBill = ({ open, close, services, youPayAmount }) => {
  let total = services?.reduce((acc, ele) => acc + ele?.displayPrice, 0);
  const { subTotal, couponDiscount, cartItems, cartTotal, payNow } =
    useSelector((state) => state.CartTotalReducer);

  return (
    <Box>
      <Modal
        className="view-detailed-bill-Modal"
        keepMounted
        open={open}
        onClose={close}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal_body">
          <Box className="header-wrapper">
            <Typography className="header-titles">Items</Typography>
            <Box className="price-wrapper">
              <Typography className="header-titles">Price</Typography>
            </Box>
          </Box>

          {services?.map((service) => {
            return (
              <Box className="service-wrapper">
                <Typography className="service-fields">
                  {service?.service}
                </Typography>
                <Box className="price-wrapper">
                  <Typography className="service-price-fields">
                    <CurrencyRupeeIcon className="footer-total-rupee-icon" />
                    <span>{service?.displayPrice}</span>
                  </Typography>
                </Box>
              </Box>
            );
          })}

          <Divider color="#000000" sx={{ height: 2, width: "100%" }} />
          <Box className="header-wrapper">
            <Typography className="bill-title">Bill Details</Typography>
          </Box>
          <Box className="bill-wrapper">
            <Typography className="bill-fields">Sub Price</Typography>
            <Box className="price-wrapper">
              <Typography className="bill-price-fields">
                <CurrencyRupeeIcon className="footer-total-rupee-icon" />
                <span>{subTotal}</span>
              </Typography>
            </Box>
          </Box>
          <Box className="bill-wrapper">
            <Typography className="bill-fields">You Pay Now</Typography>
            <Box className="price-wrapper">
              <Typography className="bill-price-fields">
                <CurrencyRupeeIcon className="footer-total-rupee-icon" />
                <span>{payNow}</span>
              </Typography>
            </Box>
          </Box>
          {couponDiscount > 0 && (
            <Box className="bill-wrapper">
              <Typography className="bill-fields">Coupon Discount</Typography>
              <Box className="price-wrapper">
                <Typography className="bill-price-fields">
                  <CurrencyRupeeIcon className="footer-total-rupee-icon" />
                  <span>{couponDiscount}</span>
                </Typography>
              </Box>
            </Box>
          )}
          <Divider />
          <Box className="pay-later-bill-wrapper">
            <Typography className="bill-fields">You Pay Later</Typography>
            <Box className="price-wrapper">
              <Typography className="bill-price-fields">
                <CurrencyRupeeIcon className="footer-total-rupee-icon" />
                <span>{cartTotal}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewDetailedBill;
