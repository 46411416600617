import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import './AllSelection.css'
import { myContext } from './MainSelection.Component'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from 'react-redux';
import { getMobileNumber, handleOpenModalState } from '../../action';
import { AppContext } from '../../App';
import { async } from '@firebase/util';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import httpCommon from '../../http-common';

export default function MobileNumber({ wati }) {
    const [mobileNumber, setMobileNumber] = useState('')

    const context = useContext(myContext)
    const { setMobileState, setModalOpen,
        Backbuttonhandle, } = context

    const mobileState = useSelector(
        state => state.MobileSectionReducer
    )


    const location = useSelector((state) => state.LocationReducer);
    
    const URL = httpCommon.defaults.baseURL

    const appContext = useContext(AppContext);
    const { customerPickData } = appContext

    const dispatch = useDispatch();
    const navigation = useNavigate();

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value)
    }

    const handleMobileNumberClick = () => {
        ReactGA.event({
            category: "Leads",
            action: "Clicked on Enter Your Mobile Number",
            label: "Enter Your Mobile Number"
        })
    }

     // ------------------------------------ geting service category from redux --------------------
     const serviceCategory = useSelector(
        state => state.serviceCategoryReducer
    )

    // ------------------------------------ geting service from cart redux --------------------
    const servicesSelectionData = useSelector(
        state => state.AddToCartReducer
    )

    // --------------------------- getting mobile number from redux ---------------------
    const getMobileNumberRedux = useSelector(
        state => state.MobileNumberReducer
    )

    // --------------------------getting current location from redux ---------------------
    const currentLocation = useSelector(
        state => state.LocationReducer
    )

    // ---------------------------- getting selected vehicle from redux -------------------
    const vehicleSelectedData = useSelector(
        (state) => state.SelectionVehicleReducer
    );

    // ---------------------------- getting browerId from redux --------------------
    const getBrowserIdRedux = useSelector(
        state => state.SaveBrowserId
    )
    const cityName = location?.cityName?.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace(/-([a-z])/g, (_, letter) => ` ${letter.toUpperCase()}`);

    // console.log(cityName)
    //--------------------------- post lead obj ---------------------------
    //Aditya
    const postLead = {
        mobileNumber: getMobileNumberRedux.mobileNumber,
        vehicleType: customerPickData.vehicleType.id,
        leadName: "",
        channel: 1,
        brandId: customerPickData.brand.id,
        modelId: customerPickData.model.id,
        variant: customerPickData.variant.id,
        city: cityName,
    }

    //---------------------------------------------- save data w.r.t Browser Id ----------------------

    const SaveDataWBrowser = async (visitorId) => {
        const userDataObj = {
            browserId: visitorId,
            vehicleType: vehicleSelectedData?.vehicleType?.id,
            brandId: vehicleSelectedData?.brand?.id,
            modelId: vehicleSelectedData?.model?.id,
            variantId: vehicleSelectedData?.variant?.id,
            mobileNumber: getMobileNumberRedux?.mobileNumber,
            cartItems: servicesSelectionData?.result,
            city:currentLocation?.cityName
        }

        const saveCart = await axios.post(`${URL}/cart`, userDataObj)
        const result = await saveCart.data

    }

    // ---------------------------- handle check price click ------------------
    const handleCheckPriceClick = async () => {
        if (mobileNumber?.length == 0 || mobileNumber?.length < 10) {
            document.getElementById('mobilevalidation').style.display = 'block'
        }
        else if (mobileNumber?.length != 0 && mobileNumber?.length == 10) {
            document.getElementById('mobilevalidation').style.display = 'none'

            wati(mobileNumber)

            ReactGA.event({
                category: "Leads",
                action: "Clicked on Check Price",
                label: "Check Price",
            });

            const result = await axios.post(`${URL}/leads/save`, postLead)
            const response = await result.data
            if (response.statusCode === 200) {
                navigation(`/${currentLocation?.cityName?.toLowerCase()}/${vehicleSelectedData?.vehicleType?.vehicleName.toLowerCase()}/${serviceCategory?.serviceCategory ? serviceCategory?.serviceCategory?.replaceAll(" ", "-").toLowerCase() : `periodic-service` }/${customerPickData?.brand?.brandName?.replaceAll(" ", "-").toLowerCase()}/${customerPickData?.model?.modelName?.replaceAll(" ", "-").toLowerCase()}/${customerPickData?.variant?.variantName?.replaceAll(" ", "-").toLowerCase()}`)
            }

            // setModalOpen(false)
            // dispatch(handleOpenModalState(false))
            // call post api to save user data as per browser id
            SaveDataWBrowser(getBrowserIdRedux && getBrowserIdRedux)
        }

    }

    const input = document.getElementById("mobile-input");

    useEffect(() => {
        dispatch(getMobileNumber({ mobileNumber: mobileNumber }))

        input?.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                document.getElementById("check-price-btn").click();
            }
        })
    }, [mobileNumber]);

    return (
        <>
            <Box className='selection-sections'>
                {
                    mobileState && <Box>
                        <Box className="select-item">
                            <h6>{<button onClick={Backbuttonhandle}><ArrowBackIcon className='arrow-back-icon' style={{ fontSize: "16px" }} /></button>} Enter Mobile Number</h6>
                        </Box>
                        <Box sx={{ marginTop: '10%' }}>
                            <Box className='checkPrice_section'>
                                <Box>
                                    <Box className='Box' id='mobile-number'>
                                        <span>+91</span>
                                        <input id="mobile-input" onChange={(e) => handleMobileNumberChange(e)} onClick={handleMobileNumberClick} type='tel' pattern='[0-9]{3} [0-9]{3} [0-9]{4}' maxLength='10' title='10 digit code' required mt={2} placeholder='Enter Your Mobile Number' />
                                    </Box>
                                    <Typography variant='span' id='mobilevalidation' mt={1} style={{ color: 'red', display: 'none' }}>Please Enter Your Mobile Number.</Typography>
                                </Box>
                                <Button id="check-price-btn" className='Box checkPrice-button' onClick={handleCheckPriceClick} variant='contained'  >Check Price For Free</Button>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}