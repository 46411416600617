import React, { useState, useEffect, useContext } from 'react';
import "./serviceComparison.css"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Grid, Card, CardContent, Typography, CardActions } from '@mui/material';



import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { getVehicleType } from '../../../services/vehicleservices/vehicleservices';
import httpCommon from '../../../http-common';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../App';




function createData(name, basic, pro, comprehensive) {
    return { name, basic, pro, comprehensive };
}

const carsRows = [
    createData('Engine Oil Replacement', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Air Filter Replacement', "Only Cleaning", <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Oil Filter Replacement', "Only Cleaning", <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Coolant top-up', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Battery Water top-up', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Wire Fluid Replacement', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Scanning', "Basic", <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Heater & Spark Plug Check', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Interior Vacumming', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Washing', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('AC Filter Cleaning', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Front Brake Pad Service', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Rear Brake Shoe Service', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Brake Fluid Top-up', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Fuel Filter Replacement', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Wheel Alignment', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Wheel Balancing', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Gear Oil Top-up', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Throttle Body Cleaning', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Tyre Rotation', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Exterior Deep Cleaning', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
];

const bikeRows = [
    createData('Engine Oil Replacement', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Oil Filter Replacement', "Only Cleaning", <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Air Filter Replacement', "Only Cleaning", <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Carburettor Cleaning and Adjustment', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Clutch Overall', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Complete Bike Check', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Engine Tuning', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Headlight Focus Adjustment', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Horn Tuning', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Rear & Front Brake Adjustment', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Chain Alignment & Tightening/Lubrication', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Exterior Deep Cleaning', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Spark Plug Replacement', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('All Nut & Bolt Tightening', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Chain Spray', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('General Lubrication', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Clutch Play Adjustment', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Bike Wash', <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Wheel And Tyre Care', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Brake Liner Replacement', <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />, <DoneIcon className='check-circle' />),
    createData('Disc Oil Replacement', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Petrol Tank Cleaning', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Battery Charging', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Gear Oil Change', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
    createData('Towing', <CloseIcon className='check-cancle-circle' />, <CloseIcon className='check-cancle-circle' />, <DoneIcon className='check-circle' />),
];

const bikeCards = [
    {
        id: "basic",
        service: "Basic Service",
        price: "Starting @ Rs.795",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Contains up to 20 Basic Services"
            },
            {
                id: 2,
                serviceDetails: "1 Week Warranty"
            },
        ]
    },
    {
        id: "pro",
        service: "Pro Service",
        price: "Starting @ Rs.1520",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Contains up to 24 Pro Services"
            },
            {
                id: 2,
                serviceDetails: "1 Week Warranty"
            }
        ]
    },
    {
        id: "comprehensive",
        service: "Restart Service",
        price: "Starting @ Rs.2369",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Contains up to 30 Restart Services"
            },
            {
                id: 2,
                serviceDetails: "1 Week Warranty"
            }
        ]
    }
]

const carsCards = [
    {
        id: "basic",
        service: "Basic Service",
        price: "Starting @ Rs.2399",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Every 5000 kms/ 3 Months"
            },
            {
                id: 2,
                serviceDetails: "Service Time: 4 Hours"
            },
            {
                id: 3,
                serviceDetails: "1 Month Warranty"
            },
            {
                id: 4,
                serviceDetails: "Contain 9 Services"
            },
        ]
    },
    {
        id: "pro",
        service: "Pro Service",
        price: "Starting @ Rs.3099",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Every 10000 kms/ 6 Months"
            },
            {
                id: 2,
                serviceDetails: "Service Time: 6 Hours"
            },
            {
                id: 3,
                serviceDetails: "1 Month Warranty"
            },
            {
                id: 4,
                serviceDetails: "Basic +6 Extra Pro Services"
            },
        ]
    },
    {
        id: "comprehensive",
        service: "Comprehensive Service",
        price: "Starting @ Rs.3799",
        serviceDetails: [
            {
                id: 1,
                serviceDetails: "Every 15000 kms/ 8 Months"
            },
            {
                id: 2,
                serviceDetails: "Service Time: 8 Hours"
            },
            {
                id: 3,
                serviceDetails: "Pro +6 Extra Comprehensive"
            },
        ]
    }
]

const bikeServices = [
    {
        id: "basic",
        service: "Basic"
    },
    {
        id: "pro",
        service: "Pro"
    },
    {
        id: "comprehensive",
        service: "Restart"
    },
]

const carServices = [
    {
        id: "basic",
        service: "Basic"
    },
    {
        id: "pro",
        service: "Pro"
    },
    {
        id: "comprehensive",
        service: "Comprehensive"
    },
]

export default function ServiceComparison() {

    const [vehicleData, setvehicleData] = useState([])
    const [vehicle, setVehicle] = useState()

    const [select, setSelect] = useState("basic")

    const URL = httpCommon.defaults.baseURL

    const width = window.innerWidth

    const { vehicleType, serviceCategoryType } = useParams();

    const navigation = useNavigate();

    const AppContextData = useContext(AppContext);
    const { customerPickData, setCustomePickData, serviceCategoryId, setServiceCategoryId } = AppContextData


    const columns = [
        { id: 'name', label: 'Service Activities', minWidth: 270 },
        { id: 'basic', label: 'Basic Service', minWidth: 50 },
        { id: 'pro', label: 'Pro Service', minWidth: 50 },
        { id: 'comprehensive', label: vehicle === 1 ? "Comprehensive Service" : "Restart Service", minWidth: 50 },
    ];



    const getVehicleInfo = async () => {
        try {
            const Response = await getVehicleType()
            const VehicleData = await Response.data
            if (VehicleData.statusCode === 200) {
                setvehicleData(VehicleData?.result?.reverse())
            }
        } catch (error) {
            console.log(error)
        }
    }



    // getting current location from redux
    const currentLocation = useSelector(
        state => state.LocationReducer
    )

    const handleBookNow = () => {
        const location = currentLocation.cityName.toLowerCase()
        navigation(`${location}/periodic-services`)
        if (vehicle === 1) {
            setCustomePickData({ ...customerPickData, vehicleType: { id: "1", vehicleName: "Car" } })
        } else if (vehicle === 2) {
            setCustomePickData({ ...customerPickData, vehicleType: { id: "2", vehicleName: "Bike" } })
        }
    }

    useEffect(() => {
        getVehicleInfo();
        setVehicle(customerPickData.vehicleType.id)
    }, [])

    useEffect(() => {
        setVehicle(customerPickData.vehicleType.id)
    }, [customerPickData])

    useEffect(() => {
        // if (vehicle === 1) {
        //     setCustomePickData({ ...customerPickData, vehicleType: { id: "1", vehicleName: "Car" } })
        // } else if (vehicle === 2) {
        //     setCustomePickData({ ...customerPickData, vehicleType: { id: "2", vehicleName: "Bike" } })
        // }
        customerPickData?.vehicleType?.id == 2 ? setServiceCategoryId(10) : setServiceCategoryId(23)
    }, [vehicle, customerPickData])



    return (
        <>
            <Box>
                {/* <Box container className="toggle-container">
                        {vehicleData.map((item) => {
                            const { id, vehicleType, url } = item
                            return (
                                <Button key={id} className="car-btn toggle-btn" variant='text'
                                    onClick={() => {
                                        {
                                            if (id === 2) {
                                                setVehicle(2)
                                                setCustomePickData({ ...customerPickData, vehicleType: { id: "2", vehicleName: "Bike" } })
                                            }
                                            else if(id === 1) {
                                                setVehicle(1);
                                                setCustomePickData({ ...customerPickData, vehicleType: { id: "1", vehicleName: "Car" } })
                                            }
                                        }
                                    }
                                    }
                                    style={{
                                        fontWeight: 'bold',
                                        backgroundColor: vehicle == id ? "#7B8BD4" : "#fff",
                                        color: vehicle == id ? "#fff" : "#000000",
                                    }}> <img src={`${URL}${url}`} /> {vehicleType} </Button>
                            )
                        })}
                    </Box> */}


                <Grid container col={12} className="service-toggle-container" >
                    {(vehicleType == 'car' ? carServices : bikeServices).map((item) => {
                        const { id, service } = item
                        return (
                            <Grid item xs={4} className="service-toggle" key={id} onClick={() => { setSelect(id) }}>
                                <Button className="service-toggle-btn"
                                    sx={{
                                        backgroundColor: select == id ? '#E9EDFB !important' : '',
                                        color: select == id ? 'black' : 'gray',
                                        borderBottom: select == id ? '4px solid #7B8BD4' : '',
                                        textTransform: "none"
                                    }}
                                >{service}</Button>
                            </Grid>
                        )
                    })}
                </Grid>



                <Grid container spacing={3} mb={4} className="card-table-grid">
                    <Grid item sm={3} m="auto" className="empty-container">

                    </Grid>

                    {
                        (customerPickData?.vehicleType?.id == 1 ? carsCards : bikeCards).map((item) => {
                            const { id, service, price, serviceDetails } = item


                            return (
                                <Grid item key={id} sm={3} xs={12} sx={{ display: width < "768" ? (select === id ? "block" : "none") : '' }}>
                                    <Card className="card" >
                                        <CardContent>
                                            <Typography className="card-heading" variant="h6" component="div">
                                                {service}
                                            </Typography>
                                            <Typography className="card-desc" color="text.secondary">
                                                <ul>
                                                    {
                                                        serviceDetails?.map((item) => {
                                                            const { id, serviceDetails } = item
                                                            return (
                                                                <li key={id}>{serviceDetails}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Typography>
                                            <Typography className="card-price" variant="body2">
                                                {price}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button className="card-btn" onClick={() => handleBookNow()} variant="contained" size="small">Book Now</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                    }

                </Grid>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className="table-head-cell"
                                            // sx={{display:column.id === "name" || column.id === select?"":"none"}}
                                            sx={{zIndex:0, display: width < "768" ? (column.id === "name" || column.id === select ? "" : "none") : '', borderLeft: "1px solid #E7EBF0", borderRight: "1px solid #E7EBF0" }}
                                        // style={{ minWidth: column.minWidth, backgroundColor: "#F3F6F9", fontWeight: "800", fontSize: "16px" }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(vehicle === 1 ? carsRows : bikeRows).map((row) => {
                                    const className = String(row.name).replaceAll(" ", "-").toLowerCase()
                                    return (
                                        <TableRow hover role="checkbox" className={className} tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell className='table-body-cell' key={column.id} align={column.align} sx={{ display: width < "768" ? (column.id === "name" || column.id === select ? "" : "none") : '', borderLeft: "1px solid #E7EBF0", borderRight: "1px solid #E7EBF0" }}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    )
}



