import {
  Box,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./ViewDetails.css";
// import FAQ_ViewDetails from "../faq/FAQ__ViewDetails";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppContext } from "../../App";
import { chooseLocation, handleServiceCategory } from "../../action";
import httpCommon from "../../http-common";
import {
  getServiceByCategoryAndService, getServiceByCategoryAndServiceNotSelected
} from "../../services/vehicleservices/vehicleservices";
import FAQ_home from "../ReuseComponent/FAQ/FAQ_home";
import GativanBenefits from "../ReuseComponent/gativanBenefits/GativanBenefits";
import Testimonials from "../ReuseComponent/testimonials/Testimonials";
import ViewCartFooter from "../ReuseComponent/viewCartFooter/ViewCartFooter";




import { useNavigate } from 'react-router-dom';
import useHistoryNavigation from "../useHistoryNavigation/useHistoryNavigation";

// const {  } = useSelector((state) => state.SelectionVehicleReducer);










// =====================View Details Carousel=============//
const viewdetails_carousel = [
  {
    id: 1,
    images: "Engine Oil Replacement.png",
  },
  {
    id: 2,
    images: "Air Filter Cleaning.png",
  },
  {
    id: 3,
    images: "Coolant top-up.png",
  },
  {
    id: 4,
    images: "Engin oil replacement.png",
  },
];
// ==================Essential Service=======================//
const essentialService = [
  {
    id: 1,
    images: "/Assets/viewdetalis/Engine Oil Replacement.png",
    name: "Engine Oil Replacement ",
  },
  {
    id: 2,
    images: "/Assets/viewdetalis/Oil Filter replacement.png",
    name: "Oil Filter  Replacement",
  },
  {
    id: 3,
    images: "/Assets/viewdetalis/Air Filter Cleaning.png",
    name: "Air Filter Cleaning ",
  },
];
// ==================Performance Service=======================//
const performanceService = [
  {
    id: 1,
    images: "/Assets/viewdetalis/Heat & Spark Plug Checking.png",
    name: "Heat & Spark Plug Checking ",
  },
  {
    id: 2,
    images: "/Assets/viewdetalis/Coolant top-up.png",
    name: "Coolant top-up ",
  },
];
// =======================Additional Services===================//
const additionalServices = [
  {
    id: 1,
    services: "Wiper Fluid Replacement",
  },
  {
    id: 2,
    services: "Car Wash",
  },
  {
    id: 3,
    services: "Battery Water Top Up",
  },
];
// ====================Rating and Review==================//
const ratingReview = [
  {
    id: 1,
    photo: "xyz.png",
    name: "Shailesh Jian",
    desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
  },
  {
    id: 2,
    photo: "xyz.png",
    name: "Shailesh Jian",
    desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
  },
  {
    id: 3,
    photo: "abc.png",
    name: "Shailesh Jian",
    desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
  },
];
// =====================Gativan Benefits===================//
const gativanBenefits = [
  {
    id: 1,
    image: "doorstep",
    title: "Free Doorstep Pick up and Drop",
  },

  {
    id: 2,
    image: "doorstep",
    title: "100% Genuine Spare Parts",
  },
  {
    id: 3,
    image: "doorstep",
    title: "Quality Workmanship",
  },
];

const FAQData = [
  {
    title: "How can I book my vehicle service on Gativan?",
    body: "Book your service within a minute. Dive in to gativan.in.Select your vehicle type (bike/car),select the brand (e.g. Honda) followed by the vehicle (e.g. Amaze). Enter your phone number and tap on Check price to book your slots! Select your convenient time slots and book it! We offer free pick up and delivery all over Pune, so you don’t miss out the best moments with your dear ones! ",
  },
  {
    title: "How much do you charge for Periodic Servicing?",
    body: " Gativan’s Periodic Servicing starts at Rs. 795 for bikes and Rs. 2399 for cars.",
  },
  {
    title: "Why should I choose Gativan in Pune?",
    body: "Gativan offers best bike/car services with best prices in Pune. Not only you get a quality service from our trained mechanics, but also avail transparency throughout.",
  },

  {
    title: "Can I choose a Gativan Service Centre of my choice in the city?",
    body: "Yes, definitely! Rest assured, all our service stations are trained for quality servicing. We will assign you the near most service centre based on your location.",
  },
];

export default function ViewDetails() { 
  useHistoryNavigation()

  const { location } =
    useParams();
  
  const navigate = useNavigate();
  // const [shouldGoBack, setShouldGoBack] = useState(false);


  // useEffect(() => {
  //   const handlePopstate = (event) => {
  //     if (!shouldGoBack) {
  //       setShouldGoBack(true);
  //     }
  //   };

  //   window.addEventListener('popstate', handlePopstate);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, [shouldGoBack]);

  // useEffect(() => {
  //   const shouldGoBackFromStorage = sessionStorage.getItem('shouldGoBack');
  //   if (shouldGoBackFromStorage === 'true') {
  //     sessionStorage.removeItem('shouldGoBack');
  //     setShouldGoBack(true);
  //   }
  // }, []);

  // useEffect(() => {

  //   if (shouldGoBack) {
  //     // Redirect to a specific URL when shouldGoBack is true
  //    navigate(-1)
  //     setShouldGoBack(false); // Reset shouldGoBack after navigating
  //   }
  // }, [shouldGoBack]);
  

  const [data, setData] = useState([]);


  // get services from redux
  const ServiseReduxData = useSelector((state) => state.AddToCartReducer);

  const {
    customerPickData,
    setCustomePickData,
    serviceCategoryId,
    setServiceCategoryId,
  } = useContext(AppContext);
  const dispatch = useDispatch(); 

  let footerBtn = "Add Service";
  const {vehicleType, serviceCategoryType, model, variant ,service} =
  useParams();

  
 useEffect(() => {
  dispatch(
    handleServiceCategory({
      serviceCategoryId: serviceCategoryId,
      serviceCategory: serviceCategoryType,
    })
  );
}, [serviceCategoryType]);
 



const fetchData = async (vehicleType, serviceCategoryType, service, model, variant) => {
  try {
    const response = await getServiceByCategoryAndService(vehicleType, serviceCategoryType, service, model, variant); 
    const result = await response?.data;
    setData(result?.result);
  } catch (error) {
    console.error('There was an error fetching the data:', error);
  }
};



const fetchDataNotSelected = async (vehicleType, serviceCategoryType, service) => {
  try {
    const response = await getServiceByCategoryAndServiceNotSelected(vehicleType, serviceCategoryType, service); 
    const result = await response?.data;
    setData(result?.result);
  } catch (error) {
    console.error('There was an error fetching the data:', error);
  }
};


 useEffect(() => {
  if (variant)
    fetchData(vehicleType, serviceCategoryType, service, model, variant);
  else
  fetchDataNotSelected(vehicleType, serviceCategoryType, service)
}, [vehicleType, serviceCategoryType, service, model, variant]);

ServiseReduxData.result?.filter(e => {
  if (e?.serviceId == data?.serviceId) {
    footerBtn = "Added"
  }
})

useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  dispatch(chooseLocation({ cityName: location }));
  localStorage.removeItem("cityName123");
  localStorage.setItem("cityName123", location);
}, []);



  return (
    <>
      {/*------------------View Details Container-------------*/}
      <Box>
        
        <Box className="view_details_container" sx={{ marginTop: "0.5rem" }}>
          <Box className="service-detalis-section">
            {/* <Typography className="heading">{viewDetailsData?.services}</Typography> */}
            <Typography className="heading">{data?.service}</Typography>
            <Box className="service-details-container">
              {data?.detailsList?.map((item, index) => {
                const { id, detail } = item;
                return (
                  <React.Fragment key={index}>
                    <Box className="service-details">
                      <Box>
                        <FiberManualRecordIcon
                          className="commonMenu-circleIcon"
                          style={{ color: "#122164" }}
                          fontSize="1"
                        />
                      </Box>
                      <Typography className="service-details-heading">
                        {detail}
                      </Typography>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
         
          <Box className="whats_included">
            <Box className="homepage-headers">
              <h2> What's included </h2>
            </Box>
            <Box className="service-activities">
              {data?.activities?.map((item, index) => {
                const { id, activity } = item;
                return (
                  <Box key={index}>
                    <p>
                      <CheckCircleIcon className="commonMenu-checkIcon" />
                      {activity}
                    </p>
                  </Box>
                );
              })}
            </Box>
           
          </Box>
         
          <Box className="gativan-benefits">
            <GativanBenefits />
          </Box>
          {/*-----------FAQ  -------*/}
          <Box className="faq">
            <Box className="homepage-headers">
              <h2> FAQ </h2>
            </Box>
            <FAQ_home data={FAQData} />
          </Box>
          {/*-----------Rating and Review  -------*/}
          <Box className="rating_review">
            <Testimonials />
            
          </Box>
        </Box>
       

        <Box className="mobile-view-footer">
          <ViewCartFooter
            buttonName={footerBtn}
            serviceData={data}
            serviceCategoryType={serviceCategoryType}
          />
        </Box>
      </Box>
    </>
  );
}