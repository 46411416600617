import { Box, Button, Grid } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import './AddToCart.css'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { removeServiceFromCart } from '../../../action';
import { ServiceContext } from '../VehicleServiceItems';

import ReactGA from "react-ga4";
import axios from 'axios';
import httpCommon from '../../../http-common';
import { getCartBrowserId } from '../../../services/vehicleservices/vehicleservices';



export default function AddToCart() {

  const [browserIdDetails, setBrowserIdDetails] = useState({});

  const handleRemoveServiceFromCart = useContext(ServiceContext)

  const dispatch = useDispatch()

  const URL = httpCommon.defaults.baseURL

  let TotalPrice = 0;

  let PayNowPrice = 50;

  const servicesData = useSelector(
    state => state.AddToCartReducer
  )

  const browserData = useSelector(
    state => state.BrowserUserData
  )


  //---------------------------------------------- getting Browser Id ----------------------

  const getBrowserIdRedux = useSelector(
    state => state.SaveBrowserId
  )

  // get user data w.r.t. browser id
  const getUserDataByBrowserId = async (browserId) => {
    try {
      const Response = await getCartBrowserId(browserId);
      const data = await Response.data;
      if (data.statusCode == 200) {
        setBrowserIdDetails(data.result)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const deleteBrowserCart = async (serviceId) => {
    const filterBrowserCart = browserIdDetails?.cartItems?.filter((i) => {
      return i.serviceId == serviceId;
    })


    const cartItemId = filterBrowserCart[0]?.cartItemId;
    try {
      const deleteCartData = await axios.delete(`${URL}/cart/item/${cartItemId}`);
      const result = await deleteCartData.data;
    } catch (err) {
      console.log(err)
    }

  }

  const handelRemoveservice = (index, item) => {
    handleRemoveServiceFromCart(item)
    dispatch(removeServiceFromCart(index))
    deleteBrowserCart(item?.serviceId)
  }

  const handleCheckoutClick = () => {
    ReactGA.event({
      category: "Checkout desktop view",
      action: "Clicked on Checkout desktop view",
      label: "Checkout"
    })
  }


  useEffect(() => {
    getBrowserIdRedux && getUserDataByBrowserId(getBrowserIdRedux)
  }, [getBrowserIdRedux, servicesData]);



  return (
    <>
      {servicesData?.result?.length === 0 ?
        <Box className="empty-cart">
          <img src={process.env.PUBLIC_URL + "/Assets/images/addtocart/EmptyCart.png"} />
        </Box> :
        <Box className="addtocart-container">
          <Box className="addtocart-box">
            {
              servicesData?.result?.map((item, index) => {

                TotalPrice += parseInt(item.displayPrice)

                return (
                  <Grid key={item.index} container pt={1}>
                    <Grid item sm={6} className='addtocart-serviceName' >
                      <h6 >{item.service}</h6>
                    </Grid>
                    <Grid item sm={6} className='addtocart-servicePrice' display='flex' justifyContent='end' >
                      <h6>{item.displayPrice} </h6> <CancelIcon onClick={() => handelRemoveservice(index, item)} className='cancel-icon' />
                    </Grid>
                  </Grid>
                )
              })
            }
          </Box>
          <Box className='addtocart-totalprice'>
            <Box className='total' display='flex' justifyContent='space-between'>
              <b>Total:</b>
              <b className='total-rupee'><CurrencyRupeeIcon /> {TotalPrice}</b>
            </Box>
            <Box className="paynow-section" display='flex' justifyContent='space-between'>
              <b>Pay Now:</b>
              <b className='paynow-rupee'><CurrencyRupeeIcon /><span style={{ color: '#7B8BD4' }}>0</span></b>
            </Box>
            <Box className='paylater' display='flex' justifyContent='space-between'>
              <b>Pay After Service:</b>
              <b className='paylater-rupee'><CurrencyRupeeIcon /> {TotalPrice}</b>
            </Box>
            <Box mt={2}>
              <Link to='/checkout' className='addtocart-checkoutbutton-link' >
                <Button className='addtocart-cheackoutButton' onClick={handleCheckoutClick} fullWidth>Checkout</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      }

    </>
  )
}