import React, { createContext, useState, useEffect } from "react";
import ConditionalSelction from "./ConditionalSelection";
import { Box, Modal } from "@mui/material";
import "./AllSelection.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SelectVehicle from "./SelectVehicle";
import MobileNumber from "./MobileNumber";
import {
  handleBrandState,
  handleChangeVehicelComponent,
  handleChangeVehicleState,
  handleMobileSection,
  handleModelState,
  handleOpenModalState,
  handleVariantState,
} from "../../action";
// import { useDispatch } from 'react-redux';
// import { SetCartSelection_redux } from '../../action';

//Create Context and export
export const myContext = createContext();

export default function MainSelection() {
  // const [BrandState, setBrandState] = useState(false);
  // const [modelState, setmodelState] = useState(false);
  // const [variantState, setvariantState] = useState(false);

  const [vehicleState, setVehicleState] = useState(false);
  const [mobileState, setMobileState] = useState(false);

  const [changeVehicle, setChangeVehicle] = useState();

  const [vehicleTypeState, setVehicleTypeState] = useState("Car"); //select Car or Bike

  const width = window.innerWidth;

  const dispatch = useDispatch();

  const BrandState = useSelector((state) => state.BrandReducer);

  const modelState = useSelector((state) => state.ModelReducer);

  const variantState = useSelector((state) => state.VariantReducer);

  const { location, vehicleType, serviceCategoryType, brand, model, variant,garagenearme } =
    useParams();




    
  // getting current location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);

  // ----------------------- heading converted to Pascal Casing Function --------------------------
  function toPascalCase(string) {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(
        new RegExp(/\s+(.)(\w*)/, "g"),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  }

  let areaSplittedArray;

  const cartChangeVehicle = useSelector(
    (state) => state.ChangeVehicleComponent
  );

  // ----- Modal open in mobile device -------------
  // const [Modalopen, setModalOpen] = React.useState(false);

  const Modalopen = useSelector((state) => state.OpenModalReducer);

  const Backbuttonhandle = () => {
    if (width < "768") {
      switch (true) {
        case mobileState:
          // setvariantState(true)
          dispatch(handleVariantState(true));
          // setMobileState(false)
          dispatch(handleMobileSection(true));
          setVehicleState(false);
          break;
        case variantState:
          // setmodelState(true)
          dispatch(handleModelState(true));
          // setvariantState(false)
          dispatch(handleVariantState(false));
          setVehicleState(false);
          break;
        case modelState:
          // setBrandState(true)
          dispatch(handleBrandState(true));
          // setmodelState(false)
          dispatch(handleModelState(false));
          setVehicleState(false);
          break;
        case BrandState:
          setVehicleState(true);
          // setBrandState(false)
          dispatch(handleBrandState(false));
          break;
      }
      if (vehicleState) {
        setVehicleState(false);
        // setModalOpen(false)
        dispatch(handleOpenModalState(false));
      }
    } else if (width > "768") {
      switch (true) {
        case modelState:
          // setBrandState(true)
          dispatch(handleBrandState(true));
          // setmodelState(false)
          dispatch(handleModelState(false));
        case variantState:
          // setmodelState(true)
          dispatch(handleModelState(true));

          // setvariantState(false)
          dispatch(handleVariantState(false));
      }
      if (BrandState) {
        // setBrandState(false)
        dispatch(handleBrandState(false));

        // setmodelState(false)
        dispatch(handleModelState(false));
      }
    }
  };

  const mydata = {
    BrandState,
    //  setBrandState,
    modelState,
    // setmodelState,
    variantState,
    // setvariantState,
    vehicleTypeState,
    setVehicleTypeState,
    vehicleState,
    setVehicleState,
    mobileState,
    setMobileState,
    // setModalOpen,
    Backbuttonhandle,
    changeVehicle,
    setChangeVehicle,
  };

  const ModalClosehandle = () => {
    // setModalOpen(false)
    dispatch(handleChangeVehicelComponent(false));
    dispatch(handleOpenModalState(false));
  };

  useEffect(() => {
    if (cartChangeVehicle) {
      if (
        vehicleType != undefined &&
        serviceCategoryType != undefined &&
        brand == undefined
      ) {
        // setBrandState(true)
        dispatch(handleBrandState(true));
      } else if (
        vehicleType != undefined &&
        serviceCategoryType != undefined &&
        brand != undefined &&
        model == undefined
      ) {
        // setBrandState(false)
        dispatch(handleBrandState(false));
        // setmodelState(true)
        dispatch(handleModelState(true));
      } else if (
        vehicleType != undefined &&
        serviceCategoryType != undefined &&
        brand != undefined &&
        model != undefined &&
        variant == undefined
      ) {
        // setvariantState(true)
        dispatch(handleVariantState(true));
      } else {
        // if(window.innerWidth >'768'){
        // setBrandState(true)
        dispatch(handleBrandState(true));
        // }
      }
    }
    // setChangeVehicle(cartChangeVehicle)
    dispatch(handleChangeVehicleState(cartChangeVehicle));
  }, [cartChangeVehicle, vehicleType, brand, model, variant]);

  useEffect(() => {
    if (window.innerWidth < "768") {
      if (!cartChangeVehicle) {
        if (!Modalopen) {
          // setBrandState(false)
          dispatch(handleBrandState(false));
          // setmodelState(false)
          dispatch(handleModelState(false));
          // setvariantState(false)
          dispatch(handleVariantState(false));

          setVehicleState(false);
          // setMobileState(false)
          dispatch(handleMobileSection(false));
        }
      }
    }
  }, [Modalopen, cartChangeVehicle]);

  useEffect(() => {
    if (vehicleType == undefined && cartChangeVehicle == false) {
      // setBrandState(false)
      dispatch(handleBrandState(false));

      // setmodelState(false)
      dispatch(handleModelState(false));

      // setvariantState(false)
      dispatch(handleVariantState(false));
    }
  }, [cartChangeVehicle, vehicleType]);

  if (location.includes("garage-near-me")) {
    areaSplittedArray = location.split("-");

    areaSplittedArray && areaSplittedArray.splice(0, 3);

    areaSplittedArray = areaSplittedArray?.join(" ");

    areaSplittedArray = areaSplittedArray.replace(
      /(\w)(\w*)/g,
      function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      }
    );

  }




  const citynew = localStorage.getItem("cityName123") || ""; // Provide a default value if null

  function formatCityName(cityName) {
    if (!cityName) return '';
  
    const formattedCity = cityName
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase())
      .replace('-', ' ');
  
    return formattedCity;
  }

//   if (!garagenearme) {
//     return null
//   }
//   else{
//     const { garagenearme } = useParams();
// console.log(garagenearme);

// // Extract the text after "garage-near-me-"
// const extractedText = garagenearme.split('garage-near-me-')[1];

// // Capitalize the first letter
// const capitalizedText = extractedText.charAt(0).toUpperCase() + extractedText.slice(1);

// // Replace hyphens with spaces
// const textWithSpaces = capitalizedText.replace(/-/g, ' ');

// // Capitalize the first letter after each space
// const finalText = textWithSpaces.replace(/\b\w/g, (match) => match.toUpperCase());

// console.log(finalText);

//   }



if (garagenearme) {
  // Extract the text after "garage-near-me-"
  var extractedText = garagenearme.split('garage-near-me-')[1];

  if (extractedText) {
    // Capitalize the first letter
    var capitalizedText = extractedText.charAt(0).toUpperCase() + extractedText.slice(1);

    // Replace hyphens with spaces
    var textWithSpaces = capitalizedText.replace(/-/g, ' ');

    // Capitalize the first letter after each space
    var finalText = textWithSpaces.replace(/\b\w/g, (match) => match.toUpperCase());

    // console.log(finalText);
  } else {
    // Handle the case when extractedText is undefined
    // console.error('Extracted text is undefined');
    var finalText = "";
  }
} else {
  var finalText = "";
  // Handle the case when garagenearme param is not present
  // console.error('garagenearme parameter is not present in the URL');
  // You can add further error handling or redirection logic as needed
}





  return (
    <Box container className="main-selection-container">
      <myContext.Provider value={mydata}>
      <Box className="Selection-header">
          {BrandState || modelState || variantState || (
            <h2>{`Easily Repair your Car ${
              areaSplittedArray && areaSplittedArray?.length != 0
                ? `in ${areaSplittedArray} ${finalText} ${(citynew).replaceAll("-"," ").replace(/(?:^|\s)\S/g, (char) => char.toUpperCase())}`
                : 
                currentLocation && `in ${finalText} ${(citynew ? citynew : "").replaceAll("-"," ").replace(/(?:^|\s)\S/g, (char) => char.toUpperCase())}`
            } `}</h2>
          )}
        </Box>

        {/* If device width <768px then and then Modal work  */}
        {Modalopen ? (
          <Modal
            className="Cart-Modal"
            keepMounted
            onClose={ModalClosehandle}
            open={Modalopen}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="modal_body">
              <ConditionalSelction />
              {/* <MobileNumber/> */}
            </Box>
          </Modal>
        ) : (
          <ConditionalSelction />
        )}
      </myContext.Provider>
    </Box>
  );
}
