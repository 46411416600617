import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import './MobileNumber.css'
import BorderColorIcon from '@mui/icons-material/BorderColor';

export default function MobileNumber({handelContinue}) {
     const [mobileNumber, setMobileNumber] = useState();
   
     const handelMobileNumber = (e) => {
          setMobileNumber(e.target.value)
     }
     
     return (
          <>
               <Box className="cart-mobile-container">
                    <Box className='cart-mobile-header'>
                         <h4>Enter Your Mobile Number</h4>
                    </Box>
                    <Box className='cart-mobile-input-section'>
                         <span>+91</span>
                         <input onChange={handelMobileNumber} className='cart-mobile-input-field' type="text" maxlength="10" pattern="[679]{10}" title="Enter your mobile number" required></input>
                    </Box>
                    <Box >
                         <Button onClick={handelContinue} className='cart-mobile-button'>Continue</Button>
                    </Box>
               </Box>

          </>


     )
}
