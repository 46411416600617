const selectionState = {
    ChangeVehicle:false,
    Brand:false,
    Model:false,
    Variant:false,
    MobileSection:false,
    OpenModal:false
}

export const ChangeVehicleReducer = (state = selectionState.ChangeVehicle, action) => {
    switch (action.type) {
        case 'IsChangeVehicle':
            return state = action.payload;
        default:
            return state;
    }
}

export const BrandReducer = (state = selectionState.Brand, action) => {
    switch (action.type) {
        case 'IsBrand':
            return state = action.payload;
        default:
            return state;
    }
}

export const ModelReducer = (state = selectionState.Model, action) => {
    switch (action.type) {
        case 'IsModel':
            return state = action.payload;
        default:
            return state;
    }
}

export const VariantReducer = (state = selectionState.Variant, action) => {
    switch (action.type) {
        case 'IsVariant':
            return state = action.payload;
        default:
            return state;
    }
}

export const MobileSectionReducer = (state = selectionState.MobileSection, action) => {
    switch (action.type) {
        case 'IsMobileSection':
            return state = action.payload;
        default:
            return state;
    }
}

export const OpenModalReducer = (state = selectionState.OpenModal, action) => {
    switch (action.type) {
        case 'IsOpenModal':
            return state = action.payload;
        default:
            return state;
    }
}




