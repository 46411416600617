import { combineReducers } from "redux";
import { AddToCartReducer, CartTotalReducer } from "./Addtocart";
import {
  CustomerDetails_Reducer,
  ChangeVehicleComponent,
  MobileNumberReducer,
} from "./CustomerDetail";
import { LocationReducer } from "./LocationReducer";
import { SelectionVehicleReducer } from "./VehicleSelection";
import { serviceCategoryReducer } from "./VehicleSelection";
import { SaveBrowserId } from "./BrowserId";
import { BrowserUserData } from "./BrowserUserData";

import {
  ChangeVehicleReducer,
  BrandReducer,
  ModelReducer,
  VariantReducer,
  MobileSectionReducer,
  OpenModalReducer,
} from "./SelectionStates";

const rootReducer = combineReducers({
  SelectionVehicleReducer,
  AddToCartReducer,
  CustomerDetails_Reducer,
  LocationReducer,
  ChangeVehicleComponent,
  MobileNumberReducer,
  serviceCategoryReducer,
  SaveBrowserId,
  BrowserUserData,
  ChangeVehicleReducer,
  BrandReducer,
  ModelReducer,
  VariantReducer,
  MobileSectionReducer,
  OpenModalReducer,
  CartTotalReducer,
});

export default rootReducer;