import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getQueryResult } from '../../../services/vehicleservices/vehicleservices';
import "./SearchBar.css";

export default function Searchbar({ searchState, handleClick }) {
    const { searchResult, setSearchResult, searchQueryResult, setSearchQueryResult } = searchState;
    const [isLoading, setIsLoading] = useState(false);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));



    const getServiceByTag = async (tag) => {

            try {
                const response = await getQueryResult(tag);
                const data = response?.data;
                if (data.statusCode === 200) {
                    setSearchQueryResult([])
                    setSearchQueryResult(data?.result);
                }
            }
            catch (error) {
                console.log(error);
                setSearchQueryResult([])
            } finally {
                setIsLoading(false);
            }

    }

    const handleChange = async (e) => {

        setSearchQueryResult([])
        await setSearchResult(e.target.value);
        await delay(200);
        return getServiceByTag(e.target.value);
    };



    useEffect(() => {
        const result = document.getElementById("result");
        if (result) {
            if (searchQueryResult?.length === 0 || searchResult?.length === 0) {
                result.style.display = "none";
            } else {
                result.style.display = "block";
            }
        }
    }, [searchQueryResult, searchResult]);

    return (
        <>
            <Box className="search-bar-container" id="search-container">
                <Box className="search-bar" id="search-bar">
                    <SearchIcon />
                    <input
                        className="search-bar-input"
                        id="search-bar-input"
                        type="text"
                        autoComplete="off"
                        onChange={(e) => {
                            setSearchQueryResult([])
                            setIsLoading(true)
                            handleChange(e)
                        }
                        }
                        placeholder="Search For Services"
                    />
                </Box>
                {!isLoading && (
                    <Box className="result" id="result"
                        style={{ display: "none" }}
                    >
                        <ul>
                            {searchQueryResult?.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            setSearchResult(item.service);
                                            handleClick(item);
                                        }}
                                    >
                                        {`${item.service} for ${item.vehicleType?.vehicleType} `}
                                    </li>
                                );
                            })}
                        </ul>
                    </Box>
                )}
            </Box>
        </>
    );
}