import React, { useEffect, useMemo, useState } from "react";
import "./OrderStatus.css";
import { Box, Button, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import FAQ_home from "../ReuseComponent/FAQ/FAQ_home";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import { useNavigate, useLocation } from "react-router-dom";

// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  getOrders,
  getOrderStatus,
  orderStatus,
} from "../../services/vehicleservices/vehicleservices";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 10,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "green",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "green",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 20,
    height: 20,
    borderRadius: "50%",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function OrderStatus({ google, locations = [] }) {
  const [orders, setOrders] = useState([]);
  const [orderStatusData, setOrderStatusData] = useState([]);
  // const [activeStep, setActiveStep] = useState(0)

  const navigate = useNavigate();

  const { state } = useLocation();

  const { orderId, navigateNumber } = state;

  let activeStep = 0;

  const FAQData = [
    {
      title: "How can I book my vehicle service on Gativan?",
      body: "Book your service within a minute. Dive in to gativan.in.Select your vehicle type (bike/car),select the brand (e.g. Honda) followed by the vehicle (e.g. Amaze). Enter your phone number and tap on Check price to book your slots! Select your convenient time slots and book it! We offer free pick up and delivery all over Pune, so you don’t miss out the best moments with your dear ones! ",
    },
    {
      title: "How much do you charge for Periodic Servicing?",
      body: " Gativan’s Periodic Servicing starts at Rs. 795 for bikes and Rs. 2399 for cars.",
    },
    {
      title: "Why should I choose Gativan in Pune?",
      body: "Gativan offers best bike/car services with best prices in Pune. Not only you get a quality service from our trained mechanics, but also avail transparency throughout.",
    },

    {
      title: "Can I choose a Gativan Service Centre of my choice in the city?",
      body: "Yes, definitely! Rest assured, all our service stations are trained for quality servicing. We will assign you the near most service centre based on your location.",
    },
  ];

  const OrderStatus = async () => {
    const response = await getOrderStatus();
    const orderStatusAPI = response.data;
    if (orderStatusAPI.statusCode === 200) {
      setOrderStatusData(orderStatusAPI.result);
    }
  };

  const getOrdersApi = async (mobileNumber) => {
    const response = await getOrders(mobileNumber);
    const ordersData = response.data;
    if (ordersData.statusCode === 200) {
      setOrders(ordersData.result);
    }
  };

  const filterData =
    orders &&
    orders.filter((e) => {
      return e.orderId === orderId;
    });

  useMemo(() => {
    orderStatusData.map((item) => {
      if (
        (filterData?.length > 0 && filterData[0]?.orderStatus) ===
        item.orderStatus
      ) {
        activeStep = item.id - 1;
      }
    });
  }, [filterData]);

  useMemo(() => {
    if (orderStatusData[orderStatusData?.length - 1]?.orderStatus === "Lost") {
      orderStatusData.splice(orderStatusData.length - 1, 1);
    }
  }, [orderStatusData]);

  useEffect(() => {
    OrderStatus();
    navigateNumber && getOrdersApi(navigateNumber);
    // if(orderStatusData[orderStatusData?.length-1]?.orderStatus === "Lost"){
    //   orderStatusData.splice(orderStatusData.length-1,1)
    // }
  }, []);

  const downloadJobCard = () => {
    if (filterData?.length > 0 && filterData[0]) {
      const { user, orderId, serviceBookingDate, serviceBookingTime } =
        filterData[0];
      const firstName = user?.first_name?.toLowerCase();
      const date = serviceBookingDate?.split("-");
      const time = serviceBookingTime?.split(":");

      const dateId = `${date[0]}${time[0]}${time[1]}${date[1]}${time[2]}${date[2]}`;

      const jobCardUrl = `/pune/jobcard/${orderId?.toLowerCase()}/${firstName}/${dateId}`;

      navigate(jobCardUrl);
    }
  };

  return (
    <>
      {filterData.map((item, index) => {
        const {
          orderId,
          user,
          vehicle,
          bookingMode,
          totalPrice,
          orderStatus,
          arrivalMode,
          arrivalDate,
          arrivalTime,
          paymentId,
        } = item;

        return (
          <Box className="order-status-container" key={index}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Box className="ongoing-order-status">
                  <span className="heading-ongoing">Ongoing Order</span>
                  <span className="order-number">
                    {" "}
                    {">"} Order #{orderId}
                  </span>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={5}>
                      <Box className="customer-details">
                        <Box className="customer-name-working-order">
                          <Typography className="customer-name">
                            Hi, {user.first_name}!
                          </Typography>
                          <Typography>
                            {" "}
                            We are working on your order.
                          </Typography>
                        </Box>
                        <Box className="vehicle-details-container">
                          <>
                            <Box className="vehicle-details">
                              <Box className="vehicle-details-logo">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/Assets/orderStatus/motorcycle.png`
                                  }
                                />
                              </Box>
                              <Box className="details">
                                <h4>
                                  Vehicle Details{" "}
                                  <h6>{`${
                                    vehicle?.brand ? vehicle?.brand.brand : ""
                                  } ${
                                    vehicle?.model ? vehicle?.model.model : ""
                                  }`}</h6>
                                </h4>
                              </Box>
                              <Box className="view-details"></Box>
                            </Box>
                            <Box className="vehicle-details">
                              <Box className="vehicle-details-logo">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/Assets/orderStatus/calender.png`
                                  }
                                />
                              </Box>
                              <Box className="details">
                                <h4>
                                  {arrivalMode ? arrivalMode?.mode : "Pickup"}{" "}
                                  Date{" "}
                                  <h6>{`${arrivalDate ? arrivalDate : " -- "} ${
                                    arrivalTime ? arrivalTime : " -- "
                                  }`}</h6>
                                </h4>
                              </Box>
                              <Box className="view-details"></Box>
                            </Box>
                            <Box className="vehicle-details">
                              <Box className="vehicle-details-logo">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/Assets/orderStatus/creditcard.png`
                                  }
                                />
                              </Box>
                              <Box className="details">
                                <h4>
                                  {/* Payment History <h6>{`Amount Pending Rs.${bookingMode.modeId === 5 ? totalPrice - 50 : totalPrice}`}</h6> */}
                                  {/* IF 50 RUPEES PAID ALREADY MINUS 50 RUPEES AND RETURN  ELSE RETURN TOTAL PRICE */}
                                  Payment History{" "}
                                  <h6>{`Amount Pending Rs.${
                                    paymentId &&
                                    paymentId != "Pay_After_Service"
                                      ? totalPrice
                                        ? totalPrice - 50
                                        : 0
                                      : totalPrice
                                  }`}</h6>
                                </h4>
                              </Box>
                              <Box className="view-details">View Details</Box>
                            </Box>
                          </>
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Grid item md={2} xs={4}>
                  <Typography className="view-details">View Details</Typography>
                </Grid> */}
                    <Grid item xs={8} md={3}>
                      <Stack sx={{ width: "100%" }}>
                        <span className="heading-orderstatus">
                          Order Status
                        </span>
                        <Stepper
                          orientation="vertical"
                          activeStep={orderStatus?.sequenceNumber - 1}
                          connector={
                            <QontoConnector style={{ iconColor: "#5BAA41" }} />
                          }
                          iconColor="#5BAA41"
                        >
                          {orderStatusData.map((item) => {
                            return (
                              <Step key={item.id}>
                                <StepLabel style={{ iconColor: "#5BAA41" }}>
                                  {item.status}
                                </StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Box
                        className="job-card-download"
                        onClick={downloadJobCard}
                        style={{ cursor: "pointer" }}
                      >
                        <SaveAltRoundedIcon className="download-icon" />
                        Job card
                      </Box>
                      {/* <Button className="job-card-download" onClick={downloadJobCard}>
                        <SaveAltRoundedIcon className="download-icon" />
                        Job card
                      </Button> */}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="refer-faq-container">
                  <Box className="refer-earn">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "Assets/orderStatus/refer&earn.png"
                      }
                    />
                  </Box>
                  <Box className="refer-faq">
                    <FAQ_home data={FAQData} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="order-status-map">
                <Box className="map">
                  <Map
                    google={google}
                    containerStyle={{
                      width: "50%",
                      height: "50%",
                    }}
                    style={{
                      width: "180%",
                      height: "80%",
                    }}
                    center={locations[0]}
                    initialCenter={locations[0]}
                    zoom={locations.length === 1 ? 18 : 20}
                    disableDefaultUI={true}
                  >
                    {locations.map((coords) => (
                      <Marker position={coords} />
                    ))}
                  </Map>
                </Box>
              </Box> */}
          </Box>
        );
      })}
    </>
  );
}
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyAw6FKhd-3NokZFPlLCNd0wGHMRM2Dq64g",
// })(OrderStatus);
