import { Box, Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../App';
import httpCommon from '../../../http-common';
import { getServiceCategory, getVehicleType } from '../../../services/vehicleservices/vehicleservices';
import Searchbar from '../../ReuseComponent/searchbar/SearchBar';
import "./OurServices.css";
import { handleBrandState, handleOpenModalState, handleServiceCategory } from '../../../action';



export default function OurServices() {
  const [vehicleData, setvehicleData] = useState([])
  const [vehicle, setVehicle] = useState()
  const [vehicleServices, setVehicleServices] = useState([])
  const [searchResult, setSearchResult] = useState('')
  const [searchQueryResult, setSearchQueryResult] = useState([])

  const URL = httpCommon.defaults.baseURL
  let navigation = useNavigate();

  const searchState = {
    searchResult, setSearchResult,
    searchQueryResult, setSearchQueryResult
  }

  const dispatch = useDispatch();
  const AppContextData = useContext(AppContext);
  const { customerPickData, setCustomePickData, serviceCategoryId, setServiceCategoryId } = AppContextData


  const { location, vehicleType, serviceCategoryType, brand, model, variant } = useParams();


  const brandName = customerPickData?.brand?.brandName?.replaceAll(" ", "-").toLowerCase();
  const modelName = customerPickData?.model?.modelName?.replaceAll(" ", "-").toLowerCase();
  const variantName = customerPickData?.variant?.variantName?.replaceAll(" ", "-").toLowerCase();


  const getVehicleInfo = async () => {
    try {
      const Response = await getVehicleType()
      const VehicleData = await Response.data
      if (VehicleData.statusCode === 200) {
        setvehicleData(VehicleData.result?.reverse())
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getVehicleServices = async (vehicleId) => {
    try {
      const Response = await getServiceCategory(vehicleId)
      const VehicleServiceData = await Response.data
      if (VehicleServiceData.statusCode === 200) {
        setVehicleServices(VehicleServiceData.result)
      }
    } catch (error) {
      console.log(error)
    }
  }



  // getting current location from redux
  const currentLocation = useSelector(
    state => state.LocationReducer
  )



  const handleOurServicesNavigation = (event, id, serviceCategory) => {
    event.preventDefault();
    event.stopPropagation();
    setServiceCategoryId(id)
    dispatch(handleServiceCategory({serviceCategoryId:id, serviceCategory:serviceCategory}))
    if (vehicle === 1) {
      setCustomePickData({ ...customerPickData, vehicleType: { id: "1", vehicleName: "Car" } })
    } else if (vehicle === 2) {
      setCustomePickData({ ...customerPickData, vehicleType: { id: "2", vehicleName: "Bike" } })
    }
    const location = currentLocation.cityName.toLowerCase()
    const vehicleType = customerPickData?.vehicleType?.vehicleName.toLowerCase()
    const navigateUrl = serviceCategory.replaceAll(" ", "-").replaceAll("&","and").toLowerCase()
    if (customerPickData?.variant?.variantName) {
      navigation(`/${location}/${vehicleType}/${navigateUrl}/${brandName}/${modelName}/${variantName}`, { state: { id: id, vehicleTypeId: vehicle } });
      // if (window.innerWidth <= '768')
      //   dispatch(handleOpenModalState(true))
      // dispatch(handleBrandState(true))
    }
    else {
      // navigation(`/${location}/${vehicleType}/${navigateUrl}`, { state: { id: id, vehicleTypeId: vehicle } });
      if (window.innerWidth <= '768')
        dispatch(handleOpenModalState(true))
      dispatch(handleBrandState(true))
    }


    ReactGA.event({
      category: "Our Services",
      action: `Clicked on ${serviceCategory}`,
      label: `Select ${serviceCategory}`
    })

  }





  useEffect(() => {
    setVehicle(1)
    getVehicleInfo()
  }, []);


  useEffect(() => {
    if (vehicle != undefined) {
      vehicle && getVehicleServices(vehicle)
    }
    setVehicle(customerPickData.vehicleType.id)
  }, [vehicle, customerPickData])

  useEffect(() => {
    setServiceCategoryId(vehicleServices[0]?.categoryId)
  }, [vehicleServices])



  const handleClick = (value) => {
    if ((value?.vehicleType?.vehicleType == customerPickData?.vehicleType?.vehicleName) && (customerPickData?.brand?.brandName != undefined && customerPickData?.model?.modelName != undefined && customerPickData?.variant?.variantName != undefined)) {
      navigation(`${value?.vehicleType?.vehicleType?.replaceAll(" ", "-").toLowerCase()}/${value?.serviceCategory?.category?.replaceAll(" ", "-").toLowerCase()}/${brandName}/${modelName}/${variantName}`)
      setSearchResult('');
    }
    else {
      navigation(`${value?.vehicleType?.vehicleType?.replaceAll(" ", "-").toLowerCase()}/${value?.serviceCategory?.category?.replaceAll(" ", "-").toLowerCase()}`)
      setSearchResult('');
    }
  }
  // const citynew = localStorage.getItem("cityName123")
  // const currentLocation = useSelector((state) => state.LocationReducer);
  // {(citynew).replace(/-/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase())}
  return (
    <>
      <Box container >
        <Box className='homepage-headers'>
        <h2>Our Services In {currentLocation?.cityName ? (currentLocation.cityName).replace(/-/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase()) : ''}</h2>
          <p className="sub-heading" >Discover professional periodic servicing for cars and bikes, including top-notch car and bike repair, wheel care services, accidental repair and more with Gativan.</p>
        </Box>
        {/* <Box className='search-container'>
          <Searchbar searchState={searchState} handleClick={handleClick} />
        </Box> */}
        {/* 02-08-2024 Bike code commented Aditya */}
        {/* <Box container className="toggle-container">
          {vehicleData.map((item) => {
            const { id, vehicleType, url } = item
            return (
              <Button key={id} className="car-btn toggle-btn" variant='text'
                onClick={() => {
                  {
                    if (id == 2) {
                      setVehicle(2)
                      setCustomePickData({ ...customerPickData, vehicleType: { id: "2", vehicleName: "Bike" } })
                    }
                    else {
                      setVehicle(1);
                      setCustomePickData({ ...customerPickData, vehicleType: { id: "1", vehicleName: "Car" } })
                    }
                  }
                }
                }
                style={{
                  fontWeight: 'bold',
                  backgroundColor: vehicle == id ? "#7B8BD4" : "#fff",
                  color: vehicle == id ? "#fff" : "#000000",
                }}> <img src={`${URL}${url}`} /> {vehicleType} </Button>
            )
          })}
        </Box> */}
        <Grid container className="service-card-container">
          {
            vehicleServices.map((item, index) => {
              const { categoryId, category, image_url } = item
              return (
                <Grid key={categoryId}
                  item
                  xs={3.7} md={2.7}
                  className="grid"
                  id="grid"
                >
                  <Box className='grid-link'
                    onClick={(event) => {
                      handleOurServicesNavigation(event, categoryId, category)
                    }}
                  >
                    {(item.flag && item.flagName) ?
                      <Box container-fluid className="flag">
                        <Box display='flex' justifyContent='end'>
                          <img src={process.env.PUBLIC_URL + `/Assets/homepage/ourServices/${item.flag}`} loading="lazy" />
                          <p>{item.flagName}</p>
                        </Box>

                      </Box>
                      : ""}
                     <Box className="car-bike-img-container-parent">
                  <Box className="car-bike-img-container">
                    <img src={`${URL}/${image_url}`} />
                  </Box>
                  </Box>
                    <Box>
                      <p>{category}</p>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
        </Grid>
      </Box>
    </>
  )
}