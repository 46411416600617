import React, {useState, useEffect, useRef, useMemo} from 'react';
import "./SlideMenu.css"
import { useParams } from 'react-router-dom';
import { getServiceCategoryByVehicleName } from '../../../../services/vehicleservices/vehicleservices';

const Slide = (props) => {
    const { isActive, onClick, currentRef } = props;

    const handleScroll = (ref) => {
      window.scrollTo({
          top:0,
          left:ref.offsetLeft,
          behavior:"smooth"
      })
  };

  useEffect(() => {
    handleScroll(currentRef)
  }, [currentRef, isActive]);
  return (
    <div className={`slide ${isActive ? 'active' : ''}`} onClick={onClick} style={{filter:"grayscale(100%)"}}>
      {props.children}
    </div>
  );
};

const Menu = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [currentRef, setCurrentRef] = useState('')
    const [serviceCategory, setServiceCategory] = useState([])
    const Ref = useRef(null);

    const { location, vehicleType, serviceCategoryType, brand, model, variant } = useParams();

    const getServiceCategories = async (vehicleTypeName) => {
      try {
          const Response = await getServiceCategoryByVehicleName(vehicleTypeName);
          const VehicleServiceCategoryData = await Response.data;
          if (VehicleServiceCategoryData.statusCode === 200) {
              setServiceCategory(VehicleServiceCategoryData.result);
          }
      } catch (error) {
          console.log(error);
      }
  };



  useEffect(()=>{
    vehicleType && getServiceCategories(vehicleType)
  },[vehicleType])

  useMemo(()=>{
    const filterCategoryId = serviceCategory?.filter((item, index) => {
      if(item?.category.replaceAll(" ","-").replaceAll("&","and").toLowerCase() == serviceCategoryType){
        setActiveSlide(index)
      }
    })
  },[serviceCategory, serviceCategoryType])

    useEffect(() => {
        // setActiveSlide(5);
        setCurrentRef(Ref.current);
    }, []);

   

    const handleSlideChange = (index, ref) => {
      setActiveSlide(index);
      setCurrentRef(ref)
    };
  return (
    <div className="menu" ref={Ref}>
      {/* {props.children} */}
      {React.Children.map(props.children, (child, index) => {
        const isActive = index === activeSlide;
        return React.cloneElement(child, { isActive, currentRef, onClick: () => handleSlideChange(index,Ref.current) });
      })}
    </div>
  );
};

export { Slide, Menu };