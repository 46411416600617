import React, { useState, useEffect, useRef, useContext } from 'react';
import HorizontalMenu from './HorizontalMenu';
import { Menu, Slide } from './SlideMenu';
import "./SlideMenu.css"
import httpCommon from '../../../../http-common';
import { getServiceCategoryByVehicleName } from '../../../../services/vehicleservices/vehicleservices';
import { Box } from '@mui/material';
import { AppContext } from '../../../../App';
import { useParams } from 'react-router-dom';

const TestMenu = ({ ServiceCategoryData, selectVehicleCategory }) => {
    const [serviceCategory, setServiceCategory] = useState([])


    const { select, setSelect, category, setCategory } = selectVehicleCategory
    const { serviceCategoryId, setServiceCategoryId } = useContext(AppContext)


    const { location, vehicleType, serviceCategoryType, brand, model, variant } = useParams();

    const URL = httpCommon.defaults.baseURL

    const Ref = useRef(null);


    const getServiceCategories = async (vehicleTypeName) => {
        try {
            const Response = await getServiceCategoryByVehicleName(vehicleTypeName);
            const VehicleServiceCategoryData = await Response.data;
            if (VehicleServiceCategoryData.statusCode === 200) {
                setServiceCategory(VehicleServiceCategoryData.result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleScroll = (ref) => {
        window.scrollTo({
            top: 0,
            left: ref.offsetLeft,
            behavior: "smooth"
        })
    };

    const handleURLChange = (categoryId, category) => {
        setCategory(category)
    }

    useEffect(() => {
        getServiceCategories(vehicleType)
    }, []);
    return (
        <Box className="service-container">
            <Menu>
                {
                    ServiceCategoryData.map((item) => {
                        const { categoryId, image_url, category } = item;
                        return (
                            <Slide key={categoryId} ref={Ref} >
                                <Box
                                    className="service-btn"
                                    key={categoryId}
                                    onClick={() => {
                                        setSelect(category?.replaceAll(" ", "-").toLowerCase())
                                        setServiceCategoryId(categoryId)
                                        handleURLChange(categoryId, category)
                                    }}
                                >
                                    <Box container className="service-image-container" >
                                        <img src={`${URL}/${image_url}`} />
                                    </Box>
                                    <Box className="service-text-container" >
                                        <h4>{category}</h4>
                                    </Box>
                                </Box>
                            </Slide>
                        )
                    })
                }
            </Menu>
            {/* <HorizontalMenu items={serviceCategory} /> */}
        </Box>
    );
};

export default TestMenu;