export const chooseLocation = (payload) => {
  return {
    type: "CHOOSE_LOCATION",
    payload: payload,
  };
};
export const getVehicleData = (vehicleData) => {
  return {
    type: "CustomerPickData",
    payload: vehicleData,
  };
};

export const addToCart = (payload) => {
  return {
    type: "ADDTOCART",
    payload: payload,
    price: payload.actualPrice,
  };
};

export const StoreCartTotal = (payload) => {
  return {
    type: "CARTTOTAL",
    payload: payload,
  };
};

export const removeAllCart = (payload) => {
  return {
    type: "REMOVEALLCART",
    payload: payload,
  };
};

export const removeServiceFromCart = (id) => {
  return {
    type: "REMOVEFROMCART",
    payload: id,
  };
};

// add all services w.r.t. browser id
export const addAllServices = (payload) => {
  return {
    type: "AddAllToCart",
    payload: payload,
  };
};

export const getMobileNumber = (payload) => {
  return {
    type: "MobileNumber",
    payload: payload,
  };
};


export const getSelectedCity = (payload) => {
  return {
    type: "City",
    payload: payload,
  };
};


export const costomerDetail = (payload) => {
  return {
    type: "ADD_CUSTOMERDETAILS",
    payload: payload,
  };
};

export const handleChangeVehicelComponent = (payload) => {
  return {
    type: "triggerChangeVehocle",
    payload: payload,
  };
};

export const handleServiceCategory = (payload) => {
  return {
    type: "ServiceCategory",
    payload: payload,
  };
};

export const handleSetBrowserId = (payload) => {
  return {
    type: "SetBrowserId",
    payload: payload,
  };
};

export const handleBrowserUserData = (payload) => {
  return {
    type: "BrowserData",
    payload: payload,
  };
};

export const handleChangeVehicleState = (payload) => {
  return {
    type: "IsChangeVehicle",
    payload: payload,
  };
};

export const handleBrandState = (payload) => {
  return {
    type: "IsBrand",
    payload: payload,
  };
};

export const handleModelState = (payload) => {
  return {
    type: "IsModel",
    payload: payload,
  };
};

export const handleVariantState = (payload) => {
  return {
    type: "IsVariant",
    payload: payload,
  };
};

export const handleMobileSection = (payload) => {
  return {
    type: "IsMobileSection",
    payload: payload,
  };
};

export const handleOpenModalState = (payload) => {
  return {
    type: "IsOpenModal",
    payload: payload,
  };
};