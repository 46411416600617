import React, { useEffect, useState } from "react";
import "./ApplyPromocode.css";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { StoreCartTotal } from "../../../action";
import { getOrders } from "../../../services/vehicleservices/vehicleservices";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ApplyPromocode = ({ handleApplyPromocode }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [viewTC, setViewTC] = useState("");
  const [isNewUser, setIsNewUser] = useState(true);

  const dispatch = useDispatch();
  const CartTotalReducer = useSelector((state) => state.CartTotalReducer);
  const servicesData = useSelector((state) => state.AddToCartReducer);
  const { mobileNumber } = useSelector((state) => state.MobileNumberReducer);
  const { vehicleType } = useSelector((state) => state.SelectionVehicleReducer);
  const [coupons, setCoupons] = useState([]);

  const isExistingUser = async () => {
    try {
      const response = await getOrders(mobileNumber);
      const data = await response.data;
      if (data?.result?.length > 1) {
        setIsNewUser(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    isExistingUser();
  }, [mobileNumber]);

  const [searchCoupon, setSearchCoupon] = useState("");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const viewTermsConditions = (coupon) => {
    if (viewTC == coupon) {
      setViewTC("");
    } else {
      setViewTC(coupon);
    }
  };

  const carCoupons = [
    {
      id: 1,
      couponTitle: "Flat 10% OFF on Periodic Service",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "GATIVAN10",
      applicableText: "Applicable only on Car & Bike Periodic Services",
      discountPercentage: 10,
      discountValue: 0,
      serviceId: null,
      serviceCategoryId: 23,
    },
    {
      id: 2,
      couponTitle: "Flat 500/- OFF on your first car service",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "FIRST500",
      applicableText: "Applicable only on your car’s first service",
      discountPercentage: 0,
      discountValue: 500,
      serviceId: null,
    },
    {
      id: 3,
      couponTitle: "Up to 10% OFF on Full Body Denting & Painting",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "DENTPAINT10",
      applicableText: "Applicable on Car & Bike Full Body Denting & Painting",
      discountPercentage: 10,
      discountValue: 0,
      serviceId: null,
    },
    {
      id: 4,
      couponTitle: "Free AC Gas Top-Up (250g) on Periodic Services",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "FREEACGAS",
      applicableText: "Applicable only on Car Periodic Services",
      discountPercentage: 0,
      discountValue: 0,
      serviceId: null,
      freeAcGas: "250g",
    },
  ];

  const bikeCoupons = [
    {
      id: 1,
      couponTitle: "Flat 10% OFF on Periodic Service",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "GATIVAN10",
      applicableText: "Applicable only on Car & Bike Periodic Services",
      discountPercentage: 10,
      discountValue: 0,
      serviceId: null,
    },
    {
      id: 2,
      couponTitle: "Flat 120/- OFF on your first bike service",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "FIRST120",
      applicableText: "Applicable only on your bike’s first service",
      discountPercentage: 0,
      discountValue: 120,
      serviceId: null,
    },
    {
      id: 3,
      couponTitle: "Up to 10% OFF on Full Body Denting & Painting",
      tnc: [
        "Offer applicable on Periodic Services",
        "AC Fitment Services and High Performance AC Service.",
        "AC Gas top-up will be done at the time of Service.",
      ],
      couponCode: "DENTPAINT10",
      applicableText: "Applicable on Car & Bike Full Body Denting & Painting",
      discountPercentage: 10,
      discount: 0,
      serviceId: null,
    },
  ];

  useEffect(() => {
    if (vehicleType?.id == 1) {
      let filteredCoupons = carCoupons?.filter((coupon) => {
        if (coupon?.couponCode != "FIRST500") {
          return coupon;
        }
      });

      setCoupons(isNewUser ? carCoupons : filteredCoupons);
    } else {
      let filteredCoupons = bikeCoupons?.filter((coupon) => {
        if (coupon?.couponCode != "FIRST500") {
          return coupon;
        }
      });
      setCoupons(isNewUser ? bikeCoupons : filteredCoupons);
    }
  }, [isNewUser]);

  const applyCoupon = () => {
    const temp = coupons?.filter((coupon) => {
      if (coupon?.couponCode?.toLowerCase() == searchCoupon?.toLowerCase()) {
        return coupon;
      }
    });

    if (temp?.length == 0) {
      alert(
        searchCoupon ? "Invalid coupon code!" : "Please enter coupon code!"
      );
      setSearchCoupon("");
      return false;
    }

    const discountPercentage = temp[0]?.discountPercentage;
    const youPay = CartTotalReducer?.payNow || 0;
    const total = CartTotalReducer?.subTotal;
    const couponDiscount = total - total / `1.${discountPercentage}`;

    const finalTotal = total - couponDiscount - youPay;

    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        cartTotal: Math.round(finalTotal),
        couponDiscount: Math.round(couponDiscount),
        coupon: temp[0],
      })
    );
    handleApplyPromocode(false);
  };

  const clickToApplyCoupon = (item) => {
    const total = CartTotalReducer?.subTotal;
    // const youPay = CartTotalReducer?.payNow || 0;
    const youPay = 0;
    const discountPercentage = item?.discountPercentage;
    let couponDiscount = 0;

    let finalTotal = total;
    if (item?.discount) {
      finalTotal = item?.discount;
      couponDiscount = total - total / `1.${discountPercentage}`;
    }

    if (item?.discountPercentage) {
      couponDiscount = total - total / `1.${discountPercentage}`;
      finalTotal = total - couponDiscount - youPay;
    }

    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        cartTotal: Math.round(finalTotal),
        couponDiscount: Math.round(couponDiscount),
        coupon: item,
      })
    );
    handleApplyPromocode(false);
  };

  return (
    <Box
      className="promocode-container"
      sx={{ height: "100vh", width: "100%", overflow: "auto" }}
    >
      <Box className="coupon-header">
        <Typography className="coupon-heading">
          <ArrowBackIcon
            onClick={() => handleApplyPromocode(false)}
            className="back-arrow"
          />{" "}
          <span>Apply Coupon | Gativan Money</span>
        </Typography>
      </Box>
      <Box className="promocode-wrapper">
        <Box className="search-box">
          <TextField
            className="search-field"
            placeholder="ENTER COUPON"
            size="small"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-input": {
                height: "1.8em",
                fontSize: "1.1rem",
                borderRadius: "none",
                outline: "none",
              },
              "& .MuiTypography-root": {
                fontSize: "1rem !important",
                color: "#122164",
              },
            }}
            value={searchCoupon}
            onChange={(e) => setSearchCoupon(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className="apply-button"
                  position="end"
                  onClick={applyCoupon}
                >
                  APPLY
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography className="promocode-title">Available Offers</Typography>
        <Box className="coupons-container">
          {coupons?.map((item, index) => {
            return (
              <Card sx={{ minWidth: 345 }} className="coupon-card">
                <CardContent>
                  <Box className="logo-header">
                    <img
                      className="coupon-gativan-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/GativanLogo.png"
                      }
                    />
                    <Typography className="card-title">Gativan</Typography>
                  </Box>
                  <Typography className="coupon-title">
                    {item?.couponTitle}
                  </Typography>
                  <Typography
                    className="terms-conditions"
                    onClick={() => viewTermsConditions(item?.couponCode)}
                  >
                    <span>View T&C</span>
                    <span>
                      {viewTC == item?.couponCode ? (
                        <ExpandLessIcon className="tnc-icons" />
                      ) : (
                        <ExpandMoreIcon className="tnc-icons" />
                      )}
                    </span>
                  </Typography>
                  {viewTC == item?.couponCode && (
                    <Box className="tnc-content-box">
                      {item?.tnc?.map((tc, i) => {
                        return (
                          <Typography className="tnc-content" key={i}>
                            {tc}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}

                  <Divider color="#ccc" sx={{ width: "100%" }} />

                  <Box className="coupon-box">
                    {/* <Button type="button" className="coupon-code-button">
                      {item?.couponCode}
                    </Button> */}
                    <Typography className="coupon-code">
                      {item?.couponCode}
                    </Typography>
                    <Button
                      type="button"
                      className="apply-coupon-button"
                      onClick={() => clickToApplyCoupon(item)}
                    >
                      Apply
                    </Button>
                  </Box>

                  <Divider color="#ccc" sx={{ width: "100%" }} />

                  <Typography className="applicable-text">
                    {item?.applicableText}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ApplyPromocode;
