import { Box, inputClasses } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useWhyChooseGativan from "../../../customHooks/useWhyChooseGativan";
import {
  getServiceGuideWithCategoryAndVehicleType,
  getServiceGuideWithCityName,
} from "../../../services/vehicleservices/vehicleservices";
import "./whyChooseGativan.css";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function WhyChooseGativan() {
  const [serviceGuide, setServiceGuide] = useState({});
  const [vehicleName, setVehicleName] = useState("");
  const [serviceGuideContent, setServiceGuideContent] = useState("");

  const {
    periodicService,
    cleaning,
    ACServiceAndRepair,
    wheelAndTyreCare,
    dentingAndPenting,
    detailing,
    clutchAndFitments,
    insuranceClaim,
    bikePeriodicService,
    bikeBatteries,
    batteries,
  } = useWhyChooseGativan();

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();


// Function to capitalize the first letter of each word
function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

  const staticContent = `
<Box className='blog-mainContainer'>
     <Box className="blog-servicesOffers">
          <Box className='blog-header'>
               <h4>Car services offered in Pune</h4>
          </Box>
          <Box className='blog-header-description'>
               <p>Gativan offer a wide variety of best car services like Periodic Services, Denting & Painting, Batteries Replacement, Car Spa & Cleaning, AC Service & Repair, Tyre Replacement & Wheelcare, Car Detailing, Custom Services, Windshields & Glass Replacement, Lights & Fitments, Car & Bike Accessories and much more.
               </p>
          </Box>
     </Box>
     <Box className='blog-why-choose'>
          <Box className='blog-header'>
               <h4>Why Choose Gativan for your Car  Servicing?</h4>
          </Box>
          <ul className="why-choose-subheader">
                              <li >
                                   Servicing every vehicle brand out there
                                   <p className='subdesc'>We have got you covered! Gativan services 40+ Car brands and 20+ bike brands.</p>
                              </li>
                              <li >
                                   Select the appropriate bike/car service
                                   <p className='subdesc'>Select the most appropriate service from a plethora of vehicle services on Gativan. Be it a bike or car, you can find multiple options to get it serviced.</p>
                              </li>
                              <li >
                                   Schedule free pick-up and drop
                                   <p className='subdesc'>We offer a free pick up and drop on every selected service, so that you can just sit home without any need of workshop visits, making servicing hassle-free!</p>
                              </li>
                              <li >
                                   Get a Quality Service from our certified professionals
                                   <p className='subdesc'>Get your vehicles serviced from our skilled mechanics using the best equipment in the industry.</p>
                              </li>
                              <li >
                                   Quick Service
                                   <p className='subdesc'>Don’t want to lend your vehicle for more than a day for servicing? Get your vehicle serviced on the same day with Gativan.</p>
                              </li>
                              <li >
                                   100% Genuine Spare parts
                                   <p className='subdesc'>We use OEM recommended parts and Synthetic Engine Oil for servicing, maintaining the quality of the service.</p>
                              </li>
                              <li >
                                   Free Vehicle Inspection
                                   <p className='subdesc'>We offer free vehicle inspection by our professional for our users. This will help know more about the underlying issues your vehicle is facing.</p>
                              </li>
          </ul>
     </Box>
     <Box className='servicesOfferd-brands'>
          <Box className='blog-header'>
               <h4>Car  services offered in Pune</h4>
          </Box>
          <Box className='blog-header-description'>
               <p>We service all brands and models of cars & bikefrom Honda, Bajaj, Hyundai, Maruti Suzuki, Daewoo, Mahindra, Volkswagen, Isuzu, Skoda, Volvo, etc.
               </p>
          </Box>

     </Box>
</Box>
     `;

  const getServiceDataByCity = async (city) => {
    try {
      const result = await getServiceGuideWithCityName(city);
      const data = await result.data;
      if (data?.statusCode == 200) setServiceGuide(data?.result);
      else setServiceGuide(null);
    } catch (e) {
      console.log(e);
    }
  };

  const getServiceDataByCategoryAndVehicle = async (
    isVehicle,
    category,
    vehicleType
  ) => {
    try {
      if (isVehicle != null && category && vehicleType) {
        const result = await getServiceGuideWithCategoryAndVehicleType(
          isVehicle,
          category,
          vehicleType
        );
        const data = await result?.data;
        if (data?.statusCode == 200) setServiceGuide(data?.result);
        else setServiceGuide(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const HTMLRenderer = ({ htmlContent }) => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.innerHTML = htmlContent;
      }
    }, [htmlContent]);

    return <div ref={containerRef} />;
  };

  useEffect(() => {
    if (
      location &&
      vehicleType == undefined &&
      serviceCategoryType == undefined &&
      brand == undefined &&
      model == undefined &&
      variant == undefined
    ) {
      getServiceDataByCity(location);
    }
  }, [location, vehicleType, serviceCategoryType, brand, model, variant]);
  useMemo(() => {
    if (location && vehicleType && serviceCategoryType && brand == undefined) {
      getServiceDataByCategoryAndVehicle(
        false,
        serviceCategoryType,
        vehicleType
      );
    } else if (location && vehicleType && serviceCategoryType && brand) {
      getServiceDataByCategoryAndVehicle(
        true,
        serviceCategoryType,
        vehicleType
      );
    }
  }, [location, vehicleType, serviceCategoryType, brand]);

  useEffect(() => {
    let localBrand = brand && `${brand[0]?.toUpperCase()}${brand?.slice(1)}`;
    let localModel = model && `${model[0]?.toUpperCase()}${model?.slice(1)}`;
    let localVariant =
      variant && `${variant[0]?.toUpperCase()}${variant?.slice(1)}`;
    if (localBrand && localModel == undefined && localVariant == undefined) {
      setVehicleName(localBrand);
    } else if (localBrand && localModel && localVariant == undefined) {
      setVehicleName(`${localBrand} ${localModel}`);
    } else if (localBrand && localModel && localVariant) {
      setVehicleName(`${localBrand} ${localModel} ${localVariant}`);
    }
  }, [brand, model, variant]);

  //  if you need to capitalize first and lowercase the rest
  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }

  useMemo(() => {
    setServiceGuideContent(
      serviceGuide
        ? serviceGuide?.htmlContent
            ?.replaceAll("{{$city}}", toTitleCase(location))
            ?.replaceAll("{{$vehicle}}", capitalizeWords(vehicleName).replaceAll("-"," "))
        : staticContent
    );
  }, [vehicleName, serviceGuide]);

  const currentLocation = useSelector((state) => state.LocationReducer);

  return (
    <React.Fragment>
      <Box container className="homepage-headers">
        <h2>{`Why Choose Gativan ${
          serviceCategoryType
            ? ` For ${capitalizeWords(vehicleName).replaceAll("-"," ")} ${capitalizeWords(serviceCategoryType).replaceAll("-"," ")}`
            
            : capitalizeWords(vehicleName).replaceAll("-"," ")
            ? `For ${capitalizeWords(vehicleName)}`.replaceAll("-"," ")
            : ""
        } In ${currentLocation?.cityName ? (currentLocation.cityName).replace(/-/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase()) : ''} ?`}</h2>
      </Box>
      <Box className="blog-mainContainer">
        <Box>
          {/* <HTMLRenderer htmlContent={serviceGuideContent} /> */}
          <HTMLRenderer htmlContent={serviceGuideContent} />
        </Box>
      </Box>
    </React.Fragment>
  );
}
