import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  AppBar,
  Badge,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getCities,
  getDistrict,
} from "../../services/vehicleservices/vehicleservices";
import "./NavBar.css";

import ReactGA from "react-ga4";

import { chooseLocation, handleChangeVehicelComponent } from "../../action";
import { AppContext } from "../../App";
import Searchbar from "../ReuseComponent/searchbar/SearchBar";
import Location from "./Location";
import axios from "axios";
import httpCommon from "../../http-common";
import CityModal from "../CityModal";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  marginRight: drawerWidth,
}));

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showCities, setShowCities] = useState([]);
  const [vehicleTypeId, setVehicleTypeId] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [searchQueryResult, setSearchQueryResult] = useState([]);

  const [modalopen, setModalOpen] = useState(false);

  const appContext = useContext(AppContext);

  const { isModalOpen, setIsModalOpen } = appContext;

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();

  // const [SelectCity, setSelectCity] = useState({
  //   cityId: 2,
  //   cityName: "Pune",
  // });

  // getting current location from redux
  // getting current location from redux


  const currentLocation = useSelector((state) => state.LocationReducer);
  const reduxLocation = currentLocation.cityName?.toLowerCase();

  // const [isModalOpen, setIsModalOpen] = useState(false);

  //   const openModal = () => {
  //       setIsModalOpen(true);
  //   };



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modelState = { isModalOpen, setIsModalOpen };

  // const [data, setData] = React.useState([]);

  // console.log('this is navbar ', data   )

  // React.useEffect(() => {
  //  // Axios GET request
  //  axios.get(`${URL}/cart/0606c0f6b151084ca277f0214b86cb93`)
  //    .then(response => {
  //      // Handle successful response
  //      setData(response.data.result.city);
  //    })
  //    .catch(error => {
  //      // Handle error
  //      console.error('Error fetching data:', error);
  //    });
  // }, [browserData]); // Empty dependency array means this effect runs once on component mount

  const browserData = useSelector((state) => state.BrowserUserData);

  // console.log(location)
  const [SelectCity, setSelectCity] = useState({
    // cityId: 1,
    // cityName: browserData.city,
    cityName: "Pune",
  });





  // const [uniqueCities, setUniqueCities] = useState([]);

  // useEffect(() => {
  //   axios.get('https://devapi.gativan.in/api/city/unique')
  //     .then(response => {
  //       // Assuming the response contains an array of city names in data.cities
  //       const citiesArray = response.data.result;
  //       setUniqueCities(citiesArray);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching unique cities:', error);
  //     });
  // }, []); 

  // console.log(uniqueCities); 








  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  // console.log(capitalizeFirstLetter(browserData.city));

  // console.log(SelectCity)

  useEffect(() => {
    setSelectCity(currentLocation);
  }, [reduxLocation]);

  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  // const userObj={
  //   browserId:getBrowserIdRedux,
  //   brandId:null,
  //   modelId:null,
  //   variantId:null,
  //   mobileNumber:null
  //   ,vehicleType:null,
  //   city:location
  //   ,cartItems:[]
  // }

  //   useEffect(() => {

  //       axios.post(`${URL}/cart/`,userObj )
  //         .then(response => {
  //           console.log('POST request successful!', response.data);
  //         })

  //   }, [currentLocation]);

  // console.log("hi")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [SelectCity]);



  const onecity = localStorage.getItem("cityName123")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // if (window.location.pathname.includes('/garage-near-me')) {
    //   navigation(`/${currentLocation?.cityName?.toLocaleLowerCase()?.replace(/\s/g, "-")}`)
    // }
  }, [onecity]);









  const currentURL = window.location.href;

  const AppContextData = useContext(AppContext);
  const {
    customerPickData,
    setCustomePickData,
    serviceCategoryId,
    setServiceCategoryId,
    isShowSearch,
    setIsShowSearch,
  } = AppContextData;

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const brandName = customerPickData?.brand?.brandName
    ?.replaceAll(" ", "-")
    .toLowerCase();
  const modelName = customerPickData?.model?.modelName
    ?.replaceAll(" ", "-")
    .toLowerCase();
  const variantName = customerPickData?.variant?.variantName
    ?.replaceAll(" ", "-")
    .toLowerCase();

  const searchState = {
    searchResult,
    setSearchResult,
    searchQueryResult,
    setSearchQueryResult,
  };

  // const getCityInfo = async () => {
  //   try {
  //     const request = await getCities();
  //     const response = await request.data;
  //     setShowCities(response.result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getDistrictInfo = async () => {
    try {
      const request = await getDistrict();
      const response = await request.data;
      setShowCities(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  // getting Vehicle details from redux
  const vehicleSelectionData = useSelector(
    (state) => state?.SelectionVehicleReducer
  );

  const vehicleData = vehicleSelectionData?.vehicleType?.vehicleName;
  const brandData = vehicleSelectionData?.brand?.brandName;
  const modelData = vehicleSelectionData?.model?.modelName;
  const variantData = vehicleSelectionData?.variant?.variantName;

  useMemo(() => {
    // getCityInfo();
    // getDistrictInfo();
    // dispatch(chooseLocation({
    //     // cityId: SelectCity.cityId,
    //     cityName: SelectCity.cityName,
    //   })
    // );
  }, [SelectCity]);

  useEffect(() => {
    getDistrictInfo();

    // setIsModalOpen(true)
  }, []);

  // useEffect(() => {
  //   console.log("location ********************** ",location)
  //   if(location) {
  //     console.log("in $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
  //     dispatch(chooseLocation({ cityName: location }));
  //     localStorage.setItem("cityName123",location?.replaceAll(" ","-")?.toLocaleLowerCase())
  //   }
  // },[location])

  useEffect(() => {
    const splittedCurrentURL = currentURL.split("/");

    splittedCurrentURL.splice(0, 3);

    if (splittedCurrentURL[0]) {
      showCities?.filter((item) => {
        if (
          item?.city
            ?.toLocaleLowerCase()
            .includes(splittedCurrentURL[0]?.toLocaleLowerCase())
        ) {
          setSelectCity({
            // cityId: item?.districtId,
            cityName: item?.city,
          });
        }
      });
    }
  }, [showCities]);

  // =========================NAvbar=================//
  const navbar = [
    {
      id: 1,
      name: "Car Service",
      url:
        vehicleData == "Car" && brandData && modelData && variantData
          ? `${reduxLocation}/car/periodic-services/${brandData
              ?.toLocaleLowerCase()
              .replaceAll(" ", "-")}/${modelData
              ?.toLocaleLowerCase()
              .replaceAll(" ", "-")}/${variantData
              ?.toLocaleLowerCase()
              .replaceAll(" ", "-")}`
          : `${reduxLocation}/car/periodic-services`,
      gaCategory: "mobile side navbar",
      gaAction: "Clicked on Car Service side navbar",
      gaLable: "mobile side navbar",
    },
    // {
    //   id: 2,
    //   name: "Bike Service",
    //   url:
    //     vehicleData == "Bike" && brandData && modelData && variantData
    //       ? `${reduxLocation}/bike/periodic-services/${brandData
    //           ?.toLocaleLowerCase()
    //           .replaceAll(" ", "-")}/${modelData
    //           ?.toLocaleLowerCase()
    //           .replaceAll(" ", "-")}/${variantData
    //           ?.toLocaleLowerCase()
    //           .replaceAll(" ", "-")}`
    //       : `${reduxLocation}/bike/periodic-services`,
    //   gaCategory: "mobile side navbar",
    //   gaAction: "Clicked on Bike Service side navbar",
    //   gaLable: "mobile side navbar",
    // },
    {
      id: 3,
      name: "Blog",
      url: "blog",
      gaCategory: "mobile side navbar",
      gaAction: "Clicked on Blog side navbar",
      gaLable: "mobile side navbar",
    },
    {
      id: 4,
      name: "FAQs",
      url: "faq",
      gaCategory: "mobile side navbar",
      gaAction: "Clicked on FAQ side navbar",
      gaLable: "mobile side navbar",
    },
  ];

  const handleNavMenuClick = (item) => {
    const { id, name, url, gaCategory, gaAction, gaLable } = item;
    if (id == 1) {
      setCustomePickData({
        ...customerPickData,
        vehicleType: { id: 1, vehicleName: "Car" },
      });
      setServiceCategoryId(13);
    } else if (id == 2) {
      setCustomePickData({
        ...customerPickData,
        vehicleType: { id: 2, vehicleName: "Bike" },
      });
      setServiceCategoryId(1);
    }
    navigation(`/${url}`);
    ReactGA.event({
      category: gaCategory,
      action: gaAction,
      label: gaLable,
    });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const reduxCategory = useSelector((state) => state.serviceCategoryReducer);

  // ------------------------------------ geting service from cart redux --------------------
  const servicesSelectionData = useSelector((state) => state.AddToCartReducer);

  const itemCount = servicesSelectionData?.result?.length;

  // -------------------------------- url changes -----------------------------------------

  const splittedCurrentURL = currentURL.split("/");

  const URL = httpCommon.defaults.baseURL;

  splittedCurrentURL.splice(0, 3);

  // console.log(
  //   'localStorage.getItem("cityName123") ',
  //   localStorage.getItem("cityName123")
  // );

  // useEffect(() => {
  //   if (
  //     splittedCurrentURL[0] == "pune" ||
  //     splittedCurrentURL[0] == "mumbai" ||
  //     splittedCurrentURL[0] == "thane" ||
  //     splittedCurrentURL[0] == "thane"
  //   ) {
  //     localStorage.removeItem("cityName123");
  //     localStorage.setItem("cityName123", splittedCurrentURL[0]);
  //     dispatch(chooseLocation({ cityName: splittedCurrentURL[0] }));

  //     if (getBrowserIdRedux) {
  //       const userObj = {
  //         browserId: getBrowserIdRedux,
  //         city: splittedCurrentURL[0],
  //         cartItems: [],
  //       };
  //       axios.post(`${URL}/cart/`, userObj).then((response) => {
  //         console.log("POST request successful!", response.data);
  //       });
  //     }
  //   }
  // }, [getBrowserIdRedux]);

  // useEffect(() => {

  if (splittedCurrentURL[0])
    splittedCurrentURL[0] = localStorage
      .getItem("cityName123")
      ?.replaceAll(" ", "-")
      ?.toLocaleLowerCase();
  // }, [SelectCity])

  // if (splittedCurrentURL[0])
  //   splittedCurrentURL[0] = currentLocation?.cityName?.toLocaleLowerCase();

  // if (splittedCurrentURL[0]) {
  //   showCities?.filter((item) => {
  //     if(item?.district.includes(splittedCurrentURL[0]?.toLocaleLowerCase())) {
  //       setSelectCity({
  //         cityId: item?.districtId,
  //         cityName: item?.district
  //       })
  //     }
  //   });
  //   console.log("temp city ", SelectCity)
  // }

  if (!splittedCurrentURL[1]?.includes("jobcard")) {
    if (!splittedCurrentURL[1]?.includes("privacy")) {
      if (splittedCurrentURL[2]) {
        splittedCurrentURL[2] = reduxCategory.serviceCategory;
      }
    }
  }

  // Navigate to Lead Card Page.
  if (splittedCurrentURL[1]?.includes("leadcard")) {
    const path = window.location.pathname;
    splittedCurrentURL[2] = path?.split("/")[3];
  }
  const reNavigateURL = splittedCurrentURL
    .join("/")
    ?.replaceAll(" ", "-")
    ?.replaceAll("&", "and")
    .toLowerCase();

  useMemo(() => {
    navigation(`/${reNavigateURL}`);
  }, [currentLocation, reduxCategory]);

  // useEffect(() => {
  //   if (
  //     currentURL.includes("www.gativan.in/pune/denting-&-painting") ||
  //     currentURL.includes("www.gativan.in/pune/denting-and-painting")
  //   ) {
  //     window.location.href =
  //       "https://www.gativan.in/pune/car/denting-and-painting";
  //   } else if (currentURL.includes("www.gativan.in/pune/periodic-services")) {
  //     window.location.href =
  //       "https://www.gativan.in/pune/car/periodic-services";
  //   }
  // }, [currentURL]);

  // =============================== handle search model ===========================
  const handleClick = (value) => {
    if (
      value?.vehicleType?.vehicleType ==
        customerPickData?.vehicleType?.vehicleName &&
      customerPickData?.brand?.brandName != undefined &&
      customerPickData?.model?.modelName != undefined &&
      customerPickData?.variant?.variantName != undefined
    ) {
      navigation(
        `${currentLocation?.cityName?.toLocaleLowerCase()}/${value?.vehicleType?.vehicleType
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${value?.serviceCategory?.category
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${brandName}/${modelName}/${variantName}`
      );
      setSearchResult("");
      handleClose();
    } else {
      navigation(
        `${currentLocation?.cityName?.toLocaleLowerCase()}/${value?.vehicleType?.vehicleType
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${value?.serviceCategory?.category
          ?.replaceAll(" ", "-")
          .toLowerCase()}`
      );
      setSearchResult("");
      handleClose();
    }
  };

  const buttonStyle = {
    width: "50%",
    textDecoration: "none",
    fontFamily: "AvenirDemi",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.01em",
    color: "#122164",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center", // Aligns items vertically in the center
    justifyContent: "space-between", // Distributes space evenly between items
    padding: "8px 12px", // Adjust padding as needed
    marginLeft: "2%",
    marginRight: "1%",
    overflow: "hidden",
    textOverflow: "ellipsis", // Truncates long text with ellipsis
  };

  const capitalizeFirstLetter = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  };

//   console.log("ggggyyyyyyyyyyyyyyy",currentLocation.cityName)

// console.log(uniqueCities)



// console.log(currentLocation.cityName);

// Assuming currentLocation.cityName contains the city name

// const formattedCityName = currentLocation.cityName.toLowerCase().replace(/\s+/g, '-');
// console.log(formattedCityName);

const myCity = localStorage.getItem("cityName123")
const validCities = ["pune", "mumbai", "navi-mumbai", "thane", ];




// useEffect(() => {
//   const validCities = ["pune", "mumbai", "navi-mumbai", "thane", ];
  
//   if (!validCities.includes(myCity)) {
//     navigation("/not-found");
//   }
// }, [currentLocation]);








const formattedCityName = currentLocation.cityName?.toLowerCase()?.replaceAll("-"," ")
.split(' ')
.map(word => word.charAt(0).toUpperCase() + word.slice(1))
.join(' ');




const handleCityClick = () => {
  if (!validCities.includes(myCity)) {
    setIsModalOpen(true);
  } else {
    // Perform navigation if the city is valid
    // You might use history.push or a similar method here
    // to navigate to the desired location
  }
};

  return (
    <>
      <Box className="navbar-container">
        <AppBar
          position="static"
          className="app-bar"
          sx={{ mx: "5", bgcolor: "#122164", width: "100%" }}
        >
          <CssBaseline />
          <Toolbar>
            <Box onClick={() => dispatch(handleChangeVehicelComponent(false))}>
            <Link to={`/${currentLocation?.cityName?.toLocaleLowerCase()?.replace(/\s/g, "-")}`} onClick={handleCityClick}>
             





                <img
                  className="navbarLogo"
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/homepage/GativanLogo/GativanFullLogoWhite.png"
                  }
                  alt="Gativan logo | bike and car service company - gativan.in"
                />
              </Link>
            </Box>
            <Box className="navbarMobileLogoContainer">
              {isShowSearch ? (
                <Box className="cart-and-search-container">
                  {customerPickData?.variant?.variantName && (
                    <Box
                      className="cart-container"
                      onClick={() => {
                        navigation("/viewcart");
                      }}
                    >
                      <Badge color="secondary" badgeContent={itemCount}>
                        <ShoppingCartOutlinedIcon sx={{ fontSize: "30px" }} />{" "}
                      </Badge>
                    </Box>
                  )}
                  <Box
                    className="cart-container"
                    onClick={() => setModalOpen(true)}
                  >
                <SearchIcon
  sx={{
    fontSize: "30px",
   
  }}
/>

                  </Box>
                </Box>
              ) : (
                <Box className="navbarMobileCallWAContainer">
                  <Box className="navbarMobileLogo">
                    <a href="tel:+918263090692">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/homepage/navbar/carbon_phone-filled.png"
                        }
                        alt="Call Gativan"
                      />
                    </a>
                  </Box>
                  <Box className="navbarMobileCallWALine"></Box>
                  <Box className="navbarMobileLogo">
                    <a href="https://wa.me/+918263090692">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/homepage/navbar/logos_whatsapp.png"
                        }
                        alt="WhatsApp Gativan"
                      />
                    </a>
                  </Box>
                </Box>
              )}

              {/* <Box
                className="navbarMobileLocationContainer"
                sx={{ margin: "2% 2% 0", width: "20%" }}
              >
                <Location
         showCities={showCities}
         SelectCity={SelectCity.cityName}
         setSelectCity={setSelectCity}
                  
                />
              </Box> */}




<Button
  onClick={() => {
    setIsModalOpen(true);
  }}
  style={{
    maxWidth: "150px", // Set a maximum width for the button
    textDecoration: "none",
    fontFamily: "AvenirDemi",
    fontStyle: "normal",
    fontWeight: 600,
    // fontSize: formattedCityName.length >= 11 ? "11px" : "12px", // Check word count for size adjustment
    fontSize:"12px",
    lineHeight: "20px",
    letterSpacing: "0.01em",
    color: "#122164",
    backgroundColor: "white",
    marginLeft: "2%",
    marginRight: "1%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Position text and arrow

 
  }}
>
  {formattedCityName}
  <ArrowDropDownIcon sx={{ fontSize: "24px" }} />
</Button>



              {modelState && modelState.isModalOpen && (
                <CityModal modelState={modelState} />
              )}

              <Box
                className="hamburger-button"
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/homepage/navbar/icon-park-outline_hamburger-button.png"
                  }
                />
              </Box>
            </Box>
            {isMobile ? (
              <Grid>
                <Drawer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  anchor="right"
                >
                  <Grid className="drawer-header">
                    <DrawerHeader style={{ width: "100%" }}>
                      <Grid
                        container
                        spacing={2}
                        display="flex"
                        alignItem="baseline"
                      >
                        <Grid item xs={10}>
                          {/* <Location
                            showCities={showCities}
                            SelectCity={SelectCity.cityName}
                            setSelectCity={setSelectCity}
                          /> */}
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="end">
                          <IconButton onClick={() => setOpenDrawer(false)}>
                            <ArrowForwardIcon style={{ color: "black" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DrawerHeader>
                  </Grid>

                  <Box
                    className="login-container"
                    onClick={() => {
                      navigation("/login");
                      ReactGA.event({
                        category: "Login/Sign-up",
                        action: "Clicked on Login/Sign-up",
                        label: "Login/Sign-up",
                      });
                    }}
                  >
                    Log In/Sign Up
                  </Box>
                  <Divider />
                  <List>
                    {navbar.map((item) => {
                      const { id, name, url, gaCategory, gaAction, gaLable } =
                        item;
                      return (
                        <React.Fragment key={id}>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            onClick={() => {
                              handleNavMenuClick(item);
                              // navigation(`/${url}`);
                              // ReactGA.event({
                              //   category: gaCategory,
                              //   action: gaAction,
                              //   label: gaLable
                              // })
                            }}
                          >
                            <Grid item xs={6}>
                              <ListItem onClick={() => setOpenDrawer(false)}>
                                <ListItemText>
                                  <ArrowBackIosIcon />
                                </ListItemText>
                              </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                              <ListItem onClick={() => setOpenDrawer(false)}>
                                <ListItemText className="menu-name">
                                  {name}
                                </ListItemText>
                              </ListItem>
                            </Grid>
                          </Grid>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </List>
                  <Box className="footer-navbar">
                    <Grid className="footer-nav">
                      <Box className="footer-logo">
                        <a href="https://www.facebook.com/mygativan/">
                          <img
                            width={16}
                            height={35}
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/homepage/navbar/facebook.png"
                            }
                          />
                        </a>
                      </Box>
                      <Box className="footer-logo">
                        <a href="https://twitter.com/kalyanistudio?lang=en">
                          <img
                            width={35}
                            height={35}
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/homepage/navbar/twitter.png"
                            }
                          />
                        </a>
                      </Box>
                      <Box className="footer-logo">
                        <a href="https://www.instagram.com/mygativan/?hl=en">
                          <img
                            width={32}
                            height={35}
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/homepage/navbar/Instagram.png"
                            }
                          />
                        </a>
                      </Box>
                    </Grid>
                    <Grid className="footer-nav-contact">
                      <Box className="footer-logo-contact">
                        <a href="tel:+918263090692">
                          <img
                            width={30}
                            height={32}
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/homepage/navbar/carbon_phone-voice.png"
                            }
                          />
                        </a>
                      </Box>
                      <Box className="footer-logo-contact">
                        <span>+918263090692</span>
                      </Box>
                    </Grid>
                  </Box>
                </Drawer>
              </Grid>
            ) : (
              <div className="navbarLinks">
                <ul>
                  <li>
                    {/* <Link to="#" className="links" >
                      <Location
                        showCities={showCities}
                        SelectCity={SelectCity.cityName}
                        setSelectCity={setSelectCity}
                      />
                    </Link> */}

                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                      style={{
                        width: "100%",
                        textDecoration: "none",
                        fontFamily: "AvenirDemi",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "19px",
                        letterSpacing: "0.01em",
                        color: "#122164",
                        backgroundColor: "white",
                        textTransform: "none",
                        padding: "3px 12px"
                      }}
                    >
                      {formattedCityName} 
                      <ArrowDropDownIcon sx={{ fontSize: "30px" }} />
                    </Button>

                    {modelState && modelState.isModalOpen && (
                      <CityModal modelState={modelState} />
                    )}
                  </li>
                  {isShowSearch ? (
                    <li className="desktop-search">
                      {/* <Searchbar
                        searchState={searchState}
                        handleClick={handleClick}
                      /> */}
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Link to={`/${currentLocation?.cityName?.toLocaleLowerCase()?.replace(/\s/g, "-")}`} className="links">
                      Home
                    </Link>
                  </li>
                  <li>
                    <a href="#footer" className="links">
                      About Us
                    </a>
                  </li>
                  <li>
                    <Link to="/contact-us" className="links">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      onClick={() => {
                        ReactGA.event({
                          category: "Blogs",
                          action: "Clicked on Blogs",
                          label: "Blogs",
                        });
                      }}
                      className="links"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="links">
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="links">
                      <Button>Log In/Sign Up</Button>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <Modal
          className="Cart-Modal"
          keepMounted
          open={modalopen}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modal_body search-modal-body"
            sx={{ padding: "4% 2% !important" }}
          >
            <Searchbar searchState={searchState} handleClick={handleClick} />
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default NavBar;