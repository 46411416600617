import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState, useMemo } from "react";
import "./ViewCart.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"; //Right Arrow
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; //Dow Arrow
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MobileNumber from "./MobileNumber";
import ClearIcon from "@mui/icons-material/Clear";
import ViewCartFooter from "../../ReuseComponent/viewCartFooter/ViewCartFooter";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppContext } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import {
  StoreCartTotal,
  addToCart,
  removeServiceFromCart,
} from "../../../action";
import httpCommon from "../../../http-common";
import { useNavigate } from "react-router-dom";
import { ServiceContext } from "../VehicleServiceItems";
import axios from "axios";
import {
  getAllRecommendeServices,
  getCartBrowserId,
  getRecommendeServices,
} from "../../../services/vehicleservices/vehicleservices";
import ApplyPromocode from "../selectPromocode/ApplyPromocode";
import CouponDrawer from "../selectPromocode/CouponDrawer";

export default function ViewCart({ handlePayNow, postData, youPayAmount }) {
  const [promocode, setPromocode] = useState(false);
  const [Modalopen, setModalOpen] = React.useState(false);
  const [remark, setRemark] = useState("");
  const [browserIdDetails, setBrowserIdDetails] = useState({});
  const [subCateIdList, setSubCatIdList] = useState([]);
  const [recommendedData, setRecommendedData] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState({});

  // const [TotalPrice, setTotalPrice] = useState(0);
  // const subCateIdList = [];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigation = useNavigate();

  const URL = httpCommon.defaults.baseURL;

  const contextData = useContext(AppContext);
  const { setIsCheckOut, customerPickData, setCustomePickData } = contextData;
  const dispatch = useDispatch();

  const width = window.innerWidth;

  const ServiseData = useSelector((state) => state.AddToCartReducer);
  // getting current location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);
  const { subTotal, couponDiscount, cartTotal, cartItems, payNow } =
    useSelector((state) => state.CartTotalReducer);

  const vehicleSelectionData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  // --------------------------- getting mobile number from redux ---------------------
  const getMobileNumberRedux = useSelector(
    (state) => state.MobileNumberReducer
  );

  const browserData = useSelector((state) => state.BrowserUserData);

  const servicesData = useSelector((state) => state.AddToCartReducer);

  const vehicleId = vehicleSelectionData?.vehicleType?.id;
  const modelId = vehicleSelectionData?.model?.id;
  const variantId = vehicleSelectionData?.variant?.id;

  //---------------------------------------------- getting Browser Id ----------------------

  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);
  const CartTotalReducer = useSelector((state) => state.CartTotalReducer);

  const { mobileNumber } = vehicleSelectionData;

  if (width <= "768") {
    youPayAmount = 50;
  }

  // get user data w.r.t. browser id
  const getUserDataByBrowserId = async (browserId) => {
    try {
      const Response = await getCartBrowserId(browserId);
      const data = await Response.data;
      if (data.statusCode == 200) {
        setBrowserIdDetails(data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // let TotalPrice = 0;

  // const recommendedData = [
  //      {
  //           title: 'Wheel Replacement',
  //           desc: 'Wheel Replacement, Wheel Balancing, Wheel Alignment',
  //           img: '/Assets/homepage/gativanmotion/TyreReplacement.png',
  //           oldPrice: '2499',
  //           newPrice: '2299'
  //      },
  //      {
  //           title: 'Disk Brack',
  //           desc: 'Wheel Replacement, Wheel Balancing, Wheel Alignment',
  //           img: '/Assets/homepage/gativanmotion/BrakePadsReplacement.png',
  //           oldPrice: '2499',
  //           newPrice: '2299'
  //      }
  // ]

  const deleteBrowserCart = async (serviceId) => {
    const filterBrowserCart = browserIdDetails?.cartItems?.filter((i) => {
      return i.serviceId == serviceId;
    });

    try {
      const cartItemId = filterBrowserCart[0]?.cartItemId;
      const deleteCartData = await axios.delete(
        `${URL}/cart/item/${cartItemId}`
      );
      const result = await deleteCartData.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getRecommendeServicesData = async (
    vehicleTypeId,
    subCategoryId,
    modelId,
    variantId
  ) => {
    try {
      const response = await getRecommendeServices(
        vehicleTypeId,
        subCategoryId,
        modelId,
        variantId
      );
      const data = await response?.data;
      if (String(data.statusCode)[0] == 2) {
        setRecommendedData(data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllRecommendeServicesData = async (
    vehicleTypeId,
    modelId,
    variantId
  ) => {
    try {
      const response = await getAllRecommendeServices(
        vehicleTypeId,
        modelId,
        variantId
      );
      const data = await response?.data;
      if (String(data.statusCode)[0] == 2) {
        setRecommendedData(data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteService = (index, serviceId, displayPrice) => {
    deleteBrowserCart(serviceId);
    dispatch(removeServiceFromCart(index));
    // const coupon = CartTotalReducer?.coupon;
    // const discountPercentage = coupon?.discountPercentage || 0;
    // const totalItems = CartTotalReducer.cartItems;
    const items = CartTotalReducer.cartItems - 1;
    const total = CartTotalReducer.subTotal - displayPrice;

    // const couponDiscount = total - total / `1.${discountPercentage}`;
    // const finalTotal =
    //   CartTotalReducer.cartTotal - displayPrice - couponDiscount;

    // const couponDiscount = total - total / `1.${discountPercentage}`;
    const finalTotal = CartTotalReducer.cartTotal - displayPrice;

    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        cartItems: items,
        subTotal: total,
        cartTotal: finalTotal,
        // couponDiscount: Math.round(couponDiscount),
        // coupon: totalItems == 1 ? {} : CartTotalReducer?.coupon || {},
      })
    );
  };

  const ModalClosehandle = () => {
    setModalOpen(false);
  };
  const handelContinue = () => {
    setModalOpen(false);
  };

  const handleRemarkChange = (e) => {
    e.preventDefault();
    setRemark(e.target.value);
    setCustomePickData({ ...customerPickData, remark: e.target.value });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getBrowserIdRedux && getUserDataByBrowserId(getBrowserIdRedux);
  }, [getBrowserIdRedux, servicesData]);

  useEffect(() => {
    scrollToTop();
    // setIsCheckOut(false)
    if (width <= "768") {
      youPayAmount = 50;
    }
  }, []);

  useEffect(() => {
    if (ServiseData?.result?.length != 0) {
      setIsCheckOut(false);
    } else {
      setIsCheckOut(true);
    }
  }, [ServiseData]);

  // useEffect(() => {
  //      if (width <= "768") {
  //           if (ServiseData?.result?.length == 0) {
  //                // navigation(`/${currentLocation?.cityName?.toLowerCase()}`)
  //           }
  //      }
  //      servicesData?.result?.map((item) => {
  //           const { subCategoryId } = item
  //           if (!subCateIdList.includes(subCategoryId)) {
  //                subCateIdList.push(subCategoryId)
  //                // setSubCatIdList([...subCateIdList, subCategoryId])
  //           }
  //      })
  // }, [ServiseData?.result])

  useEffect(() => {
    subCateIdList.length != 0
      ? getRecommendeServicesData(vehicleId, subCateIdList, modelId, variantId)
      : getAllRecommendeServicesData(vehicleId, modelId, variantId);
  }, [subCateIdList, ServiseData, vehicleId, modelId, variantId]);

  const populateSubCateIdList = () => {
    const newSubCateIdList = [];
    servicesData?.result?.forEach((item) => {
      const { subCategoryId } = item;
      if (!newSubCateIdList.includes(subCategoryId)) {
        newSubCateIdList.push(subCategoryId);
      }
    });
    return newSubCateIdList;
  };

  useMemo(() => {
    const newSubCateIdList = populateSubCateIdList();
    setSubCatIdList(newSubCateIdList);
  }, [servicesData?.result]);

  const handleAddServiceClick = (item) => {
    handleAddServiceToCart(item);
  };

  // function handleAddServiceToCart(service) {

  //      const found = servicesData.result.some(e => e.serviceId == service.serviceId)

  // function handleAddServiceToCart(service) {

  //      const found = servicesData.result.some(e => e.serviceId == service.serviceId)

  //      if (!found) {
  //           service.addServiceToCart = true
  //           dispatch(addToCart(service, customerPickData))
  //      }
  // }

  const SaveDataWBrowser = async (visitorId, serviceData) => {
    const cartItems = {
      serviceId: serviceData?.serviceId,
      categoryId: serviceData?.serviceCategoryId,
      subCategoryId: serviceData?.serviceSubCategoryId,
    };
    const userDataObj = {
      browserId: visitorId,
      vehicleType: vehicleSelectionData?.vehicleType?.id,
      brandId: vehicleSelectionData?.brand?.id,
      modelId: vehicleSelectionData?.model?.id,
      variantId: vehicleSelectionData?.variant?.id,
      mobileNumber: getMobileNumberRedux?.mobileNumber,
      cartItems: [cartItems],
    };
    const saveCart = await axios.post(`${URL}/cart`, userDataObj);
    const result = await saveCart.data;
  };

  function handleAddServiceToCart(serviceData) {
    const found = servicesData.result.some(
      (e) => e.serviceId === serviceData.serviceId
    );

    if (!found) {
      serviceData.addServiceToCart = true;
      dispatch(addToCart(serviceData, customerPickData));
      SaveDataWBrowser(getBrowserIdRedux && getBrowserIdRedux, serviceData);
    }
  }

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (o) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(o);
  };

  const handleApplyPromocode = (e, isOpen) => {
    if (e) {
      e.stopPropagation();
    }

    if (Object.keys(CartTotalReducer?.coupon).length < 1) {
      setPromocode(isOpen);
    } else {
      alert("Coupon already applied!");
    }
    // setIsSelectCoupon(true);
  };

  const removeCoupon = (e) => {
    e.stopPropagation();
    // Dispatch remove coupon discound on coupon remove.
    const finalTotal = CartTotalReducer.subTotal - CartTotalReducer.payNow - 0;
    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        cartTotal: finalTotal,
        couponDiscount: 0,
        coupon: {},
      })
    );
  };

  useEffect(() => {
    setAppliedCoupon(CartTotalReducer?.coupon);
  }, [CartTotalReducer]);

  useMemo(() => {
    const itemsLength = servicesData?.result?.length;
    const youPayNow = itemsLength == 0 ? 0 : youPayAmount;

    if (itemsLength == CartTotalReducer?.cartItems) {
      dispatch(
        StoreCartTotal({
          ...CartTotalReducer,
          cartTotal:
            CartTotalReducer?.subTotal -
            youPayNow -
            CartTotalReducer?.couponDiscount,
          payNow: youPayNow,
        })
      );

      return;
    }

    const TotalPrice = servicesData?.result?.reduce(
      (acc, ele) => acc + ele?.displayPrice,
      0
    );

    let finalTotal = TotalPrice - (itemsLength == 0 ? 0 : youPayAmount);

    if (itemsLength == 0) {
      dispatch(
        StoreCartTotal({
          ...CartTotalReducer,
          couponDiscount: 0,
          coupon: {},
        })
      );
      setAppliedCoupon({});
    }

    // *************
    // let couponDiscount = 0;
    // const discountPercentage = CartTotalReducer?.coupon?.discountPercentage;

    // if (discountPercentage) {
    //   couponDiscount = Math.round(
    //     TotalPrice - TotalPrice / `1.${discountPercentage}`
    //   );

    //   finalTotal = TotalPrice - couponDiscount;
    // }

    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        subTotal: TotalPrice,
        cartTotal: CartTotalReducer?.couponDiscount
          ? finalTotal - CartTotalReducer?.couponDiscount
          : finalTotal,

        // cartTotal: couponDiscount ? finalTotal - couponDiscount : finalTotal,
        // couponDiscount: couponDiscount
        //   ? couponDiscount
        //   : CartTotalReducer?.couponDiscount,

        cartItems: itemsLength,
        // payNow: youPayNow,
      })
    );
  }, [servicesData]);

  return (
    <Box className="viewCart-mainCotainer">
      {open && (
        <StyledEngineProvider injectFirst>
          <CouponDrawer toggleDrawer={toggleDrawer} open={open}>
            <ApplyPromocode handleApplyPromocode={toggleDrawer} />
          </CouponDrawer>
        </StyledEngineProvider>
      )}
      {promocode ? (
        <ApplyPromocode handleApplyPromocode={handleApplyPromocode} />
      ) : (
        <Box>
          <Box className="view-cart-container">
            {/* ----------Addesd Services------------------------------ */}
            <Box className="viewCart-added-services">
              {/* ------- Addesd Services------------------ */}

              <Box className="added-services-container">
                <h6 className="viewcart-dekstop-cart-icon">
                  Cart{" "}
                  <AddShoppingCartIcon className="viewcart-shopping-cart" />{" "}
                </h6>
                {ServiseData.result.map((item, index) => {
                  const {
                    serviceId,
                    service,
                    oil,
                    displayPrice,
                    actualPrice,
                    serviceDetails,
                    imagePath,
                  } = item;
                  // TotalPrice += parseInt(displayPrice);
                  return (
                    <Grid
                      key={index}
                      className="added-services-each-service"
                      container
                    >
                      <Grid item xs={4} md={0}>
                        <Box className="added-service-image">
                          <img src={`${URL}${imagePath}`} alt="" />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box
                          className="added-service-details"
                          style={{ width: "82%", marginRight: "5%" }}
                        >
                          <h5 className="added-serviceName">{service}</h5>
                          <p className="added-serviceDesc">{oil}</p>
                          <span className="oldPrice">
                            <CurrencyRupeeIcon className="oldPrice-rupeeIcon" />
                            {actualPrice}
                          </span>
                          <span className="newPrice">
                            <CurrencyRupeeIcon className="newPrice-rupeeIcon" />
                            {displayPrice}
                          </span>
                        </Box>
                        <Box className="added-service-delete-btn">
                          <DeleteForeverIcon
                            onClick={() => {
                              handleDeleteService(
                                index,
                                serviceId,
                                displayPrice
                              );
                            }}
                            className="delete-btn"
                          />
                          <ClearIcon
                            onClick={() => {
                              handleDeleteService(
                                index,
                                serviceId,
                                displayPrice
                              );
                            }}
                            className="delete-btn-dekstop"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>

              <Box className="bill-detail">
                <h5>Bill Details</h5>
                <Box className="bill-sub-total">
                  <h6>Sub Total </h6>
                  <h6>
                    <CurrencyRupeeIcon className="bill-rupee-icon" /> {subTotal}
                  </h6>
                </Box>
                <Box className="bill-you-pay">
                  <h6>You Pay Now</h6>
                  <h6>
                    {" "}
                    <CurrencyRupeeIcon className="bill-rupee-icon" />
                {   width <= "768" ? 0 : 50}


                    
                  </h6>
                </Box>
                {/* {couponDiscount > 0 && (
                <>
                  <Box className="coupon-discount-box">
                    <h6 className="coupon-discount-label">Coupon Discount </h6>
                    <h6 className="coupon-discount">
                      -
                      <CurrencyRupeeIcon className="coupon-discount-rupee-icon" />
                      <span>{couponDiscount}</span>
                    </h6>
                  </Box>
                  <Divider />
                <Box className="bill-sub-total">
                  <h6>You Pay Later </h6>
                  <h6>
                    <CurrencyRupeeIcon className="bill-rupee-icon" />{" "}
                    {cartTotal}
                  </h6>
                </Box>
                </>
              )} */}
                <Divider />
                <Box className="you-pay-later">
                  <h6>You Pay After Service </h6>
                  <h6>
                    <CurrencyRupeeIcon className="bill-rupee-icon" />{" "}
                    
                    {   width <= "768" ? subTotal : cartTotal}
                  </h6>
                </Box>

                {/* <Box className="bill-you-pay">
              <h6>You Pay</h6>
              <h6>
                {" "}
                <CurrencyRupeeIcon className="bill-rupee-icon" />
                {youPayAmount}
              </h6>
            </Box> */}
              </Box>

              <Box className="viewcart-recommended-services">
                <Box
                  display="flex"
                  className="viewcart-recommended-services-heading"
                >
                  <Box className="bulb">
                    <img
                      src={process.env.PUBLIC_URL + "/Assets/images/g10.png"}
                      alt=""
                    />
                  </Box>
                  <Box ml={2}>
                    <h4>Recommended Services</h4>
                    <p>Add more services for extra saving</p>
                  </Box>
                </Box>
                <Box className="recommended-services-carousel">
                  {/* <Carousel
                  className="carousel"
                  // infinite
                  itemClass="carousel-item-margin-40-px"
                  removeArrowOnDeviceType={["mobile", "dekstop"]}
                  // autoPlay autoPlaySpeed={30000}
                  centerMode
                  arrows
                  responsive={{
                    dekstop: {
                      breakpoint: { min: 768, max: 3000 },
                      items: 0.5,
                      slidesToSlide: 1,
                      partialVisibilityGutter: 50,
                    },
                    mobile: {
                      breakpoint: { min: 0, max: 768 },
                      items: 0.5,
                      slidesToSlide: 1,
                      partialVisibilityGutter: 15,
                    },
                  }}
                >
                  {
                    recommendedData.map((item) => {
                      // const { title, desc, img, oldPrice, newPrice } = item
                      const {
                        serviceId,
                        service,
                        displayPrice,
                        actualPrice,
                        imagePath,
                        activities,
                      } = item;

                      return (
                        // <Box pl={1} key={serviceId}>
                        <Box className="recommended-servic-card">
                          <Box
                            display="flex"
                            className="recommende-image-title"
                          >
                            <Box className="recommended-card-image">
                              <img src={`${URL}${imagePath}`} alt="" />
                            </Box>
                            <Box className="recommended-card-title">
                              <h4>{service}</h4>
                              <Box className="recommended-card-desc">
                                <p className="desc">
                                  {activities
                                    ?.splice(0, 3)
                                    ?.map((item, index) => {
                                      const { id, activity } = item;
                                      return (
                                        <span key={id}>
                                          {index > 0 && ", "}
                                          {activity}
                                        </span>
                                      );
                                    })}
                                </p>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            pl={1}
                            justifyContent="space-around"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ width: "50%" }}
                            >
                              <Box className="oldPrice">
                                <CurrencyRupeeIcon className="oldPrice-rupeeIcon" />
                                {actualPrice}
                              </Box>
                              <Box className="newPrice">
                                <CurrencyRupeeIcon className="newPrice-rupeeIcon" />
                                {displayPrice}
                              </Box>
                            </Box>
                            <Box>
                              <Button
                                className="add-service-btn"
                                p={1}
                                onClick={() => {
                                  handleAddServiceClick(item);
                                }}
                              >
                                {" "}
                                + Add Service
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                    // </Box>
                  }
                </Carousel> */}
                  <Carousel
                    className="carousel"
                    // infinite
                    itemClass="carousel-item-margin-40-px"
                    removeArrowOnDeviceType={["mobile", "dekstop"]}
                    // autoPlay autoPlaySpeed={30000}
                    centerMode
                    arrows
                    responsive={{
                      dekstop: {
                        breakpoint: { min: 768, max: 3000 },
                        items: 0.5,
                        slidesToSlide: 1,
                        partialVisibilityGutter: 50,
                      },
                      mobile: {
                        breakpoint: { min: 0, max: 768 },
                        items: 0.5,
                        slidesToSlide: 1,
                        partialVisibilityGutter: 15,
                      },
                    }}
                  >
                    {recommendedData.map((item) => {
                      // const { title, desc, img, oldPrice, newPrice } = item
                      const {
                        serviceId,
                        service,
                        displayPrice,
                        actualPrice,
                        imagePath,
                        activities,
                      } = item;

                      return (
                        // <Box pl={1} key={serviceId}>
                        <Box className="recommended-servic-card">
                          <Box
                            display="flex"
                            className="recommende-image-title"
                          >
                            <Box className="recommended-card-image">
                              <img src={`${URL}${imagePath}`} alt="" />
                            </Box>
                            <Box className="recommended-card-title">
                              <h4>{service}</h4>
                              <Box className="recommended-card-desc">
                                <p className="desc">
                                  {activities
                                    ?.splice(0, 3)
                                    ?.map((item, index) => {
                                      const { id, activity } = item;
                                      return (
                                        <span key={id}>
                                          {index > 0 && ", "}
                                          {activity}
                                        </span>
                                      );
                                    })}
                                </p>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            pl={1}
                            justifyContent="space-around"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ width: "50%" }}
                            >
                              <Box className="oldPrice">
                                <CurrencyRupeeIcon className="oldPrice-rupeeIcon" />
                                {actualPrice}
                              </Box>
                              <Box className="newPrice">
                                <CurrencyRupeeIcon className="newPrice-rupeeIcon" />
                                {displayPrice}
                              </Box>
                            </Box>
                            <Box>
                              <Button
                                className="add-service-btn"
                                p={1}
                                onClick={() => {
                                  handleAddServiceClick(item);
                                }}
                              >
                                {" "}
                                + Add Service
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        // </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              </Box>
            </Box>

            {/* ------------------------------------Apply Promocode-------------------------------- */}

            {/* <Box
              className="promocode"
              style={{
                pointerEvents: cartItems ? "all" : "none",
                opacity: cartItems ? "1" : "0.5",
              }}
            >
              <Box
                className="promocode-selection"
                onClick={
                  isMobile
                    ? (e) => handleApplyPromocode(e, true)
                    : toggleDrawer(true)
                }
              >
                <Typography className="promocode-heading">
                  <LocalOfferIcon className="offer-icon" />
                  <span>
                    {appliedCoupon?.couponCode
                      ? `${appliedCoupon?.couponCode} - Coupon Applied`
                      : "Apply Promocode"}
                  </span>
                </Typography>
                {appliedCoupon?.couponCode ? (
                  <CloseIcon
                    className="remove-promocode"
                    onClick={(e) => removeCoupon(e)}
                  />
                ) : (
                  <KeyboardArrowRightIcon className="promocode-rightArrow" />
                )}
              </Box>
            </Box> */}

            {/* -------------------Bill Details------------------------- */}

            {/* ------------------------------ empty div ------------------------ */}
            <Box container className="empty-box"></Box>
            {/* </Box> */}
            {/* </Box> */}

            {/* ----------View Cart Footer----------------------- */}

            <Box className="Dekstop-Viewcart-footer">
              <ViewCartFooter
                buttonName="Pay Now"
                handlePayNow={handlePayNow}
                youPayAmount={youPayAmount}
                postData={postData}
              />
            </Box>

            <Modal
              className="Cart-Modal"
              keepMounted
              onClose={ModalClosehandle}
              open={Modalopen}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box className="modal_body">
                <MobileNumber handelContinue={handelContinue} />
              </Box>
            </Modal>
          </Box>
          <Box className="mobile-view-footer">
            <ViewCartFooter
              buttonName="Checkout"
              youPayAmount={youPayAmount}
              postData={postData}
            />
    
          </Box>
        </Box>
      )}
    </Box>
  );
}
