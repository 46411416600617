import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import Store from "./Store";
import "./index.css";



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={Store}>
       <App />
    </Provider>
  // </React.StrictMode>
);

// const rootElement = ReactDOM.createRoot(document.getElementById("root"));
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// ServiceWorker.unregister();
