import React from 'react'
import './CarouselLogo.css'
import {
    Box,
    Grid,
} from '@mui/material'; 
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function CarouselLogo({CarouselLogoData}) {


    const brandData = [
        {
            id: 1,
            bikebrand: 'Honda1.png',
            carbrand: 'MarutiSuzuki.png',
        },
        {
            id: 2,
            bikebrand: 'Bajaj.png',
            carbrand: 'Tata.png',
        },
        {
            id: 3,
            bikebrand: 'KTM.png',
            carbrand: 'Ford.png',
        },
        {
            id: 4,
            bikebrand: 'Hero.png',
            carbrand: 'Hyundai.png',
        },
        {
            id: 5,
            bikebrand: 'Tvs.png',
            carbrand: 'Mercedes.png',
        },
        {
            id: 6,
            bikebrand: 'Suzuki.png',
            carbrand: 'Toyota.png',
        },
        {
            id: 7,
            bikebrand: 'aprilia.png',
            carbrand: 'Audi.png',
        },
        {
            id: 8,
            bikebrand: 'Yamaha.png',
            carbrand: 'BMW.png',
        },
        {
            id: 9,
            bikebrand: 'Vespa.png',
            carbrand: 'MG.png',
        },
        {
            id: 10,
            bikebrand: 'Jawa.png',
            carbrand: 'Skoda.png',
        },
    ]

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 50 // this is needed to tell the amount of px that should be visible.
        }
    }


    return (
        <>
            <Box>
                <Box className="logo-img-container">
                    <Box className="logo-img-sub-container" >
                        <Carousel
                            // infinite

                            partialVisible={true}
                            responsive={responsive}
                            autoPlaySpeed={10000}
                            containerClass="container"
                            className="Carousel"
                            additionalTransfrom={0}
                        >
                            {CarouselLogoData.map((item, index) => {
                                return (
                                    <Box container key={index} >
                                        <Box container className="img-sub-container" >
                                            <img
                                                src={process.env.PUBLIC_URL + `/Assets/homepage/${item.brand1}`}
                                                alt="brands we service"
                                            />
                                        </Box>
                                        <Box container className="img-sub-container">
                                            <img
                                                src={process.env.PUBLIC_URL + `/Assets/homepage/${item.brand2}`}
                                                alt="brands we service"
                                            />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Carousel>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
