import http from "../../http-common";



//get vehicle type
export const getVehicleType = () => {
     return http.get('/vehicleType')
}

//get brands
export const getBrand = (vehicleType) => {
     if (vehicleType)
          return http.get(`/brand/${vehicleType}`);
     return;
}

//get brands by vehicle name
export const getBrandByName = (vehicleTypeName) => {
     if (vehicleTypeName)
          return http.get(`/brand/vehicle/${vehicleTypeName}`);
     return;
}

//get model
export const getModel = (vehicleTypeId, brandId) => {
     if (vehicleTypeId && brandId)
          return http.get(`/models/${vehicleTypeId}/${brandId}`);
     return;
}

//get model by brand name
export const getModelByBrandName = (vehicleTypeName, brandName) => {
     if (vehicleTypeName && brandName)
          return http.get(`/models/byBrand/${vehicleTypeName}/${brandName}`);
     return;
}

// get variant by id
export const getVariant = (modelId) => {
     if (modelId)
          return http.get(`/variant/${modelId}`);
     return;
}

//get variant by name
export const getVariantByModelName = (vehicleTypeName, modelName) => {
     if (vehicleTypeName && modelName)
          return http.get(`/variant/byModel/${vehicleTypeName}/${modelName}`);
     return;
}

//get service categories by id
export const getServiceCategory = (vehicleType) => {
     if (vehicleType)
          return http.get(`/serviceCategory/${vehicleType}`)
     return;
}

//get service categories by name
export const getServiceCategoryByVehicleName = (vehicleTypeName) => {
     if (vehicleTypeName)
          return http.get(`/serviceCategory/byVehicle/${vehicleTypeName}`);
     return;
}

//get subcategories by name
export const getSubCategories = (vehicleTypeName, serviceCategoryName) => {
     if (vehicleTypeName && serviceCategoryName)
          return http.get(`/serviceSubCategory/bycategory/${vehicleTypeName}/${serviceCategoryName}`);
     return;
}

//get serviceDetails
export const getServiceDetails = (serviceId) => {
     if (serviceId)
          return http.get(`/serviceDetail/getDetailByServiceId/${serviceId}`);
     return;
}

//get serviceActivities
export const getServiceActivities = (serviceId) => {
     if (serviceId)
          return http.get(`/serviceActivity/getActivityByServiceId/${serviceId}`);
     return;
}

// get sub categories services by id with price
export const getSubCategoriesWithPriceById = (categoryId, modelId, variantId) => {
     if (categoryId && modelId && variantId)
          return http.get(`/serviceSubCategory/${categoryId}/${modelId}/${variantId}`);
     return;
}

//get subCategories with price by name
export const getSubCategoriesWithPrice = (vehicleTypeName, serviceCategoryName, modelName, variantName) => {
     if (vehicleTypeName && serviceCategoryName && modelName && variantName)
          return http.get(`/serviceSubCategory/bycategory/${vehicleTypeName}/${serviceCategoryName}/${modelName}/${variantName}`);
     return;
}

//get date and time 
// export const getSlots = () => {
//      return http.get('/cartController/getSystemDate')
// }

// Get city

export const getCities = () => {
     return http.get('/city')
}

// get Slots 

// export const getTimeSlots = (cityId) => {
//      if (cityId)
//           return http.get(`/BookingSlotController/getSlots/${cityId}`)
//      return;

// }

//get service
export const getService = (vehicleTypeId, serviceCategory, brand, variant) => {
     if (vehicleTypeId && serviceCategory && brand && variant)
          return http.get(`/services/${vehicleTypeId}/${serviceCategory}/${brand}/${variant}`);
     return;
}

// get order status
export const getOrders = (mobileNumber) => {
     if (mobileNumber)
          return http.get(`/orders/ordersByMobileNumber/${mobileNumber}`);
     return;
}

// get order status
export const getOrderStatus = () => {
     return http.get(`/orderStatus`)
}

// get services by search query
export const getQueryResult = (searchQuery) => {
     if (searchQuery)
          return http.get(`/services/search/${searchQuery}`);
     return;
}

// get dates and slots by current date
export const getDateSlots = (currentDate,cityId) => {
     if (currentDate)
          return http.get(`/availableSlots/?city=${cityId}&from=${currentDate}`);
     return;
}

// get user details and cart by browser id
export const getCartBrowserId = (browserId) => {
     if (browserId)
          return http.get(`/cart/${browserId}`);
     return null;
}

//get vehilce type by id
export const getVehicleById = (vehicleTypeId) => {
     if (vehicleTypeId)
          return http.get(`/vehicleType/type/${vehicleTypeId}`);
     return;
}

//get brand by id
export const getBrandById = (brandId) => {
     if (brandId)
          return http.get(`/brand/brand/${brandId}`);
     return;
}

//get model by id
export const getModelById = (modelId) => {
     if (modelId)
          return http.get(`/models/model/${modelId}`);
     return;

}

//get variant by id
export const getVariantById = (variantId) => {
     if (variantId)
          return http.get(`/variant/id/${variantId}`);
     return;
}

//get service id with price
export const getServiceByIdWithPrice = (serviceId, modelId, variantId) => {
     if (serviceId && modelId && variantId)
          return http.get(`/services/${serviceId}?modelId=${modelId}&variantId=${variantId}`)
     return;
}

// get all arrival modes
export const getAllArrivalModes = () => {
     return http.get(`/arrivalModes`)
}

// get service guide by category id and vehicle type id
export const getServiceGuide = (categoryId, vehicleTypeId) => {
     if (categoryId && vehicleTypeId)
          return http.get(`/serviceGuide/getServiceGuide/${categoryId}/${vehicleTypeId}`)
     return;
}

// get service category by category id
export const getCategoryByCategoryId = (categoryId) => {
     if (categoryId)
          return http.get(`/serviceCategory/byCategory/${categoryId}`)
     return;
}

// get order types
export const getOrderTypes = () => {
     return http.get(`/orderTypes`)
}

// get recommended Services
export const getRecommendeServices = (vehicleTypeId, subCategoryId, modelId, variantId) => {
     if (vehicleTypeId && subCategoryId && modelId && variantId)
          return http.get(`/serviceSubCategory/recommendedServices/${vehicleTypeId}/${subCategoryId}/${modelId}/${variantId}`);
     return;
}

// get all recommended Services
export const getAllRecommendeServices = (vehicleTypeId, modelId, variantId) => {
     if (vehicleTypeId && modelId && variantId)
          return http.get(`/serviceSubCategory/recommendedServices/${vehicleTypeId}/${modelId}/${variantId}`);
     return;
}

// get service guide with city name
export const getServiceGuideWithCityName = (cityName) => {
     if (cityName)
          return http.get(`/serviceGuide?city=${cityName}`);
     return;
}

//get service guide with category and vehicle type
export const getServiceGuideWithCategoryAndVehicleType = (isVehicle, category, vehicleType) => {
     if (isVehicle != null && category && vehicleType) {
          return http.get(`/serviceGuide/byCategory?isVehicle=${isVehicle}&category=${category}&vehicleType=${vehicleType}`);
     }
     return;
}



//get lead by id
export const getLeadById = (leadId) => {
     return http.get(`/leads/leadbyid/${leadId}`);
};


//get all district 
export const getUniqueCity = () => {
     return http.get(`city/unique`);
}

export const getServiceByCategoryAndService = (type, category, service, model, variant) => {
     if ( type && category && service && model && variant)
          return http.get(`/services/byCategoryAndService/${type}/${category}/${service}/${model}/${variant}`);
     return;
}

export const getServiceByCategoryAndServiceNotSelected = (type, category, service) => {
     if ( type && category && service )
          return http.get(`/services/byCategoryAndService/${type}/${category}/${service}`);
     return;
}

//get all district 
export const getDistrict = () => {
     return http.get(`city/unique`);
}