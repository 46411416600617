import React from "react";
import "./SignUp.css";
import { Box, TextField, Button, Grid } from "@mui/material";

export default function SignUp() {
  return (
    <Grid container spacing={2}>
      {/*------------image conatiner------------*/}
      <Grid item xs={12} md={7} sm={12}>
        <Box className="img-container">
          <Box className="back-to-booking">Back to booking </Box>
          <img
            className="login-background"
            src={process.env.PUBLIC_URL + "/Assets/login/loginbackgroung.png"}
          />
          <img
            className="gativan-logo"
            src={
              process.env.PUBLIC_URL + "/Assets/login/GativanFullLogoWhite.png"
            }
          />
          <Box className="social-media-logo">
            <Box className="facebook-logo">
              <img
                src={process.env.PUBLIC_URL + "/Assets/login/facebook.png"}
              />
            </Box>
            <Box className="twitter-logo">
              <img src={process.env.PUBLIC_URL + "/Assets/login/Twitter.png"} />
            </Box>
            <Box className="instagram-logo">
              <img
                src={process.env.PUBLIC_URL + "/Assets/login/Instagram.png"}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      {/*------------Sign up form container-----*/}
      <Grid item xs={12} md={5} sm={12} >
        <Box className="personal-info">
          <Box className="signup-form">
            <h4>Sign Up</h4>
            <h5>
              Already a member?<strong> Log In</strong>
            </h5>
            <Box className="signup-name">Name</Box>
            <TextField
              className="enter-signup-info"
              placeholder="Enter Name"
            ></TextField>

            <Box className="signup-name">Email Address</Box>

            <TextField
              className="enter-signup-info"
              placeholder="Enter Email "
            ></TextField>

            <Box className="signup-name">Mobile No.</Box>
            <Box>
              <TextField
                className="enter-signup-info"
                placeholder="Enter Mobile Number"
              ></TextField>
            </Box>
            <Box className="sign-up-btn">
              <Button variant="contained" size="large" className="signup-btn">
                Sign Up
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
