import React from 'react'
import { Box } from '@mui/material'

export default function Guidelines() {
    const data = [
        {
            id: 1,
            title: "User Details:",
            details: " Users are required to register their phone number on the Gativan website to book a service. Users should ensure that their mobile number provided is accurate and up-to-date."
        },
        {
            id: 2,
            title: "Service Requirements: ",
            details: "Users should provide accurate information about the type of service required, the make and model of their vehicle, and any specific details about the service needed."
        },
        {
            id: 3,
            title: "Service Booking:",
            details: "Users should book their service well in advance to ensure the availability of their preferred time slot. Users should also ensure that they have provided accurate contact information for confirmation of the service booking."
        },
        {
            id: 4,
            title: "Payment:",
            details: "Users are required to make a payment before the vehicle is delivered. Payment can be made through the payment link sent on the registered mobile number. "
        },
        {
            id: 5,
            title: "Cancellation and Rescheduling: ",
            details: "Users should follow the cancellation and rescheduling policy outlined by Gativan. Any cancellations or rescheduling should be done at least 24 hours before the scheduled time slot to avoid penalties."
        },
        {
            id: 6,
            title: "Booking Time:",
            details: "Users should be available at the service location at the scheduled time slot to ensure timely service. Any delay in arrival may result in a delay in the service."
        },
        {
            id: 7,
            title: "Safety:",
            details: "Users should ensure that their vehicle is safe to drive and complies with local laws and regulations. Users should also follow the safety measures outlined by Gativan, such as removing any valuables from the vehicle before the service."
        },
        {
            id: 8,
            title: "Communication:",
            details: "Users should communicate clearly with the Gativan mechanics regarding any issues or concerns with their vehicle. Users should also notify Gativan of any changes in the service requirements."
        },
        {
            id: 9,
            title: "Feedback and Review:",
            details: "Users should provide feedback and review of the service provided by Gativan. This will help Gativan to improve its services and address any issues or concerns raised by the users."
        },
        {
            id: 10,
            title: "Environmental Responsibility:",
            details: "Users should be responsible for their impact on the environment. Users should follow local laws and regulations regarding waste disposal and should avoid any activities that may harm the environment during the use of their vehicle."
        },
    ]
    return (
        <React.Fragment>
            <Box style={{ padding: "3%" }}>
                <h2>
                    User Guidelines:
                </h2>
                <br />
                {data?.map((item) => {
                    const { id, title, details } = item
                    return (
                        <Box>
                            <p key={id}>
                                <h4>{title}</h4>{details}
                            </p>
                            <br />
                        </Box>
                    )
                })}
            </Box>
        </React.Fragment>
    )
}

