import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  handleChangeVehicelComponent,
  handleOpenModalState,
} from "../../../action";
import httpCommon from "../../../http-common";
import MainSelection from "../../Selection/MainSelection.Component";
import AddToCart from "./AddToCart";
import "./Cart.css";

export default function Cart({ cartModal }) {
  const [showChangeVehicle, setShowChangeVehicle] = useState(false);

  const {
    // location,
    // vehicleType,
    serviceCategoryType,
    brand,
    // model,
    variant,
  } = useParams();

  // const { Modalopen, setModalOpen } = cartModal

  const Modalopen = useSelector((state) => state.OpenModalReducer);

  const URL = httpCommon.defaults.baseURL;
  const dispatch = useDispatch();
  const CustomerSelectData = useSelector(
    (state) => state.SelectionVehicleReducer
  );
  const {
    vehicleType,
    // brand,
    model,
    // variant
  } = CustomerSelectData;

  const changeTrigger = useSelector((state) => state.ChangeVehicleComponent);

  const vehicleSelection = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  const width = window.innerWidth;

  const ChangeVehiclehendle = () => {
    // setChangeVehicle(true)
    // setModalOpen(true)
    dispatch(handleChangeVehicelComponent(true));
    if (width < "768") {
      // setModalOpen(true)
      dispatch(handleOpenModalState(true));
    }
  };

  const handleClose = () => {
    // setChangeVehicle(false)

    // setModalOpen(false)
    dispatch(handleChangeVehicelComponent(false));
    dispatch(handleOpenModalState(false));
  };

  useMemo(() => {
    if (Modalopen === false) {
      dispatch(handleChangeVehicelComponent(false));
    }
  }, [Modalopen]);

  useEffect(() => {
    if (width > "768") {
      if (serviceCategoryType != undefined && variant == undefined) {
        dispatch(handleChangeVehicelComponent(true));
      }
      if (variant != undefined) {
        setShowChangeVehicle(true);
      }
    } else if (width <= "768") {
      if (
        serviceCategoryType != undefined &&
        brand != undefined &&
        variant == undefined
      ) {
        dispatch(handleChangeVehicelComponent(true));
        dispatch(handleOpenModalState(true));
      }
    }
  }, []);

  useEffect(() => {
    if (variant != undefined) {
      setShowChangeVehicle(true);
    }
    if (width <= "768") {
      if (variant == undefined) {
        setShowChangeVehicle(false);
      }
    }
  }, [vehicleSelection]);

  // useMemo(() => {
  //      if (width > "768") {
  //           if (serviceCategoryType != undefined && variant == undefined) {
  //                console.log("serviceCategoryType != undefined && variant == undefined triggered")
  //                dispatch(handleChangeVehicelComponent(true))
  //           }
  //           if (variant != undefined) {
  //                setShowChangeVehicle(true)
  //           }
  //      }
  // }, [vehicleType])

  return (
    <>
      {changeTrigger ? (
        Modalopen === true ? (
          <Modal
            className="Cart-Modal"
            keepMounted
            open={Modalopen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="modal_body">
              <Box>
                {" "}
                <MainSelection cartbrand={true} />
              </Box>
            </Box>
          </Modal>
        ) : (
          <MainSelection cartbrand={true} />
        )
      ) : showChangeVehicle ? (
        <Box className="change-vehicle-card">
          <Box className="changeVehicle-cantainer">
            <Box className="changeVehicle-modelName">
              {variant ? (
                <Box>
                  <Typography className="brandAndModelName">
                    {" "}
                    {CustomerSelectData.brand.brandName}{" "}
                    {CustomerSelectData.model.modelName}
                  </Typography>
                  <Typography className="modelVariant">
                    {CustomerSelectData.variant.variantName}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box className="ChangeVehicle-btn">
              <Box>
                <Typography
                  className="ChangeVehicle-btn-button"
                  onClick={ChangeVehiclehendle}
                  variant="p"
                >
                  <BorderColorIcon
                    className="ChangeVehicle-btn-button-pencil"
                    style={{ color: "black" }}
                  />
                  <h4>Change Vehicle</h4>
                </Typography>
              </Box>
            </Box>
            <Box className="changeVehicle-modelImage">
              {variant ? (
                <Box className="changeVehicle-modelImage-Image">
                  <img src={`${URL}${CustomerSelectData.model.imagePath}`} />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>

          {/* <Box className='cart-image'>
                                        <img src={process.env.PUBLIC_URL + '/Assets/images/addtocart/AddToCart.png'} alt="" />
                                   </Box> */}
          <AddToCart />
        </Box>
      ) : (
        <Box
          className="change-vehicle-card"
          // style={{ textAlign: "center", paddingTop: '10%' }}
        >
          {/* <h4>Please Select Vehicle</h4> */}
          <img
            src={
              process.env.PUBLIC_URL +
              "/Assets/vehicleServiceItems/vehicleNotSelected.png"
            }
          />
        </Box>
      )}
    </>
  );
}
