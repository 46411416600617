import React, { useEffect, useMemo, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import httpCommon from "../../http-common";

import fontURL1 from "../../font/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../font/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../font/AvenirNextLTPro-Demi.otf";
import { getOrderById } from "../../services/getOrderById";
import { useParams } from "react-router-dom";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

const TermsAndConditionCars = [
  "During first start of car/cold start, keep the car on to let the engine heat for at least 5 mins. ",
  "Ride daily or once in 2 days to keep the engine working and battery from draining out.",
  "Please change your engine oil after 3-6 months or within 5000-10000 kms whichever comes soon.",
  "Service warranty will be only valid for 30 days after delivery.",
  "Only the jobs mentioned in the job card will be done.",
  "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
];
const TermsAndConditionBikes = [
  "During first start of bike/cold start every morning start it with kick as your bike was stand still.",
  "Ride daily to keep the engine working and battery from draining out.",
  "Service warranty will be only valid for 7 days after delivery.",
  "Please change your engine oil after 2-3 months or within 2000-3000 kms whichever comes soon.",
  "Only the jobs mentioned in the job card will be done.",
  "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
];
// Create styles

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#122164",
    padding: "2%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  jobcardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  jobcardSubHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30px",
    margin: "10px 0px",
  },
  heading: {
    color: "white",
    marginRight: "0%",
    fontSize: 20,
    fontFamily: "AvenirBold",
  },
  subHeading: {
    color: "white",
    marginRight: "0%",
    fontSize: 16,
    fontFamily: "AvenirBold",
  },
  logo: {
    width: "120px",
    marginRight: "0%",
  },
  subLogo: {
    height: "100%",
    marginRight: "0%",
  },
  subLogoImage: {
    width: "25px",
    height: "25px",
  },

  userInfoSection: {
    height: "150px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "2%",
    position: "relative",
  },
  label: {
    fontSize: "10px",
    fontFamily: "AvenirBold",
    marginTop: "5px",
  },
  fields: {
    color: "#6a7086",
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    fontWeight: "400",
    marginTop: "2px",
  },

  servicesContainer: {
    backgroundColor: "white",
    padding: "2%",
  },
  packageName: {
    fontSize: "14px",
    fontFamily: "AvenirBold",
    color: "#122164",
  },
  packageNameUnderline: {
    width: "100px",
    height: "1px",
    backgroundColor: "black",
  },
  serviceActivity: {
    padding: "5px 0px",
  },
  serviceActivityList: {
    // height: "25px",
    color: "#6a7086",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  activityName: {
    width: "85%",
    fontSize: "12px",
    fontFamily: "AvenirDemi",
  },

  check_Image: {
    height: "15px",
    width: "15px",
  },
  subtotalSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
  },
  subtotalNonCataSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "5px 0px",
  },
  subtotalHeading: {
    fontSize: "11px",
    fontFamily: "AvenirBold",
  },

  priceSection: {
    display: "flex",
    flexDirection: "row",
    // alignItems:'center',
  },
  rupee_Image: {
    width: 10,
    height: 10,
    fontFamily: "AvenirBold",
  },
  subtotalPrice: {
    fontSize: 10,
    fontFamily: "AvenirDemi",
    color: "gray",
  },

  calculationTopBorder: {
    width: "104%",
    height: "1px",
    backgroundColor: "gray",
    marginLeft: "-2%",
    marginTop: "2px",
    marginBottom: "5px",
  },
  discount: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    color: "gray",
  },
  discountHeading: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    color: "gray",
  },
  discountTopBorder: {
    width: "100%",
    height: "1px",
    backgroundColor: "gray",
    margin: "5px 0px",
  },
  totalPrice: {
    fontSize: 10,
    fontFamily: "AvenirBold",
  },
  termsAndCondSection: {
    // backgroundColor:'white',
    margin: "10px 0px",
  },
  termsAndConHeading: {
    color: "white",
    fontSize: "14px",
    fontFamily: "AvenirBold",
  },
  termsList: {
    fontSize: "10px",
    fontFamily: "AvenirRegular",
    marginBottom: "3px",
  },
});

export default function PDF() {
  const [OrderData, setOrderData] = React.useState();
  const [allServices, setAllServices] = React.useState([]);
  const [allNonCata, setAllCata] = React.useState([]);

  const [TermsAndCondition, setTermsAndCondition] = useState([]);

  const baseURL = httpCommon.defaults.baseURL;

  const OrderId = localStorage.getItem("orderId");

  //   let OrderId='O-CABN-2212-00054'

  const firstName = OrderData?.user?.first_name;
  const lastName = OrderData?.user?.last_name;
  const mobile = OrderData?.user?.mobile_number;

  const vehicleType = OrderData?.vehicle?.vehicleType?.vehicleType;
  const brand = OrderData?.vehicle?.brand?.brand;
  const model = OrderData?.vehicle?.model?.model;
  const modelImage = OrderData?.vehicle?.model?.imageUrl;
  const variant = OrderData?.vehicle?.variant?.variantName;
  const vehicleNumber = OrderData?.vehicle?.vehicleNumber;

  const housNumber = OrderData?.address?.houseNumber;
  const area = OrderData?.address?.area;
  const city = OrderData?.address?.city;
  const address = housNumber + ", " + area + ", " + city;

  const customerName = firstName + " " + lastName;
  const vehicle = brand + " " + model + " " + variant;

  const TotalPrice = OrderData?.totalPrice;
  const discount = OrderData?.discount;

  const ServivesTotal = TotalPrice + discount;

  const NonCatalogues = OrderData?.nonCatalogueJobs;
  const Services = OrderData?.services;

  useMemo(() => {
    let temp = [];
    Services &&
      Services.map((i) => {
        const Obj = {
          service: i.service,
          price: i.displayPrice,
          activity: i.activities,
        };
        temp.push(Obj);
      });
    setAllServices([...temp]);
  }, [Services]);

  useMemo(() => {
    let temp = [];
    NonCatalogues &&
      NonCatalogues.map((i) => {
        const countPrice = parseInt(i.quantity) * parseInt(i.price);
        const Obj = {
          service: i.name,
          price: i.price,
          quantity: i.quantity,
        };
        temp.push(Obj);
      });
    setAllCata([...temp]);
  }, [NonCatalogues]);

  const getOrderDetails = async () => {
    const request = await getOrderById(OrderId);
    const response = await request.data;
    setOrderData(response.result);
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  useEffect(() => {
    const vehicle = vehicleType?.toLowerCase();
    if (vehicle === "car") {
      setTermsAndCondition(TermsAndConditionCars);
    } else {
      setTermsAndCondition(TermsAndConditionBikes);
    }
  }, []);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.jobcardHeader}>
            <Text style={styles.heading}> Job Card </Text>
            <View style={styles.logo}>
              <Image
                src={process.env.PUBLIC_URL + "/Assets/images/GativanLogo.png"}
              />
            </View>
          </View>

          <View style={styles.userInfoSection}>
            <View style={{ width: "50%" }}>
              <Text style={styles.fields}>{customerName}</Text>
              <Text style={styles.fields}>{vehicle}</Text>
              <Text style={styles.fields}>{vehicleNumber}</Text>
              <Text style={styles.label}>Customer Contact</Text>
              <Text style={styles.fields}>{mobile}</Text>
              <Text style={styles.label}>Customer Address</Text>
              <Text style={styles.fields}>{address} </Text>
            </View>
            <View
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              className="vehicle-image"
            >
              <Image
                style={{ height: "100%" }}
                src={baseURL + modelImage}
                // src="https://5.imimg.com/data5/JF/VZ/HA/SELLER-10578870/yamaha-yzf-r15-v-3-0-155-cc-racing-blue-motorcycle-500x500.jpg"
              />
            </View>
          </View>

          <View style={styles.jobcardSubHeader}>
            <Text style={styles.subHeading}> Job's </Text>
            <View style={styles.subLogo}>
              <Image
                style={styles.subLogoImage}
                src={process.env.PUBLIC_URL + "/Assets/images/logo.png"}
              />
            </View>
          </View>

          <View style={styles.servicesContainer}>
            <View>
              {allServices &&
                allServices.map((item, index) => {
                  const { service, price, activity } = item;

                  return (
                    <View style={{ marginBottom: "15px" }} key={index}>
                      <Text style={styles.packageName}> {service}</Text>
                      <Text style={styles.packageNameUnderline}></Text>
                      <View style={styles.serviceActivity}>
                        {activity &&
                          activity.map((list, index) => {
                            let SirNo = index + 1;
                            return (
                              <View
                                style={styles.serviceActivityList}
                                key={index}
                              >
                                <Text style={styles.activityName}>
                                  {" "}
                                  {`${SirNo} ${list.activity}`}
                                </Text>
                                <View>
                                  <Image
                                    style={styles.check_Image}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Assets/check.png"
                                    }
                                  />
                                </View>
                              </View>
                            );
                          })}
                      </View>

                      <View style={styles.subtotalSection}>
                        <Text style={styles.subtotalHeading}>Subtotal</Text>
                        <View style={styles.priceSection}>
                          <Image
                            style={styles.rupee_Image}
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <Text style={styles.subtotalPrice}>{price}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}

              {allNonCata &&
                allNonCata.map((item, index) => {
                  const { service, price, quantity } = item;

                  return (
                    <View key={index} style={{ marginBottom: "15px" }}>
                      <Text style={styles.packageName}> {service}</Text>
                      <Text style={styles.packageNameUnderline}></Text>
                      <View style={styles.subtotalNonCataSection}>
                        <Text style={styles.subtotalHeading}>Subtotal</Text>
                        <View style={styles.priceSection}>
                          <Image
                            style={styles.rupee_Image}
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <Text style={styles.subtotalPrice}>{price}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </View>

            <View style={styles.calculationTopBorder}></View>

            <View style={styles.subtotalSection}>
              <Text style={styles.subtotalHeading}>
                {discount > 0 ? "" : "Total"}
              </Text>
              <View style={styles.priceSection}>
                <Image
                  style={styles.rupee_Image}
                  src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                />
                <Text
                  style={
                    discount > 0 ? styles.subtotalPrice : styles.subtotalHeading
                  }
                >
                  {ServivesTotal}
                </Text>
              </View>
            </View>

            {discount > 0 && (
              <>
                <View style={styles.subtotalSection}>
                  <Text style={styles.discountHeading}> Discount </Text>
                  <View style={styles.priceSection}>
                    {/* <Image
                  style={styles.rupee_Image}
                  src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                /> */}
                    <Text style={styles.discount}> - {discount}</Text>
                  </View>
                </View>

                <View style={styles.discountTopBorder}></View>

                <View style={styles.subtotalSection}>
                  <Text style={styles.subtotalHeading}> Total </Text>
                  <View style={styles.priceSection}>
                    <Image
                      style={styles.rupee_Image}
                      src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                    />
                    <Text style={styles.totalPrice}>{TotalPrice}</Text>
                  </View>
                </View>
              </>
            )}
          </View>

          <View
            style={styles.termsAndCondSection}
            className="termsAndConditionContainer"
          >
            <Text
              style={styles.termsAndConHeading}
              className="termsAndCondition-header"
            >
              Terms & Conditions
            </Text>
            <View style={{ padding: "2%", backgroundColor: "white" }}>
              {TermsAndCondition.map((item, index) => (
                <Text
                  style={styles.termsList}
                  key={index}
                  className="termsAndCondition-conditionsList"
                >
                  {item}
                </Text>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
