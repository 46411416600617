import React from 'react'
import "./BlogPost.css"
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'

export default function BlogPost() {
    const blogsData = [
        {
            id: 1,
            img: "/Assets/blog/new blogs/How to Repair Rust on a Car.jpg",
            name: "How to Repair Rust on a Car?",
            details: "What's sadder than knowing rust is eating your new car? The truth is that you",
            url: "/blog/how-to-repair-rust-on-a-car"
        },
        {
            id: 2,
            img: "/Assets/blog/new blogs/Bike Maintenance Tips – Easy Steps of Bike Service.jpg",
            name: "Bike Maintenance Tips – Easy Steps of Bike Service",
            details: "In a country like India, owning a bike is still a dream for many. People",
            url: "/blog/bike-maintenance-tips-easy-steps-of-bike-service"
        },
        {
            id: 3,
            img: "/Assets/blog/new blogs/Signs That You Need to Repair Your Car Air Conditioner.jpg",
            name: "Signs That You Need to Repair Your Car Air Conditioner",
            details: "A good air conditioner is a lifesaver if you are planning a great road trip",
            url: "/blog/signs-that-you-need-to-repair-your-car-air-conditioner"
        },
    ]

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
        },
    };

    return (
        <Box container >
            <Box container className="homepage-headers">
                <h2>Blog Posts & Articles</h2>
            </Box>
            <Carousel
                // infinite
                partialVisible={true}
                arrows
                responsive={responsive}
                autoPlaySpeed={3000}
                centerMode={false}
                containerClass="container"
                className="Carousel"
                additionalTransfrom={0}
                dotListClass=""
                draggable
                focusOnSelect={false}
                renderDotsOutside
                slidesToSlide={1}
                swipeable
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                showDots
            >
                {
                    blogsData?.map((item) => {
                        const { id, img, name, details, url } = item;
                        return (
                            <Box className="card-container" key={id}>
                                <Box className="card-img-container">
                                    <img src={`${img}`} />
                                </Box>
                                <Box className="card-details-container">
                                    <h2>{name}</h2>
                                    <p>{details} ...<Link className="blog-link" to={url}>Read More</Link></p>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Carousel>
        </Box>
    )
}
