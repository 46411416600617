import React, {
  Suspense,
  createContext,
  lazy,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import style from "./App.module.css";

import { Helmet } from "react-helmet";

// ---------New Home page Import ----------------

import Footer from "./components/Footer/Footer";
import NavBar from "./components/Header/NavBar";
// const TRACKING_ID = "UA-202262670-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
import { Box } from "@mui/material";
import Home from "./components/HomePage/Home";
import Blog from "./components/blog/Blog";
import CheckOut from "./components/checkout/Checkout";
import Login from "./components/login/Login";
import SignUp from "./components/login/SignUp";
import ViewCart from "./components/vehicleserviceitems/Cart/ViewCart";
import VehicleServiceItems from "./components/vehicleserviceitems/VehicleServiceItems";
import WorkShop from "./components/workShop/WorkShop";

// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Wati from "./Wati";
import { getVehicleData } from "./action";
import SelectedBrowserIdData from "./components/SelectBrowserIdData";
import Accessories from "./components/accessories/Accessories";
import ErrorPage from "./components/errorPage404/errorPage";
import GarageRegistration from "./components/garageRegistration/GarageRegistration";
import Otp from "./components/login/Otp";
import NoOrderHistory from "./components/orderHistory/NoOrderHistory";
import OrderHistory from "./components/orderHistory/OrderHistory";
import OrderStatus from "./components/orderStatus/OrderStatus";
import Policies from "./components/policies/Policies";
import EditProfile from "./components/profile/EditProfile";
import RoadSideAssistance from "./components/roadSideAssistance/RoadSideAssistance";
import Sharejobcard from "./components/shareJobCard/ShareJobCard";
import ShareLeadCard from "./components/shareLeadCard/ShareLeadCard";
import ViewDetails from "./components/viewDetails/ViewDetails";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";

const LazyContactUs = lazy(() => import("./components/contactUs/ContactUs"));

const LazyFAQ = lazy(() => import("./components/faq/FAQ"));

const LazyOrderSummary = lazy(() =>
  import("./components/orderSummary/OrderSummary")
);

const LazyBlog = lazy(() => import("./components/blog/Blog"));

const LazyBlogpage = lazy(() => import("./components/blog/BlogPage"));

ReactGA.initialize("UA-202262670-1");
ReactGA.initialize("G-MV6P9VNPW1");
ReactGA.initialize("AW-520455667");
// gtag('config', 'AW-520455667');
// ReactGA.initialize("G-XF65E77WYD");
// ReactGA.pageview(window.location.pathname + window.location.search);

// ReactGA.send({ hitType: "pageview", page: "/my-path" })

export const AppContext = createContext();

const App = () => {
  const [isPdfLink, setIsPdfLink] = useState(false);
  const [isCheckOut, setIsCheckOut] = useState(true); //main footer handel
  const [isHeader, setIsHeader] = useState(true);
  const [isWati, setIsWati] = useState(false);
  const [selectPaymentMethod, setselectPaymentMethod] = useState(false); // user select payment method then and then show footer
  const [serviceCategoryId, setServiceCategoryId] = useState();
  const [isShowSearch, setIsShowSearch] = useState(false);

  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  // ==================================================================================
  const index = document.getElementsByTagName("body");

  React.useEffect(() => {
    const WA = document.getElementById("whatsApp");
  }, []);

  // -------------------------------------------------------------------------
  const initObj = {
    bookingMode: "Web",
    vehicleType: {
      id: "1",
      vehicleName: "Car",
    },
    brand: {
      id: "",
      brandName: "",
    },
    model: {
      id: "",
      modelName: "",
      imagePath: "",
    },

    variant: {
      id: "",
      variantName: "",
    },
  };
  const [customerPickData, setCustomePickData] = useState(initObj);

  // send data throw context Hooks
  const MyContextData = {
    setIsCheckOut,
    setselectPaymentMethod,
    selectPaymentMethod,
    isCheckOut,
    customerPickData,
    setCustomePickData,
    serviceCategoryId,
    setServiceCategoryId,
    isShowSearch,
    setIsShowSearch,
    isModalOpen,
    setIsModalOpen
  };

  const currentURL = window.location.href;

  useEffect(() => {
    if (
      currentURL === "http://localhost:3000/partner-with-us" ||
      currentURL === "https://www.gativan.in//partner-with-us"
    ) {
      setIsCheckOut(false);
      setIsHeader(false);
    } else {
      // setIsCheckOut(true);
      // setIsHeader(true);
    }

    if (
      currentURL === "http://localhost:3000/partner-with-us/" ||
      currentURL === "http://localhost:3000/" ||
      currentURL === "https://www.gativan.in//partner-with-us/" ||
      currentURL === "https://www.gativan.in/"
    ) {
      setIsWati(true);
    } else {
      setIsWati(false);
    }

    if (currentURL.includes("/jobcard/o-") || currentURL.includes("policies")) {
      setIsPdfLink(true);
    } else {
      setIsPdfLink(false);
    }
  }, [currentURL]);

  const currentLocation = useSelector((state) => state.LocationReducer);

  // get services from redux
  const ServiseReduxData = useSelector((state) => state.AddToCartReducer);

  useMemo(() => {
    dispatch(getVehicleData(customerPickData));
  }, [customerPickData]);

  return (
    <AppContext.Provider value={MyContextData}>
      <SelectedBrowserIdData />
      <Box className={style.main}>
        {window.location.href.includes("dev.gativan.in") ? (
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
        ) : (
          ""
        )}
        {isWati && <Wati />}
        <Router>
          <Box className="navbar-container">
            <NavBar />
            {/* {isHeader ? <NavBar /> : <GarageHeader />} */}
          </Box>
          <Routes>
            {/* ----Routing here------ */}
            {/* <Box> */}
            {/* <Routes> */}
            <Route
              path="/"
              element={
                <Navigate
                  to={`pune`}
                />
              }
            
            />
            <Route path="/:location" element={<Home />} />
            <Route path="/:location/:garagenearme" element={<Home />} />

            {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path='car-repair' element={<VehicleServiceItems />} /> */}
            {/* <Route path="/:location/:url" element={<VehicleServiceItems />} /> */}
            <Route path="viewcart" element={<ViewCart />} />
            <Route path="checkout" element={<CheckOut />} />
            <Route path="profile" element={<EditProfile />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route path="no-order-history" element={<NoOrderHistory />} />
            {/* <Route path='ordersummary' element={<OrderSummary/>} /> */}
            <Route
              path="ordersummary"
              element={
                <Suspense
                  fallback={
                    <CircularProgress
                      sx={{ color: "#122164", marginLeft: "50%" }}
                    />
                  }
                >
                  <LazyOrderSummary />
                </Suspense>
              }
            />
            {/* <Route path="contact-us" element={<ContactUs/>} /> */}
            <Route
              path="contact-us"
              element={
                <Suspense
                  fallback={
                    <CircularProgress
                      sx={{ color: "#122164", marginLeft: "50%" }}
                    />
                  }
                >
                  <LazyContactUs />
                </Suspense>
              }
            />
            {/* <Route path="faq" element={<FAQ/>} /> */}
            <Route
              path="faq"
              element={
                <Suspense
                  fallback={
                    <CircularProgress
                      sx={{ color: "#122164", marginLeft: "50%" }}
                    />
                  }
                >
                  <LazyFAQ />
                </Suspense>
              }
            />
            <Route exact path="/blog" element={<Blog />} />
            {/* <Route
                path="blog"
                element={
                  <Suspense
                    fallback={
                      <CircularProgress
                        sx={{ color: "#122164", marginLeft: "50%" }}
                      />
                    }
                  >
                    <LazyBlog />
                  </Suspense>
                }
              /> */}
            {/* <Route path='/blog/:blogdetails' element={<BlogPage/>} /> */}
            <Route
              path="blog/:blogdetails"
              element={
                <Suspense
                  fallback={
                    <CircularProgress
                      sx={{ color: "#122164", marginLeft: "50%" }}
                    />
                  }
                >
                  <LazyBlogpage />
                </Suspense>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/workshop" element={<WorkShop />} />
            {/*-------------------garage location----------*/}
            <Route path=":location" element={<WorkShop />} />
            <Route
              path="car-roadside-assistance-near-me"
              element={<RoadSideAssistance />}
            />
            <Route path="accessories" element={<Accessories />} />
            <Route path="order-status" element={<OrderStatus />} />

            <>
              <Route
                path="/:location/:vehicleType/:serviceCategoryType"
                element={<VehicleServiceItems />}
              >
                <Route path=":brand" element={<VehicleServiceItems />}>
                  <Route path=":model" element={<VehicleServiceItems />}>
                    <Route path=":variant" element={<VehicleServiceItems />} />
                  </Route>
                </Route>
              </Route>
            </>

            <>
              <Route
                path="/:location/:vehicleType/:serviceCategoryType/:service"
                element={<ViewDetails />}
              >
                <Route path=":brand" element={<ViewDetails />}>
                  <Route path=":model" element={<ViewDetails />}>
                    <Route path=":variant" element={<ViewDetails />} />
                  </Route>
                </Route>
              </Route>
            </>

            <Route path="partner-with-us" element={<GarageRegistration />} />
            <Route path="policies/*" element={<Policies />} />
            <Route path=":location/privacy/gativanopsapp" element={<PrivacyPolicy />} />
            <Route
              path="/:location/jobcard/:Id/:cName/:cDT"
              element={<Sharejobcard />}
            />
            <Route
              // path="/pune/:leadId"
              path="/:location/leadcard/:leadId/:customerName/:cDT"
              element={<ShareLeadCard />}
            />
            {/* <Route path="/pune/lead" element={<ShareLeadCard />} /> */}
            {/* </Routes> */}
            {/* </Box> */}
            {/* {!isPdfLink && (
              <>
                <Route
                  path="/:location/:vehicleType/:serviceCategoryType"
                  element={<VehicleServiceItems />}
                >
                  <Route path=":brand" element={<VehicleServiceItems />}>
                    <Route path=":model" element={<VehicleServiceItems />}>
                      <Route
                        path=":variant"
                        element={<VehicleServiceItems />}
                      />
                    </Route>
                  </Route>
                </Route>
              </>
            )} */}

            <Route path="*" element={<ErrorPage />} />
            <Route path="not-found" element={<ErrorPage />} />
          </Routes>
          <Box>{isCheckOut && <Footer />}</Box>
        </Router>
      </Box>
    </AppContext.Provider>
  );
};
export default App;
