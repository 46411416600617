import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Avatar,
  ListItemAvatar,
  List,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { getOrders } from "../../services/vehicleservices/vehicleservices";

export default function EditProfile() {

  const [mobNumber, setMobNumber] = useState('')
  const [orderStatusArray, setOrderStatusArray] = useState([])
  const [profileData, setProfileData] = useState({})



  const getMobileNumberRedux = useSelector(
    state => state.getMobileNumber
  )

  const navigation = useNavigate()
  const { state } = useLocation()


  const getOrderStatusData = async (number) => {
    try {
      const response = await getOrders(number);
      const orderStatus = await response.data
      if (orderStatus.statusCode === 200) {
        setOrderStatusArray(orderStatus.result)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleOrderHistoryClick = () => {

    if (state) {
      navigation('/order-history', { state: {navigateNumber:mobNumber} })
    }
  }

  useEffect(() => {
    if (state) {
      setMobNumber(state)
      getOrderStatusData(state)
    }
    if (orderStatusArray) {
      setProfileData(orderStatusArray[0])
    }
  }, []);



  return (
    <>
      {/*-------------edit profile container-------*/}
      <Box className="edit-Profile-container">
        {/*----------avatarname---------*/}
        <Box className="avatar-name">
          <List>
            <ListItemAvatar>
              <Avatar
                sx={{ background: " #92A7EF", width: 100, height: 100 }}
                alt="Ritesh"
                src={process.env.PUBLIC_URL + `Assets/homepage/testimonials/testimonial profile.png`}
              />
            </ListItemAvatar>
          </List>
          <h4>Hi,{profileData ? profileData.customerName : ''}</h4>
        </Box>
        {/*-----------Personal details-------*/}
        <Box className="personal-details">
          <h3>Personal Details</h3>
          <br />
          {/*---------enter text field------*/}
          {/* <Box className="text-name">Name</Box>
          <TextField
            className="text-field"
            placeholder="Enter Name"
          ></TextField>

          <Box className="text-name">Email Address</Box>

          <TextField
            className="text-field"
            placeholder="Enter Email "
          ></TextField> */}

          <Box className="text-name">Mobile No.</Box>
          <Box>
            <TextField
              className="text-field"
              value={mobNumber}
              placeholder="Enter Mobile Number"
            ></TextField>
          </Box>
          {/*----------edit and logout button---------*/}
          <Box className="edit-logout-btn">
            <Button variant="contained" size="large" onClick={handleOrderHistoryClick} className="edit-btn">
              Order History
            </Button>
            {/* <Button variant="contained" size="large" className="logout-btn">
              Logout
            </Button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
