const UserData = {

}

export const BrowserUserData = (state = UserData, action) => {
    switch (action.type) {
        case 'BrowserData':
            return state = action.payload;
        default:
            return state;
    }
}