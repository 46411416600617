const location ={ }
export const LocationReducer=(state=location,action)=>{
    switch (action.type) {
     case 'CHOOSE_LOCATION':
          return state=action.payload;
    
     default:
          return state;
    }
}
