import React, { createContext, useContext, useEffect, useState } from "react";
// import { Provider } from 'react-redux';
import { Box } from "@mui/material";
import axios from "axios";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../App";
import {
  StoreCartTotal,
  chooseLocation,
  handleOpenModalState,
  handleServiceCategory,
  removeAllCart,
} from "../../action";
import {
  getBrandByName,
  getModelByBrandName,
  getServiceCategoryByVehicleName,
  getSubCategories,
  getSubCategoriesWithPrice,
  getVariantByModelName,
  getVehicleType,
} from "../../services/vehicleservices/vehicleservices";
import FAQ_home from "../ReuseComponent/FAQ/FAQ_home";
import ServiceComparison from "../ReuseComponent/serviceComparison/serviceComparison";
import Testimonials from "../ReuseComponent/testimonials/Testimonials";
import ViewCartFooter from "../ReuseComponent/viewCartFooter/ViewCartFooter";
import WhyChooseGativan from "../ReuseComponent/whyChooseGativan/whyChooseGativan";
import Cart from "./Cart/Cart";
import ServiceCategories from "./Service Menu/ServiceCategories";
import TestMenu from "./Service Menu/service Menu for mobile/TestMenu";
import "./VehicleServiceItems.css";
import Services from "./allServices/Services";
import httpCommon from "../../http-common";
import BookServices from "../ReuseComponent/bookServices/BookServices";
import useHistoryNavigation from "../useHistoryNavigation/useHistoryNavigation";
import useHistoryNavigationTwo from "../useHistoryNavigation/useHistoryNavigationTwo";

export const ServiceContext = createContext();

const CarPeriodicServiceFAQData = [
  {
    id: 1,
    title: "Why should I choose Gativan over other service providers in Pune? ",
    body: "Trusted by over a million car owners, Gativan is Pune most loved car service provider. We offer a wide variety of car and bike services.",
  },
  {
    id: 2,
    title: "How can I book my car service with Gativan in Pune?",
    body: "Booking your car service is as easy as counting 1-2-3. Just select your car or bike service, choose your service package, mention your preferred time slot and that's it! You have just booked your car service.",
  },
  {
    id: 3,
    title: "What kind of car services can I availble with Gativan? ",
    body: "You can choose from a variety of car services with Gativan. Need an oil change? we have the service for you. Want car coating? We offer Teflon and Ceramic coating. Need repairs under insurance? We offer cashless insurance claim facility. The possibilities are endless both for bike and car services.",
  },
  {
    id: 4,
    title: "What are the basic charges for my car service in Pune?",
    body: "A basic car service package starts at just Rs. 2,399. The basic service package covers all the bare essentials that your car needs to stay road-worthy. You can also upgrade your car service experience with our Standard and Comprehensive car service packages.",
  },
  {
    id: 5,
    title: "Do I have to drop my car to the workshop?",
    body: "We Offer total feasibility of our customers. You can drop your vehicle at Gativan Garages or Avail FREE PICK UP AND DROP facility from doorstep. Gativan mechanic will pick your vehicle and drop it after servicing. No Extra cost will be charged!",
  },
  {
    id: 6,
    title: "What if I am not satisfied with my car service?",
    body: "100% customer satisfaction is the highest priority for us. Therefore, Gativan offers Professional Trained Mechanics, Transparent servicing and Transparent Job Cards.",
  },
];

const BikeBasicServiceFAQData = [
  {
    id: 1,
    title:
      "How regularly would it be advisable for me to go for a Basic Service for my bike?",
    body: "We propose you get a Basic Bike Service at your closest Go Gativan Mechanic workshop at regular intervals or after 5000 km to guarantee the best of your bike.",
  },
  {
    id: 2,
    title: "How will getting a Basic Bike service benefit my bike?",
    body: "Continuous oil change, cleaning of curls, and battery water assists, all of this is covered under Gativan basic Service.",
  },
  {
    id: 3,
    title: "For what reason is it important to get a basic service for my car?",
    body: "Basic Servicing is vital as it will clean and drive away little blunders which later can turn into a purpose behind the bigger difficulty. So it's better to get your Bike serviced at your closest Gativan workshop .",
  },
  {
    id: 4,
    title:
      "What are the services available by Gativan in a Basic Car Service for my bike in Pune?",
    body: "We offer motor oil substitution, oil channel substitution, air channel cleaning, battery water top-up, warmers/flashes plug check, vehicle wash, inside vacuuming. Each of these services will be indispensable and significant for your bike.",
  },
  {
    id: 5,
    title: "Does Gativan offer any guarantee over their services for my bike? ",
    body: "For sure At Gativan we offer the assistance guarantee for more than 1000 km or a -month guarantee.",
  },
  {
    id: 6,
    title:
      "What amount of time will Gativan take to finish the service for my bike?",
    body: "The Basic Service is finished within the time span of in and around 4 hrs for your bike.",
  },
];

export default function VehicleServiceItems() {
  useHistoryNavigationTwo();

  const contextData = useContext(AppContext);

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();

  const dispatch = useDispatch();

  const {
    isCheckOut,
    setIsCheckOut,
    serviceCategoryId,
    setServiceCategoryId,
    customerPickData,
    setCustomePickData,
    isShowSearch,
    setIsShowSearch,
  } = contextData;
  const [serviceCategory, setServiceCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesWithPrice, setSubCategoriesWithPrice] = useState([]);
  const [select, setSelect] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [Modalopen, setModalOpen] = useState(false);
  const [faqData, setFaqData] = useState(BikeBasicServiceFAQData);
  const [category, setCategory] = useState(serviceCategoryType); // Aditya

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [ShowBrand, setShowBrand] = useState([]);
  const [ShowModel, setShowModel] = useState([]);
  const [showVariant, setShowVariant] = useState([]);
  // const [browserIdDetails, setBrowserIdDetails] = useState({});

  const navigation = useNavigate();

  const URL = httpCommon.defaults.baseURL;

  const cartModal = {
    Modalopen,
    setModalOpen,
  };

  const selectContext = {
    select,
    setSelect,
    category,
    setCategory,
  };

  // const { url } = useParams();

  // getting current location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);
  const CartTotalReducer = useSelector((state) => state.CartTotalReducer);

  // getting selected vehicle from redux
  const vehicleSelectedData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  // getting browserId from redux
  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  const locatedCity = currentLocation.cityName?.toLowerCase();

  const serviceCategoryMetaContent = [
    {
      id: 1,
      url: "periodic-services",
      vehicle: "car",
      title: `Book Car Periodic Maintainance Services in Pune - Gativan`,
      desciption: `Book car periodic maintenance services in Pune. At Gativan, get your car repair & servicing with expert mechanics inspection on less charges and delivery at your doorstep.`,
    },
    {
      id: 2,
      url: "periodic-services",
      vehicle: "bike",
      title: `Bike Servicing and Maintenance in Pune |Bike Repair Shop - Gativan`,
      desciption: `Looking for bike servicing and maintenance in Pune. Contact Gativan as your bike repair shop and book your bike service online at affordable price. Contact us now!`,
    },
    {
      id: 3,
      url: "batteries",
      vehicle: `car`,
      title: "Best Car and Bike Battery Repair and Services in Pune - Gativan",
      desciption:
        "Book car and bike battery repair and replacement services in Pune at India's no.1 platform. We have a wide collection of batteries for all models of vehicles. Visit now.",
    },
    {
      id: 3,
      url: "batteries",
      vehicle: `bike`,
      title: "Best Car and Bike Battery Repair and Services in Pune - Gativan",
      desciption:
        "Book car and bike battery repair and replacement services in Pune at India's no.1 platform. We have a wide collection of batteries for all models of vehicles. Visit now.",
    },
    {
      id: 4,
      url: "denting-&-painting",
      vehicle: "car",
      title: `Car Denting Painting Services in Pune | Car Scratch Repair - Gativan`,
      desciption:
        "Get car denting painting services in Pune at the best price online at Gativan. Book our car scratch repair expert today. Contact us now!",
    },
    {
      id: 5,
      url: "custom-services",
      vehicle: "car",
      title: `Best Custom Services For Car and Bike in Pune - Gativan`,
      desciption:
        "Find car and bike service packages in Pune at Gativan. We provides best automotive care and car and bike customization services in Pune. Visit website to avail services.",
    },
    {
      id: 6,
      url: "accessories",
      vehicle: "car",
      title: "Buy Car and Bike Accessories in Pune - Gativan",
      desciption:
        "Shop cars and bikes accessories in Pune online at Gativan. We provide a wide range of accessories with premium quality that makes your rides safe and enjoyable.",
    },
    {
      id: 7,
      url: "ac-service-&-repair",
      vehicle: "car",
      title: "Book Car AC Service and Repair Service In Pune - Gativan",
      desciption:
        "Book best car ac repair service in Pune at Gativan with a dedicated and well trained team of mechanics. It will provide regular maintenance for car air conditioner.",
    },
    {
      id: 8,
      url: "wheel-and-tyre-care",
      vehicle: "car",
      title: `Best Wheel and Tyre Care For Bike and Car - Gativan`,
      desciption: `Gativan has brought a huge collection of bike and car tyre cares online in Pune. We are leading tyre stores in Pune that gives premium quality services for tyres.`,
    },
    {
      id: 9,
      url: "detailing-services",
      vehicle: "car",
      title: `Premium Car Detailing Services in Pune - Gativan`,
      desciption: `Looking for car detailing services in Pune online? Gativan offers a wide range of detailing services in Pune. Visit website and book our service now.`,
    },
    {
      id: 10,
      url: "car-cleaning",
      vehicle: "car",
      title: `Book Car Cleaning Services In Pune - Gativan`,
      desciption: `Book your appointment for car cleaning service in Pune online at Gativan. You can get premium quality car wash services. It will be one stop solution for your car wash.`,
    },
    {
      id: 11,
      url: "clutch-&-fitments",
      vehicle: "car",
      title: `Get Trusted Clutch and Fitments Service In Pune - Gativan`,
      desciption: `If you are looking for most trusted clutch and fitments services in Pune online, Gativan is one stop solution for your car and bike clutch and fitment service.`,
    },
    {
      id: 12,
      url: "insurance-claim",
      vehicle: "car",
      title: `Get Car Insurance Claim in Pune - Gativan`,
      desciption: `Car Owners in Pune can avail best Insurance Claim Services at Gativan faster and in flexible way. We provide car insurance claim of all Car brands and services. Book today!`,
    },
    // {
    //   id: 13,
    //   url: ``,
    //   // url: `${vehicleSelectedData.vehicleType.vehicleName.toLowerCase()}-repair`,
    //   title: `${vehicleSelectedData.vehicleType.vehicleName} repair In ${currentLocation.cityName} | ${vehicleSelectedData.vehicleType.vehicleName} services near me - Gativan `,
    //   desciption: `Book Best ${vehicleSelectedData.vehicleType.vehicleName} services and ${vehicleSelectedData.vehicleType.vehicleName} repair in ${currentLocation.cityName}, Top ${vehicleSelectedData.vehicleType.vehicleName} Garages near you in ${currentLocation.cityName}. Free pickup and drop, Professional Trained Mechanics, Free Service Inspection`,
    // },
  ];

  //get vehicle type
  const getVehicleInfo = async () => {
    try {
      const Response = await getVehicleType();
      const VehicleData = await Response.data;
      if (VehicleData.statusCode === 200) {
        setVehicleTypes(VehicleData?.result?.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get brand by vehicle type name
  const getBrandVehicleTypeName = async (vehicleTypeName) => {
    try {
      const Response = await getBrandByName(vehicleTypeName);
      const data = await Response.data;
      if (data.statusCode === 200) {
        setShowBrand(data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //get model by name
  const getModelByName = async (vehicleTypeName, brandName) => {
    try {
      const Response = await getModelByBrandName(vehicleTypeName, brandName);
      const data = await Response.data;
      if (data.statusCode === 200) {
        setShowModel(data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get variant by name
  const getVariantByName = async (vehicleTypeName, modelName) => {
    try {
      const Response = await getVariantByModelName(vehicleTypeName, modelName);
      const data = await Response.data;
      if (data.statusCode === 200) {
        setShowVariant(data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // // get user data w.r.t. browser id
  // const getUserDataByBrowserId = async (browserId) => {
  //   try{
  //     const Response = await getCartBrowserId(browserId);
  //     const data = await Response.data;
  //     if(data.statusCode == 200) {
  //       setBrowserIdDetails(data.result)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  let meta = serviceCategoryMetaContent.find(
    (o) => o.url == serviceCategoryType && o.vehicle == vehicleType
  );

  const windowWidth = window.innerWidth;

  let ServiceCategoryId = serviceCategoryId;
  let VehicleTypeId = vehicleSelectedData.vehicleType.id;
  let modelId = vehicleSelectedData.model.id;
  let variantId = vehicleSelectedData.variant.id;

  /* ----------------------- API calls ------------------------------------- */

  const getServiceCategories = async (vehicleTypeName) => {
    try {
      const Response = await getServiceCategoryByVehicleName(vehicleTypeName);
      const VehicleServiceCategoryData = await Response.data;
      if (VehicleServiceCategoryData.statusCode === 200) {
        setServiceCategory(VehicleServiceCategoryData.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategoriesData = async (vehicleType, serviceCategoryType) => {
    try {
      const response = await getSubCategories(vehicleType, serviceCategoryType);
      const data = response.data;
      if (data.statusCode === 200) {
        setSubCategories(data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBrowserAllCart = async (browserId) => {
    try {
      const deleteCartData = await axios.delete(`${URL}/cart/${browserId}`);
      const result = await deleteCartData.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getSubCategoriesDataWithPrice = async (
    vehicleTypeName,
    serviceCategoryName,
    modelName,
    variantName
  ) => {
    try {
      const response = await getSubCategoriesWithPrice(
        vehicleTypeName,
        serviceCategoryName,
        modelName,
        variantName
      );
      const data = await response.data;
      if (data.statusCode === 200) {
        setSubCategoriesWithPrice(data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get services from redux
  const ServiseReduxData = useSelector((state) => state.AddToCartReducer);

  subCategoriesWithPrice?.map((i) => {
    i.service?.map((j) => {
      ServiseReduxData?.result?.map((k) => {
        if (j?.serviceId === k?.serviceId) {
          j.addServiceToCart = true;
        }
      });
    });
  });

  // subCategoriesWithPrice?.service?.map((i) => {
  //   console.log("i subCategoriesWithPrice", i)

  //   ServiseReduxData.result.map((j) => {
  //     console.log("i subCategoriesWithPrice", i, " j ServiseReduxData.result ", j)
  //     if (i.serviceId == j.serviceId) {
  //       console.log("in redux and subCategoriesWithPrice get true")
  //       i.addServiceToCart = true;
  //     }
  //   });
  // });

  /* ---------------------- UseEffects and UseMemos -------------------- */

  useEffect(() => {
    if (windowWidth <= 768) {
      if (ServiseReduxData.result.length != 0) {
        setIsCheckOut(false);
      } else if (ServiseReduxData.result.length == 0) {
        setIsCheckOut(true);
      }
    }
  }, [ServiseReduxData]);

  const handleRemoveServiceFromCart = (item) => {
    subCategoriesWithPrice?.map((i) => {
      i?.service?.map((j) => {
        if (j.serviceId === item.serviceId) {
          j.addServiceToCart = false;
        }
      });
    });
    // subCategoriesWithPrice?.service?.map((i) => {
    //   if (i.serviceId === item.serviceId) {
    //     i.addServiceToCart = false;
    //   }
    // });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // let ServiceCategoryId = serviceCategoryId
    // let VehicleTypeId = vehicleSelectedData.vehicleType.id;
    // modelId = vehicleSelectedData.model.id;
    // variantId = vehicleSelectedData.variant.id;
    getServiceCategories(vehicleType);

    //call to get vehicle type
    getVehicleInfo();

    //call to get brands
    if (vehicleType) {
      getBrandVehicleTypeName(vehicleType);
    }

    //call to get models
    if (vehicleType && brand) {
      getModelByName(vehicleType, brand.replaceAll("-", " "));
    }

    const modelNamefiltered = ShowModel.filter((e) => {
      return e.model.toLowerCase() === model;
    });

    //call to get variants
    if (vehicleType && model) {
      getVariantByName(
        vehicleType,
        modelNamefiltered
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model.replaceAll("-", " ")
      );
    }

    if (variant) {
      getSubCategoriesDataWithPrice(
        vehicleType,
        serviceCategoryType.replaceAll("-", " "),
        modelNamefiltered.length != 0
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model.replaceAll("-", " "),
        variant
      );
    } else {
      getSubCategoriesData(vehicleType, serviceCategoryType);
    }
  }, [vehicleType, serviceCategoryType, brand, model, variant]);

  useEffect(() => {
    if (!variant) {
      deleteBrowserAllCart(getBrowserIdRedux);
      dispatch(removeAllCart({ result: [] }));
    }
  }, [variant, getBrowserIdRedux]);

  useMemo(() => {
    const modelNamefiltered = ShowModel.filter((e) => {
      if (e.model.toLowerCase() === model) {
        return e.model;
      }
    });

    if (variant) {
      getSubCategoriesDataWithPrice(
        vehicleType,
        serviceCategoryType.replaceAll("-", " "),
        modelNamefiltered.length != 0
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model.replaceAll("-", " "),
        variant
      );
    } else {
      getSubCategoriesData(vehicleType, serviceCategoryType);
    }
  }, [ShowModel]);

  useMemo(() => {
    const modelNamefiltered = ShowModel?.filter((e) => {
      return e.model.toLowerCase() == model;
    });

    //call to get variants
    if (vehicleType && model) {
      // getVariantByName(vehicleType, model)

      getVariantByName(
        vehicleType,
        modelNamefiltered?.length != 0
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model.replaceAll("-", " ")
      );
    }
  }, [ShowModel]);

  useEffect(() => {
    const filterVehicleType = vehicleTypes?.filter((e) => {
      return e.vehicleType?.toLowerCase() === vehicleType;
    });

    const filterBrand = ShowBrand.filter((e) => {
      return e.brand.replaceAll(" ", "-").toLowerCase() === brand;
    });

    const filterModel = ShowModel.filter((e) => {
      return e.model.replaceAll(" ", "-").toLowerCase() === model;
    });

    const filterVariant = showVariant?.filter((e) => {
      return e.variantName.toLowerCase() == variant;
    });

    setCustomePickData({
      ...customerPickData,
      vehicleType: {
        id: filterVehicleType[0]?.id,
        vehicleName: filterVehicleType[0]?.vehicleType,
      },
      brand: { id: filterBrand[0]?.brand_Id, brandName: filterBrand[0]?.brand },
      model: {
        id: filterModel[0]?.model_id,
        modelName: filterModel[0]?.model,
        imagePath: filterModel[0]?.image_url,
      },
      variant: {
        id: filterVariant[0]?.variantId,
        variantName: filterVariant[0]?.variantName,
      },
    });
  }, [ShowBrand, ShowModel, showVariant]);

  useEffect(() => {
    // getServices(VehicleTypeId, ServiceCategoryId, modelId, variantId);
    setSelect(serviceCategoryId);
  }, [select]);

  useEffect(() => {
    scrollToTop();
    // setIsCheckOut(true);
    getServiceCategories(vehicleType);

    // getServices(VehicleTypeId, ServiceCategoryId, modelId, variantId);

    if (VehicleTypeId === 1) {
      setFaqData(CarPeriodicServiceFAQData);
    } else if (VehicleTypeId === 2) {
      setFaqData(BikeBasicServiceFAQData);
    }

    //call to get vehicle type
    getVehicleInfo();

    //call to get brands
    if (vehicleType) {
      getBrandVehicleTypeName(vehicleType);
    }
    //call to get models
    if (vehicleType && brand) {
      getModelByName(vehicleType, brand);
    }

    const modelNamefiltered = ShowModel?.filter((e) => {
      return e.model.toLowerCase() == model;
    });

    //call to get variants
    if (vehicleType && model) {
      // getVariantByName(vehicleType, model)

      getVariantByName(
        vehicleType,
        modelNamefiltered?.length != 0
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model.replaceAll("-", " ")
      );
    }

    if (variant) {
      getSubCategoriesDataWithPrice(
        vehicleType,
        serviceCategoryType?.replaceAll("-", " "),
        modelNamefiltered?.length != 0
          ? modelNamefiltered[0]?.model?.toLowerCase()
          : model?.replaceAll("-", " "),
        variant
      );
    } else {
      getSubCategoriesData(vehicleType, serviceCategoryType);
    }

    setCategory(serviceCategoryType);

    setIsShowSearch(true);

    setCategory(serviceCategoryType);

    setIsShowSearch(true);

    // if (window.innerWidth < "768") dispatch(handleOpenModalState(false));
  }, []);

  useEffect(() => {
    // getUserDataByBrowserId(getBrowserIdRedux)
  }, [getBrowserIdRedux, ServiseReduxData]);

  // useEffect(()=>{
  //   dispatch(handleBrowserUserData(browserIdDetails))
  // },[browserIdDetails, browserIdDetails?.cartItems])

  // if(ServiseReduxData.result?.length !== 0){
  //   setIsCheckOut(true)
  // }
  // else if (ServiseReduxData.result?.length === 0){
  //   setIsCheckOut(false)
  // }

  useEffect(() => {
    dispatch(
      handleServiceCategory({
        serviceCategoryId: serviceCategoryId,
        serviceCategory: category,
      })
    );
  }, [category]);

  // const filterVehicleType = vehicleTypes?.filter((e)=>{
  //   return e.vehicleName?.toLowerCase() === vehicleType
  // })

  //   brand
  // :
  // "Honda"
  // brand_Id
  // :
  // 9
  // image_url
  // :
  // "/CarBrands/Honda.png"

  // useEffect(() => {

  //   setCustomePickData({ ...customerPickData, vehicleType: {} })
  // }, [filterBrand, filterModel, filterVariant])

  // geting service from cart redux
  const servicesSelectionData = useSelector((state) => state.AddToCartReducer);

  useEffect(() => {
    // const TotalPrice = servicesSelectionData?.result?.reduce(
    //   (acc, ele) => acc + ele?.displayPrice,
    //   0
    // );
    // if (TotalPrice) {
    //   // const couponDis = appliedCoupon?.discountPercentage
    //   //   ? TotalPrice - TotalPrice / `1.${appliedCoupon?.discountPercentage}`
    //   //   : 0;
    //   const couponDis = 0;
    //   const youPayAmount = 50;
    //   const obj = {
    //     subTotal: Math.round(TotalPrice),
    //     couponDiscount: Math.round(couponDis) || 0,
    //     cartTotal: Math.round(TotalPrice - youPayAmount - couponDis) || 0,
    //     cartItems: servicesSelectionData?.result?.length,
    //     payNow: Math.round(youPayAmount),
    //   };
    //   dispatch(StoreCartTotal(obj));
    // }
  }, [servicesSelectionData]);

  useMemo(() => {
    const items = servicesSelectionData?.result?.length;
    const TotalPrice = servicesSelectionData?.result?.reduce(
      (acc, ele) => acc + ele?.displayPrice,
      0
    );

    dispatch(
      StoreCartTotal({
        ...CartTotalReducer,
        subTotal: TotalPrice,
        cartTotal: TotalPrice,
        cartItems: items,
      })
    );
    // }
  }, [servicesSelectionData]);

  const SaveDataWBrowser = async (visitorId) => {
    const userDataObj = {
      browserId: visitorId,
      vehicleType: vehicleSelectedData?.vehicleType?.id,
      brandId: vehicleSelectedData?.brand?.id,
      modelId: vehicleSelectedData?.model?.id,
      variantId: vehicleSelectedData?.variant?.id,
      // mobileNumber: getMobileNumberRedux?.mobileNumber,
      cartItems: servicesSelectionData?.result,
    };

    const saveCart = await axios.post(`${URL}/cart`, userDataObj);
    const result = await saveCart.data;
  };

  useEffect(() => {
    if (currentLocation) {
      if (
        location?.toLocaleLowerCase()?.replaceAll("-", " ") !==
        currentLocation.cityName?.replaceAll("-", " ")?.toLocaleLowerCase()
      ) {
        dispatch(chooseLocation({ cityName: location }));
      }
    }
    localStorage.removeItem("cityName123");
    localStorage.setItem("cityName123", location.toLocaleLowerCase());
  }, [location]);

  return (
    <ServiceContext.Provider value={handleRemoveServiceFromCart}>
      <Helmet>
        <title>{meta?.title}</title>
        <meta name="description" content={`${meta?.desciption}`} />
      </Helmet>

      <Box container className="addtocart_container">
        {/* -------------------------------------------Service Menu------------------------------------------------- */}
        <Box container className="services-menu">
          {windowWidth <= "768" ? (
            <TestMenu
              ServiceCategoryData={serviceCategory}
              // vehicleType={ServiceCategoryId}
              selectVehicleCategory={selectContext}
            />
          ) : (
            <ServiceCategories
              ServiceCategoryData={serviceCategory}
              // vehicleType={ServiceCategoryId}
              selectVehicleCategory={selectContext}
            />
          )}
        </Box>

        {/* -----------------------------------------Cahange Vehicle-------------------------------------------------- */}
        <Box className="change-vehicle">
          <Cart cartModal={cartModal} />
        </Box>

        {/* ----------------------------------------Main Sections---------------------------------------------------- */}
        <Box container className="addtocart-mainsection">
          {/* -----------------------------------------Show Services---------------------------------------------------- */}
          <Box className="our-services">
            <Services
              selectVehicleCategory={select}
              subCategoriesData={subCategories}
              servicesData={servicesData}
              vehicleSelectedData={vehicleSelectedData}
              cartModal={cartModal}
              subCategoriesWithPrice={subCategoriesWithPrice}
            />
          </Box>

          {/* -------------------------------------------Trending Services------------------------------------------ */}
          {/* <Box className='Trending-Services'>
            <h1>Trending Services</h1>
          </Box> */}

          {/* ----------------------------------------How It’s Done---------------------------------------------- */}
          {/* <Box className='how-its-done'>
            <h1>How It’s Done</h1>
          </Box> */}

          {/* ----------------------------------------Blog Posts & Article---------------------------------------------- */}
          {/* <Box className='blog-article'>
            <h1>Blog Posts & Article</h1>
          </Box> */}

          {/* ----------------------------------------Car Service Related FAQs---------------------------------------------- */}
          <Box className="FAQ">
            <Box className="homepage-headers" id="FAQ">
              <h2>Frequently Asked Vehicle service Questions</h2>
            </Box>
            <FAQ_home data={faqData} />
          </Box>

          {/* ------------------------------------------- service comparison ---------------------------------------- */}
          {serviceCategoryType == "periodic-services" ? (
            <Box className="">
              <Box className="homepage-headers">
                <h2>Service Comparison</h2>
              </Box>
              <Box>
                <ServiceComparison />
              </Box>
            </Box>
          ) : (
            ""
          )}

          {/* ----------------------------------------What our customers say about us?---------------------------------------------- */}
          <Box className="WOC-say-about">
            <Testimonials />
          </Box>

          {/* ----------------------------------------Why Choose Gativan?---------------------------------------------- */}
          <Box className="why-choose-gativan">
            <WhyChooseGativan />
          </Box>

          {/* ---------------------------------------- SERVICES SECTION STARTS---------------------------------------------- */}
          <Box className="why-choose-gativan">{/* <BookServices /> */}</Box>
          {/* ---------------------------------------- SERVICES SECTION ENDS---------------------------------------------- */}
        </Box>
      </Box>
      <Box className="mobile-view-footer">
        {!isCheckOut && <ViewCartFooter buttonName="View Cart" />}
      </Box>
    </ServiceContext.Provider>
  );
}
