import React, { useState, useEffect, useContext } from "react";
import "./ServiceCategories.css";
import { Box, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useParams } from "react-router-dom";
import httpCommon from "../../../http-common";
import { AppContext } from "../../../App";

export default function ServiceCategories({
  ServiceCategoryData,
  selectVehicleCategory,
}) {
  // const [select, setSelect] = useState('')

  const { select, setSelect, category, setCategory } = selectVehicleCategory;
  const { serviceCategoryId, setServiceCategoryId } = useContext(AppContext);

  const { location, vehicleType, serviceCategoryType, brand, model, variant } =
    useParams();

  const URL = httpCommon.defaults.baseURL;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 80, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 35, // this is needed to tell the amount of px that should be visible.
    },
  };

  useEffect(() => {
    serviceCategoryType && setSelect(serviceCategoryType);
  }, []);

  const handleURLChange = (categoryId, category) => {
    setCategory(category);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Box container className="service-container">
        <Carousel
          // infinite
          partialVisible={true}
          responsive={responsive}
          // autoPlaySpeed={10000}
          containerClass="container"
          className="Carousel"
          additionalTransfrom={0}
        >
          {ServiceCategoryData.map((item, index) => {
            const { categoryId, category, image_url } = item;
            return (
              <Link key={index} to="#" className="service-menu-link">
                <Button
                  className="service-btn"
                  key={categoryId}
                  onClick={() => {
                    setSelect(category?.replaceAll(" ", "-").toLowerCase());
                    setServiceCategoryId(categoryId);
                    handleURLChange(categoryId, category);
                    scrollToTop();
                  }}
                >
                  <Box
                    container
                    className="service-content-container"
                    sx={{
                      backgroundColor:
                        serviceCategoryType ==
                        category
                          ?.replaceAll(" ", "-")
                          ?.replaceAll("&", "and")
                          .toLowerCase()
                          ? "#E9EDFB"
                          : "",
                      filter:
                        serviceCategoryType ==
                        category
                          ?.replaceAll(" ", "-")
                          ?.replaceAll("&", "and")
                          .toLowerCase()
                          ? "grayscale(0%)"
                          : "",
                      borderTop:
                        serviceCategoryType ==
                        category
                          ?.replaceAll(" ", "-")
                          ?.replaceAll("&", "and")
                          .toLowerCase()
                          ? "8px solid #7B8BD4"
                          : "",
                    }}
                  >
                    <Box container className="service-image-container">
                      <img src={`${URL}/${image_url}`} />
                    </Box>
                    <Box container className="service-text-container">
                      <h4>{category}</h4> 
                    </Box>
                  </Box>
                </Button>
              </Link>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
}
