import React from 'react'
import "./HowGativanWorks.css"
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    Typography,
    CssBaseline,
    useMediaQuery,
    useTheme,
    Drawer,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText
} from '@mui/material';



export default function HowGativanWorks() {
    const HowGativanWorksData = [
        {
            'id': 1,
            'img': 'one - check Price.png',
            'heading': 'Select the desired service',
            'content': 'From a plethora of services at fair and reasonable prices on our website'
        },
        {
            'id': 2,
            'img': 'two - pickup & drop.png',
            'heading': 'Schedule a free pick-up and drop',
            'content': 'We offer a free pick up and drop on all our services.'
        }, {
            'id': 3,
            'img': 'three - vehicle is serviced.png',
            'heading': 'Vehicle is serviced',
            'content': 'Your car/bike is serviced at our nearby Gativan Certified Garage.'
        }, {
            'id': 4,
            'img': 'four - vehicle delivered.png',
            'heading': 'Hassle-free Delivery',
            'content': 'Get a hassle-free delivery after the service by our mechanics at your doorstep.'
        }, {
            'id': 5,
            'img': 'five - pay post service.png',
            'heading': 'Pay post service',
            'content': 'Pay online through payment link sent by Gativan through UPI/card'
        },
    ]
    return (
        <>
            <Box container position='relative' >
                <Box container>
                    <Box container className="homepage-headers">
                        <h2>How Gativan Works?{" "}
                        </h2>
                    </Box>
                </Box>
                {HowGativanWorksData.map((item, index) => {
                    return (
                        <Box key={index} className='Content'>
                            <Box sx={{ display: "flex" }}>
                                <Box className="number">
                                    <h4>{item.id}</h4>
                                </Box>
                                <Box className="workscontent">
                                    <span className="worktitle">
                                        {item.heading}
                                    </span>
                                    <p className="workdetails">
                                        {item.content}
                                    </p>
                                </Box>
                                <Box className="workimg">
                                    <img
                                        src={process.env.PUBLIC_URL + `/Assets/homepage/howGativanWorks/${item.img}`}
                                        alt="service select "
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )
                })}


            </Box>
        </>
    )
}
