import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { RecaptchaVerifier, onAuthStateChanged, signInWithPhoneNumber } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getMobileNumber } from "../../action";
import { auth } from "../firebase/firebase";
import "./Login.css";




export default function Login() {

  const [user, setUser] = useState({});
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [result, setResult] = useState("");
  const [otp, setOtp] = useState("");

  const navigation = useNavigate();

  const dispatch = useDispatch();

  const width = window.innerWidth;

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
  }

  const getOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");

    try {
      const response = await setUpRecaptha("+91" + number);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message)
    }
  }

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      dispatch(getMobileNumber({ mobileNumber: number }))
      navigation("/profile", { state: number })
    } catch (err) {
      setError(err.message)
    }
  };


  // getting current location from redux
  const currentLocation = useSelector(
    state => state.LocationReducer
  )



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Log in - Gativan
        </title>
        <meta
          name="description"
          content="Login to Gativan and book our car and bike servicing in Pune online. Visit website now. In case you are unable to login, kindly close and reopen your browser."
        />
      </Helmet>
      <Grid container spacing={2}>
        {/*------------image conatiner------------*/}
        <Grid item xs={12} md={7} sm={12}>
          <Box className="img-container">
            <Box className='back-to-booking' onClick={()=>{navigation(`/${currentLocation.cityName}`)}}>{(width<"768") ? <ArrowBackIosIcon fontSize="17px"/> : <KeyboardBackspaceIcon/> } Back to booking </Box>
            <img className="login-background" src={process.env.PUBLIC_URL + "/Assets/login/loginbackgroung.png"} />
            <img className="gativan-logo" src={process.env.PUBLIC_URL + "/Assets/login/GativanFullLogoWhite.png"} />
            <Box className="social-media-logo">
              <Box className="facebook-logo">
                <img src={process.env.PUBLIC_URL + "/Assets/login/Facebook.png"} />
              </Box>
              <Box className="twitter-logo">
                <img src={process.env.PUBLIC_URL + "/Assets/login/Twitter.png"} />
              </Box>
              <Box className="instagram-logo">
                <img src={process.env.PUBLIC_URL + "/Assets/login/Instagram.png"} />
              </Box>
            </Box>
          </Box>
        </Grid>
        {/*------------login form container-----*/}
        <Grid item xs={12} md={5} sm={12} className="login-form-container">
          {flag ?
            <Box className="login-form">
              <h4>Verification Code</h4>
              <h5>OTP has been sent to your mobile number please verify.</h5>
              <Box className="text-field-mobile">
                <TextField
                  className="enter-mobile-no"
                  placeholder="Enter Your OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Box>
              <Button className="confirm-btn" onClick={verifyOtp} >Confirm</Button>
            </Box>
            :

            <Box className="login-form">
              <h4>Login</h4>
              <h5>Enter your Mobile Number to continue</h5>
              <Box className="text-field-mobile">
                <TextField
                  className="enter-mobile-no"
                  placeholder="+91  Mobile Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Box>
              <Button className="confirm-btn" onClick={getOtp} >Confirm</Button>
              <Box id="recaptcha-container"></Box>
              <Box className="sign-up">
                <Typography >
                  Don't have an account ? <strong onClick={() => { navigation('/signup') }}>Sign Up</strong>
                </Typography>
              </Box>
            </Box>
          }

        </Grid>
      </Grid>
    </>
  );
}
