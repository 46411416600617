import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAcdF8Ef6G5uU-G3pmG9WNxAVG2J3wzWSM",
    authDomain: "gativan-ded23.firebaseapp.com",
    projectId: "gativan-ded23",
    storageBucket: "gativan-ded23.appspot.com",
    messagingSenderId: "599980207381",
    appId: "1:599980207381:web:0786cdb801b56b783c8d1c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
