import React, { useEffect, useMemo, useState } from "react";

import httpCommon from "../../http-common";
import { getOrderById } from "../../services/getOrderById";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { PDFViewer, PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";

import "./Sharejobcard.css";
import PDF from "./PDF";

const TermsAndConditionCars = [
  "During first start of car/cold start, keep the car on to let the engine heat for at least 5 mins. ",
  "Ride daily or once in 2 days to keep the engine working and battery from draining out.",
  "Please change your engine oil after 3-6 months or within 5000-10000 kms whichever comes soon.",
  "Service warranty will be only valid for 30 days after delivery.",
  "Only the jobs mentioned in the job card will be done.",
  "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
];
const TermsAndConditionBikes = [
  "During first start of bike/cold start every morning start it with kick as your bike was stand still.",
  "Ride daily to keep the engine working and battery from draining out.",
  "Service warranty will be only valid for 7 days after delivery.",
  "Please change your engine oil after 2-3 months or within 2000-3000 kms whichever comes soon.",
  "Only the jobs mentioned in the job card will be done.",
  "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
];
// Create styles

export default function Sharejobcard() {
  const [isPdfURL, setIsPdfURL] = useState(false);
  const [OrderData, setOrderData] = React.useState();
  const [allServices, setAllServices] = React.useState([]);
  const [allNonCata, setAllCata] = React.useState([]);

  const [TermsAndCondition, setTermsAndCondition] = useState([]);

  const baseURL = httpCommon.defaults.baseURL;

  const [searchParams, setSearchParams] = useSearchParams();

  const { Id, cName, cDT } = useParams();

  const name = cName;
  const orderId = Id.toUpperCase();
  const CDT = cDT;

  const Year = CDT.slice(0, 4);
  const hours = CDT.slice(4, 6);
  const minutes = CDT.slice(6, 8);
  const month = CDT.slice(8, 10);

  const seconds = CDT.slice(10, 12);
  const date = CDT.slice(12, 14);

  const createDate = Year + "-" + month + "-" + date;
  const createTime = hours + ":" + minutes + ":" + seconds;

  localStorage.setItem("orderId", orderId);

  const jobCardPath = `/pune/jobcard/${Id}/${name}/${CDT}`;
  console.log("jobCardPath", jobCardPath);

  useEffect(() => {
    if (orderId) {
      if (
        name == OrderData?.user.first_name.toLowerCase() &&
        OrderData?.serviceBookingDate == createDate &&
        OrderData?.serviceBookingTime == createTime
      ) {
        setIsPdfURL(true);
      }
    } else {
      setIsPdfURL(false);
    }
  }, [OrderData]);

  const firstName = OrderData?.user?.first_name;
  const lastName = OrderData?.user?.last_name;
  const mobile = OrderData?.user?.mobile_number;

  const vehicleType = OrderData?.vehicle?.vehicleType?.vehicleType;
  const brand = OrderData?.vehicle?.brand?.brand;
  const model = OrderData?.vehicle?.model?.model;
  const modelImage = OrderData?.vehicle?.model?.imageUrl;
  const variant = OrderData?.vehicle?.variant?.variantName;
  const vehicleNumber = OrderData?.vehicle?.vehicleNumber;

  const housNumber = OrderData?.address?.houseNumber;
  const area = OrderData?.address?.area;
  const city = OrderData?.address?.city;
  const address = housNumber + ", " + area + ", " + city;

  const customerName = firstName + " " + lastName;
  const vehicle = brand + " " + model + " " + variant;
  const TotalPrice = OrderData?.totalPrice;
  const discount = OrderData?.discount;

  const ServivesTotal = TotalPrice + discount;

  const NonCatalogues = OrderData?.nonCatalogueJobs;
  const Services = OrderData?.services;

  useMemo(() => {
    let temp = [];
    Services &&
      Services.map((i) => {
        const Obj = {
          service: i.service,
          price: i.displayPrice,
          activity: i.activities,
        };
        temp.push(Obj);
      });
    setAllServices([...temp]);
  }, [Services]);

  useMemo(() => {
    let temp = [];
    NonCatalogues &&
      NonCatalogues.map((i) => {
        const Obj = {
          service: i.name,
          price: i.price,
          quantity: i.quantity,
        };
        temp.push(Obj);
      });
    setAllCata([...temp]);
  }, [NonCatalogues]);

  const getOrderDetails = async () => {
    try {
      const request = await getOrderById(orderId);
      const response = await request.data;
      setOrderData(response.result);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    const vehicle = vehicleType?.toLowerCase();
    if (vehicle === "car") {
      setTermsAndCondition(TermsAndConditionCars);
    } else {
      setTermsAndCondition(TermsAndConditionBikes);
    }
  }, [vehicleType]);

  return (
    <div>
      {isPdfURL && (
        <div
          style={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="shareJobContainer">
            <div className="shareJobcardHeader">
              <h5 className="shareJobcard-header-heading">Job Card</h5>

              <div className="Gativanlogo">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Assets/images/GativanLogo.png"
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="customerInfo-container">
              <div className="userDetail">
                <h5 className="fields">{customerName}</h5>
                <h5 className="fields">{vehicle}</h5>
                <h5 className="fields">{vehicleNumber}</h5>
                <h5 className="label">Customer Contact</h5>
                <h5 className="fields ">{mobile}</h5>
                <h5 className="label">Customer Address</h5>
                <h5 className="fields">{address}</h5>
              </div>
              <div className="vehicle-image">
                <img src={baseURL + modelImage} />
              </div>
            </div>

            <div className="shareJobcardSubHeader">
              <h5 className="shareJobcard-subHeader-heading">Job's</h5>

              <div className="GativanSubLogo">
                <img
                  src={process.env.PUBLIC_URL + "/Assets/logos/logo.png"}
                  alt=""
                />
              </div>
            </div>

            <div className="serviceContainer">
              {allServices &&
                allServices.map((item, index) => {
                  const { service, price, activity } = item;

                  return (
                    <div key={index} style={{ marginBottom: "15px" }}>
                      <h5 className="packageName"> {service}</h5>
                      <h5></h5>
                      <div className="services-activity">
                        {activity &&
                          activity.map((list, index) => {
                            let SirNo = index + 1;
                            return (
                              <div
                                key={index}
                                className="services-activity-list"
                              >
                                <h5 className="activityName">
                                  {" "}
                                  {`${SirNo} ${list.activity}`}
                                </h5>
                                <div>
                                  <img
                                    className="check_Image"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Assets/check.png"
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="subtotalSection">
                        <h5>Subtotal</h5>
                        <div className="priceSection">
                          <img
                            className="rupee_Image"
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <h5 className="subtotalPrice">{price}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {allNonCata &&
                allNonCata.map((item, index) => {
                  const { service, price, quantity } = item;
                  return (
                    <div key={index} style={{ marginBottom: "15px" }}>
                      <h5 className="packageName">
                        {" "}
                        {service} ({quantity})
                      </h5>

                      <div className="subtotalSection">
                        <h5>Subtotal</h5>
                        <div className="priceSection">
                          <img
                            className="rupee_Image"
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <h5 className="subtotalPrice">{price}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="services-bottomBorder"></div>

              <div className="subtotalSection">
                <h5 className="subtotalHeading">
                  {discount > 0 ? "" : "Total"}
                </h5>
                <div className="priceSection">
                  <img
                    className="rupee_Image"
                    src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                  />
                  <h5
                    className={
                      discount > 0 ? "subtotalPrice" : "subtotalHeading"
                    }
                  >
                    {ServivesTotal}
                  </h5>
                </div>
              </div>

              {discount > 0 && (
                <>
                  <div className="subtotalSection">
                    <h5 className="discountHeading"> Discount </h5>
                    <div className="priceSection">
                      <h5 className="discountPrice"> - {discount}</h5>
                    </div>
                  </div>

                  <div className="discountTopBorder"></div>

                  <div className="subtotalSection">
                    <h5 className="subtotalHeading"> Total </h5>
                    <div className="priceSection">
                      <img
                        className="rupee_Image"
                        src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                      />
                      <h5 className="totalPrice">{TotalPrice}</h5>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="termsAndCondSection">
              <h5 className="termsAndConHeading">Terms & Conditions</h5>
              <div style={{ padding: "2%", backgroundColor: "white" }}>
                {TermsAndCondition.map((item, index) => (
                  <h5 key={index} className="termsList">
                    {item}
                  </h5>
                ))}
              </div>
            </div>
          </div>

          <div className="download-container">
            <div>
              <PDFDownloadLink
                document={<PDF />}
                fileName={`${customerName}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <button className="icon">
                    {loading ? "Loading..." : "Download"}
                  </button>
                )}
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
