import React from "react";
import "./NoOrderHistory.css";
import { Box, Button, Grid, Divider } from "@mui/material";

export default function NoOrderHistory() {
  return (
    <>
      <Box className="no-order-history-container">
      <h2>Order History</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={12}>
            {/*--------------------------No Order ---------------------------*/}
            <Box className="order-status">
              <h3>Ongoing Order</h3>
              <Divider className="divider" />
            </Box>
            <Box className="no-order">
              <img
                className="noorder"
                src={
                  process.env.PUBLIC_URL + "/Assets/orderHistory/noorder.png"
                }
              />
              <h4>You Have No Ongoing order</h4>
              {/*------------Order Again Button------------- */}
              <Box className="book-service-now-btn">
                <Button className="book-service-btn" variant="contained">
                  Book Your Servicing Now
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Box className="order-status">
              <h3>Complete Order</h3>
              <Divider className="divider" />
            </Box>
            {/*--------------------------No Order ---------------------------*/}
            <Box className="no-order">
              <img
                className="noorder"
                src={
                  process.env.PUBLIC_URL + "/Assets/orderHistory/noorder.png"
                }
              />
              <h4>It Seems you have no previous order.</h4>
           
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
