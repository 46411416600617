import React, { useState, useEffect } from "react";
import "./OrderHistory.css";
import NoOrderHistory from "./NoOrderHistory";
import { useNavigate, useLocation } from "react-router-dom";


import { Box, Button, Grid, Divider, Avatar } from "@mui/material";
import { getOrders, getOrderStatus } from "../../services/vehicleservices/vehicleservices";
import httpCommon from "../../http-common";

export default function OrderHistory() {
  const [orderStatusArray, setOrderStatusArray] = useState([])
  const URL = httpCommon.defaults.baseURL

  const { state } = useLocation();
  const navigation = useNavigate();

  const getOrderStatusData = async (number) => {
    try {
      const response = await getOrders(number);
      const orderStatus = await response.data
      if (orderStatus.statusCode === 200) {
        setOrderStatusArray(orderStatus.result)
      }
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {

    if (state) {
      getOrderStatusData(state.navigateNumber)
    }
  }, []);



  return (
    <>
      <Box className="order-history-container">
        <h2>Order History</h2>
        <Grid container spacing={0}>
          {/*=====================Ongoing Order==============*/}
          <Grid item xs={12} md={6} sm={6}>
            <Box className="order-status">
              <h3>Ongoing Order</h3>
              <Divider className="divider" />
            </Box>
            {/*--------Ongoing Order Map --------------*/}
            {orderStatusArray.map((item) => {

              const { id, vehicle, serviceBookingDate, items, orderId, orderStatus, preferredServiceDate, preferredServiceTime } = item

              const navigationData = {
                orderId,
                navigateNumber: state.navigateNumber
              }

              return (
                <React.Fragment key={id}>
                  <Box className="on-going-order">
                    <Box className="vehicle-image-and-name">
                      <Box className="vehicle-image">
                        <img src={`${URL}/${vehicle?.model?.imageUrl}`} />
                        {/* <Avatar>{vehicle?.model?.imageUrl}</Avatar> */}
                      </Box>
                      <Box className="vehicle-name">
                        {`${(vehicle.brand !== null) ? vehicle.brand.brand : ''} ${(vehicle.model !== null) ? vehicle.model.model : ''} ${(vehicle.variant !== null) ? vehicle.variant.variant : ''}`}
                        <Box className="booking-date">
                          {serviceBookingDate}- {items}
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------booking id and status-------*/}
                    <Box className="bookingid-status">
                      <Box className="booking-id">
                        Booking ID
                        <Box className="booking-number">{orderId}</Box>
                      </Box>
                      <Box className="status">
                        Status<Box className="status-name">{orderStatus.status}</Box>
                      </Box>
                    </Box>
                    {/*--------------date time and view details---------*/}
                    <Box className="datetime-viewdetails">
                      <Box className="slot-date-time">
                        Slot Date and Time
                        <Box className="date-time">{`${preferredServiceDate} ${preferredServiceTime}`}</Box>
                      </Box>
                      <Box className="view-details-btn">View Details...</Box>
                    </Box>
                    <Box>
                      {/*------------Track order button---------*/}
                      <Button className="track-btn" onClick={() => { navigation('/order-status', { state: navigationData }) }} variant="contained">
                        Track Order
                      </Button>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
          </Grid>
          {/*======================Completed Order=================*/}
          {/* <Grid item xs={12} md={6} sm={6}>
            <Box className="order-status">
              <h3>Completed Order</h3>
              <Divider className="divider" />
            </Box>
            --------------------------Complete Order Map---------------------------
            {completeOrder.map((item) => (
              <Box className="complete-order">
                <Box className="vehicle-image-and-name">
                  <Box className="vehicle-image">
                    <Avatar>{item.vehicleimage}</Avatar>
                  </Box>
                  <Box className="vehicle-name">
                    {item.vehiclename}
                    <Box className="booking-date">
                      {item.bookingdate}- {item.items}
                    </Box>
                  </Box>
                </Box>
                <Divider />
                -----------booking is and status-----------
                <Box className="bookingid-status">
                  <Box className="booking-id">
                    Booking ID
                    <Box className="booking-number">{item.bookingid}</Box>
                  </Box>
                  <Box className="status">
                    Status<Box className="status-name">{item.status}</Box>
                  </Box>
                </Box>
                --------slot date and time--------
                <Box className="datetime-viewdetails">
                  <Box className="slot-date-time">
                    Slot Date and Time
                    <Box className="date-time">{item.slotdateandtime}</Box>
                  </Box>
                  <Box className="view-details-btn">View Details...</Box>
                </Box>
                <Box>
                  ------------Order Again Button-------------
                  <Button className="order-again-btn" variant="outlined">
                    Order again
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
}
