
import React, { useState } from 'react'
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import './FAQ_home.css'
import { ExpandCircleDown } from '@mui/icons-material'


export default function FAQ_home({ data }) {


    const [expanded, setexpanded] = useState(false);


    const handleChange = (panel) => (event, isExpended) => {
        setexpanded(isExpended ? panel : false)
    }



    return (
        <Box>

            {
                data.map((item, index) => {
                    const { title, body } = item
                    return (
                        <Accordion className='faq-accordion' key={index} expanded={expanded === title} onChange={handleChange(title)} >
                            <AccordionSummary className='faq-accordion-header faq-header' expandIcon={<ExpandCircleDown />} aria-controls="panel1a-content" id="panel1a-header" >
                                <h2>{title}</h2>
                            </AccordionSummary>
                            <AccordionDetails className='faq-accordion-body'>
                                <p>{body}</p>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Box>
    )
}
