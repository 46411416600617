import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import httpCommon from "../../http-common";

import fontURL1 from "../../font/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../font/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../font/AvenirNextLTPro-Demi.otf";
import { getOrderById } from "../../services/getOrderById";

import { useParams } from "react-router-dom";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

// Create styles

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#122164",
    padding: "2% 2%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  jobcardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  jobcardSubHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    margin: "10px 0px",
    padding: "0px 1.5%",
  },
  heading: {
    color: "white",
    marginRight: "0%",
    fontSize: "26px",
    fontFamily: "AvenirBold",
  },
  subHeading: {
    color: "white",
    marginRight: "0%",
    fontSize: "20px",
    fontFamily: "AvenirRegular",
  },
  logo: {
    width: "140px",
    marginRight: "0%",
  },
  subLogo: {
    height: "100%",
    width: "20px",
    marginRight: "0%",
  },
  subLogoImage: {
    width: "25px",
    height: "25px",
  },
  userInfoSection: {
    height: "160px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "2%",
    position: "relative",
  },
  label: {
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    lineHeight: "10px",
    color: "#121212",
    fontWeight: 200,
  },
  labelManufacture: {
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    color: "#121212",
    fontWeight: 200,
    lineHeight: "10px",
  },
  fields: {
    color: "#6A7086",
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    fontWeight: 200,
    lineHeight: "10px",
  },

  servicesContainer: {
    backgroundColor: "white",
    padding: "2%",
    // flexGrow: 1,
    position: "relative",
  },
  packageName: {
    fontSize: "14px",
    fontFamily: "AvenirBold",
    color: "#122164",
    marginLeft: "-5px",
  },
  packageNameUnderline: {
    width: "100px",
    height: "1px",
    backgroundColor: "black",
  },
  serviceActivity: {
    padding: "8px 0px 5px",
    margin: "0px 8px",
  },
  serviceActivityList: {
    // height: "25px",
    color: "#6a7086",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "3px",
    marginBottom: "3px",
  },
  activityName: {
    width: "85%",
    fontSize: "12px",
    fontFamily: "AvenirRegular",
  },

  srNo: {
    color: "#5b5757",
    fontSize: "13px",
    fontFamily: "AvenirDemi",
    marginRight: "5px",
  },

  check_Image: {
    height: "15px",
    width: "15px",
  },
  subtotalSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
    margin: "0px 8px",
  },

  totalContainer: {
    // marginTop: "10px !important",
    width: "100%",
    padding: "5px 0",
    position: "absolute",
    bottom: 0,
    display: "flex !important",
    justifyContent: "center !important",
    flexDirection: "column",
    // marginLeft: "4% !important",
  },

  totalSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
  },

  subtotalNonCataSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "5px 0px",
  },
  subtotalHeading: {
    fontSize: "13px",
    fontFamily: "AvenirBold",
  },
  totalHeadingLabel: {
    fontSize: "20px",
    fontFamily: "AvenirBold",
  },

  priceSection: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
  },
  rupee_Image: {
    width: 15,
    height: 15,
    fontFamily: "AvenirBold",
  },
  subtotalPrice: {
    fontSize: 16,
    fontFamily: "AvenirRegular",
    color: "#6A7086",
  },

  calculationTopBorder: {
    width: "104%",
    // width: "100%",
    height: "1px",
    backgroundColor: "gray",
    // marginLeft: "-2%",
    marginTop: "2px",
    marginBottom: "5px",
  },
  totalPrice: {
    fontSize: 16,
    fontFamily: "AvenirRegular",
    color: "#6A7086",
  },
  termsAndCondSection: {
    // backgroundColor:'white',
    margin: "10px 0px",
  },
  termsAndConHeading: {
    color: "white",
    fontSize: "14px",
    fontFamily: "AvenirBold",
  },
  termsTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontFamily: "AvenirRegular",
  },
  termsBody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: 10,
    marginBottom: 5,
    marginRight: 5,
    fontSize: "11px",
    fontFamily: "AvenirRegular",
    // marginBottom: "3px",
  },

  bullet: {
    width: 5,
    height: 5,
    backgroundColor: "#000",
    borderRadius: 50,
  },
  subBullet: {
    marginTop: 5,
    width: 4,
    height: 4,
    backgroundColor: "#000",
    borderRadius: 50,
  },
});

export default function PDF({ lead }) {
  const baseURL = httpCommon.defaults.baseURL;

  const leadName = lead?.leadName;
  const mobileNumber = lead?.mobileNumber;
  const vehicle = `${lead?.brand?.brand} ${lead?.model?.model} ${lead?.variant?.variantName}`;
  const vehicleNumber = lead?.vehicleNumber;
  const address = lead?.city?.city;
  const modelImage = lead?.model?.imageUrl;
  const totalPrice = lead?.totalPrice;
  const services = lead?.servicesList;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <View style={styles.jobcardHeader}>
            <Text style={styles.heading}> Lead Card </Text>
            <View style={styles.logo}>
              <Image
                src={process.env.PUBLIC_URL + "/Assets/images/GativanLogo.png"}
              />
            </View>
          </View>

          <View style={styles.userInfoSection}>
            <View style={{ width: "50%" }}>
              <Text style={styles.label}>
                {leadName || "Customer Name: ---"}
              </Text>
              <Text style={styles.fields}>{vehicle || "Vehicle: ---"}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.fields}>
                  {vehicleNumber ? vehicleNumber : "Vehicle Number"}
                </Text>
              </View>

              <Text style={styles.label}>Customer Contact</Text>
              <Text style={styles.fields}>{mobileNumber || "---"}</Text>
              <Text style={styles.label}>Customer Address</Text>
              <Text style={styles.fields}>{address || "---"} </Text>
            </View>
            <View
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              // className="vehicle-image"
            >
              <Image style={{ height: "100%" }} src={baseURL + modelImage} />
            </View>
          </View>

          <View style={styles.jobcardSubHeader}>
            <Text style={styles.subHeading}> Jobs </Text>
            <View style={styles.subLogo}>
              <Image src={process.env.PUBLIC_URL + "/Assets/images/logo.png"} />
            </View>
          </View>

          <View
            style={{
              ...styles.servicesContainer,
              flexGrow: 1,
            }}
          >
            <View
              style={{
                marginBottom: "15px",
              }}
            >
              {services &&
                services.map((item, index) => {
                  const { service, displayPrice, activities } = item;

                  return (
                    <View
                      // style={{ marginTop: "7px", marginBottom: "7px" }}
                      style={{ marginBottom: "15px" }}
                      key={index}
                    >
                      <Text style={styles.packageName}> {service}</Text>
                      <Text style={styles.packageNameUnderline}></Text>
                      <View style={styles.serviceActivity}>
                        {activities &&
                          activities.map((list, index) => {
                            let SirNo = index + 1;
                            return (
                              <View
                                style={styles.serviceActivityList}
                                key={index}
                                wrap={false}
                              >
                                {/* <View> */}
                                <Text style={styles.activityName}>
                                  <Text style={styles.srNo}>{SirNo} </Text>
                                  {list.activity}
                                  {/* {`${SirNo} ${list.activity}`} */}
                                </Text>
                                {/* </View> */}
                                {/* <View> */}
                                <Text>
                                  <Image
                                    style={styles.check_Image}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Assets/check.png"
                                    }
                                  />
                                </Text>
                                {/* </View> */}
                              </View>
                            );
                          })}
                      </View>

                      <View style={styles.subtotalSection}>
                        <Text style={styles.subtotalHeading}>Subtotal: </Text>
                        <View style={styles.priceSection}>
                          <Image
                            style={styles.rupee_Image}
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <Text style={styles.subtotalPrice}>
                            {displayPrice}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </View>

            <View
              // style={{ ...styles.totalContainer, marginTop: "150px" }}
              style={styles.totalContainer}
              wrap={false}
            >
              <View style={styles.calculationTopBorder}></View>

              <View>
                {/* <View style={{ ...styles.subtotalSection, paddingLeft: "1%" }}>
                  <Text style={styles.discountHeading}> Discount </Text>
                  <View style={{ ...styles.priceSection, marginRight: "-2%" }}>
                    <Text style={styles.discount}> - {discount}</Text>
                  </View>
                </View> */}

                <View style={styles.discountTopBorder}></View>

                <View
                  style={{
                    ...styles.subtotalSection,
                    padding: "3% 0% 3% 1%",
                  }}
                >
                  <Text style={styles.totalHeadingLabel}> Total: </Text>
                  <View style={{ ...styles.priceSection, marginRight: "-2%" }}>
                    <Image
                      style={styles.rupee_Image}
                      src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                    />
                    <Text style={styles.totalPrice}>{totalPrice}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
