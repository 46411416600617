import React from "react";

const useWhyChooseGativan = () => {
  const periodicService = `
    <Box className='blog-mainContainer'>
         <Box className='blog-why-choose'>
         <ul className="why-choose-subheader">
                   <li>
                        <span>Regular car maintenance is crucial to ensure a smooth and trouble-free car ownership experience.</span>
                   </li>
                   <li>
                        <span>Vital components such as brake pads, tyres, and engine oil have finite lifespans and require periodic replacement.</span>
                   </li>
                   <li>
                        <span>Investing in routine maintenance helps reduce the overall cost of ownership, saving both time and money.</span>
                   </li>
                   <li>
                        <span>A well-maintained car will perform better over the long term and maintain a higher resale value.</span>
                   </li>
                   <li>
                        <span>A well-maintained car will perform
                         better over the long term and maintain a higher resale value.</span>
                   </li>
              </ul>
              <Box className='blog-header'>
                   <h3><span>{{$vehicle}}</span> Car Services Available:</h3>
              </Box>
              <ul className="why-choose-subheader">
                   <li>
                        <strong>Basic Periodic Service:</strong>
                        <span>Includes all necessary services to keep your car in good working condition.</span>
                   </li>
                   <li>
                        <strong>Pro Periodic Service:</strong>
                        <span>Our most popular service package, offering the benefits of the essential package with additional services.</span>
                   </li>
                   <li>
                        <strong>Comprehensive Periodic Service: </strong>
                        <span> Gativan's signature package, providing the overall car servicing, ensuring the efficient running of your car.</span>
                   </li>
              </ul>
              <Box className='blog-header'>
                    <h3>Standard OEM Equipments:</h3>
                    <Box className='blog-header-description'>
                    <p>At every Gativan workshop in {{$city}}, we utilize cutting-edge industry-standard car service equipments. This includes automatic AC gas recharging equipment, laser automated wheel balancing and alignment machines, authorized diagnostic scanners, Standard programming devices, and specialized tools tailored to your vehicle's needs.</p>
                    </Box>
               </Box>
               <Box className='blog-header'>
               <h3>Warranty on Car Services:</h3>
               <Box className='blog-header-description'>
               <p>When you choose Gativan, you benefit from the Gativan Advantage. Your Tata service is backed by our 1000kms/1 month warranty policy, valid at any Gativan service center in {{$city}}.</p>
               </Box>
          </Box>
         </Box>
    </Box>
         `;

  const batteries = `
         <Box className='blog-mainContainer'>
              <Box className="blog-servicesOffers">
              <p>Gativan is your preferred destination for 100% genuine car batteries, complete with the original manufacturer's warranty. </p>
              <p>Here are the reasons to opt for Gativan when it comes to your battery requirements:</p>
              <h3>The Significance of Car Batteries for <span>{{$vehicle}}</span>:</h3>
              <ul className="why-choose-subheader">
                   <li> 
                        <span>Power Source:</span>
                        <span>Car batteries are the primary source of electrical energy in a vehicle, providing the power needed to start the engine and operate various electrical systems, including lights, radio, air conditioning, and more.</span>
                   </li>
                   <li>
                        <span>Ignition System:</span>
                        <span>Car batteries play a critical role in powering the ignition system, enabling the engine to start. Without a functioning battery, a vehicle cannot be started.</span>
                   </li>
                   <li>
                        <span>Stabilizing Voltage:</span>
                        <span>Car batteries help stabilize the voltage in the vehicle's electrical system, ensuring consistent and reliable performance of electronic components and accessories.</span>
                   </li>
                   <li>
                        <span>Backup Power:</span>
                        <span>In case of alternator failure or when the engine is off, the car battery serves as a backup power source, allowing essential functions like headlights and hazard lights to continue operating. </span>
                   </li>
                   <li>
                        <span>Regular maintenance or replacement of your car battery is essential to ensure the smooth operation of your car's electronic systems. </span>
                   </li>
                   <li>
                        <span>A typical car battery can last up to 4 years, although this duration may vary depending on any additional accessories integrated into your vehicle.</span>
                   </li>
         </ul>
           <Box className='blog-header'>
                <h3>{{$vehicle}} Battery Services Available at Gativan:</h3>
                <p>You can select from a range of battery services tailored to your needs:</p>
                <ul className="why-choose-subheader">
                     <li> 
                          <span>Doorstep Battery Replacement:</span>
                          <span>Purchase a new car battery from renowned battery manufacturers like Amaron, Exide, Luminous, and more.</span>
                     </li>
                     <li>
                          <span>Battery Recharging Service:</span>
                          <span>Benefit from Gativan's {{$vehicle}} battery recharging service, including Volts/Amperage checks and water/acid level inspections.</span>
                     </li>
                </ul>
           </Box>
           <Box className='blog-header'>
                <h3>Free Doorstep Battery Replacement:</h3>
                <Box className='blog-header-description'>
                     <p>Enjoy FREE doorstep battery replacement, all from the comfort of your home. We exclusively stock branded car batteries from reputable manufacturers such as Exide, Amaron, Luminous, and more. Our safe and efficient car battery recharging stations ensure top-notch service in {{$city}}.</p>
                </Box>
           </Box>
           <Box className='blog-header'>
                <h3>Manufacturer Warranty on Car Batteries:</h3>
                <Box className='blog-header-description'>
                     <p>When you choose Gativan, you gain access to the Gativan Advantage. Take advantage of the manufacturer warranty on your new car battery purchase, redeemable nationwide and across {{$city}}.</p>
                </Box>
            </Box>
    </Box>
 </Box>
              `;

  const cleaning = `
      <Box className='blog-mainContainer'>
           <Box className="blog-servicesOffers">
                <Box className='blog-header'>
                     <h3>Car Cleaning Services in {{$city}} by Gativan</h3>
                </Box>
                <Box className='blog-header-description'>
                     <p>Maintaining the pristine condition of your car is essential, and our Car Cleaning Services in {{$city}} offer the perfect solution. Whether it's removing dust, dirt, mud, or protecting your car's paintwork, we've got you covered. 
                     </p>
                </Box>
           </Box>
           <Box className="blog-servicesOffers">
                <Box className='blog-header'>
                     <h3>Why Car Cleaning Services Are Vital:</h3>
                </Box>
                <ul className="why-choose-subheader">
                 <li>
                    <strong>Thorough Cleaning: </strong>
                      <span>Car cleaning is the most effective way to eliminate dust, dirt, mud, and other unsightly build-ups from your vehicle.</span>
                 </li>
                 <li>
                 <strong>Paint Protection: </strong>
                      <span>Washing and waxing not only keep your car clean but also safeguard its delicate paintwork. Regular car care services help maintain your car's appearance as if it were brand new..</span>
                 </li>
                 <li>
                    <strong>Interior Sanitization: </strong>
                      <span>Interior car care is equally crucial, as it de-contaminates and sanitizes the interior, including upholstery, seats, and carpets.</span>
                 </li>
               </ul>
          <Box className="blog-servicesOffers">
                <Box className='blog-header'>
                     <h3>{{$vehicle}} Car Cleaning Services Offered by Gativan:</h3>
                </Box>
                <Box className='blog-header-description'>
                     <p>Choose from a wide range of premium car cleaning services available at your nearest Gativan workshop in {{$city}}: 
                     </p>
                </Box>
           </Box>
           <ul className="why-choose-subheader">
                 <li>
                    <strong>Interior and Exterior Cleaning Services: </strong>
                      <span>Comprehensive cleaning solutions for both the interior and exterior of your car.</span>
                 </li>
                 <li>
                 <strong>Car Germ Cleaning Service: </strong>
                      <span>Car Germ Cleaning Service involves thorough sanitization and disinfection of the car's interior to eliminate harmful germs and bacteria for a healthier driving environment.</span>
                 </li>
                 <li>
                    <strong>Silencer Coating: </strong>
                      <span>Ensure a quiet and smooth ride with our silencer coating service.</span>
                 </li>
                 <li>
                    <strong>Ceramic and Teflon Coating: </strong>
                      <span>Enhance the shine and protection of your car's exterior with advanced coatings.</span>
                 </li>
                 <li>
                    <strong>Rubbing and Polishing Services: </strong>
                      <span>Restore the gloss and finish of your car's paintwork.</span>
                 </li>
                 <li>
                    <strong>Tyre Dressing and Alloy Cleaning: </strong>
                      <span>Revitalize your tires and alloy wheels for a polished look.</span>
                 </li>

                 <li>
                    <strong>Sunroof Cleaning Service: </strong>
                      <span>Restore the clean sunroof with our Sunroof Cleaning Service.</span>
                 </li>
          </ul>
           </Box>
          <Box className="blog-servicesOffers">
               <Box className='blog-header'>
                    <h3>Premium Car Care Products:</h3>
               </Box>
               <Box className='blog-header-description'>
                    <p>At Gativan, we exclusively use top-tier, pH-neutral car care products sourced from renowned global brands like 3M to deliver the best possible shine for your {{$vehicle}}.
                    </p>
               </Box>
          </Box>
          <Box className="blog-servicesOffers">
               <Box className='blog-header'>
                    <h3>Specialized Car Care Equipment:</h3>
               </Box>
               <Box className='blog-header-description'>
                    <p>Professional {{$vehicle}} cleaning and detailing demand specialized tools and machinery. That's why every Gativan workshop in {{$city}} is equipped with industry-grade buffing and polishing machines, automatic pressure washers, and other car care tools. These, combined with our special car care products, ensure exceptional results for your vehicle.
                    </p>
               </Box>
          </Box>
      </Box>
           `;

  const ACServiceAndRepair = `
       <Box className='blog-mainContainer'>
            <Box className="blog-servicesOffers">
                 <Box className='blog-header'>
                      <h3>Gativan Car AC Service:</h3>
                 </Box>
                 <ul className="why-choose-subheader">
                      <li>
                           <span>Regular AC servicing is essential for maintaining the efficiency of your car's AC system.</span>
                      </li>
                      <li>
                           <span>Routine AC maintenance includes inspecting and cleaning the cabin air filter, which captures pollen, bacteria, dust, and exhaust fumes, preventing headaches, allergies, or respiratory issues.</span>
                      </li>
                      <li>
                           <span>The compressor plays a crucial role in the car's AC system. Regular maintenance and proper lubrication reduce the risk of premature compressor failure.</span>
                      </li>
                 </ul>
                 </Box>
                 <Box className='blog-why-choose'>
                 <p><span>{{$vehicle}}</span> AC Services Offered by Gativan:</p>
                 <ul className="why-choose-subheader">
                      <li>
                           <strong>Regular AC Service:</strong>
                           <span>Includes Car AC Filter Cleaning, Car AC Gas Top-up, Car AC Inspection and Condenser Cleaning</span>
                      </li>
                      <li>
                           <strong>High Performance AC Service:</strong>
                           <span>Includes Car AC Filter Cleaning, Car AC Gas Replacement, Car AC Inspection and Condenser Cleaning, Coolant Draining, Radiator Cleaning, Radiator Flushing, Dashboard Removing and refitting.</span>
                      </li>
                      <li>
                           <strong>Radiator Flush Cleaning:</strong>
                           <span>Thoroughly clean the Radiator with anti-freeze coolant replacement.</span>
                      </li>
                      <li>
                           <strong>AC Condenser Cleaning:</strong>
                           <span>Thoroughly clean the AC condenser with a high-pressure jet washer and specialized chemicals.</span>
                      </li>
                      <li>
                           <strong>Cooling Coil Replacement:</strong>
                           <span>Replace the AC cooling coil for efficient AC performance.</span>
                      </li>
                      <li>
                           <strong>AC Diagnosis & Check-up:</strong>
                           <span>Detect high-pressure and low-pressure AC leaks using an AC gas charging machine.</span>
                      </li>
                 </ul>
                 <Box className='blog-header'>
                       <h3>Industry-Leading Equipment at Gativan:</h3>
                       <Box className='blog-header-description'>
                       <p>At every Gativan workshop in {{$city}}, we utilize state-of-the-art industry-standard automotive AC service equipment. This includes automatic AC gas recharging tools, AC gas leak detection devices, refrigerant recovery equipment, flushing tools, and other specialized equipment tailored to your <span>{{$vehicle}}</span>. Visit the nearest Gativan workshop in {{$city}}.</p>
                       </Box>
                  </Box>
                  <Box className='blog-header'>
                  <h3>Warranty on Car AC Services:</h3>
                  <Box className='blog-header-description'>
                  <p>When you choose Gativan, you enjoy the Gativan Advantage. Your car's AC service is covered by our 1000kms/1 month warranty policy, valid at any Gativan service center in {{$city}}.</p>
                  </Box>
             </Box>
            </Box>
       </Box>
            `;

  const wheelAndTyreCare = `
            <Box className='blog-mainContainer'>
                 <Box className="blog-servicesOffers">
                      <Box className='blog-header'>
                           <h3>Why opt for Gativan Wheel & Tyre Care services?</h3>
                      </Box>
                      <ul className="why-choose-subheader">
                           <li> 
                              <strong>Safety: </strong>
                                <span>Worn-out or damaged tires can significantly compromise vehicle safety, leading to reduced traction, longer braking distances, and an increased risk of accidents. Fresh tires with proper tread depth and condition ensure better grip and handling, especially in adverse road conditions.</span>
                           </li>
                           <li>
                                   <strong>Performance: </strong>
                                <span>New tires improve overall vehicle performance, including better fuel efficiency, smoother rides, and enhanced cornering and handling capabilities. They contribute to a more comfortable and enjoyable driving experience.</span>
                           </li>
                           <li>
                              <strong>Longevity: </strong>
                                <span>Regular tire replacement helps extend the lifespan of your vehicle's other components, such as the suspension and alignment systems, by ensuring even wear and tear. This proactive maintenance approach can save you money in the long run by preventing costly repairs and replacements.</span>
                           </li>
                           <li>
                           <strong>Limited Lifespan: </strong>
                             <span>Car tyres have a finite lifespan and should typically be replaced every 4 years or at 40,000 kms, whichever comes earlier.</span>
                        </li>
                        <li>
                              <strong>Indicators for Replacement: </strong>
                              <span>Indicators that suggest it's time for tyre replacement include uneven tread wear, cracks, cupping, bulges, bubbles, and sidewall damage.</span>
                         </li>
                      </ul>
                      </Box>
                      <Box className='blog-header'>
                           <h3>Tyre Services Available at Gativan:</h3>
                           <p>Choose from a variety of tyre services tailored to your needs:</p>
                      </Box>
                      <ul className="why-choose-subheader">
                           <li> 
                              <strong>Tyre Replacement: </strong>
                                <span>Purchase top-quality {{$vehicle}} tyres from leading brands such as MRF, Michelin, Apollo, Bridgestone, and more.</span>
                           </li>
                           <li>
                              <strong>Tyre Rotation: </strong>
                                <span>Tyre rotation helps ensure even wear and tear on your car tyres, extending their lifespan.</span>
                           </li>
                           <li>
                              <strong>Wheel Balancing: </strong>
                                <span>Gativan provides laser-assisted wheel balancing with precision counterweights for a smoother and safer ride.</span>
                           </li>
                      </ul>
                      </Box>
                      <Box className='blog-header'>
                            <h3>Industry-Leading Equipment:</h3>
                            <Box className='blog-header-description'>
                            <p>At Gativan, we utilize state-of-the-art technology in tyre replacement, wheel balancing/alignment, and tyre rotation services. From specialized tyre replacement jigs to nitrogen gas inflators and other tools tailored to your <span>{{$vehicle}}</span>, we have you covered. Visit the nearest Gativan workshop in {{$city}}.</p>
                            </Box>
                       </Box>
                       <Box className='blog-header'>
                       <h3>Dealership Warranty on Car Tyres:</h3>
                       <Box className='blog-header-description'>
                       <p>When you choose Gativan, you enjoy the Gativan Advantage. Your new car tyres are covered by the dealership warranty directly from the tyre manufacturer, valid anywhere in {{$city}}. Now, book with confidence.</p>
                       </Box>
                 </Box>
            </Box>
                 `;

  const dentingAndPenting = `
            <Box className='blog-mainContainer'>
                 <Box className="blog-servicesOffers">
                      <Box className='blog-header'>
                           <h3>{{$vehicle}} Denting and Painting Services in {{$city}} by Gativan:</h3>
                           <p>In the bustling streets of India, dents and dings are all too common. That's why we provide top-notch denting and painting services in {{$city}}, tailored to your {{$vehicle}}.</p>
                      </Box>
                      <Box className='blog-header'>
                           <h3>Why Choose Denting and Painting Services:</h3>
                      </Box>
                      <ul className="why-choose-subheader">
                           <li> 
                              <strong>Cost-Effective Maintenance: </strong>
                                <span>It's a cost-effective way to preserve the longevity and appearance of your vehicle, ensuring it serves you well for years to come.</span>
                           </li>
                           <li>
                                   <strong>Resale Value: </strong>
                                <span>A car with dents and scratches tends to fetch less value in the resale market. Denting and painting services help maintain your vehicle's market worth.</span>
                           </li>
                           <li>
                              <strong>Preventing Rust: </strong>
                                <span>Neglecting paint damage can lead to rusting problems, which can be costly to repair. Timely denting and painting services help prevent rust from taking hold.</span>
                           </li>
                      </ul>
                      </Box>
                      <Box className='blog-header'>
                           <h3>Denting and Painting Services Offered:</h3>
                           <p>We offer a range of denting and painting services suitable for all car makes and models at your nearest Gativan auto body workshop in {{$city}}:</p>
                      </Box>
                      <ul className="why-choose-subheader">
                           <li> 
                              <strong>Per Panel Denting and Painting Service: </strong>
                                <span>Targeted repair for specific panels with dents or scratches.</span>
                           </li>
                           <li>
                              <strong>Fibre and Plastic Dent Removal: </strong>
                                <span>Expert removal of dents from fiberglass and plastic parts.</span>
                           </li>
                           <li>
                              <strong>Full Body Denting and Painting Service: </strong>
                                <span>Comprehensive solutions for full-body repair.</span>
                           </li>
                           <li>
                              <strong>Custom Denting and Painting Repairs: </strong>
                                <span>Tailored services to address your unique denting and painting needs.</span>
                           </li>
                      </ul>
                      <Box className='blog-header'>
                           <h3>State-of-the-Art Auto-Body Workshop:</h3>
                      </Box>
                      <ul className="why-choose-subheader">
                           <li> 
                              <strong>Specialized {{$vehicle}} Auto-Body Workshop:</strong>
                                <span>Our precision denting and painting equipment, along with hermetically sealed paint booths, ensure the finest results for your {{$vehicle}}.</span>
                           </li>
                           <li>
                              <strong>Nippon Paint/Grade-A Primer:</strong>
                                <span>We use premium Nippon paint paired with Grade-A primer, ensuring a 100% color match and a showroom-new finish.</span>
                           </li>
                           <li>
                              <strong>2-Year Warranty on Paint: </strong>
                                <span>We offer a 2-year unconditional warranty on paint at every Gativan workshop.</span>
                           </li>
                      </ul>
                      </Box>
                       <Box className='blog-header'>
                       <h3>Warranty on Denting and Painting Services:</h3>
                       <Box className='blog-header-description'>
                       <p>When you choose Gativan for denting and painting services, you benefit from a 2-year warranty clause, valid at every Gativan workshop in {{$city}}. Drive with assurance in a vehicle that boasts a fresh, like-new appearance and performance.</p>
                       </Box>
                 </Box>
            </Box>
                 `;

  const detailing = `
                 <Box className='blog-mainContainer'>
                      <Box className="blog-servicesOffers">
                           <Box className='blog-header'>
                                <h3>Car Detailing Services in {{$city}} by Gativan</h3>
                                <p>Gativan's Car Detailing Services in {{$city}} provide you with a hassle-free way to access premium detailing and coating solutions for your {{$vehicle}}. At Gativan {{$city}}, we exclusively utilize top-quality industry products for all your {{$vehicle}} detailing and coating needs.</p>
                           </Box>
                           <Box className='blog-header'>
                                <h3>Services offered in Detailing Services:</h3>
                                <p>Our detailing services are available for all car makes and models at Gativan {{$city}}, and they cover a wide range of offerings:</p>
                           </Box>
                           <ul className="why-choose-subheader">
                                <li> 
                                   <strong>PPF - Paint Protection Film: </strong>
                                     <span>Safeguard your vehicle's paint from environmental elements with a protective film.</span>
                                </li>
                                <li>
                                        <strong>Ceramic Coating: </strong>
                                     <span>Elevate the shine and durability of your car's paint with ceramic coating.</span>
                                </li>
                                <li>
                                   <strong>Anti-Rust Underbody Coating: </strong>
                                     <span> Shield your vehicle's undercarriage from rust and corrosion.</span>
                                </li>
                                <li>
                                   <strong>Teflon Coating: </strong>
                                     <span>Preserve the lustre and lifespan of your car's paint with Teflon coating.</span>
                                </li>
                                <li>
                                   <strong>Paint Protection Film (PPF): </strong>
                                     <span>Shield your car's paint from external elements with a protective film.</span>
                                </li>
                           </ul>
                           
                              <Box className='blog-header'>
                                   <h3>Warranty on Denting and Painting Services:</h3>
                                   <Box className='blog-header-description'>
                                        <p>When you choose Gativan for denting and painting services, you benefit from a 2-year warranty clause, valid at every Gativan workshop in {{$city}}. Drive with assurance in a vehicle that boasts a fresh, like-new appearance and performance.</p>
                                   </Box>
                              </Box>
                              <Box className='blog-header'>
                                   <h3>Warranty on Detailing Services:</h3>
                                   <Box className='blog-header-description'>
                                        <p>All our detailing services at Gativan {{$city}} are backed by warranties, although the warranty period may vary depending on the specific service you select.</p>
                                   </Box>
                              </Box>
                      </Box>
                 </Box>
                      `;

  const clutchAndFitments = `
                      <Box className='blog-mainContainer'>
                           <Box className="blog-servicesOffers">
                                <Box className='blog-header'>
                                     <h3>Clutch & Fitment Services in {{$city}} by Gativan</h3>
                                     <p>Gativan {{$city}} offers a seamless solution for Clutch, Flywheel, Lights Replacement Services, providing you with OEM/OES assemblies for your car.</p>
                                </Box>
                                <Box className='blog-header'>
                                     <h3>Inclusions in Clutch & Fitment Services:</h3>
                                     <p>Our light replacement services cater to all car makes and models at Gativan {{$city}} and encompass the following:</p>
                                </Box>
                                <ul className="why-choose-subheader">
                                     <li> 
                                        <strong>Clutch Replacement Services: </strong>
                                          <span>Set/ Bearing Replacement for its proper functioning.</span>
                                     </li>
                                     <li>
                                             <strong>Clutch Overhaul: </strong>
                                          <span>Removing and refitting the clutch for its smooth operation.</span>
                                     </li>
                                     <li>
                                        <strong>Flywheel Replacement: </strong>
                                          <span> The flywheel can experience wear and tear, clutch issues, resurfacing limit reached, vibrations and noise. This causes the need to replace Flywheel on time for a preventive maintenance.</span>
                                     </li>
                                </ul>
                                
                                   <Box className='blog-header'>
                                        <h3>Specialized Tools & Equipment:</h3>
                                        <Box className='blog-header-description'>
                                             <p>Every Gativan workshop in {{$city}} is equipped with specialized tools and modern equipment to ensure the precise fitting and functioning of your car's clutch and flywheel or any other fitments like lights, etc. </p>
                                        </Box>
                                   </Box>
                                   <Box className='blog-header'>
                                        <h3>Warranty on Clutch & Fitment Services:</h3>
                                        <Box className='blog-header-description'>
                                             <p>For any replacement services at Gativan {{$city}} include a 1-month warranty on fitting, ensuring your peace of mind.</p>
                                        </Box>
                                   </Box>
                           </Box>
                      </Box>
                           `;

  const insuranceClaim = `
                           <Box className='blog-mainContainer'>
                                <Box className="blog-servicesOffers">
                                     <Box className='blog-header'>
                                          <h3>{{$vehicle}} Car Insurance Claims and Repairs in {{$city}} by Gativan</h3>
                                          <p>Experience hassle-free insurance claims and repairs for your {{$vehicle}} in {{$city}} with Gativan:</p>
                                     </Box>
                                     <ul className="why-choose-subheader">
                                          <li> 
                                             <strong>Claim Assistance: </strong>
                                               <span>We simplify the insurance claim process through our corporate tie-ups with all insurance companies, making it quick and straightforward.</span>
                                          </li>
                                          <li>
                                                  <strong>Significant Savings: </strong>
                                               <span>Save up to 50% on insurance car repairs and reduce your expenses by up to 25% compared to dealership garages. Enjoy the best prices in {{$city}}, guaranteed!.</span>
                                          </li>
                                          <li>
                                             <strong>Instant Quotation: </strong>
                                               <span>Provide your vehicle details, and within just 2 hours, receive personalized insurance repair quotes for your {{$vehicle}}.</span>
                                          </li>
                                          <li>
                                             <strong>Inclusive Warranty: </strong>
                                               <span>Benefit from an inclusive warranty on service and spare parts, valid across {{$city}}. Plus, enjoy 2 years of unconditional warranty on paint when you choose Gativan.</span>
                                          </li>
                                          <li>
                                             <strong>Genuine Spares: </strong>
                                               <span>We exclusively use 100% genuine OEM and OES spares along with high-quality consumables for your car, ensuring top-notch quality.</span>
                                          </li>
                                     </ul>
                                     
                                        <Box className='blog-header'>
                                             <h3>Insurance Repair Services Offered:</h3>
                                             <p>Choose from a range of insurance repair services for your car in {{$city}}:</p>
                                        <ul className="why-choose-subheader">
                                          <li> 
                                               <span>Doorstep Windshield Replacement</span>
                                          </li>
                                          <li>
                                               <span>Free Doorstep Pick-up and Drop.</span>
                                          </li>
                                          <li>
                                               <span>24×7 Roadside Assistance & Towing (for accidental assistance).</span>
                                          </li>
                                     </ul>
                                        </Box>
                                        <Box className='blog-header'>
                                             <h3>Network Warranty Across {{$city}}:</h3>
                                             <Box className='blog-header-description'>
                                                  <p>Our warranty coverage extends city-wide, providing unrestricted protection across all Gativan garages in {{$city}}.</p>
                                             </Box>
                                        </Box>
                                        <Box className='blog-header'>
                                             <h3>Highly Equipped Workshops:</h3>
                                             <Box className='blog-header-description'>
                                                  <p>Each Gativan workshop is equipped with specialized dent-pulling tools, precision paint booths, and industry-certified mechanics skilled in operating automotive equipment.</p>
                                             </Box>
                                        </Box>
                                </Box>
                           </Box>
                                `;

  const bikePeriodicService = `
                                <Box className='blog-mainContainer'>
                                     <Box className="blog-servicesOffers">
                                     <ul className="why-choose-subheader">
                                               <li> 
                                                    <span>Regular bike maintenance is crucial to ensure a smooth and trouble-free bike ownership experience.</span>
                                               </li>
                                               <li>
                                                    <span>Vital components such as brake pads, tyres, and engine oil have finite lifespans and require periodic replacement.</span>
                                               </li>
                                               <li>
                                                    <span>Investing in routine maintenance helps reduce the overall cost of ownership, saving both time and money.</span>
                                               </li>
                                               <li>
                                                    <span>A well-maintained bike will perform better over the long term and maintain a higher resale value.</span>
                                               </li>
                                          </ul>
                                             <Box className='blog-header'>
                                                  <h3>{{$vehicle}} Bike Services Available:</h3>
                                                  <ul className="why-choose-subheader">
                                                       <li> 
                                                            <strong>Basic Periodic Service:</strong>
                                                            <span>Includes all necessary services to keep your bike in good working condition.</span>
                                                       </li>
                                                       <li>
                                                            <strong>Pro Periodic Service:</strong>
                                                            <span>Our most popular service package, offering the benefits of the essential package with additional services.</span>
                                                       </li>
                                                       <li>
                                                            <strong>Restart Service:</strong>
                                                            <span>Gativan's signature package, providing the overall bike servicing, ensuring your idle bike is restarted for its use.</span>
                                                       </li>
                                                  </ul>
                                             </Box>
                                             <Box className='blog-header'>
                                                  <h3>Standard OEM Equipments:</h3>
                                                  <Box className='blog-header-description'>
                                                       <p>At every Gativan workshop in {{$city}}, we utilize cutting-edge industry-standard bike service equipments. This includes specialized tools tailored to your vehicle's needs.</p>
                                                  </Box>
                                             </Box>
                                             <Box className='blog-header'>
                                                  <h3>Warranty on Car Services:</h3>
                                                  <Box className='blog-header-description'>
                                                       <p>When you choose Gativan, you benefit from the Gativan Advantage. Your {{$vehicle}} bike service is backed by our 15 days warranty policy, valid at any Gativan service center in {{$city}}.</p>
                                                  </Box>
                                             </Box>
                                     </Box>
                                </Box>
                                     `;

  const bikeBatteries = `
            <Box className='blog-mainContainer'>
                 <Box className="blog-servicesOffers">
                 <p>Gativan is your preferred destination for 100% genuine bike batteries, complete with the original manufacturer's warranty.</p>
                 <p>Here are the reasons to opt for Gativan when it comes to your battery requirements:</p>
                 <h3>The Significance of Bike Batteries for <span>{{$vehicle}}</span>:</h3>
                 <ul className="why-choose-subheader">
                      <li> 
                           <strong>Reliable Starting:</strong>
                           <span>A timely replacement ensures that the bike starts reliably, reducing the risk of being stranded due to a dead battery.</span>
                      </li>
                      <li>
                           <strong>Electrical System Performance:</strong>
                           <span>A new battery provides consistent power to the bike's electrical system, ensuring that lights, indicators, and other components function at their best.</span>
                      </li>
                      <li>
                           <strong>Safety:</strong>
                           <span>A well-maintained battery contributes to safer riding by preventing unexpected electrical failures while on the road.</span>
                      </li>
                      <li>
                           <strong>Longevity:</strong>
                           <span>Replacing the battery on schedule helps extend its lifespan, offering reliable performance over an extended period and saving costs on emergency replacements.</span>
                      </li>
            </ul>
              <Box className='blog-header'>
                   <h3>{{$vehicle}} Battery Services Available at Gativan:</h3>
                   <p>You can select from a range of battery services tailored to your needs:</p>
                   <ul className="why-choose-subheader">
                        <li> 
                             <strong>Doorstep Battery Replacement:</strong>
                             <span>Purchase a new bike battery from renowned battery manufacturers like Amaron, Exide and more.</span>
                        </li>
                        <li>
                             <strong>Pro Periodic Service:</strong>
                             <span>Our most popular service package, offering the benefits of the essential package with additional services.</span>
                        </li>
                        <li>
                             <strong>Battery Recharging Service:</strong>
                             <span>Benefit from Gativan's {{$vehicle}} battery recharging service, including Volts/Amperage checks and water/acid level inspections.</span>
                        </li>
                   </ul>
              </Box>
              <Box className='blog-header'>
                   <h3>Free Doorstep Battery Replacement:</h3>
                   <Box className='blog-header-description'>
                        <p>Enjoy FREE doorstep battery replacement, all from the comfort of your home. We exclusively stock branded bike batteries from reputable manufacturers such as Exide, Amaron and more. Our safe and efficient bike battery recharging stations ensure top-notch service in {{$city}}.</p>
                   </Box>
              </Box>
              <Box className='blog-header'>
                   <h3>Manufacturer Warranty on Bike Batteries:</h3>
                   <Box className='blog-header-description'>
                        <p>When you choose Gativan, you gain access to the Gativan Advantage. Take advantage of the manufacturer warranty on your new car battery purchase, redeemable nationwide and across {{$city}}.</p>
                   </Box>
               </Box>
       </Box>
    </Box>
                 `;

  return {
    periodicService,
    ACServiceAndRepair,
    batteries,
    wheelAndTyreCare,
    dentingAndPenting,
    detailing,
    cleaning,
    clutchAndFitments,
    insuranceClaim,
    bikePeriodicService,
    bikeBatteries,
  };
};

export default useWhyChooseGativan;
