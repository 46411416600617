import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { myContext } from "./MainSelection.Component";
import { Box, Grid, Button, Typography, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { getVehicleType } from "../../services/vehicleservices/vehicleservices";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getMobileNumber,
  handleBrandState,
  handleOpenModalState,
} from "../../action";
import httpCommon from "../../http-common";
import moment from "moment";
import gif from "./Gif Gativan Form.gif";

import ReactGA from "react-ga4";

import CircularProgress from "@mui/material/CircularProgress";
import { AppContext } from "../../App";
import SelectVehicle from "./SelectVehicle";
import MobileNumber from "./MobileNumber";

const LazyBrandSelection = lazy(() => import("./BrandSelection"));
const LazyModelSelection = lazy(() => import("./ModelSelection"));
const LazyVariantSelection = lazy(() => import("./VariantSeletion"));

// ReactGA.initialize("UA-202262670-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

export default function ConditionalSelction() {
  const [userMobile, setUserMobile] = useState();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [toggleVehicleBtn, setToggleVehicleBtn] = useState(1);
  const [loading, setLoading] = useState(true);

  const activeButton = {
    backgroundColor: "#7B8BD4",
    color: "#fff",
  };

  const deActiveButton = {
    backgroundColor: "#fff",
    color: "#000000",
  };
  const URL = httpCommon.defaults.baseURL;
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const contextData = useContext(myContext);
  const AppContextData = useContext(AppContext);

  const width = window.innerWidth;

  const {
    setBrandState,
    setModalOpen,
    vehicleTypeState,
    setVehicleTypeState,
    vehicleState,
    setVehicleState,
  } = contextData;

  const { customerPickData, setCustomePickData } = AppContextData;

  const BrandState = useSelector((state) => state.BrandReducer);

  const modelState = useSelector((state) => state.ModelReducer);

  const variantState = useSelector((state) => state.VariantReducer);

  const mobileState = useSelector((state) => state.MobileSectionReducer);

  const getVehicleInfo = async () => {
    try {
      const Response = await getVehicleType();
      const VehicleData = await Response.data;
      // if (VehicleData.statusCode === 200) {
      //     setVehicleTypes(VehicleData?.result?.reverse())
      //     setLoading(false)
      // }
      if (VehicleData?.result?.length > 0) {
        setVehicleTypes(VehicleData?.result?.reverse());
        setLoading(false);
      } else {
        // console.log("vehicle data " , VehicleData)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicleInfo();
    // setVehicleTypes(vehicleTypes?.reverse())
    dispatch(getMobileNumber({ mobileNumber: userMobile }));
  }, [userMobile]);

  // get vehicle selection data from redux
  const getReduxVehicleSelection = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  // console.log("redux vehicle selection data",getReduxVehicleSelection)

  //   --------if select button click then barnd Component show ------------------
  const handleShowBrand = () => {
    ReactGA.event({
      category: "Leads",
      action: "Clicked on Select Your Vehicle",
      label: "Select Vehicle",
    });
    // setBrandState(true)
    dispatch(handleBrandState(true));
    if (width < "768") {
      // setModalOpen(true)
      dispatch(handleOpenModalState(true));
    }
  };

  // ---------Mobile number handler------------------
  const MobileNumberChangehadle = (e) => {
    e.preventDefault();
    setUserMobile(e.target.value);
  };

  const handleMobileNumberClick = () => {
    ReactGA.event({
      category: "Leads",
      action: "Clicked on Enter Your Mobile Number",
      label: "Enter Your Mobile Number",
    });
  };

  //getting mobile number from redux
  const getMobileNumberRedux = useSelector(
    (state) => state.MobileNumberReducer
  );

  useEffect(() => {
    if (getMobileNumberRedux.mobileNumber) {
      setUserMobile(getMobileNumberRedux.mobileNumber);
    }
  }, [getMobileNumberRedux]);

  useEffect(() => {
    setToggleVehicleBtn(customerPickData.vehicleType.id);
    setVehicleTypeState(customerPickData.vehicleType.vehicleName);
    // setServiceCategoryId(1)
  }, [customerPickData]);

  // geting service from cart redux
  const servicesSelectionData = useSelector((state) => state.AddToCartReducer);

  // getting current location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);

  // getting selected vehicle from redux
  const vehicleSelectedData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  const webLink = `https://www.gativan.in/pune/${vehicleSelectedData?.vehicleType?.vehicleName?.toLowerCase()}/periodic-services/${vehicleSelectedData?.brand?.brandName
    ?.replaceAll(" ", "-")
    ?.toLowerCase()}/${vehicleSelectedData?.model?.modelName
    ?.replaceAll(" ", "-")
    ?.toLowerCase()}/${vehicleSelectedData?.variant?.variantName
    ?.replaceAll(" ", "-")
    ?.toLowerCase()}`;

  // ---------------------- WATI Integration Function -------------------------------------------------

  let date = +new Date();

  const whatsappMsg = (userMobile) => {
    let selectedVehicle = customerPickData.brand.brandName
      ? `${customerPickData.brand.brandName}, ${customerPickData.model.modelName}, ${customerPickData.variant.variantName}`
      : "";

    let option = {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
      },
      body: JSON.stringify({
        receivers: [
          {
            customParams: [
              { name: "1", value: selectedVehicle },
              { name: "2", value: moment(date).format("DD/MM/YYYY") },
              { name: "link", value: webLink },
            ],
            whatsappNumber: +("91" + userMobile),
          },
        ],
        broadcast_name: "Gativan",
        template_name: "message_to_leads_nov_2023",
      }),
    };
    const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";

    fetch(url, option)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  //--------------------------- post lead obj ---------------------------
  const postLead = {
    mobileNumber: getMobileNumberRedux?.mobileNumber,
    vehicleType: customerPickData?.vehicleType?.id,
    leadName: "",
    channel: 1,
    brandId: customerPickData?.brand?.id,
    modelId: customerPickData?.model?.id,
    variant: customerPickData?.variant?.id,
    city:currentLocation.cityName
  };

  //---------------------------------------------- getting Browser Id ----------------------

  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  const SaveDataWBrowser = async (visitorId) => {
    const userDataObj = {
      browserId: visitorId,
      vehicleType: vehicleSelectedData?.vehicleType?.id,
      brandId: vehicleSelectedData?.brand?.id,
      modelId: vehicleSelectedData?.model?.id,
      variantId: vehicleSelectedData?.variant?.id,
      mobileNumber: getMobileNumberRedux?.mobileNumber,
      cartItems: servicesSelectionData?.result,
    };

    const saveCart = await axios.post(`${URL}/cart`, userDataObj);
    const result = await saveCart.data;
  };

  // -------------------Check Price Button handler---------------------------

  const CheckPricehandle = async (e) => {
    if (!customerPickData.brand.brandName) {
      document.getElementById("selectbuttonValidation").style.display = "block"; //select vehicel validation is show
    }
    if (!userMobile) {
      document.getElementById("mobilevalidation").style.display = "block"; //mobile number validation is show
    }
    if (customerPickData.brand.brandName && !isNaN(userMobile)) {
      // dispatch(TakeVehicleData(customerPickData)) //all customer pick data store in redux
      // setCustomePickData({}) //All customer pick data empty
      // setUserMobile('') // empty mobile number field
      // setModalOpen(false) //hide modal component
      if (width < "768") {
        dispatch(handleOpenModalState(true));
      }

      document.getElementById("mobilevalidation").style.display = "none"; //mobile number validetion is hidde
      document.getElementById("selectbuttonValidation").style.display = "none"; //Select vahicel validetion is hidde

      // store customer pick data in redux
      // dispatch(getVehicleData(SelectVehicleData))

      //store service category id
      // vehicleSelectedData?.vehicleType?.id == 2 ? setServiceCategoryId(1) : setServiceCategoryId(13)

      // post lead information to backend
      const result = await axios.post(`${URL}/leads/save`, postLead);
      const response = await result.data;
      if (response.statusCode === 200) {
        navigation(
          `/${currentLocation.cityName.toLowerCase()}/${vehicleSelectedData?.vehicleType?.vehicleName.toLowerCase()}/periodic-services/${customerPickData?.brand?.brandName
            ?.replaceAll(" ", "-")
            .toLowerCase()}/${customerPickData?.model?.modelName
            ?.replaceAll(" ", "-")
            .toLowerCase()}/${customerPickData?.variant?.variantName
            ?.replaceAll(" ", "-")
            .toLowerCase()}`
        );
      }

      ReactGA.event({
        category: "Leads",
        action: "Clicked on Check Price",
        label: "Check Price",
      });

       whatsappMsg(userMobile); // WATI function call
      
      // call post api to save user data as per browser id
      SaveDataWBrowser(getBrowserIdRedux && getBrowserIdRedux);
    }
  };

  // ----------------Car and Bike Button Handler----------------------

  const CarBikeSelectionhandle = (vehicleType, Id) => {
    ReactGA.event({
      category: "Leads",
      action: `Clicked on ${vehicleType} in Check Prices Form`,
      label: "Check Price",
    });
    setVehicleTypeState(vehicleType); //data set in vehicle type
    setCustomePickData({
      ...customerPickData,
      vehicleType: { id: Id.toString(), vehicleName: vehicleType },
    }); //vehicle type store in customer Pick Data state
    setToggleVehicleBtn(Id);
    // dispatch(getVehicleData(customerPickData))
  };

  // --------------------  mobile view new D design ------------------
  const handleCheckPrice = () => {
    if (customerPickData?.variant?.variantName == "") {
      document.getElementById("select-vehicle-validation").style.display =
        "block";
    } else if (customerPickData?.variant?.variantName !== "") {
      document.getElementById("select-vehicle-validation").style.display =
        "none";
      navigation(
        `/${currentLocation.cityName.toLowerCase()}/${vehicleSelectedData?.vehicleType?.vehicleName.toLowerCase()}/periodic-services/${customerPickData?.brand?.brandName
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${customerPickData?.model?.modelName
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${customerPickData?.variant?.variantName
          ?.replaceAll(" ", "-")
          .toLowerCase()}`
      );
    }
  };

  const handleSelectVehicle = () => {
    ReactGA.event({
      category: "Leads",
      action: "Clicked from mobile on Select Your Vehicle ",
      label: "Select Vehicle",
    });
    if (width < "768") {
      setVehicleState(true);
      // setModalOpen(true)
      dispatch(handleOpenModalState(true));
    }
  };

  // useEffect(() => {
  //     dispatch(getVehicleData(customerPickData))
  // }, [customerPickData]);

  useEffect(() => {
    getVehicleInfo();
    setLoading(true);
  }, []);

  useEffect(() => {
    setCustomePickData({ ...customerPickData, mobileNumber: userMobile });
  }, [userMobile]);
  // conditional Rendering
  switch (true) {
    // case vehicleState:
    //   return <SelectVehicle />;
    case BrandState:
      // return <BrandSelection />
      return (
        <Suspense
          fallback={
            <CircularProgress sx={{ color: "#122164", marginLeft: "50%" }} />
          }
        >
          <LazyBrandSelection />
        </Suspense>
      );
    case modelState:
      // return <ModelSelection />
      return (
        <Suspense
          fallback={
            <CircularProgress sx={{ color: "#122164", marginLeft: "50%" }} />
          }
        >
          <LazyModelSelection />
        </Suspense>
      );
    case variantState:
      // return <VariantSeletion />
      return (
        <Suspense
          fallback={
            <CircularProgress sx={{ color: "#122164", marginLeft: "50%" }} />
          }
        >
          <LazyVariantSelection />
        </Suspense>
      );
    case mobileState:
      return <MobileNumber wati={whatsappMsg} />;
    default:
      return width < "768" ? (
        <Box>
          <Box>
            <Grid
              container
              className="select-vehicle"
              onClick={() => handleShowBrand()}
            >
              <Grid item xs={11}>
                <span >Select Your Vehicle</span>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="end">
                <img src={gif} alt="" />
              </Grid>
            </Grid>
            <Typography
              variant="span"
              id="select-vehicle-validation"
              ml={2}
              style={{ color: "red", display: "none" }}
            >
              * Please Select Vehicle.
            </Typography>
          </Box>
          <Box className="check-price-button-container">
            <Button onClick={handleCheckPrice} variant="contained">
              Check Prices For Free
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className="selection-sections">
          <Grid container className="Selection-header-Buttons">
            {loading ? (
              <Grid
                item
                xs={6}
                className="toggle-skeleton"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Skeleton
                  variant="rectangular"
                  className="toggle-skeleton-first"
                />
                <Skeleton
                  variant="rectangular"
                  className="toggle-skeleton-first"
                />
              </Grid>
            ) : (
              "" //02-08-2024 Bike code commented Aditya
              // vehicleTypes.map((item) => {
              //   const { id, vehicleType, url } = item;
              //   return (
              //     <Grid key={id} item xs={6}>
              //       <Button
              //         id="vehicle-btns"
              //         style={
              //           toggleVehicleBtn == id ? activeButton : deActiveButton
              //         }
              //         onClick={() => {
              //           CarBikeSelectionhandle(vehicleType, id);
              //         }}
              //       >
              //         <img src={`${URL}${url}`} alt="" /> {vehicleType}
              //       </Button>
              //     </Grid>
              //   );
              // })
            )}
          </Grid>

          <Box className="checkPrice_section">
            {loading ? (
              <Box>
                <Skeleton
                  variant="rectangular"
                  className="select-vehicle-skeleton"
                />
              </Box>
            ) : (
              <Box>
                <Grid
                  container
                  className="Box"
                  onClick={() => handleShowBrand()}
                >
                  <Grid item xs={11}>
                    <Box id="modal-title">
                      {" "}
                      {/* modal-title to handle modal in Mobile View */}
                      {
                        //if pick data cusomer then show here....
                        customerPickData.brand.brandName ? (
                            <bold style={{ fontWeight: "800" }}>
                            {customerPickData.brand.brandName !== undefined ? `${customerPickData.brand.brandName}, ` : ""}
                            {customerPickData.model.modelName !== undefined ? `${customerPickData.model.modelName}, ` : ""}
                            {customerPickData.variant.variantName !== undefined ? `${customerPickData.variant.variantName}` : ""}
                          </bold>
                        ) : (
                          <span style={{ cursor: "pointer" }}>Select Your {vehicleTypeState}</span>
                        )
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={1} display="flex" justifyContent="end">
                    {/* <KeyboardArrowDownIcon /> */}
                    <img src={gif} alt="" />
                  </Grid>
                </Grid>
                <Typography
                  variant="span"
                  id="selectbuttonValidation"
                  mt={1}
                  style={{ color: "red", display: "none"  }}
                >
                  Please Select Your {vehicleTypeState}.
                </Typography>
              </Box>
            )}

            {loading ? (
              <Box>
                <Skeleton
                  variant="rectangular"
                  className="select-vehicle-skeleton"
                />
              </Box>
            ) : (
              <Box>
                <Box className="Box mobile-number" id="mobile-number">
                  <span>+91</span>
                  <input
                    type="tel"
                    onChange={MobileNumberChangehadle}
                    onClick={handleMobileNumberClick}
                    value={userMobile}
                    pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                    maxLength="10"
                    title="10 digit code"
                    required
                    mt={2}
                    placeholder="Enter Your Mobile Number"
                  />
                </Box>
                <Typography
                  variant="span"
                  id="mobilevalidation"
                  mt={1}
                  style={{ color: "red", display: "none" }}
                >
                  Please Enter Your Mobile Number.
                </Typography>
              </Box>
            )}

            {/* <Link to='/url' className="check-price-link" > */}
            {loading ? (
              <Box>
                <Skeleton
                  variant="rectangular"
                  className="select-vehicle-skeleton"
                />
              </Box>
            ) : (
              <Button
                onClick={CheckPricehandle}
                className="Box checkPrice-button"
                variant="contained"
              >
                Check Price For Free
              </Button>
            )}

            {/* </Link> */}

            <Grid
              container
              mt={2}
              display="flex"
              alignItems="end"
              height="auto"
            >
              {loading ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    className="rating-skeleton"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={6}
                  className="bottumLeft"
                  onClick={() => {
                    window.location.href =
                      "https://www.google.com/search?q=GATIVAN&rlz=1C1CHBF_enIN1001IN1001&oq=GATIVAN+&aqs=chrome..69i57j69i59j35i39j69i60l4j69i65.1952j0j9&sourceid=chrome&ie=UTF-8#lrd=0x3bc2c3244a1fed11:0x8f1dcf07ec2ce7ca,1";
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h4"
                      color="#5BAA41"
                      className="rating"
                      fontWeight="bold"
                    >
                      <h4  style={{ cursor: "pointer" }}>
                        4.4/<font>5</font>
                      </h4>
                    </Typography>
                    <Box>
                      <StarRateIcon style={{ color: "#FFCE31" }} />
                      <StarRateIcon style={{ color: "#FFCE31" }} />
                      <StarRateIcon style={{ color: "#FFCE31" }} />
                      <StarRateIcon style={{ color: "#FFCE31" }} />
                      <StarHalfIcon style={{ color: "#FFCE31" }} />
                    </Box>
                    <Box mt={1}>
                      <Typography
                        className="description"
                        variant="p"
                        style={{ fontSize: "16px",cursor: "pointer"  }}
                      >
                        Based on 10,000+
                      </Typography>
                      <br />
                      <Typography
                        className="description"
                        variant="p"
                        style={{ fontSize: "16px",cursor: "pointer"  }}
                      >
                        Genuine Customers
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}

              <Grid item xs={1} style={{ height: "120px" }}>
                <h2 className="vertical_line"> </h2>
              </Grid>
              {loading ? (
                <Grid item xs={5}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    className="rating-skeleton"
                  />
                </Grid>
              ) : (
                <Grid item xs={5} className="bottumRight">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="cenetr"
                    justifyContent="center"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/homepage/check price.png"
                      }
                      alt=""
                    />
                    <Typography
                      className="description"
                      mt={1}
                      variant="p"
                      style={{ fontSize: "16px" ,cursor: "default" }}
                    >
                      Over 50+ Car &
                    </Typography>
                    <Typography className="description" variant="p"    style={{cursor: "default" }}>
                      {" "}
                      Bike Services
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      );
  }
}