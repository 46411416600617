import React from 'react';
import './Otp.css';
import { Box, TextField, Button , Grid } from "@mui/material";
export default function Otp() {
  return (
 <>
   <Grid container spacing={2}>
        {/*------------image conatiner------------*/}
        <Grid item xs={12} md={7} sm={12}>
            <Box className="img-container">
            <Box className='back-to-booking'>Back to booking </Box>
              <img  className="login-background" src={ process.env.PUBLIC_URL + "/Assets/login/loginbackgroung.png"}/>
              <img className="gativan-logo" src={ process.env.PUBLIC_URL + "/Assets/login/GativanFullLogoWhite.png"} />
              <Box className="social-media-logo">
                <Box className="facebook-logo">
                  <img src={process.env.PUBLIC_URL + "/Assets/login/facebook.png"} />
                </Box>
                <Box className="twitter-logo">
                  <img src={process.env.PUBLIC_URL + "/Assets/login/Twitter.png"} />
                </Box>
                <Box className="instagram-logo">
                  <img src={process.env.PUBLIC_URL + "/Assets/login/Instagram.png"} />
                </Box>
              </Box>
            </Box>
        </Grid>
        {/*------------OTP form container-----*/}
        <Grid item xs={12} md={5} sm={12} className="otp-form-container">
          <Box className="otp-form">
            <h4>Verification Code</h4>
            <h5>OTP has been sent to your mobile number please verify.</h5>
            <Box className="text-field-otp">
              <TextField className="enter-otp"></TextField>
               <TextField className="enter-otp"></TextField>
               <TextField className="enter-otp" ></TextField>
               <TextField className="enter-otp" ></TextField>
            </Box>
            <h6>Resend in 23 Sec</h6>
            <Button className="verify-btn">Verify</Button>
          </Box>
        </Grid>
      </Grid>
 </>
  )
}
