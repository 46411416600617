import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material'
import "./Home.css"
import "./ServicePriceListTable.css"
export default function ServiceTable(props) {
    return (
        <>
        
         <Box className="table-container">
            <Table className='Serviceprice_table' >
                <TableHead className='Serviceprice_Heading'>
                    <TableRow className='Serviceprice_Heading' >
                        <TableCell align="left" className='price-table-header' style={{ color: '#fff' }}>Service Type</TableCell>
                        <TableCell align="left" className='price-table-header' style={{ color: '#fff' }}>Authorized Service Center Price </TableCell>
                        <TableCell align="left" className='price-table-header' style={{ color: '#fff' }}>Gativan Starting Price</TableCell>
                        <TableCell align="left" className='price-table-header' style={{ color: '#fff' }}>Savings</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='Serviceprice_tabledata' style={{ color: 'white' }}>
                    {props.Data.map((row,index) => {
                        const { servicetype, Authorized, GativanStartingPrice, Savings } = row
                        return (
                            <TableRow key={index} style={{ color: 'white' }} key={row.servicetype} >
                                <TableCell className='price-table-body' component="th" scope="row" >{servicetype}</TableCell>
                                <TableCell className='price-table-body' align="left">{Authorized}</TableCell>
                                <TableCell className='price-table-body' align="left">{GativanStartingPrice}</TableCell>
                                <TableCell className='price-table-body' align="left">{Savings}</TableCell>

                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </Box>
        </>
       

    )
}
