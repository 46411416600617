import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import "./ViewCartFooter.css";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactGA from "react-ga4";
import { StoreCartTotal, addToCart } from "../../../action";
import ViewDetailedBill from "../../viewDetailedBill/ViewDetailedBill";

export default function ViewCartFooter({
  buttonName,
  handlePayNow,
  youPayAmount,
  postData,
  serviceData,
  serviceCategoryType,
  openDetailedBillModal,
}) {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const contextData = useContext(AppContext);
  const { selectPaymentMethod, isCheckOut, setIsCheckOut } = contextData;
  const dispatch = useDispatch();

  const servicesSelectionData = useSelector((state) => state.AddToCartReducer);
  const { subTotal, couponDiscount, cartTotal, cartItems, payNow } =
    useSelector((state) => state.CartTotalReducer);

  // let cartTotal = 0;
  // let cartItems = 0;
  // servicesSelectionData.result.map((item, index) => {
  //   cartItems = index + 1;
  //   cartTotal += parseInt(item.displayPrice);
  // });

  // useEffect(() => {
  //   const TotalPrice = servicesSelectionData?.result?.reduce(
  //     (acc, ele) => acc + ele?.displayPrice,
  //     0
  //   );

  //   if (TotalPrice) {
  //     const obj = {
  //       subTotal: Math.round(TotalPrice),
  //       // couponDiscount: Math.round(couponDis) || 0,
  //       cartTotal: Math.round(TotalPrice) || 0,
  //       cartItems: servicesSelectionData?.result?.length,
  //       // payNow: Math.round(youPayAmount),
  //     };
  //     dispatch(StoreCartTotal(obj));
  //   }
  // }, [servicesSelectionData]);

  // getting current location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);

  const ServiseReduxData = useSelector((state) => state.AddToCartReducer);

  const reduxVehicleData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  const handelViewCart = () => {
    if (buttonName == "View Cart") {
      ReactGA.event({
        category: "View Cart",
        action: "Click on View Cart",
        label: "View Cart",
      });
      navigate("/viewcart");
    } else if (buttonName == "Checkout") {
      ReactGA.event({
        category: "Checkout",
        action: "Clicked on Checkout",
        label: "Checkout",
      });
      navigate("/checkout");
    } else if (buttonName === "Pay Now") {
      ReactGA.event({
        category: "Payment",
        action: "Clicked on Pay",
        label: "Pay",
      });
      handlePayNow();
      localStorage.clear();
    } else if (buttonName === "Add Service" || buttonName === "Added") {
      const location = currentLocation.cityName.toLowerCase();
      const found = ServiseReduxData.result.some(
        (e) => e.serviceId === serviceData.serviceId
      );
      if (!found) {
        // serviceData.addServiceToCart = "Added"

        dispatch(addToCart(serviceData));
        // setIsCheckOut(false)
      }
      navigate(
        `/${location}/${reduxVehicleData?.vehicleType?.vehicleName
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${serviceCategoryType
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${reduxVehicleData?.brand?.brandName
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${reduxVehicleData?.model?.modelName
          ?.replaceAll(" ", "-")
          .toLowerCase()}/${reduxVehicleData?.variant?.variantName
          ?.replaceAll(" ", "-")
          .toLowerCase()}`
      );
    }
  };

  return (
    <Box className="viewcart-footer" onClick={!isMobile && handelViewCart}>
      <Box className="viewcart-footer-total-items">
      {buttonName === "Pay Now" ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <h6>
              <CurrencyRupeeIcon className="footer-total-rupee-icon-view-cart" />
              50
            </h6>
            <Typography
              role="link"
              className="view-detailed-bill-link"
              onClick={openDetailedBillModal}
            >
              View Detailed Bill
            </Typography>
          </Box>
        ) : (
          <Box>
            <span className="added-items">
              {cartItems} {cartItems > 1 ? "ITEMS" : "ITEM"}
            </span>
            <h6>
              <CurrencyRupeeIcon className="footer-total-rupee-icon-view-cart" />
              {subTotal}{" "}
            </h6>
          </Box>
        )}
      </Box>
      <Box className="viewcart-footer-total-items-dekstop">
        <h6>{buttonName} (For UPI Payment's Only)</h6>
      </Box>
      <Box className="viewcar-footer-checkout-btn">
        <Button
          className="checkout-button"
          onClick={isMobile && handelViewCart}
        >
          {buttonName}{" "}
          <ArrowForwardIcon className="right-arrow" color="white" />
        </Button>
        {/* <Button className="checkout-button" onClick={handelViewCart}>
          {buttonName}
        </Button> */}
        <h6 className="viewcart-footer-youpay">
          <CurrencyRupeeIcon className="rupee-icon" /> {youPayAmount}
        </h6>
      </Box>
    </Box>
  );
}
