import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  InputBase,
  Modal,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  IconButton,
  Stack,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import puneImage from "./cityImages/Pune.png";
import mumbaiImage from "./cityImages/Mumbai.png";
import naviMumbaiImage from "./cityImages/NaviMumbai.png";
import thaneImage from "./cityImages/Thane.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { chooseLocation, handleChangeVehicelComponent } from "../action";

import {
  getCities,
  getDistrict,
} from "../../src/services/vehicleservices/vehicleservices";
import axios from "axios";
import httpCommon from "../http-common";
import { useNavigate, useParams } from "react-router-dom";
const CityModal = ({ modelState }) => {
  const { isModalOpen, setIsModalOpen } = modelState;
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [showCities, setShowCities] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const currentLocation = useSelector((state) => state.LocationReducer);

  const currentLocation = useSelector((state) => state.LocationReducer);

  // const location = currentLocation.cityName?.toLowerCase().replace(/ /g, "-");

  const browserData = useSelector((state) => state.BrowserUserData);

  const [SelectCity, setSelectCity] = useState({
    // cityId: 1,
    // cityName: browserData.city,
    cityName: "Pune",
  });

  const cities = [
    { name: "Pune", image: puneImage },
    { name: "Mumbai", image: mumbaiImage },
    { name: "Navi Mumbai", image: naviMumbaiImage },
    { name: "Thane", image: thaneImage },
  ];

  const navigate = useNavigate();

  // getting browserId from redux
  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(true);
    setFilteredCities(cities);
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filteredCities = cities.filter((city) =>
      city.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCities(filteredCities);
  };

  const dispatch = useDispatch();

  const getDistrictInfo = async () => {
    try {
      const request = await getDistrict();
      const response = await request.data;
      setShowCities(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const URL = httpCommon.defaults.baseURL;

  useEffect(() => {}, [currentLocation]);

  useEffect(() => {}, [SelectCity]);

  useEffect(() => {
    getDistrictInfo();
  }, []);

  // Function to handle city selection
  const handleCitySelection = (selectedCityName) => {
    dispatch(chooseLocation({ cityName: selectedCityName }));
    localStorage.setItem(
      "cityName123",
      selectedCityName?.replaceAll(" ", "-")?.toLocaleLowerCase()
    );
    handleCloseModal();
  
    // Check if the current URL contains 'garage-near-me' parameter
    const currentURL = window.location.href;
    const containsGarageNearMe = currentURL.includes("/garage-near-me");
  
    // If 'garage-near-me' parameter is present, navigate without it. Otherwise, just navigate to the selected city.
    if (containsGarageNearMe) {
      navigate(`/${selectedCityName.replaceAll(" ", "-").toLocaleLowerCase()}`);
    }
  };
  

  const capitalizeFirstLetter = (str) => {
    return str.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
  
  };


  
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="city-modal-title"
        aria-describedby="city-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            width: isMobile ? "90%" : 700, // Width adjusted for desktop
            maxWidth: isMobile ? "90vw" : 800, // Increased maxWidth for desktop
            maxHeight: "90vh",
            p: isMobile ? 3 : 4, // Padding adjusted for desktop
            textAlign: "left",
            borderRadius: 4,
            overflowY: "auto",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"flex-start"} // Center content for mobile
            mb={isMobile ? 2 : 3}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                marginRight: isMobile ? "14px" : 2,
                marginBottom: isMobile ? "8px" : 2,
                display: "inline-flex",
                marginLeft: isMobile ? "-12px" : 0, // Adjust the marginLeft here
              }}
            >
              <ArrowBackIcon fontSize={isMobile ? "default" : "large"} />
            </IconButton>

            <Typography
              variant={isMobile ? "h5" : "h4"}
              gutterBottom
              id="city-modal-title"
              sx={{
                fontWeight: 700,
                letterSpacing: "0.16px",
                textAlign: isMobile ? "center" : "left",
                color: "#787878",
                marginLeft: isMobile ? "-3%" : "0",
                fontSize: isMobile ? "20px" : "28px",
              }}
            >
              Select Your City
            </Typography>
          </Stack>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: isMobile ? "1rem" : "1.5rem", // Gap adjusted for desktop
              marginBottom: isMobile ? "0.5rem" : "1.5rem",
              marginTop: isMobile ? "2.5rem" : "3rem", // Top margin adjusted for desktop
            }}
          >
         <Grid container justifyContent="flex-start" spacing={isMobile ? 0 : 2}>
  {showCities
    .filter((city) => city.city !== "Bangalore")
    .map((city, index) => (
      <Grid item key={index} xs={4} sm={4} md={4}>
        <Box
          key={index}
          sx={{
    textAlign: "center",
    width: "100%",
    marginBottom: isMobile ? "1rem" : "1.5rem",
    cursor: "pointer",
    paddingRight: isMobile ? "12px" : "16px", // Adjust the padding here
  }}
          onClick={() => handleCitySelection(city.city)}
        >
          {/* Wrap both Avatar and Button inside a div for better alignment */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              sx={{
                width: isMobile ? 50 : 50,
                height: isMobile ? 50 : 50,
                margin: "0 auto",
                backgroundColor: "primary.main",
                color: "primary.contrastText",
              }}
              alt={city.city}
              src={
                cities.find((item) => item.name === city.city)?.image || ""
              }
            />
<Button
  variant="outlined"
  sx={{
    color: "#282828",
    borderColor: "transparent",
    justifyContent: "center", // Center the text content
    textTransform: "none",
    fontSize: isMobile ? 14 : 15,
    whiteSpace: "nowrap",
    marginTop: "0.5rem", // Adjust spacing between Avatar and text
    width: "100%", // Add this line to set a fixed width
  }}
>
  {capitalizeFirstLetter(city.city)}
</Button>

          </div>
        </Box>
      </Grid>
    ))}
</Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CityModal;
