import React, { useState, useRef, useEffect } from 'react';
import "./SlideMenu.css"
import httpCommon from '../../../../http-common';

const URL = httpCommon.defaults.baseURL

const MenuItem = ({ isActive, label, image_url }) => (
    <div className={`menu-item ${isActive ? 'active' : ''}`} style={{ height: "150px", width: "100%", textAlign:"center" }}>
        <div container className="service-image-container" style={{ height: "12vh", width: "27vw" }}>
            <img src={`${URL}/${image_url}`} />
        </div>
        {label}
    </div>
);

const HorizontalMenu = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newIndex = (activeIndex + 1) % items.length;
            setActiveIndex(newIndex);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [activeIndex, items.length]);

    const handleScroll = () => {
        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();
        const menuItems = container.children;

        let centerIndex = -1;
        for (let i = 0; i < menuItems.length; i++) {
            const itemRect = menuItems[i].getBoundingClientRect();
            if (itemRect.left + itemRect.width / 2 > containerRect.left + containerRect.width / 2) {
                centerIndex = i;
                break;
            }
        }

        if (centerIndex !== -1 && centerIndex !== activeIndex) {
            setActiveIndex(centerIndex);
        }
    };

    return (
        <div className="menu-container" ref={containerRef} onScroll={handleScroll} style={{ display: "inline-flex" }}>
            {items.map((item, index) => (
                <MenuItem key={index} label={item.category} image_url={item.image_url} isActive={index === activeIndex} />
            ))}
        </div>
    );
};

export default HorizontalMenu;