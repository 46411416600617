import React, { useEffect } from 'react'

export default function ErrorPage() {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
useEffect(()=>{
  scrollToTop()
},[])

  return (
    <div style={{textAlign:"center", width:"100%"}}>
        <h1>404</h1>
        <h3>Page Not Found</h3>
        <scrollToTop/>
    </div>
  )
}
