import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import httpCommon from "../../http-common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./ShareLeadCard.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getLeadById } from "../../services/vehicleservices/vehicleservices";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./PDF";

const ShareLeadCard = () => {
  const theme = useTheme();
  const belowTablets = useMediaQuery(theme.breakpoints.down("sm"));
  const LeadCardReducer = useSelector((state) => state.LeadCardReducer);
  const baseURL = httpCommon.defaults.baseURL;
  const [lead, setLead] = useState({});
  const [error, setError] = useState(null);

  const { leadId } = useParams();

  const getLead = async (leadId) => {
    try {
      const response = await getLeadById(leadId);
      const data = await response.data;
      setLead(data.result);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getLead(leadId?.toUpperCase());
  }, [leadId]);

  useEffect(() => {
    console.log("leadDetails", lead);
  }, [lead]);

  const downloadLeadCard = () => {
    alert("Lead Card!");
  };

  return (
    <Box className="share-lead-card-container">
      {error && (
        <Typography className="error">
          {error?.response?.data?.message}
        </Typography>
      )}

      <Box className="share-lead-container">
        <Box className="card-header-section">
          <Typography className="heading">Lead Card</Typography>
          <Typography className="logo">
            <img
              // src={`${process.env.PUBLIC_URL}/Assets/logos/GativanFullLogoWhite.png`}
              src={process.env.PUBLIC_URL + "/Assets/images/GativanLogo.png"}
              alt="Gativan Logo"
            />
          </Typography>
        </Box>
        <Box className="user-section">
          <Grid container spacing={2} sx={{ marginLeft: 0 }}>
            <Grid item xs={12} sm={6} className="grid-user-col">
              <Typography className="user-name">
                {lead?.leadName || "Customer Name: ---"}
              </Typography>
              <Typography className="user-value">
                {`${lead?.brand?.brand || "Brand: ---, "} ${
                  lead?.model?.model || "Model: ---, "
                } ${lead?.variant?.variantName || "Variant: ---"}`}

                {/* {"  "}
                {" "} */}
              </Typography>
              <Typography className="user-value">
                {lead?.vehicleNumber || "Vehicle Number: ---"}
              </Typography>
              <Typography className="user-label">Contact Number</Typography>
              <Typography className="user-value">
                {lead?.mobileNumber || "---"}
              </Typography>
              <Typography className="user-label">Customer Address</Typography>
              <Typography className="user-value">
                {lead?.city?.city || "---"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="grid-vehicle-col">
              <img
                src={`${baseURL}${lead?.model?.imageUrl}`}
                alt="Vehicle Image"
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="sub-header-section">
          <Typography className="sub-heading">Jobs</Typography>
          <Typography className="sub-logo">
            <img
              src={process.env.PUBLIC_URL + "/Assets/images/logo.png"}
              alt=""
            />
          </Typography>
        </Box>
        <Box className="services-section">
          <Box className="services-list">
            {lead?.servicesList?.length > 0 ? (
              lead?.servicesList?.map((service) => {
                return (
                  <Box key={service?.id}>
                    <Typography className="service-title">
                      {service?.service}
                    </Typography>
                    <Divider className="divider" />
                    <Box className="activity-wrapper">
                      {service?.activities?.map((activity, index) => {
                        return (
                          <Box className="activities-box" key={activity?.id}>
                            <Typography className="activities">
                              <span className="sr-no">{index + 1} </span>
                              {activity?.activity}
                            </Typography>
                            <CheckCircleIcon className="check-icon" />
                          </Box>
                        );
                      })}
                    </Box>
                    <Box className="sub-total-wrapper">
                      <Typography className="sub-total-heading">
                        Sub Total
                      </Typography>
                      <Typography className="sub-total-amount">
                        <CurrencyRupeeIcon className="rupee-icon" />{" "}
                        {service?.displayPrice}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Typography>No Service</Typography>
            )}
          </Box>
        </Box>
        <Box className="total-container">
          <Divider className="total-divider" />
          <Box className="total-wrapper">
            <Typography className="total-heading">Total</Typography>
            <Typography className="total-amount">
              <CurrencyRupeeIcon className="rupee-icon" />{" "}
              {lead?.totalPrice || 0}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="share-lead-card-container">
        {/* <Button
          variant="contained"
          className="share-lead-card-button"
          onClick={downloadLeadCard}
        >
          Download
        </Button> */}

        <PDFDownloadLink
          document={<PDF lead={lead} />}
          fileName={`${lead?.leadName || null}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button className="share-lead-card-button">
              {loading ? "Loading..." : "Download"}
            </Button>
          )}
        </PDFDownloadLink>
      </Box>
    </Box>
  );
};

export default ShareLeadCard;
