import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ServiceContext } from '../VehicleServiceItems'
import ServiceCard from './ServiceCard'
import './Services.css'



export default function Services({ viewFooterState, servicesData, subCategoriesData, subCategoriesWithPrice, selectVehicleCategory, vehicleSelectedData, cartModal }) {

  const { location, vehicleType, serviceCategoryType, brand, model, variant } = useParams();


  let variantId = vehicleSelectedData.variant.id;





  return (
    <Box>
      {/* -----------------------Periodic Packages--------------------- */}
      {(variant? subCategoriesWithPrice  : subCategoriesData).map((item, index) => {
        const { subCategoryId, subCategory, service, categoryId } = item

        if (service.length == 0) {
          return '';
        }
        else if (service.length != 0) {
          return (
            <Box key={index} className='periodic-packages'>
              <h1>{subCategory}</h1>
              {/* <h1>Periodic Packages</h1> */}
              {service.map((item) => {
                const { serviceId, service, subCategoryId, imagePath } = item
                return (
                  <ServiceCard serviceData={item} vehicleSelectedData={vehicleSelectedData} cartModal={cartModal} />
                )
              })}
            </Box>
          )
        }
      })}

      {/* ----------------------Additional jobs------------------------ */}
      {/* <Box className='additional-jobs'>
        <h1>Additional Jobs</h1>
        <ServiceCard />
      </Box> */}
    </Box>
  )
}
