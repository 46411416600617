import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useHistoryNavigation = () => {
  const navigate = useNavigate();
  const [shouldGoBack, setShouldGoBack] = useState(false);

  useEffect(() => {
    const handlePopstate = (event) => {
      if (!shouldGoBack) {
        setShouldGoBack(true);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [shouldGoBack]);

  useEffect(() => {
    if (shouldGoBack) {
      // Redirect to a specific URL when shouldGoBack is true
      navigate(-1);
      setShouldGoBack(false); // Reset shouldGoBack after navigating
    }
  }, [navigate, shouldGoBack]);

  return {
    triggerGoBack: () => setShouldGoBack(true)
  };
};

export default useHistoryNavigation;
