import React from 'react'
import './GativanExperts.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Grid, Card, CardContent } from "@mui/material";
import StarRateIcon from '@mui/icons-material/StarRate';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import ibrahimBaudiwale from "./gativanExpertsImages/ibrahimBaudiwale.jpeg";
import ashutoshVyavahare from "./gativanExpertsImages/ashutoshVyavahare.jpeg";
import krushnaGatte from "./gativanExpertsImages/krushnaGatte.jpg";
import mayurMankar from "./gativanExpertsImages/mayurMankar.jpeg";
import shyamnathSonigara from "./gativanExpertsImages/shyamnathSonigara.jpeg"

export default function GativanExperts() {

    const gativanExpertsData = [
        {
            id: 1,
            name: "Shyamnath Sonigara",
            image: `${shyamnathSonigara}`,
            specialization: "Premium Bike service",
            experience: "9 Years",
            city: "Pune",
            servicingdone: "6000+"
        },
        {
            id: 2,
            name: "Ashutosh Vyavahare",
            image: `${ashutoshVyavahare}`,
            specialization: "Premium Multi-Brand Car Service",
            experience: "5 Years",
            city: "Pune",
            servicingdone: "5000+"
        },
        {
            id: 3,
            name: "Mayur Mankar",
            image: `${mayurMankar}`,
            specialization: "Car Service",
            experience: "5+ Years",
            city: "Pune",
            servicingdone: "3000+"
        },
        {
            id: 4,
            name: "Krushna Gatte",
            image: `${krushnaGatte}`,
            specialization: "Car Service",
            experience: "3+ Years",
            city: "Pune",
            servicingdone: "2000+"
        },
    ]

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
        },
    };




    return (
        <>
            <Box container>
                <Box container className="homepage-headers">
                    <h2>Gativan Experts</h2>
                </Box>
                <Carousel
                    // infinite
                    partialVisible={false}
                    arrows
                    responsive={responsive}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    containerClass="container"
                    className="Carousel"
                    additionalTransfrom={0}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    renderDotsOutside
                    slidesToSlide={1}
                    swipeable
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    showDots
                    sx={{ margin: "0" }}
                >
                    {gativanExpertsData.map((item) => {
                        const { id, name, image, specialization, experience, city, servicingdone } = item
                        return (
                            <Box container className="gativan-experts-container" key={id}>
                                <Box container className="gativan-experts-profile-detail-container">
                                    <Box container className="gativan-experts-profile-container">
                                        <img src={image} />
                                    </Box>
                                    <Box container className="gativan-experts-name-container" >
                                        <h2>{name}</h2>
                                        <Box container className="rating-section">
                                            <StarRateIcon style={{ color: '#FFCE31', fontSize:'18px' }} />
                                            <StarRateIcon style={{ color: '#FFCE31', fontSize:'18px' }} />
                                            <StarRateIcon style={{ color: '#FFCE31', fontSize:'18px' }} />
                                            <StarRateIcon style={{ color: '#FFCE31', fontSize:'18px' }} />
                                            <StarHalfIcon style={{ color: '#FFCE31', fontSize:'18px' }} />
                                        </Box>
                                        <Box container className="gativan-experts-details" >
                                            <ul style={{"list-style-type":"disc"}} >
                                                <li>{`Specialization : ${specialization}`}</li>
                                                <li>{`Experience : ${experience}`}</li>
                                                <li>{`City : ${city}`}</li>
                                                <li>{`Servicing done : ${servicingdone}`}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box container className="gativan-experts-description">
                                    {/* <Box container className="rating-section">
                                        <StarRateIcon style={{ color: '#FFCE31' }} />
                                        <StarRateIcon style={{ color: '#FFCE31' }} />
                                        <StarRateIcon style={{ color: '#FFCE31' }} />
                                        <StarRateIcon style={{ color: '#FFCE31' }} />
                                        <StarHalfIcon style={{ color: '#FFCE31' }} />
                                    </Box> */}
                                    {/* <Box container className="rating-section">
                                        <StarRateIcon style={{ color: '#122164' }} />
                                        <StarRateIcon style={{ color: '#122164' }} />
                                        <StarRateIcon style={{ color: '#122164' }} />
                                        <StarRateIcon style={{ color: '#122164' }} />
                                        <StarHalfIcon style={{ color: '#122164' }} />
                                    </Box> */}
                                    {/* <p>Mayank Vishwakarma is a very genuine and helpful service buddy. He always makes sure that the customers have no problem or any issues left with their vehicle.</p> */}
                                </Box>
                            </Box>
                        )
                    })}


                </Carousel>
            </Box>


        </>
    )
}
