import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./DownloadApp.css";
export default function DownloadApp() {
  return (
    <>
      <Box container className="homepage-headers">
        <h2>Download the App</h2>
      </Box>
      <Box className="download-app-container">
        <Grid container>
          <Grid item sm={8} position="relative" >
            <Box className="blog-header">
            <h4 style={{
  color: '#122164',
  fontFamily: "AvenirBold",
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  letterSpacing: '0.34px'
}}>Scan & Download Gativan App!</h4>

            </Box>
            <Box className="blog-header-description" >
            <p style={{
  color: '#000',
  fontFamily: "AvenirRegular",
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.26px',
 
}}>
  Open the camera on your phone and scan the QR code below to download Gativan App.
</p>

            </Box>
            <Box className="barCodeAndGooglePlay" pb={5} >
              <Box className="barCode">
                <img
                  src={process.env.PUBLIC_URL + "/Assets/homepage/Gativan Android App.png"}

                  alt=""
                />
              </Box>
              <Button
                className="googlePlay"
                href="https://play.google.com/store/apps/details?id=com.KStudio.Gativan&hl=mr&gl=US"
                target="_blank"
              >
                <img
                
                  src={
                  
                    process.env.PUBLIC_URL + "/Assets/homepage/GoogleButton.png"
                  }
                  alt=""
                />
              </Button>
            </Box>
          </Grid>
          <Grid item sm={4} xs={12} >
            <Button
              className="gativanApp"
              href="https://play.google.com/store/apps/details?id=com.KStudio.Gativan&hl=mr&gl=US"
              target="_blank"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/homepage/Insert Content Here.png"
                }
                alt=""
                style={{ maxWidth: '98%', height: 'auto', borderTopLeftRadius: '7%', borderTopRightRadius: '7%' }}


              />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
