
import { Box, Drawer, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse, } from '@mui/material'
import React, { useState } from 'react'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './Policies.css'
import PrivacyPolicy from './PrivacyPolicy.js'
import {
    Link,
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate,
} from "react-router-dom";
import Guidelines from './Guidelines';
import { hasChildren } from './Utils';
import TermsAndCondition from './TermsAndConditions';

export default function Policies() {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [activeClick, setActiveClick] = useState('Guidelines & Policies')

    const sideOptions = [
        {
            title: "Guidelines & Policies",
            to: ""
        },
        {
            title: "Privacy Policy",
            items: [
                {
                    title: "Gativan Ops App",
                    to: "privacy/gativanopsapp"
                }
            ]
        },
        {
            title: "Terms",
            items: [
                {
                    title: "Gativan Ops App",
                    to: "terms-of-service/gativanopsapp"
                }
            ]
        },
    ]

    const handleDrawerToggle = (title) => {
        setMobileOpen(!mobileOpen);
        title && setActiveClick(title)
    };

    const handleActiveClick = (title) => {
        setActiveClick(title)
    }

    

    return (
        <React.Fragment>
            <Box className="policy-container" >
                <Box className='sidebar' >
                    <List
                        // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Nested List Items
                    //     </ListSubheader>
                    // }
                    >
                        {
                            sideOptions?.map((item, key) => {
                                return (
                                    <MenuItem
                                        key={key}
                                        item={item}
                                        handleDrawerToggle={handleDrawerToggle}
                                        mobileOpen={mobileOpen}
                                        activeClick={activeClick}
                                        handleActiveClick={handleActiveClick}
                                    />
                                )
                            })
                        }
                    </List>
                </Box>
                <Box sx={{ marginLeft: "20px" }} className="main-content">
                    <Routes>
                        <Route path="/" element={<Guidelines />} />
                        <Route path='/terms-of-service/gativanopsapp' element={<TermsAndCondition />} />
                        <Route path='/privacy/gativanopsapp' element={<PrivacyPolicy />} />
                    </Routes>
                </Box>

                {/* <TermsAndCondition/>
                <PrivacyPolicy/> */}
            </Box>
        </React.Fragment>
    )
}


const MultiLevel = ({ item, handleDrawerToggle, mobileOpen, activeClick, handleActiveClick }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment className="sidebar-component">
            <ListItemButton onClick={handleClick} sx={{color:item.title == activeClick? '#122164' : '#000000', backgroundColor:item.title == activeClick? '#F8F8F8' : '', fontWeight:400}} >
                <ListItemText primary={item.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child, key,event) => (
                        <Link to={child.to}  className="sidebar-link"  >
                            <ListItemButton
                                sx={{ pl: 4, color:item.title == activeClick? '#122164' : '#000000', backgroundColor:item.title == activeClick? '#F8F8F8' : '', fontWeight:700 }}
                                onClick={(event)=>handleActiveClick(item.title)}
                            >
                                <ListItemText primary={child.title} sx={{fontWeight:'700'}} />
                            </ListItemButton>
                        </Link>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

const SingleLevel = ({
    item,
    handleDrawerToggle,
    mobileOpen,
    activeClick,
    disableLinks,
    handleActiveClick
}) => {
    return (
        <ListItemButton  style={{color:item.title == activeClick? '#122164' : '#000000', backgroundColor:item.title == activeClick? '#F8F8F8' : '', fontWeight:400}} onClick={(event)=>handleActiveClick(item.title)}>
            <Link
                to={item.to}
                className="sidebar-link"
                onClick={mobileOpen && handleDrawerToggle}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
            </Link>
        </ListItemButton>
    );
};

const MenuItem = ({ item, handleDrawerToggle, mobileOpen, activeClick, disableLinks, handleActiveClick }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return (
        <Component
            item={item}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
            activeClick={activeClick}
            disableLinks={disableLinks}
            handleActiveClick={handleActiveClick}
        />
    );
};
