import { React, useState, useContext, useEffect } from "react";
import "./Accessories.css";
import { Box, Grid, Button, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, handleChangeVehicelComponent } from "../../action";

// import { AppContext } from '../../../App';
import { AppContext } from "../../App";
export default function Accessories({ serviceData }) {
  const addtocartbtn = () => {
    ReactGA.event({
      category: "Accessories",
      action: "clicked on Car Duster Add to Cart ",
      label: "Car Duster Add to Cart ",
    });
  };
  const [counter, setCounter] = useState(0);
  //increase counter
  const increase = () => {
    setCounter((count) => count + 1);
  };
  //decrease counter
  const decrease = () => {
    setCounter((count) => count - 1);
  };

 
  return (
    <>
      <Box className="accessories-image-container">
        <Box className="accessories-background-image"></Box>
        <Box className="accessories-card">
          <Box className="accessories-contain">
            <strong>JUST ONE SWIPE DUST AWAY</strong>
            <p>
              The possession of a new vehicle comes with it a flurry of
              additional needs, all aimed at making the vehicle more trendy and
              competent. Car accessories include all such innovative and
              practical gadgets & products designed to personalise your car and
              make it stand out from the crowd.
            </p>
          </Box>
        </Box>
      </Box>
      <Box className="accessories-main-contain">
        <Grid container>
          <Grid xs={12} md={6} sm={6}>
            <Box>
              <img
                width="100%"
                height="100%"
                src={
                  process.env.PUBLIC_URL + "/Assets/accessories/car duster.png"
                }
                alt=""
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} sm={6} className="accessories-desp">
            <p>
              Gativan wax-coated car duster is the best cleaning and maintaining
              the car & home appliances. It is known for cleansing the dust in
              one swipe. It is easy-to-use product and removes dust quickly
              without leaving a scratch. Also, it is lightweight - makes the
              cleaning experience easy and effective.
            </p>
            <br />
            <strong>Features & Benefits</strong>
            <ul>
              <li>
                Car Duster is concentrated to special paraffin wax to remove
                dust in one swipe.
              </li>
              <li>
                Cleanse the dust without leaving any scratch on the surface of
                the car or any other appliance and gives a new look appearance.
              </li>
              <li>Made of safe and non-toxic materials.</li>
              <li>Long-lasting, build to use for years.</li>
            </ul>
            <Box className="accessories-price">
              {" "}
              <Box className="serviceCard-pricesSection">
                <Typography className="serviceCard-displayPrice">
                  <CurrencyRupeeIcon className="displayPrice-Rupee-icon" />
                  850
                </Typography>
                <Typography className="serviceCard-actualPrice">
                  <CurrencyRupeeIcon className="actualPrice-Rupee-icon" />
                  550
                </Typography>
              </Box>
            </Box>

            <span className="btn__container">
              <Box className="control__btn" onClick={decrease}>
                -
              </Box>
              <Box className="counter__output">{counter}</Box>
              <Box className="control__btn" onClick={increase}>
                +
              </Box>
            </span>

            <Button
              variant="contained"
              className="add-to-cart-btn"
              onClick={addtocartbtn}
            >
              Add To Cart
            </Button>
          </Grid>
        </Grid>
        <Box className="duster-image">
          <img
            width="100%"
            height="100%"
            src={
              process.env.PUBLIC_URL + "/Assets/accessories/Duster Section.png"
            }
          />
        </Box>
        <Box className="contact-more-info">
          <strong>For More Info</strong>
          <Box className="contact-us-email">
            <span className="contact-info">
              <strong>Phone :</strong>
              <strong> +91 8263090692 , 9960235135</strong>
            </span>
            <br />
            <span className="contact-info">
              <strong>Email :</strong>
              <strong> gativanapp@gmail.com</strong>
            </span>
          </Box>
          <a href="tel:+918263090692" style={{ textDecoration: "none" }}>
            <Button variant="contained" className="call-me-btn">
              <PhoneInTalkIcon /> +918263090692
            </Button>{" "}
          </a>
        </Box>
      </Box>
    </>
  );
}
