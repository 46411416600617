import { Box, Button, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { React, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { useParams } from "react-router-dom";
import "./WorkShop.css";

function WorkShop() {


  // const { location } = useParams();

  const workshop_location = [
    {
      id: 1,
      garagename: "Gativan Garage - Yerawada",
      garageaddress: "Shop No. 6, 103/1A/125/P No. 2",
      location:
        "Corner View Co-Op Housing Society, Yerawada Pune, Maharashtra 411006",
      mobileno: "+91 8263090692",
    },
  ];



  // const locationMetaTags = [
  //   {
  //     id: 1,
  //     url: "garage-near-me-kharadi",
  //     title: "Best Bike and Car service centre in Kharadi, Pune | Car Repair | Car Services in Kharadi, Pune - Gativan.",
  //     description: "Book Car and Bike Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online Best Service Centres in Kharadi, Pune."
  //   },
  //   {
  //     id: 2,
  //     url: "garage-near-me-viman-nagar",
  //     title: "Best Car and Bike Service | Car Repair | Car Cleaning in Viman Nagar, Pune - Gativan",
  //     description: "Book Car and Bike Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online Best Service Centres in Viman Nagar, Pune."
  //   },
  //   {
  //     id: 3,
  //     url: "garage-near-me-kalyani-nagar",
  //     title: "Best Bike and Car service centre in Kalyani nagar| Car Repair | Car Services in Kalyani nagar, Pune - Gativan.",
  //     description: "Book Car and Bike Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online Best Service Centres in Kalyani nagar, Pune."
  //   },
  //   {
  //     id: 4,
  //     url: "garage-near-me-koregaon-park",
  //     title: "Best Bike and Car service centre in Koregaon Park, Pune | Car Repair | Car Services in Koregaon Park, Pune - Gativan.",
  //     description: "Book Car and Bike Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online Best Service Centres in Koregaon Park, Pune."
  //   },
  // ]



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop()
  }, []);


  return (
    <>
      <Helmet>
        <title>Workshop - Gativan</title>
        <meta name="description"
          content="Visit our workshop where anyone can become a expert mechanic. Learn everything about car and bike with our experts. Visit website now." />
      </Helmet>
      <Box className="workshop_container">
        {/*--------------gativan workshop-----------*/}
        <Box className="workshop">
          <h4>Gativan Workshop in pune</h4>
        </Box>
        {/*----------workshop_location ----------*/}
        <Grid container>
          <Grid xs={12}>
            <Box className="workshop_location">
              <Grid container>
                {/*---------------locations box---------------------*/}
                {workshop_location.map((item) => (
                  <Grid item xs={12} sm={4} md={4} key={item?.id}>
                    <Box className="loactions_box">
                      <Box className="heading">
                        <Box className="gativan"> Gativan - </Box>
                        <Box className="garage-name">{item.garagename}</Box>
                      </Box>
                      <Divider />
                      <Box className="garage-info">
                        <Box>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/workshop/vehicle.png"
                            }
                            alt=""
                          />
                        </Box>
                        <Box className="information">{item.garageaddress}</Box>
                      </Box>
                      <Box className="garage-info">
                        <Box>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/workshop/flag.png"
                            }
                            alt=""
                          />
                        </Box>
                        <Box className="information">{item.location}</Box>
                      </Box>
                      <Box className="garage-info">
                        <Box>
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/workshop/phone.png"
                            }
                            alt=""
                          />
                        </Box>
                        <Box className="information">{item.mobileno}</Box>
                      </Box>
                      <a href="/">
                        <Button className="locator-book-now-btn">
                          Book Now
                        </Button>
                      </a>
                      <Box className="service-location-btn">
                        <Grid container>
                          <Grid xs={6} md={6}>
                            <a href="/">
                              <Button
                                variant="outlined"
                                className="services-btn"
                              >
                                Services
                              </Button>
                            </a>
                          </Grid>
                          <Grid xs={6} md={6}>
                            <a href="https://goo.gl/maps/p2AVZQhLkVnmhsWeA">
                              <Button
                                variant="outlined"
                                className="location-btn"
                              >
                                Location
                              </Button>
                            </a>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          {/*----------Map ----------*/}
          <Grid xs={12}>
            <a href="https://goo.gl/maps/p2AVZQhLkVnmhsWeA">
              <Box className="map">
                <img
                  className="map-locaton"
                  src={process.env.PUBLIC_URL + "/Assets/workshop/Workshop.png"}
                  alt=""
                />
              </Box>
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default WorkShop;
