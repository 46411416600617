import React from 'react'
import { Box, } from '@mui/material';
import "./CarouselComponent.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardContent, Grid } from '@mui/material';

export default function CarouselComponent({ CarouselData }) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 50 // this is needed to tell the amount of px that should be visible.
        }
    }


    return (
        <>
            <Box container className="carousel-container">
                <Carousel
                    // infinite
                    partialVisible={true}
                    responsive={responsive}
                    autoPlaySpeed={10000}
                    containerClass="container"
                    className="Carousel"
                    additionalTransfrom={0}
                >
                    {CarouselData.map((step , index) => {
                        return (
                            <Box container key={index}>
                                <Box container className="carousel-content-container" >
                                    <Box container className="carousel-image-container">
                                        <img src={process.env.PUBLIC_URL + `${step.img}`} />
                                    </Box>
                                    <Box container className="carousel-text-container">
                                        <h4>{step.title}</h4>
                                        <p>{step.description}</p>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Carousel>
            </Box>
        </>
    )
}



