import React, { useState, useEffect, useContext } from "react";
import "./Footer.css";
import { Box, Grid } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../App";
import {
  handleChangeVehicelComponent,
  handleOpenModalState,
} from "../../action";
import axios from "axios";
import httpCommon from "../../http-common";

export default function Footer() {
  const AppContextData = useContext(AppContext);
  const dispatch = useDispatch();

  const { customerPickData, setCustomePickData } = AppContextData;

  const d = new Date();
  const year = d.getFullYear();

  // getting currect location from redux
  const currentLocation = useSelector((state) => state.LocationReducer);

  const citynew = localStorage.getItem("cityName123");
  const [popularArea, setPopularArea] = useState([]);
  const URL = httpCommon.defaults.baseURL;

  let popularAreaCity = "";

  if (citynew) {
    popularAreaCity = citynew.replaceAll("-", " ");
  }

  // let matchcity = "";

  // if (citynew) {
  //   matchcity = citynew.replace(/-/g, ' ');
  // }

  // console.log("popularAreaCity",popularAreaCity)
  // console.log("matchcity",matchcity)

  // if (popularArea && popularArea.length > 0) {
  //   console.log(popularArea[0].city);
  // } else {
  //   console.log("Popular area is either undefined or empty.");
  // }

  useEffect(() => {
    axios
      .get(`${URL}/area/${popularAreaCity}`)
      .then((response) => {
        const popularAreaArray = response.data.result;
        setPopularArea(popularAreaArray);
      })
      .catch((error) => {
        console.error("Error fetching unique cities:", error);
      });
  }, [currentLocation]);

  const aboutusData = [
    {
      id: 1,
      name: "FAQs",
      url: "faq",
    },
    {
      id: 2,
      name: "Contact Us",
      url: "contact-us",
    },
    {
      id: 3,
      name: "Careers",
      url: "",
    },
    {
      id: 4,
      name: "Terms and Conditions",
      url: "policies",
    },
    {
      id: 5,
      name: "Partner with Gativan",
      url: "partner-with-us",
    },
    {
      id: 6,
      name: "Workshop Locator",
      url: "workshop",
    },
    // {
    //   id: 7,
    //   name: "Bike Offers",
    //   url: "offers/bike-services",
    // },
    {
      id: 8,
      name: "Car Offers",
      url: "offers/car-services",
    },
    {
      id: 9,
      name: "Book a Service",
      url: "car-and-bike-servicing-near-me",
    },
    {
      id: 10,
      name: "Reviews",
      url: "",
    },
    {
      id: 11,
      name: "Directory",
      url: "",
    },
  ];

  const ourServicesData = [
    {
      id: 1,
      serviceName: "Periodic Services",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/periodic-services`,
    },
    {
      id: 2,
      serviceName: "AC Services",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/ac-service-and-repair/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/ac-service-and-repair`,
    },
    {
      id: 3,
      serviceName: "Denting & Painting",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/denting-and-painting/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/denting-and-painting`,
    },
    {
      id: 4,
      serviceName: "Batteries",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/batteries/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/batteries`,
    },
    {
      id: 5,
      serviceName: "Tyres & Wheels",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/wheel-and-tyre-care/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/wheel-and-tyre-care`,
    },
    {
      id: 6,
      serviceName: "Car SPA",
      url:
        customerPickData?.variant?.variantName &&
        customerPickData?.variant?.variantName !== ""
          ? `${currentLocation.cityName?.toLowerCase()}/car/car-cleaning/${customerPickData?.brand?.brandName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
              ?.toLowerCase()
              .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
              ?.toLowerCase()
              .replaceAll(" ", "-")}`
          : `${currentLocation.cityName?.toLowerCase()}/car/car-cleaning`,
    },
    // {
    //   id: 7,
    //   serviceName: "Bike Periodic Service",
    //   url:
    //     customerPickData?.variant?.variantName &&
    //     customerPickData?.variant?.variantName !== ""
    //       ? `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/${customerPickData?.brand?.brandName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}`
    //       : `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services`,
    // },
    // {
    //   id: 8,
    //   serviceName: "Bike Batteries",
    //   url:
    //     customerPickData?.variant?.variantName &&
    //     customerPickData?.variant?.variantName !== ""
    //       ? `${currentLocation.cityName?.toLowerCase()}/bike/batteries/${customerPickData?.brand?.brandName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}/${customerPickData?.model?.modelName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}/${customerPickData?.variant?.variantName
    //           ?.toLowerCase()
    //           .replaceAll(" ", "-")}`
    //       : `${currentLocation.cityName?.toLowerCase()}/bike/batteries`,
    // },
  ];

  const popularBikeBrands = [
    {
      id: 1,
      brandName: "Ampere Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/ampere`,
    },
    {
      id: 2,
      brandName: "Aprilia Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/aprilia`,
    },
    {
      id: 3,
      brandName: "Avan Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/avan`,
    },
    {
      id: 4,
      brandName: "Bajaj Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/bajaj`,
    },
    {
      id: 5,
      brandName: "Hero Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hero`,
    },
    {
      id: 6,
      brandName: "Hero Electric Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hero-electric`,
    },
    {
      id: 7,
      brandName: "Hero Honda Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hero-honda`,
    },
    {
      id: 8,
      brandName: "Honda Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/honda`,
    },
    {
      id: 9,
      brandName: "Jawa Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/jawa`,
    },
    {
      id: 10,
      brandName: "Kinetic Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/kinetic`,
    },
    {
      id: 11,
      brandName: "Mahindra Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/mahindra`,
    },
    {
      id: 12,
      brandName: "Revolt Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/revolt`,
    },
    {
      id: 13,
      brandName: "Suzuki Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/suzuki`,
    },
    {
      id: 14,
      brandName: "Tork Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/tork`,
    },
    {
      id: 15,
      brandName: "Triumph Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/triumph`,
    },
    {
      id: 16,
      brandName: "TVS Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/tvs`,
    },
    {
      id: 17,
      brandName: "Yamaha Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/yamaha`,
    },
    {
      id: 18,
      brandName: "YObykes Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/yobykes`,
    },
  ];

  const popularCarBrands = [
    {
      id: 1,
      brandName: "Chevrolet Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/chevrolet`,
    },
    {
      id: 2,
      brandName: "Datsun Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/datsun`,
    },
    {
      id: 3,
      brandName: "Fiat Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/fiat`,
    },
    {
      id: 4,
      brandName: "Force Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/force`,
    },
    {
      id: 5,
      brandName: "Ford Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/ford`,
    },
    {
      id: 6,
      brandName: "Honda Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/honda`,
    },
    {
      id: 7,
      brandName: "Hyundai Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/hyundai`,
    },
    {
      id: 8,
      brandName: "Kia Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/kia`,
    },
    {
      id: 9,
      brandName: "Mahindra Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mahindra`,
    },
    {
      id: 10,
      brandName: "Maruti Suzuki Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/maruti-suzuki`,
    },
    {
      id: 11,
      brandName: "MG Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mg`,
    },
    {
      id: 12,
      brandName: "Nissan Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/nissan`,
    },
    {
      id: 13,
      brandName: "Renault Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/renault`,
    },
    {
      id: 14,
      brandName: "Skoda Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/skoda`,
    },
    {
      id: 15,
      brandName: "Tata Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/tata`,
    },
    {
      id: 16,
      brandName: "Toyota Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/toyota`,
    },
    {
      id: 17,
      brandName: "Volkswagen Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/volkswagen`,
    },
    {
      id: 18,
      brandName: "Hindustan Motors Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/hindustan-motors`,
    },
  ];

  const luxuryCarBrands = [
    {
      id: 1,
      brandName: "Audi Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/audi`,
    },
    {
      id: 2,
      brandName: "Bentley Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/bentley`,
    },
    {
      id: 3,
      brandName: "BMW Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/bmw`,
    },
    {
      id: 4,
      brandName: "Citroen Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/citroen`,
    },
    {
      id: 5,
      brandName: "Ferrari Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/ferrari`,
    },
    {
      id: 6,
      brandName: "Isuzu Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/isuzu`,
    },
    {
      id: 7,
      brandName: "Jaguar Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/jaguar`,
    },
    {
      id: 8,
      brandName: "Jeep Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/jeep`,
    },
    {
      id: 9,
      brandName: "Jawa Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/jawa`,
    },
    {
      id: 10,
      brandName: "Lamborghini Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/lamborghini`,
    },
    {
      id: 11,
      brandName: "Land Rover Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/land-rover`,
    },
    {
      id: 12,
      brandName: "Lexus Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/lexus`,
    },
    {
      id: 13,
      brandName: "Mercedes Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mercedes`,
    },
    {
      id: 14,
      brandName: "Mini Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mini`,
    },
    {
      id: 15,
      brandName: "Mitsubishi Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mitsubishi`,
    },
    {
      id: 16,
      brandName: "Porsche Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/porsche`,
    },
    {
      id: 17,
      brandName: "Rolls Royce Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/rolls-royce`,
    },
    {
      id: 18,
      brandName: "Volvo Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/volvo`,
    },
  ];

  const luxuryBikeBrands = [
    {
      id: 1,
      brandName: "Royal Enfield Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/royal-enfield`,
    },
    {
      id: 2,
      brandName: "KTM Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/ktm`,
    },
    {
      id: 3,
      brandName: "Benelli Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/benelli`,
    },
    {
      id: 4,
      brandName: "BMW Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/bmw`,
    },
    {
      id: 5,
      brandName: "Ducati Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/ducati`,
    },
    {
      id: 6,
      brandName: "Harley Davidson Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/harley-davidson`,
    },
    {
      id: 7,
      brandName: "Husqvarna Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/husqvarna`,
    },
    {
      id: 8,
      brandName: "Hyosung Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hyosung`,
    },
    {
      id: 9,
      brandName: "Kawasaki Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/kawasaki`,
    },
    {
      id: 10,
      brandName: "MV Agusta Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/mv-agusta`,
    },
    {
      id: 11,
      brandName: "Okinawa Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/okinawa`,
    },
    {
      id: 12,
      brandName: "Piaggio Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/piaggio`,
    },
    {
      id: 13,
      brandName: "Yezdi Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/yezdi`,
    },
  ];

  const carBrandsData = [
    {
      id: 1,
      brandName: "Maruti Suzuki Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/maruti-suzuki`,
    },
    {
      id: 2,
      brandName: "Hyundai Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/hyundai`,
    },
    {
      id: 3,
      brandName: "Honda Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/honda`,
    },
    {
      id: 4,
      brandName: "Toyota Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/toyota`,
    },
    {
      id: 5,
      brandName: "Tata Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/tata`,
    },
    {
      id: 6,
      brandName: "Mahindra Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/mahindra`,
    },
    {
      id: 7,
      brandName: "Chevrolet Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/chevrolet`,
    },
    {
      id: 8,
      brandName: "Fiat Car Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/car/periodic-services/fiat`,
    },
  ];

  const bikeBrandsData = [
    {
      id: 1,
      brandName: "Bajaj Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/bajaj`,
    },
    {
      id: 2,
      brandName: "Benelli Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/benelli`,
    },
    {
      id: 3,
      brandName: "Ducati Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/ducati`,
    },
    {
      id: 4,
      brandName: "EVO Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/evo`,
    },
    {
      id: 5,
      brandName: "Harley Davidson Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/harley-davidson`,
    },
    {
      id: 6,
      brandName: "Hero Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hero`,
    },
    {
      id: 7,
      brandName: "Hero Honda Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/hero-honda`,
    },
    {
      id: 8,
      brandName: "Honda Bike Servicing",
      url: `${currentLocation.cityName?.toLowerCase()}/bike/periodic-services/honda`,
    },
  ];

  // const areaBangalore = [
  //   {
  //     id: 1,
  //     areaName: "Electronic City",
  //     url: "electronic-city",
  //   },
  //   {
  //     id: 2,
  //     areaName: "Whitefield",
  //     url: "whitefield",
  //   },
  //   {
  //     id: 3,
  //     areaName: "Indira Nagar",
  //     url: "indira-nagar",
  //   },
  //   {
  //     id: 4,
  //     areaName: "Vijay Nagar",
  //     url: "vijay-nagar",
  //   },
  //   {
  //     id: 5,
  //     areaName: "HSR Layout",
  //     url: "hsr-layout",
  //   },
  //   {
  //     id: 6,
  //     areaName: "Koramangala",
  //     url: "koramangala",
  //   },
  //   {
  //     id: 7,
  //     areaName: "Jaya Nagar",
  //     url: "jaya-nagar",
  //   },
  //   {
  //     id: 8,
  //     areaName: "Marathahalli",
  //     url: "marathahalli",
  //   },
  // ];

  const contactSectionData = [
    {
      img: "email icon.png",
      name: "Email",
      details: "info@kalyanistudio.com",
    },
    {
      img: "phone icon.png",
      name: "Phone",
      details: "+91 8983104501",
    },
    {
      img: "calendar icon.png",
      name: "working Days",
      details: "Monday to Sunday",
    },
    {
      img: "clock icon.png",
      name: "Working Hours",
      details: "9 AM to 6 PM (IST)",
    },
  ];

  const [isDesktop, setDesktop] = useState(window.innerWidth >= 768);

  const navigation = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAboutUsNavigation = (url) => {
    if (
      url.includes("offers/bike-services") ||
      url.includes("offers/car-services") ||
      url.includes("car-and-bike-servicing-near-me")
    ) {
      // If it's one of the specified URLs, open it in a new tab
      window.open(url, "_blank");
    } else {
      // If it's an internal link, navigate using react-router-dom
      navigation(`/${url}`);
      scrollToTop();
    }
  };
  
  

  const handleOurServicesNavigation = (url) => {
    navigation(`/${url}`);
    scrollToTop();
  };

  const handleCarBrandsNavigation = (url) => {
    navigation(`/${url}`);
    scrollToTop();
    dispatch(handleChangeVehicelComponent(true));
    dispatch(handleOpenModalState(false));
  };

  const handleBikeBrandsNavigation = (url) => {
    navigation(`/${url}`);
    scrollToTop();
    dispatch(handleChangeVehicelComponent(true));
    dispatch(handleOpenModalState(false));
  };

  const handleLocationNavigation = (url) => {
    navigation(`/${url.toLowerCase()}`);
    scrollToTop();
  };

  // console.log("Hiiiiiiii",citynew.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace(/-([a-z])/g, (_, letter) => ` ${letter.toUpperCase()}`))
  // console.log("Byeeeeeeeee",uuuu)

  return (
    <Box container>
      <Box>
        {isDesktop ? (
          <Box container className="menu-section">
            <Box container className="about-us-container section">
              <h2>About Us</h2>
              {aboutusData.map((item, index) => {
                return (
                  <h4
                    key={index}
                    onClick={() => {
                      handleAboutUsNavigation(item?.url);
                    }}
                  >
                    {item.name}
                  </h4>
                );
              })}
            </Box>
            <Box container className="our-services-container section">
              <h2>Our Services</h2>
              {ourServicesData.map((item, index) => {
                return (
                  <h4
                    key={index}
                    onClick={() => {
                      handleOurServicesNavigation(item?.url);
                    }}
                  >
                    {item.serviceName}
                  </h4>
                );
              })}
            </Box>
            <Box container className="car-brands-container section">
              <h2>Car Brands</h2>
              {carBrandsData.map((item, index) => {
                return (
                  <h4
                    key={index}
                    onClick={() => {
                      handleCarBrandsNavigation(item?.url);
                    }}
                  >
                    {item.brandName}
                  </h4>
                );
              })}
            </Box>
            {/* <Box container className="bike-brands-container section">
              <h2>Bike Brands</h2>
              {bikeBrandsData.map((item, index) => {
                return (
                  <h4
                    key={index}
                    onClick={() => {
                      handleBikeBrandsNavigation(item?.url);
                    }}
                  >
                    {item.brandName}
                  </h4>
                );
              })}
            </Box> */}

            {/* Footer location change code */}

            {/* Footer location change code for web*/}

            <Box container className="area-container section">
              <h2>Popular Areas Near You</h2>
              {popularArea ? (
                popularArea.map((item, index) => (
                  <h4
                    key={index}
                    onClick={() => {
                      handleLocationNavigation(item?.url);
                    }}
                  >
                    Garage near me in {item.area}
                  </h4>
                ))
              ) : (
                <Box container className="area-container section">
                  <h2>Popular Areas Near You</h2>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box className="accordian-container">
            <Accordion
              className="accordion"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  About Us
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {aboutusData.map((item, index) => {
                  return (
                    <Typography
                      onClick={() => {
                        handleAboutUsNavigation(item?.url);
                      }}
                      key={index}
                      className="accordion-details"
                    >
                      {item.name}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordion"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  Our Services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {ourServicesData.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      onClick={() => {
                        handleOurServicesNavigation(item?.url);
                      }}
                      className="accordion-details"
                    >
                      {item.serviceName}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordion"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  Popular Car Brands
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {popularCarBrands.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      onClick={() => {
                        handleCarBrandsNavigation(item?.url);
                      }}
                      className="accordion-details"
                    >
                      {item.brandName}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordion"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  Luxury Car Brands
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {luxuryCarBrands.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      onClick={() => {
                        handleCarBrandsNavigation(item?.url);
                      }}
                      className="accordion-details"
                    >
                      {item.brandName}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            {/* <Accordion
              className="accordion"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  Popular Bike Brands
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {popularBikeBrands.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      onClick={() => {
                        handleBikeBrandsNavigation(item?.url);
                      }}
                      className="accordion-details"
                    >
                      {item.brandName}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion> */}
            <Accordion
              className="accordion"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  Luxury Bike Brands
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {luxuryBikeBrands.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      onClick={() => {
                        handleBikeBrandsNavigation(item?.url);
                      }}
                      className="accordion-details"
                    >
                      {item.brandName}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordion"
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              {/* Dynamic location footer code for mobile */}
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography
                  className="accordion-summary"
                  sx={{ width: "60%", flexShrink: 0 }}
                >
                  Popular Areas Near You
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {popularArea
                  ? popularArea.map((item, index) => (
                      <Typography
                        key={index}
                        className="accordion-details"
                        onClick={() => {
                          handleLocationNavigation(item?.url);
                        }}
                      >
                        Garage near me in {item.area}
                      </Typography>
                    ))
                  : null}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
      <Box container className="gativan-info-section" id="footer">
        <Grid container columns={12}>
          <Grid item xs={12} md={9} className="gativan-contact-grid">
            <Box className="gativan-logo-info-container">
              <Box container className="gativan-full-logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/footer/Gativan Full Logo White.png"
                  }
                />
              </Box>
              <Box container className="gativan-desc">
                <p>
                  Gativan is a platform for bike and car repair and maintenance.
                  We cover all vehicle brands for scooters, motorcycles,
                  sedans,hatchbacks, SUV. Our mechanics and garages are trained
                  for best servicing techniques.{" "}
                </p>
              </Box>
              <Box className="social-site-logo">
                <a href="https://ne-np.facebook.com/ksGativan">
                  <img
                    className="facebook-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/facebook icon.png"
                    }
                    alt="Gativan Facebook Profile"
                  />
                </a>
                <a href="https://twitter.com/Gativanapp">
                  <img
                    className="twitter-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/twitter icon.png"
                    }
                    alt="Gativan Twitter  Profile"
                  />
                </a>
                <a href="https://www.instagram.com/mygativan/">
                  <img
                    className="instagram-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/instagram icon.png"
                    }
                    alt="Gativan Instagram Profile"
                  />
                </a>
              </Box>
            </Box>
            <Box container className="contact-section-container">
              <Box container className="contact-section email-section">
                <Box container className="contact-section-image-container">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/email icon.png"
                    }
                  />
                </Box>
                <Box conatainer className="contact-section-key-container">
                  <h4>Email:</h4>
                </Box>
                <Box conatainer className="contact-section-value-container">
                  <h5>info@kalyanistudio.com</h5>
                </Box>
              </Box>
              <Box container className="contact-section phone-section">
                <Box container className="contact-section-image-container">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/phone icon.png"
                    }
                  />
                </Box>
                <Box conatainer className="contact-section-key-container">
                  <h4>Phone:</h4>
                </Box>
                <Box conatainer className="contact-section-value-container">
                  <h5>+91 8263090692</h5>
                </Box>
              </Box>
              <Box container className="contact-section calendar-section">
                <Box container className="contact-section-image-container">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/calendar icon.png"
                    }
                  />
                </Box>
                <Box conatainer className="contact-section-key-container">
                  <h4>Working Days:</h4>
                </Box>
                <Box conatainer className="contact-section-value-container">
                  <h5>Monday to Sunday</h5>
                </Box>
              </Box>
              <Box container className="contact-section clock-section">
                <Box container className="contact-section-image-container">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/footer/clock icon.png"
                    }
                  />
                </Box>
                <Box conatainer className="contact-section-key-container">
                  <h4>Working Hours:</h4>
                </Box>
                <Box conatainer className="contact-section-value-container">
                  <h5>9 AM to 6 PM (IST)</h5>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box container className="kalyani-studio-logo-container">
              <Box container className="kalyani-studio-logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/footer/kalyani studio logo.png"
                  }
                />
              </Box>
              <Box container className="google-play-logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/footer/google play logo.png"
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box container className="copyright-container">
        <p>© 2016 - {year} Copyright: KStudio Solutions Private Limited</p>
      </Box>
    </Box>
  );
}
